// src/components/ConfirmationPage.js
import React, { useEffect, useState } from 'react';
import './Confim.css'; // Import your styles
import { useNavigate, useParams } from 'react-router-dom';
import { url } from '../../env';
import { SuccessMdoel } from './SuccessModel';
import BiziverseCRM from '../../Dashboard/BiziverseCRM';
import { FaCheckCircle } from 'react-icons/fa';

const ConfirmationPage = () => {

    const { id } = useParams();
    const changeAds = useParams();
  
    //after sucess
    const [modalAfterSuccess, setModalAfterSuccess] = useState(false);
    const [successObj, setSuccessObj] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
      console.log(id);
      if (id && localStorage.getItem("planValue") != null) {
        // let data1 = document.getElementById("plan").value;
        let data = JSON.parse(localStorage.getItem("planValue"));
        localStorage.removeItem("planValue");
        // console.log(data, "HELOWORLD", data1);
        debugger;
        // let data= document.getElementById("plan").value
        var bodyData = new FormData();
        bodyData.append("layer_pay_token_id", data.data["token_id"]);
        bodyData.append("tranid", data.data["txnid"]);
        bodyData.append("layer_order_amount", data.data["amount"]);
        bodyData.append("layer_payment_id", id);
        bodyData.append("hash", data.data["hash"]);
        console.log("callback calling", bodyData);
  
        var requestOptions = {
          method: "POST",
          body: bodyData,
          redirect: "follow",
        };
  
        fetch(url + "api/payment/callback", requestOptions)
          .then((response) => response.json())
          .then((result) => {
            debugger;
            console.log("aftersuccess", result);
            setModalAfterSuccess(true);
            setSuccessObj(result);
            console.log("successObj:", successObj);
            // navigate("/dashboard");
          })
          .catch((error) => console.log("error", error));
      }
    }, []);
 

  return (
    <>
   <div className="confirmation-page">
      <FaCheckCircle className="success-icon" />
      <h1>Payment Successful</h1>
      <p>Your payment has been successfully processed.</p>
      <p>Thank you for your purchase!</p>
      <button
                    className="animated-button"
                    onClick={() => navigate('/')} // Navigate to the home page
                >
                    Go to Home
                </button>
    </div>
    
     {modalAfterSuccess && <SuccessMdoel props={successObj}/>}
     <BiziverseCRM />

     </>
  );
};

export default ConfirmationPage;
