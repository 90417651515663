import "./About.scss";
import logo from "../../images/Hola9logo.jpg";
import Box from '@mui/material/Box';
import YouTubeChannel from "../../Ads/Video/youTubeChannel";
import ScrollButton from "../../ScrollTop/ScrollButton";
import Cwe from "../Contact/Cwe";
import { isMobile } from "react-device-detect";
import { AiOutlineCloseSquare } from "react-icons/ai";
import React, { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../../Base/Help.css";
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Link } from "react-router-dom";
import { AiOutlineFacebook } from "react-icons/ai";
import { AiOutlineInstagram } from "react-icons/ai";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { FaTwitter } from "react-icons/fa";
import { BsTelegram } from "react-icons/bs";
import Slider from 'react-slick';
import CityMap from "./CityMap";

// Styling object for mobile devices
const mobileStyle = {
  width: "100%",
  justifyContent: "center",
};

// Styling object for desktop devices
const desktopStyle = {
  marginRight: "60px",
};

const mobistyle = {
  width: "90%",
  marginTop: "1rem",
  marginLeft: "5%",
  marginRight: "5%",
  display: "flex",
  flexDirection: "column",
  border: "1px solid gray"
}

const deskistyle = {
  width: "90%",
  marginTop: "1rem",
  marginLeft: "5%",
  marginRight: "5%",
  display: "flex",
  flexDirection: "row",
  border: "1px solid gray"
}
const mobi1style = {
  width: "100%",
}
const deski1style = {
  width: "40%",
}
const mobi2style = {
  width: "100%",
}
const deski2style = {
  width: "60%",
  margin: "auto 2rem",
  // backgroundColor: "gray"
}
const linky = {
  fontSize: "30px",
  margin: "10px"
}

const hmob = {
  height: "auto",
  marginRight: "10px",
  marginLeft: "0px",
  marginTop: "80px",
};

// Styling for logo and company info section on desktop devices
const hdesk = {
  height: "470px",
  marginTop: "80px",
};


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const About = () => {
  // Set the document title
  const [open, setOpen] = useState(false);
  document.title = "Hola9 - About Us";

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <main id="main" className="site-main">
        <div
          className="container-fluid bgcolor"
          style={{
            display: "flex",
            justifyContent: "center",
            height: "90px",
          }}
        >
          {/* Render the title for the About page */}
          <p style={{ color: "white", paddingTop: "10px", fontSize: "30px" }}>
            About Us
          </p>{" "}
        </div>
        <>
          <div className="container">
            <div className="company-info flex-inline">

              <img src={logo} alt="Our Company" />
              <div className="ci-content">

                <h3><b>Our story begins with you</b></h3>
                <p>
                  Our Vision is to make Hola9 one stop solution for local
                  businesses, classifieds and largest business-to-business
                  marketplace for India Where you can get business to business,
                  Business to Customer and Customer to customer services at one
                  place <ins><b>E-Commerce Platform</b></ins>, focuses on expert services around
                  Home, Life and Self and where the user need is customized
                </p>
              </div>
            </div>
          </div>

        </>
        <div className="site-content">
          <div className="container">
            <div className="join-team align-center"></div>

            <div className="join-team align-center">
              <div className="" style={{ width: "100%" }}>

                <h2 className="title" style={{ color: "red" }}>Join our team</h2>
                <div className="jt-content mt-2 mb-2">
                  <h6>
                    We’re always looking for talented individuals and <br />
                    people who are hungry to do great work.
                  </h6>

                  <>
                    <p>
                      {/* Render the "View Openings" button */}
                      <a
                        className="button text-white mx-auto my-auto"
                        onClick={handleOpen}
                        style={{
                          backgroundColor: "#02094f",
                          fontFamily: "areal",
                        }}
                        title="View openings"
                      >
                        view openings
                      </a>
                    </p>

                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                          Info Box
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                          <Cwe />
                        </Typography>
                      </Box>
                    </Modal>
                    {/* Render the popup for openings */}
                    {/* <div id="popup1" className="overlay">
                      <div className="col-lg-3"></div>
                      <div className="col-lg-6 col-md-12 col-sm-12 ">
                        <div className="popup " style={isMobile ? hmob : hdesk}>
                          <h2 style={{ fontFamily: "areal" }}>Info Box</h2>
                          
                          <a
                            className="close"
                            href="#"
                            style={{
                              fontSize: "25px",
                              color: "white",
                              top: "0px",
                              right: "0px",
                            }}
                          >
                            <AiOutlineCloseSquare />
                          </a>
                          <div className="content">
                            <div style={isMobile ? mobileStyle : desktopStyle}>
                            
                              <Cwe />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}

                  </>
                </div>
              </div>
            </div>
            {/* .join-team */}
          </div>


          {/* .site-content */}
          <div style={{ marginTop: "-50px" }}>
            {/* Render the YouTube channel */}
            <YouTubeChannel style={{ width: "100%" }} />
          </div>


       
            <div className="city-div">
              <CityMap />
            </div>
     

        </div>
      </main>
    </>
  );
};
export default About;




















