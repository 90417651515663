import { useState ,useEffect} from "react";
import Payments from "../../../Ads/Payment/Payments";
import DashAds from "../DashAds/DashAds";
import DashContent from "../DashContent/DashContent";
import DashNotification from "../DashNotification/DashNotification";
import EditDashProfile from "../EditDashProfile/EditDashProfile";
import MessageDash from "../MessageDash/MessageDash";
import Orders from "../Orders/Orders";
import Safty from "../Safty/Safty";
import UserAllInformation from "../UserAllInformtaion/UserAllInformtaion";
import "./leftDashboard.css";
import Logout from "../../Login/Logout";
import MyFavorites from "../../../Ads/Allads/MyFavorites";
import { MdDashboardCustomize } from "react-icons/md";
import { IoMdWallet } from "react-icons/io";
import { FaUserEdit } from "react-icons/fa";
import { MdLibraryAdd } from "react-icons/md";
import { MdLeaderboard } from "react-icons/md";
import { BsChatSquareTextFill } from "react-icons/bs";
import { MdHelpCenter } from "react-icons/md";
import { BiPackage } from "react-icons/bi";
import { GoVerified } from "react-icons/go";
import { FaBorderAll } from "react-icons/fa";
import Profile from "../../Profile/Profile";
import AddBlog from "../../../Blogs/AddBlog";
import MyBlog from "../../../Blogs/MyBlog";
import { isMobile } from "react-device-detect";
import ActivePlans from "../../../Ads/Pricing/ActivePlans";
import { useNavigate } from "react-router-dom";
import { Button, Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { removeAllWishlist } from "../../../../store/wishlistSlice";
import { removeUserData } from "../../../../store/userIdSlice";
import { getUserProfileData } from "../../../../store/UserProfileDetailsSlice";
import { useDispatch } from "react-redux";
import ChatHome from "../../../Ads/messageAdsChat/ChatApp/ChatHome";
import Packages from "../../../BussinessprofileDashboard/Packages";
import ChatAndCommu from "../../../BussinessprofileDashboard/ChatAndCommu";
import Verified from "../../../BussinessprofileDashboard/Verified";
import Wallet from "../../../BussinessprofileDashboard/Wallet";
import Featured from "../../../BussinessprofileDashboard/Featured";
import Hola9Help from "../../../BussinessprofileDashboard/Hola9Help";
import EditProfile from "../../../BussinessprofileDashboard/EditProfile";
import MyAds from "../../../BussinessprofileDashboard/MyAds"
import MyDashboardBP from "../../../BussinessprofileDashboard/MyDashboardBP";
import UpdatingSoon from "../../../Base/UpdatingSoon";
import Wishlist from "../../Wishlist/Wishlist1";


import { FaRegUserCircle, FaRegHeart  } from "react-icons/fa";
import { BsChatDots } from "react-icons/bs";
import { MdAdsClick, MdOutlinePayment } from "react-icons/md";
import { MdOutlineCreateNewFolder } from "react-icons/md";
import { IoChatboxEllipsesOutline } from "react-icons/io5";
import { GrBlog } from "react-icons/gr";
import { FaBlog } from "react-icons/fa";
import { MdOutlineManageSearch } from "react-icons/md";



const LeftDashboard = (props) => {
  const category2 = props.qname;

  let navigate = useNavigate();
  const [active, setActive] = useState(category2);
  const [toggle, setToggle] = useState(false);

  // const commonProps = { myProp1: "prop1" };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  //logout

  const dispatch = useDispatch();
  const removeAll = () => {
    dispatch(removeAllWishlist());
    dispatch(removeUserData());
    dispatch(getUserProfileData(data));
  };

  const handleOk = () => {
    removeAll();
    var myItem = localStorage.getItem("currentLocation");
    localStorage.clear();
    localStorage.setItem("currentLocation", myItem);

    navigate("/");
    setIsModalOpen(false);
  };
  const data = [];

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const { confirm } = Modal;

  const showConfirm = () => {
    confirm({
      title: "Are you sure you want to Logout?",
      icon: <ExclamationCircleFilled />,
      onOk: handleOk,
      onCancel: handleCancel,
    });
  };



  return (
    <>
    
        <div className="container">
          <div className="row">
          
              <div
                className="col-xl-3 col-lg-12 col-md-12 left-dash-clr"
              >
                {isMobile ? (
                  <button
                    className="bg-info p-2 rounded text-white m-1 w-100"
                    onClick={() => setToggle(!toggle)}
                  >
                    Your Details
                  </button>
                ) : (
                  <div className="item1-links  mb-0 mt-1">
                    {/* <a onClick={()=>setActive("Profile")} className="active d-flex border-bottom"> */}

                    <a href
                      onClick={() => {
                        if (isMobile) {
                          setActive("Profile");
                          setToggle(!toggle);
                          // document.getElementById("toggle").style.visibility = "hidden";
                        } else {
                          setActive("Profile");
                        }
                      }}
                      className="active d-flex border-bottom "
                      style={
                        active == "Profile"
                        ? { color: "#34495e", fontWeight: "bold" }
                      : { color: "",backgroundColor:"white" }
                      }
                    >
                      <span className="icon7 me-3">
                        <i className="fas fa-user dp-ions" ></i>
                      </span>
                      EditProfile
                    </a>
                    <a href
                      onClick={() => {
                        if (isMobile) {
                          setActive("Chats");
                          setToggle(!toggle);
                          // document.getElementById("toggle").style.visibility = "hidden";
                        } else {
                          setActive("Chats");
                        }
                      }}
                      className="active d-flex border-bottom "
                      style={
                        active == "Chats"
                             ? { color: "#34495e", fontWeight: "bold" }
                      : { color: "",backgroundColor:"white" }
                      }
                    >
                      <span className="icon7 me-3">
                        <i className="far fas fa-comment-dots dp-ions"></i>
                      </span>
                      Chats
                    </a>
                    {/* <a onClick={()=>setActive("DashAds")} className=" d-flex  border-bottom"> */}
                    <a
                      onClick={() => {
                        if (isMobile) {
                          setActive("DashAds");
                          setToggle(!toggle);
                        } else {
                          setActive("DashAds");
                        }
                      }}
                      className="active d-flex border-bottom"
                      style={
                        active == "DashAds"
                             ? { color: "#34495e", fontWeight: "bold" }
                      : { color: "",backgroundColor:"white" }
                      }
                    >
                      <span className="icon1 me-3">
                        <i className="fas fa-ad dp-ions" />
                      </span>
                      My Ads
                    </a>
                    {/* <a onClick={()=>setActive("AddProduct")} className=" d-flex border-bottom"> */}
                    <a
                      onClick={() => {
                        if (isMobile) {
                          navigate("/allcatforpost/");
                          setActive("AddProduct");
                          setToggle(!toggle);
                        } else {
                          navigate("/allcatforpost/");
                          setActive("AddProduct");
                        }
                      }}
                      className="active d-flex border-bottom"
                      style={
                        active == "AddProduct"
                             ? { color: "#34495e", fontWeight: "bold" }
                      : { color: "",backgroundColor:"white" }
                      }
                    >
                      <span className="icon1 me-3">
                        <i className="fa fa-cart-plus dp-ions" />
                      </span>
                      Create Ads
                    </a>
                    {/* <a onClick={()=>setActive("MyFavorites")} className=" d-flex border-bottom"> */}
                    <a
                      onClick={() => {
              
                        if (isMobile) {
                          
                          setActive("MyFavorites");
                          setToggle(!toggle);
                          
                        } else {
                          setActive("MyFavorites");
                        }
                     }}
                      className="active d-flex border-bottom"
                      
                      style={
                        active == "MyFavorites"
                             ? { color: "#34495e", fontWeight: "bold" }
                      : { color: "",backgroundColor:"white" }
                      }
                    >
                      <span className="icon1 me-3">
                        <i className="fa fa-heart dp-ions" />
                      </span>
                      My Wishlist
                    </a>
                    {/* <a onClick={()=>setActive("FeaturedAds")} className="d-flex  border-bottom"> */}
                    {/* <a onClick={()=>{
      if(isMobile){
        setActive("FeaturedAds");
        setToggle(!toggle);
      }else{
        setActive("FeaturedAds");
      }
    }} className="active d-flex border-bottom" style={active=="FeaturedAds" ? {color:"#34495e", fontWeight:"bold" }:{color:""}}>
      <span className="icon1 me-3">
        <i className="icon icon-folder-alt" />
      </span>
      Featured Ads
    </a> */}
                    {/* <a onClick={()=>setActive("MessageDash")} className="d-flex border-bottom"> */}
                    {/* <a onClick={()=>{
      if(isMobile){
        setActive("MessageDash");
        setToggle(!toggle);
      }else{
        setActive("MessageDash");
      }
    }} className="active d-flex border-bottom" style={active=="MessageDash" ? {color:"#34495e", fontWeight:"bold" }:{color:""}}>
      <span className="icon1 me-3">
        <i className="icon icon-game-controller" />
      </span>
      Chat
    </a> */}

                    {/* <a onClick={()=>setActive("Orders")} className="d-flex  border-bottom"> */}
                    <a
                      onClick={() => {
                        if (isMobile) {
                          setActive("Orders");
                          setToggle(!toggle);
                        } else {
                          setActive("Orders");
                        }
                      }}
                      className="active d-flex border-bottom"
                      style={
                        active == "Orders"
                             ? { color: "#34495e", fontWeight: "bold" }
                      : { color: "",backgroundColor:"white" }
                      }
                    >
                      <span className="icon1 me-3">
                        <i className="fa fa-money dp-ions" />
                      </span>
                      Payments & Orders
                    </a>
                    {/* <a onClick={()=>setActive("Safty")} className="d-flex border-bottom"> */}
                    {/* <a
                  onClick={() => {
                    if (isMobile) {
                      setActive("Safty");
                      setToggle(!toggle);
                    } else {
                      setActive("Safty");
                    }
                  }}
                  className="active d-flex border-bottom"
                  style={
                    active == "Safty"
                      ? { color: "#34495e", fontWeight: "bold" }
                      : { color: "" }
                  }
                >
                  <span className="icon1 me-3">
                    <i className="icon icon-game-controller dp-ions" />
                  </span>
                  Safety Tips
                </a> */}
                    {/* <a onClick={()=>setActive("AddBlog")} className="d-flex border-bottom"> */}
                     {/*<a
                      onClick={() => {
                        if (isMobile) {
                          setActive("AddBlog");
                          setToggle(!toggle);
                        } else {
                          setActive("AddBlog");
                        }
                      }}
                      className="active d-flex border-bottom"
                      style={
                        active == "AddBlog"
                             ? { color: "#34495e", fontWeight: "bold" }
                      : { color: "",backgroundColor:"white" }
                      }
                    >
                      <span className="icon1 me-3">
                        <i className="fa fa-th dp-ions" />
                      </span>
                      Add Blogs
                    </a> */}
                    {/* <a onClick={()=>setActive("MyBlog")} className="d-flex border-bottom"> */}
                    {/* <a
                      onClick={() => {
                        if (isMobile) {
                          setActive("MyBlog");
                          setToggle(!toggle);
                        } else {
                          setActive("MyBlog");
                        }
                      }}
                      className="active d-flex border-bottom"
                      style={
                        active == "MyBlog"
                             ? { color: "#34495e", fontWeight: "bold" }
                      : { color: "",backgroundColor:"white" }
                      }
                    >
                      <span className="icon1 me-3">
                        <i className="fa fa-th-large dp-ions" />
                      </span>
                      My Blogs
                    </a> */}
                    <a
                      onClick={() => {
                        if (isMobile) {
                          setActive("Manageads");
                          setToggle(!toggle);
                        } else {
                          setActive("Manageads");
                        }
                      }}
                      className="active d-flex border-bottom"
                      style={
                        active == "Manageads"
                             ? { color: "#34495e", fontWeight: "bold" }
                      : { color: "",backgroundColor:"white" }
                      }
                    >
                      <span className="icon1 me-3">
                        <i className="fa fa-line-chart dp-ions" />
                      </span>
                      Manage Ads
                    </a>
                    {/* <a onClick={() => setActive("Logout")} className="d-flex">
                  <span className="icon1 me-3">
                    <i className="icon icon-power" />
                  </span>
                  Logout
                </a> */}
                    <Button
                      style={{ width: "100%", fontWeight: "bolder" }}
                      onClick={showConfirm}
                    >
                      Logout
                    </Button>
                    {/* <Modal
                  title="Confirmation"
                  open={isModalOpen}
                  onOk={handleOk}
                  onCancel={handleCancel}
                >
                  <p>Are you sure you want to Logout?</p>
                </Modal> */}
                  </div>
                )}
                <div className="card1" id="toggle">
                  {toggle ? (
                    <div className="item1-links  mb-0">
                      {/* <a onClick={()=>setActive("Profile")} className="active d-flex border-bottom"> */}
                      <a
                        onClick={() => {
                          if (isMobile) {
                            setActive("Profile");
                            setToggle(!toggle);
                            // document.getElementById("toggle").style.visibility = "hidden";
                          } else {
                            setActive("Profile");
                          }
                        }}
                        className="active d-flex border-bottom"
                      >
                        <span className="icon1 me-3">
                        <FaRegUserCircle />
                          {/* <i className="icon icon-user dp-ions" /> */}
                        </span>
                        Edit Profile
                      </a>
                      <a
                        onClick={() => {
                          if (isMobile) {
                            setActive("Chats");
                            setToggle(!toggle);
                            // document.getElementById("toggle").style.visibility = "hidden";
                          } else {
                            setActive("Chats");
                          }
                        }}
                        className="active d-flex border-bottom"
                      >
                        <span className="icon1 me-3">
                          {/* <i className="icon icon-user dp-ions" /> */}
                          <BsChatDots />
                        </span>
                        Chats
                      </a>
                      {/* <a onClick={()=>setActive("DashAds")} className=" d-flex  border-bottom"> */}
                      <a
                        onClick={() => {
                          if (isMobile) {
                            setActive("DashAds");
                            setToggle(!toggle);
                          } else {
                            setActive("DashAds");
                          }
                        }}
                        className="active d-flex border-bottom"
                      >
                        <span className="icon1 me-3">
                          {/* <i className="icon icon-diamond dp-ions" /> */}
                          <MdAdsClick />
                        </span>
                        My Ads
                      </a>
                      {/* <a onClick={()=>setActive("AddProduct")} className=" d-flex border-bottom"> */}
                      <a
                        onClick={() => {
                          if (isMobile) {
                            navigate("/allcatforpost/");
                            setActive("AddProduct");
                            setToggle(!toggle);
                          } else {
                            navigate("/allcatforpost/");
                            setActive("AddProduct");
                          }
                        }}
                        className="active d-flex border-bottom"
                      >
                        <span className="icon1 me-3">
                          {/* <i className="icon icon-heart dp-ions" /> */}
                          <MdOutlineCreateNewFolder />
                        </span>
                        Create Ads
                      </a>
                      {/* <a onClick={()=>setActive("MyFavorites")} className=" d-flex border-bottom"> */}
                      <a
                        onClick={() => {
                          if (isMobile) {
                            navigate("/dashboard/wishlist/");

                            setActive("MyFavorites");
                            setToggle(!toggle);
                          } else {
                            navigate("/dashboard/wishlist/");

                            setActive("MyFavorites");
                          }
                        }}
                        className="active d-flex border-bottom"
                      >
                        <span className="icon1 me-3">
                          {/* <i className="icon icon-heart dp-ions" /> */}
                          <FaRegHeart />
                        </span>
                        My Wishlist
                      </a>
                      {/* <a onClick={()=>setActive("FeaturedAds")} className="d-flex  border-bottom"> */}
                      {/* <a onClick={()=>{
            if(isMobile){
              setActive("FeaturedAds");
              setToggle(!toggle);
            }else{
              setActive("FeaturedAds");
            }
          }} className="active d-flex border-bottom">
            <span className="icon1 me-3">
              <i className="icon icon-folder-alt dp-ions" />
            </span>
            Featured Ads
          </a> */}
                      {/* <a onClick={()=>setActive("MessageDash")} className="d-flex border-bottom"> */}
                      <a
                        onClick={() => {
                          if (isMobile) {
                            setActive("MessageDash");
                            setToggle(!toggle);
                          } else {
                            setActive("MessageDash");
                          }
                        }}
                        className="active d-flex border-bottom"
                      >
                        <span className="icon1 me-3">
                          {/* <i className="icon icon-game-controller dp-ions" /> */}
                          <IoChatboxEllipsesOutline />
                        </span>
                        Chat
                      </a>
                      {/* <a onClick={()=>setActive("Orders")} className="d-flex  border-bottom"> */}
                      <a
                        onClick={() => {
                          if (isMobile) {
                            setActive("Orders");
                            setToggle(!toggle);
                          } else {
                            setActive("Orders");
                          }
                        }}
                        className="active d-flex border-bottom"
                      >
                        <span className="icon1 me-3">
                          {/* <i className="icon icon-basket dp-ions" /> */}
                          <MdOutlinePayment />
                        </span>
                        Payments & Orders
                      </a>
                      {/* <a onClick={()=>setActive("Safty")} className="d-flex border-bottom"> */}
                      {/* <a
                    onClick={() => {
                      if (isMobile) {
                        setActive("Safty");
                        setToggle(!toggle);
                      } else {
                        setActive("Safty");
                      }
                    }}
                    className="active d-flex border-bottom"
                  >
                    <span className="icon1 me-3">
                      <i className="icon icon-game-controller dp-ions" />
                    </span>
                    Safety Tips
                  </a> */}
                      {/* <a onClick={()=>setActive("AddBlog")} className="d-flex border-bottom"> */}
                    {/*  <a
                        onClick={() => {
                          if (isMobile) {
                            setActive("AddBlog");
                            setToggle(!toggle);
                          } else {
                            setActive("AddBlog");
                          }
                        }}
                        className="active d-flex border-bottom"
                      >
                        <span className="icon1 me-3">
                          <GrBlog />
                        </span>
                        Add Blogs
                      </a> */}
                      {/* <a onClick={()=>setActive("MyBlog")} className="d-flex border-bottom"> */}
                     {/* <a
                        onClick={() => {
                          if (isMobile) {
                            setActive("MyBlog");
                            setToggle(!toggle);
                          } else {
                            setActive("MyBlog");
                          }
                        }}
                        className="active d-flex border-bottom"
                      >
                        <span className="icon1 me-3">
                          <FaBlog />
                        </span>
                        My Blog
                      </a> */}
                      <a
                        onClick={() => {
                          if (isMobile) {
                            setActive("Manageads");
                            setToggle(!toggle);
                          } else {
                            setActive("Manageads");
                          }
                        }}
                        className="active d-flex border-bottom"
                      >
                        <span className="icon1 me-3">
                          {/* <i className="icon icon-settings dp-ions" /> */}
                          <MdOutlineManageSearch />
                        </span>
                        Manage Ads
                      </a>
                      {/* <a onClick={() => setActive("Logout")} className="d-flex">
                    <span className="icon1 me-3">
                      <i className="icon icon-power" />
                    </span>
                    Logout
                  </a> */}{" "}
                      <Button type="primary" onClick={showModal} style={{marginTop:'10px'}}>
                        Logout
                      </Button>
                      <Modal
                        title="Confirmation"
                        open={isModalOpen}
                        onOk={handleOk}
                        onCancel={handleCancel}
                      >
                        <p>Are you sure you want to Logout?</p>
                      </Modal>
                    </div>
                  ) : null}
                </div>
              </div>

            <div className="col-xl-9 col-lg-12 col-md-12 left-dashboard-padding">
              {active === "Profile" && <Profile />}
              {active === "editprofile" && <EditProfile />}

              {active === "Chats" && <ChatAndCommu />}
              {active === "UserAllInformation" && <UserAllInformation />}
               {active === "MyFavorites" && <Wishlist />} 
              {/* {active === "MyFavorites" && <Wishlist commonProps={commonProps} />} */}

              {/* {active ==="FeaturedAds" && <ManageAds />} */}
              {/* {active ==="PostDashAds" && <PostDashAds />} */}
              {/* {active === "AddProduct" && <AddProduct />} */}
              {/* {active === "PayMentDash" && <DashContent />} */}
              {active === "DashAds" && <DashAds />}
              {active === "EditDashProfile" && <EditDashProfile />}
              {active === "MessageDash" && <MessageDash />}
              {active === "DashNotification" && <DashNotification />}
              {active === "Payments" && <Payments />}
              {active === "Safty" && <Safty />}
              {active === "AddBlog" && <AddBlog />}
              {active === "Manageads" && <ActivePlans />}
              {active === "Logout" && <Logout />}
              {active === "MyBlog" && <MyBlog />}
              {active === "Orders" && <Orders />}
              {active === "editprofile" && <EditProfile />}
              {active === "bussiness" && <Packages />}
              {active === "chatandcommu" && <ChatAndCommu />}
              {/* {active === "chatandcommu" && <UpdatingSoon/>} */}
              {active === "verified" && <Verified />}
              {active === "wallet" && <Wallet />}
              {active === "featured" && <Featured />}
              {active === "hola9help" && <Hola9Help />}
              {active === "mydashboard" && <MyDashboardBP />}

            </div>
          </div>
        </div>
    </>
  );
};

export default LeftDashboard;
