// PricingCard.jsx

import React from 'react';
import './Leadprice.scss';

const PricingCard = ({ plan, price, features }) => {
  return (
    <section className="plans__container">
    <div className="plans">
      <div className="plansHero">
        <h1 className="plansHero__title">Simple, transparent pricing</h1>
        <p className="plansHero__subtitle">No contracts. No suprise fees.</p>
      </div>
      <div className="planItem__container">
        {/*free plan starts */}
        <div className="planItem planItem--free">
          <div className="cardp3">
            <div className="card__header">
              <div className="card__icon symbol symbol--rounded" />
              <h2>Free</h2>
            </div>
            <div className="card__desc">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            </div>
          </div>
          <div className="pricep3">
            $0<span>/ month</span>
          </div>
          <ul className="featureList">
            <li>2 links</li>
            <li>Own analytics platform</li>
            <li className="disabled">Chat support</li>
            <li className="disabled">Mobile application</li>
            <li className="disabled">Unlimited users</li>
          </ul>
          <button className="chbutton">Get Started</button>
        </div>
        {/*free plan ends */}
        {/*pro plan starts */}
        <div className="planItem planItem--pro">
          <div className="cardp3">
            <div className="card__header">
              <div className="card__icon symbol" />
              <h2>Pro</h2>
              <div className="card__label labelp3">Best Value</div>
            </div>
            <div className="card__desc">
              Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
            </div>
          </div>
          <div className="pricep3">
            $18<span>/ month</span>
          </div>
          <ul className="featureList">
            <li>2 links</li>
            <li>Own analytics platform</li>
            <li>Chat support</li>
            <li className="disabled">Mobile application</li>
            <li className="disabled">Unlimited users</li>
          </ul>
          <button className="chbutton button--pink">Get Started</button>
        </div>
        {/*pro plan ends */}
        {/*entp plan starts */}
        <div className="planItem planItem--entp">
          <div className="cardp3">
            <div className="card__header">
              <div className="card__icon" />
              <h2>Enterprise</h2>
            </div>
            <div className="card__desc">
              Nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
            </div>
          </div>
          <div className="pricep3">Let's Talk</div>
          <ul className="featureList">
            <li>2 links</li>
            <li>Own analytics platform</li>
            <li>Chat support</li>
            <li>Mobile application</li>
            <li>Unlimited users</li>
            <li>Customize Panel</li>
          </ul>
          <button className="chbutton button--white">Get Started</button>
        </div>
        {/*entp plan ends */}
      </div>
    </div>
  </section>
  
  );
};

export default PricingCard;
