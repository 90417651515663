import React, { useState } from "react";
import { FaStar } from "react-icons/fa";

const UserRating = ({rating, setRating}) => {
  const [color, setColor] = useState(null);

  return (
    <>
        <div>
            {[...Array(5)].map((star, value) => {
                const starRating = value + 1;
                return (
                    <FaStar 
                        key={value}
                        size={18}
                        color={starRating <= rating ? "gold" : "gray"}
                        // onClick={() => setRating(***)}
                    />
                );
            })}
        </div>
    </>
  );
};

export default UserRating;