import React, { useContext, useEffect, useState } from "react";
// import React, {Component, useContext, useEffect, useState } from "react"; unused component
import Slider from "react-slick";
import "./Owl.css";
import { NavLink, useNavigate } from "react-router-dom";
import { url } from "./env";
import { Link } from "react-router-dom";
import { decrypt } from "./Base/encryptDecrypt/encryptDecrypt";
// import Trending from "./adsReSorting/trending";
import { isMobile } from "react-device-detect";
import { GiMoneyStack } from "react-icons/gi";

import {
  BsReverseLayoutTextSidebarReverse,
  BsFillTagFill,
  BsFillBookmarkFill,
  BsFillClockFill,
  BsFillCheckSquareFill,
  BsGeoAltFill,
  BsBuilding,
  BsNewspaper,
  BsCart4,
  BsHeart,
  BsHeartFill,BsCoin
} from "react-icons/bs";
import {
  AiFillDelete
} from "react-icons/ai";
import BiSolidCartAdd from "react-icons/bi"
import DynamicFont from "react-dynamic-font";
// import { Button, Tooltip, Popover } from 'antd'; unused {Button, Popover}
import { Tooltip } from "antd";
import { UserContext } from "../App";
import { useInterval } from "react-interval-hook";
import YoutubeMagic from "./ContentLoader/YoutubeMagic";
import { useDispatch, useSelector } from "react-redux";
import { add, remove } from "../store/wishlistSlice";
import Distance from "./distance";
import { SliderContainer } from "../Utils/SliderContainer";
import { Money } from "@mui/icons-material";

import verified from "./Ads/Allads/verified.jpg";

const mobstyle={
  width: "auto",
  background: "transparent"
}
const deskstyle={
  //  width: "14rem",
  background: "gray",
  //  borderRadius: "2px",
   border: "1px solid gray",
}
const mobilestyle={
  height: "140px",
  width: "350px",
  borderRadius:"20px",
}
const desktopstyle={
 height: "165px",
  width: "350px",
}

var settings = {
  // dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ],
};
function Responsive(props) {
  const [products, setProduct] = useState([]);
  const [loader, setLoader] = useState(true);
  // const [details,setdetails]=useState([])  not using in the component
  // const [DataisLoaded,setDataisLoaded]=useState(false)  not using in the component
  const hola9 = useContext(UserContext);
  const wishlistData = useSelector((state) => state.wishlistSlice);
  const [lockInterval, setLockInterval] = useState(true);
  const [interval, setInterval] = useState({ start: 1, end: 8 });
  const [wishlistID] = ["wishlistId"].map(
    document.getElementById.bind(document)
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getProducts = () => {
    var formdata = new FormData();
    formdata.append("start", interval.start);
    formdata.append("end", interval.end);
    formdata.append("category", props.greeting);
    if (props?.subcategory) {
      formdata.append("subCategory", props.subcategory);
    }
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(url + "api/adsapi/categoryAdsByInterval", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        let value = result.map((result) => {
          let a = { ...result.fields };
          a["pk"] = result.pk;
          return a;
        });
        if (value.length === 0) {
          setLockInterval(false);
        } else {
          if (products.length === 0) {
            if (localStorage.getItem("lat")) {
              let s = Distance(value);
              setProduct(s);
            } else {
              setProduct(value);
            }
          } else {
            var tempProduct = [
              ...products,
              value.map((result) => products.push(result)),
            ];
            if (localStorage.getItem("lat")) {
              tempProduct = Distance(tempProduct);
            }
            setProduct(tempProduct);
          }
        }

        setInterval({ start: interval.end + 1, end: interval.end + 16 });
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
      });
  };
  var value = true;
  const [loaderTrigger, setLoaderTrigger] = useState(true);
  useEffect(() => {
    if (loaderTrigger) {
      setLoader(true);
      setLoaderTrigger(false);
    }

    getProducts();
    // setProduct(hola9.hola9DataApp)
    // setDataisLoaded(true)

    //   if(props?.trending && hola9.hola9DataApp.length>0){

    //     let ads=Trending(hola9.hola9DataApp)
    //     setProduct(ads)
    //   }
  }, [products]);
  // },[])  before it was empty array
  const addingWishlist = (value, e) => {
    if (localStorage.getItem("access_token") == null) {
      navigate("/login");
    }
    if (wishlistData?.data?.indexOf(value) !== -1) {
      dispatch(remove({ value: value }));
    } else {
      dispatch(add({ value: value }));
    }

    // wishlistID.addEventListener('click', e => e.stopPropagation());
  };

  useInterval(() => {
    if (products.length > 0 && lockInterval && products.length < 40) {
      getProducts();
    }
  }, 5000);
  useEffect(() => {}, [interval]);

  // const { DataisLoaded, products } = state;
  //     if (!DataisLoaded) return <div>
  //         <h6 className="fs-10 text-white">  Pleses wait some time.... </h6> </div> ;

  return (
    <>
        {products.length ? (
       
       <div>
                    <div className="d-flex justify-content-between">

         <h2
           className="text-decoration-underline"
           style={{ fontSize: "17px",fontWeight:"bold" }}
         >
           {" "}
                {props?.subcategory ? (
                  <span>{props.subcategory}</span>
                ) : !props.trending ? (
                  <span>{props.greeting}</span>
                ) : (
                  <span>Trending Ads</span>
                )}
         </h2>
         
              <Link
                to={`/cat/${props.greeting}/${props.subcategory}`}
                className="mt-3"
                style={{ marginRight: "20px" }}
              >
                <b> View More</b>
              </Link>
</div>
            {loader ? <YoutubeMagic /> : null}
                  <div className="row ">
           <SliderContainer>
             <Slider {...settings}>
       {/* the product object is being used to access properties and values associated with each product in the products array. */}
               {products
                 .sort((a, b) => b.pk - a.pk)
                 .map((ad, index) => {
                   // products.map((product, index) => {
                    if (
                      ad.category === props.greeting &&
                      (props?.subcategory
                        ? ad.subCategoryValue === props.subcategory
                        : true)
                    ) {
                   return (
                     <>
                     <div key={ad.pk} className="ad-card m-2" onClick={() => navigate(`/ads-listing/${
                           ad?.pk === undefined ? ad?.pk : ad.pk
                         }/`)} style={{marginRight:"50px"}}>
                           <div className="glassmorphism">
                             <div>
                             <div className="ad-image">
                             <div className="wtrmrk">Hola9.com</div>
                             <img className="imghover-scale"
                                               src={
                                                 !ad?.image
                                                   ? "https://th.bing.com/th/id/OIP.aV3_1sg9QEdADlu5byNWbwAAAA?pid=ImgDet&rs=1"
                                                   : ad?.image
                                               }
                                               alt="ad"
                                               onError={(e) => {
                                                 e.target.src =
                                                   "https://th.bing.com/th/id/OIP.aV3_1sg9QEdADlu5byNWbwAAAA?pid=ImgDet&rs=1";
                                                 e.onerror = null;
                                               }}
                                             />
                             </div>
                             <div className="product-type">
                             <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                               <span className="flat-badge booking" >
                                               <b>
                                                     <span
                                                       className="flat-badge booking "
                                                       style={{
                                                         background:
                                                         ad?.plan ==="Silver"
                                                             ? "#cce6ff"
                                                             : ad?.plan === "Gold"
                                                             ? "gold"
                                                             : ad?.plan === "Platinum"
                                                             ? "green"
                                                             : ad?.plan === "Premium"
                                                             ? "linear-gradient(to right, #800080, #8A2BE2)"
                                               : ad?.plan === "Featured"
                                               ? "linear-gradient(to right, #090979, #00d4ff)"
                                                             // : ad?.plan === "Free"
                                                             // ? "green"
                                                             : "transparent",
                                                         color:
                                                         ad?.plan ==="Silver"
                                                             ? "blue"
                                                             : ad?.plan === "Gold"
                                                             ? "black"
                                                             : ad?.plan === "Platinum"
                                                             ? "white"
                                                             : ad?.plan === "Premium"
                                                             ? "black"
                                                             : ad?.plan === "Featured"
                                                             ? "black"
                                                             // : ad?.plan === "Free"
                                                             // ? "white"
                                                             : "transparent",
                                                             padding:"5px",
                                                            
                                                         // backgroundColor: "white",
                                                         // float:"left",
                                                         // border: "2px solid black",
                                                       }}
                                                     >
                                                       {ad?.plan}
                                                     </span>
                                                   </b>
                                               </span>
                                               <img
                                      src={verified}

                                      style={{
                                        marginRight: "-10px",
                                        borderRadius: "50%",
                                        width:"22px",
                                        height:'22px'
                                      }} />
                                </div>
                                              
                                             </div>
                                             <ul className="viewsub">
                                             <li className="view">
                                               <i
                                                 className="fas fa-eye"
                                                 style={{ color: "white" }}
                                               />
                                               <span style={{ color: "white" }}>
                                                 {ad?.viewsproduct}
                                               </span>
                                             </li>
                                             {/* <li className="click">
                                               <i className="fas fa-mouse" />
                                               <span>134</span>
                                             </li> */}
                                             <li className="rating">
                                               <i className="" style={{ color: "white" }} />
                                               <span style={{ color: "white" }}>
                                                 <BsNewspaper />
                                                 &nbsp;  {ad?.subCategoryValue?.slice(0,12)+"..."}
                                               </span>
                                             </li>
                                           </ul>
                                           </div>
                                           <div className="ad-details  m-0 p-0">
                                           {/* DESKTOP VIEW */}
                                           <p className="titlefont hidden-sm hidden-xs  m-0 p-0">
                                             <Tooltip
                                               placement="topLeft"
                                               title={ad?.title}
                                             >
                                               {ad?.title?.length > 17 ? (
                                                 <p className="titlefont">
                                                   {ad?.title?.slice(0, 17)}
                                                   {ad?.title?.length > 17
                                                     ? "..."
                                                     : null}
                                                 </p>
                                               ) : (
                                                 <div>
                                                   <p className="titlefont">
                                                     <DynamicFont
                                                       content={ad?.title}
                                                     />
                                                   </p>
                                                 </div>
                                               )}
                                             </Tooltip>
                                           </p>
                                           {/* DESKTOP VIEW */}
           
                                           {/* MOBILE VIEW */}
                                           <p className="titlefont d-lg-none">
                                             <Tooltip
                                               placement="topLeft"
                                               title={ad?.title}
                                             >
                                               {ad?.title?.length > 12 ? (
                                                 <p className="titlefont">
                                                   {ad?.title?.slice(0, 12)}
                                                   {ad?.title?.length > 12
                                                     ? "..."
                                                     : null}
                                                 </p>
                                               ) : (
                                                 <div style={{}}>
                                                   <p className="titlefont">
                                                     <DynamicFont
                                                       content={ad?.title}
                                                     />
                                                   </p>
                                                 </div>
                                               )}
                                             </Tooltip>
                                           </p>
                                           {/* MOBILE VIEW */}
                                           <div
                                             className="condiv justify-content-between "
                                             style={{ marginTop: "-15px", alignItems:'center', marginBottom:'6px', gap:'10px' }}
                                           >
                                             <div className="widhdiv d-flex justify-content-between m-0 p-0  mob-v-space">
                                               <p
                                                 className="fs-10 m-0 p-0 mt-1"
                                                 style={{
                                                   color: "#062544",
                                                   fontSize: "90%",
                                                   fontFamily: "sans-serif",
                                                 }}
                                               >
                                                 <BsGeoAltFill
                                                   className="mr-1 "
                                                   style={{ color: "red" }}
                                                 />
                                                 <b>{ad?.City}</b>
                                               </p>
                                               <BsFillBookmarkFill
                                                 id="wishlibyn"
                                                 style={{
                                                   fontSize: "20px",
                                                   marginTop: "6px",
                                                 }}
                                                 className={
                                                   wishlistData?.data?.indexOf(ad.pk) !==
                                                   -1
                                                     ? "removemob"
                                                     : "heartmob"
                                                 }
                                                 onClick={(e) => {
                                                   e.stopPropagation();
                                                   e.preventDefault();
                                                   addingWishlist(ad.pk);
                                                 }}
                                               />
                                             </div>
                                             <div className="mob-v-space">
                                             {ad?.category == "RealEstate" ? (
                                               <p
                                                 className="conpir fs-14"
                                                 style={{
                                                   color: "gray",
                                                   fontSize: "90%",
                                                   fontFamily: "sans-serif",
                                                   fontWeight: "bold",
                                                   margin:'0',
                                                   transform:'unset'
                                                 }}
                                               >
                                                 <BsBuilding
                                                   className="mr-2"
                                                   style={{
                                                     color: "green",
                                                     fontSize: "90%",
                                                   }}
                                                 />
                                                 {ad.subCategoryType?.slice(0, 9)}
                                               </p>
                                             ) : (
                                               <p
                                                 className="conpir fs-14"
                                                 style={{
                                          
                                                   fontSize: "90%",
                                                   fontFamily: "sans-serif",
                                                   margin:'0',
                                                   transform:'unset'
                                                   
                                                 }}
                                               >
                                                 <BsFillCheckSquareFill
                                                   className="mr-1"
                                                   style={{
                                                     color: "green",
                                                     fontSize: "90%",
                                                     
                                                   }}
                                                 />
                                                 {ad?.condition?.slice(0, 9)}
                                               </p>
                                             )}
                                             <span className="mob-star">
                                <i className="fa fa-star" style={{color:'gold'}}> <span style={{color:'gray'}}>4.5</span></i>
                              </span>
                                </div>
                                </div>
                                <div  className="price-bot-mob">
                                           {ad?.category != "Jobs" ?
                                (
                                <p
                                  className="ad-price mr-1"
                                  style={{
                                    fontSize: "90%",
                                    fontFamily: "verdana",
                                    // marginTop: "-15px",
                                    margin:'0'
                                  }}
                                >
                                  &nbsp;₹ <b>{ad?.price}</b>
                                </p>):(<>
                                  {ad?.extraField && (
    <p  className="ad-price mr-1"
                                  style={{
                                    fontSize: "90%",
                                    fontFamily: "verdana",
                                    // marginTop: "-15px",
                                    margin:'0'
                                  }}>
      
      &nbsp;<GiMoneyStack /><b>{(JSON?.parse(ad?.extraField)["MaxSalery"])}</b>
    </p>
)}
                                </>)}
                                <span className="desk-star">
                                <i className="fa fa-star" style={{color:'gold'}}> <span style={{color:'gray'}}>4.5</span></i>
                              </span>
                              </div>
                                           <button
                                             id="wishbbt"
                                             style={{ marginTop: "-15px" }}
                                             className={
                                               wishlistData?.data?.indexOf(ad.pk) !== -1
                                                 ? "remove-button"
                                                 : "wishlist-button"
                                             }
                                             onClick={(e) => {
                                               e.stopPropagation();
                                               e.preventDefault();
                                               addingWishlist(ad.pk);
                                             }}
                                           >
                                             {wishlistData?.data?.indexOf(ad.pk) !== -1 ? (
                                               <>
                                                 <AiFillDelete />
                                                 &nbsp;&nbsp;Remove Wishlist
                                               </>
                                             ) : (
                                               <>
                                                 <BsFillBookmarkFill />
                                                 &nbsp;&nbsp;Add To Wishlist
                                               </>
                                             )}
                                           </button>
                                         </div>
                           </div>
                     </div>
                     </>
                   );
                  } else {
                    // If conditions are not met, return null
                    return null;
                  }
                 })}
             </Slider>
           </SliderContainer>
         </div>
         {/* </div> */}
       </div>
     ) : null}
        {/* {loader ? <YoutubeMagic /> : null} */}
    </>
  );
}

export default Responsive;
