import React, { useContext, useEffect, useState } from "react";
import "../../../src/component/Account/Dashboard/profileDashboard/profileDashboard.css";
import Profile from "../../../src/component/Account/Profile/Profile";
// import { decrypt } from "../../../Base/encryptDecrypt/encryptDecrypt";
import { localUrl, url } from "../../../src/component/env";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../../../src/App";
import { Button, Form } from "antd";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { getUserPlan } from "../../../src/store/allPlanDetails";
import { userProfileDataApi } from "../../../src/store/UserProfileDetailsSlice";
import { isMobile } from "react-device-detect";
import Featured from "./Featured";
import { AiOutlineCloseSquare } from "react-icons/ai";
import { GoVerified } from "react-icons/go";
import Userdash from "./Userdash";
import "./Bussinessdash.css";
// import { Electronics } from './../env';

const BusinessProfileDashboard = () => {
  const mobileStyle = {
    textAlign: "center",
    width: "80px",
    margin: "10px",
  };
  const desktopStyle = {
    width: "100px",
    margin: "40px",
  };
  const mobstyle = {
    fontSize: "10px",
    padding: "0px",
    margin: "0px",
    lineHeight: "1.25rem",
  };
  const deskstyle = {
    width: "auto",
  };

  //  Profile circular Progress
  const [percentage, setPercentage] = useState(36);
 

  const { loading1, data } = useSelector((state) => state.planData);
  console.log("!!!data", data);

  const [verified, setVerified] = useState(false);

  const [active, setActive] = useState("");
  const [name, setName] = useState();
  const [plan, setPlan] = useState();
  const [nameFlag, setnameFlag] = useState(false);
  const [ads, setads] = useState([]);
  const [Blog, setBlog] = useState([]);
  const [FeaturedData, setFeaturedData] = useState(0);
  const [id, setId] = useState();
  const [error, setError] = useState("");
  const [adde, setAdde] = useState([]);

  const dispatch = useDispatch();

  const [open, setOpen] = useState("featured");
  const [toggle, setToggle] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showPopup2, setShowPopup2] = useState(false);

  // for showing every plan ads
  const currentDate = new Date();
  const expiryDate = new Date();
  expiryDate.setDate(currentDate.getDate() + 30);
  const expiryDate1 = new Date();
  expiryDate1.setDate(currentDate.getDate() + 60);
  // const[date,setdate]=useState(Date());

  // const [users, setUsers] = useState([]);
  const [image, setImage] = useState(null);
  const [profileform, setprofileform] = useState({
    name: "",
    email: "",
    PhoneNumber: "",
    address: "",
    state: "",
    city: "",
    zipcode: "",
  });
  // const [name, setName] = useState();
  const [email, setEmail] = useState();
  let navigate = useNavigate();
  // var [wishlistmain , setWishlist] = useState([]);
  const dataUser = useContext(UserContext);
  let barererToken = "Bearer " + localStorage.getItem("access_token");
  const [lastLoginTime, setLastLoginTime] = useState(null);
  const imageValue = useSelector((state) => state.profile);

  // cosnt userProfile Datails
  const userProfileDetails = useSelector((state) => state.userProfileData);
  //userPaln data in details
  const { loading } = useSelector((state) => state.planData);

  //all ads count
  const [allAdsCount, setAllAdsCount] = useState({});
  const [lan, setplan] = useState();

  const [staticLoader, setStaticLoader] = useState(false);
  let userId = localStorage.getItem("userid");
  // alll user plan api call in redux
  const [loading2, setLoading2] = useState(false);

  const getUserData = async (id) => {
    setLoading2(true);
    try {
      await dispatch(getUserPlan(userId));
      setLoading2(false);
    } catch {
      setLoading2(false);
    }
  };
  useEffect(() => {
    getUserData(userId);
  }, [userId]);
  const [business, setBusiness] = useState(); // Initialize with null
  const useridst = localStorage.getItem("userid");

  useEffect(() => {
    var formdata = new FormData();
    formdata.append("userid", useridst);
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(url + "api/adsapi/checkBusinessPlan", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setBusiness(data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  const lastloginget = () => {
    var formdata = new FormData();
    formdata.append("user", useridst);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch("https://databytess.com/api/user/lastLoginTimeGet", requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((result) => {
        // Check the structure of the API response and log it for debugging
        console.log("API Response:", result);

        if (result?.fields?.lastloginValue) {
          setLastLoginTime(result.fields.lastloginValue);
        } else {
          console.error("Expected data not found in API response");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    setPlan(dataUser.activePlan);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", barererToken);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(url + "api/user/profile/", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setprofileform((profileform.name = result.name));
        setName(result.name);
        setEmail(result.email);
        setId(result.id);
        lastloginget(result?.id);
        setImage(result.image);
        localStorage.setItem("userid", result.id);
      })
      .catch((error) => {
        setError("Having Some Issue");
      });
    checkname();
  }, []);

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", barererToken);

    var formdata = new FormData();
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(url + "api/user/blogsby/", requestOptions)
      .then((response) => response.json())
      .then((result) => setBlog(result))
      .catch((error) => console.log("error", error));
    countvalue();
  }, []);

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", barererToken);

    var formdata = new FormData();
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(url + "api/user/adsby/", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result, "Amit");
        setAdde(result);
      })
      .catch((error) => console.log("error", error));
  }, []);

  const countvalue = () => {
    ads.map((result) => {
      if (result.fields.is_featured == false) {
        setFeaturedData(FeaturedData + 1);
      }
    });
  };

  const checkname = () => {
    setTimeout(() => {
      if (name.startsWith("user")) {
        setnameFlag(true);
      }
    }, 2000);
  };

  useEffect(() => {
    dispatch(userProfileDataApi(userId));
  }, [userId]);

  useEffect(() => {
    var formdata = new FormData();
    formdata.append("userid", useridst);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(url + "api/adsapi/checkVerified", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setVerified(result.verifiedCustomer);
        console.log(result, "resvar");
      })
      .catch((error) => console.log("error", error));
  }, []);
  console.log(verified, "lo");
  const [battery, setBattery] = useState(53); // Assuming battery value ranges from 0 to 100
  const fetchingProfilePercentage = async () => {
    const userId = localStorage.getItem('userid');
    const newAvival= 112
    try {
      const response = await fetch(`https://databytess.com/api/user/profile-completion/?user=${userId}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      };
      const data = await response.json()
      setBattery(data.percentage_filled)

    } catch (error) {
      console.log("Error", error)
    }
  }

  useEffect(() => {
    fetchingProfilePercentage();

  }, []);
  const dashArray = 85 * Math.PI * 2;
  const dashOffset = dashArray - (dashArray * battery) / 100;
  // Calculate the width of the second bar based on the battery percentage
  const secondBarWidth = `${battery}%`;
  return (
    <>
      <div className="container  ">
        <div className="row">
          {/* Middle Content Area */}
          <div className="col-md-12 col-xs-12 col-sm-12">
            <section
              className="search-result-item"
              id="bussinessdash"
              style={{
                boxShadow: "2px 2px 8px lightgray",
              }}
            >
              <div className="row">
                <div className="col-md-2 col-xs-4 col-sm-12">
                  {verified ? (
                    <div>
                      {/* <button type="button" className="btn btn-primary position-relative">
  Inbox */}
                      <span
                        className="position-absolute top-20 start-22 translate-middle badge rounded-pill bg-info p-2 "
                        style={{ fontSize: "10px" }}
                      >
                        Verified User
                      </span>
                      {/* </button> */}

                      <a className="" href="#"  style={{display:'flex', justifyContent:'center'}}>
                        {/* {default_image} */}
                        {userProfileDetails.status ? (
                          <div className="w-100 d-flex justify-content-center h-100 align-items-center text-center ">
                            <div class="loader "></div>
                          </div>
                        ) : (
                          <div className="">
                            <div
                            //  style={isMobile ? mobileStyle : desktopStyle}
                            style={{width:'130px'}}
                            >
                              <svg
                                width={130}
                                height={130}
                                viewBox={"0 0 200 200"}
                              >
                                <foreignObject
                                  x="22.5"
                                  y="23.5"
                                  width="156.5"
                                  height="156.5"
                                >
                                  <div className="dp-profile">
                                    <img
                                      className="dp-profile"
                                      alt="error"
                                      src={
                                        userProfileDetails?.data.length === 0 ||
                                        userProfileDetails?.data[0]?.fields
                                          ?.image == "undefined"
                                          ? "https://cdn-icons-png.flaticon.com/512/8214/8214212.png"
                                          : userProfileDetails?.data[0]?.fields
                                              ?.image
                                      }
                                    />
                                  </div>
                                </foreignObject>
                                <circle
                                  cx={200 / 2}
                                  cy={200 / 2}
                                  strokeWidth="15px"
                                  r="85"
                                  className="progress-circle-1"
                                />

                                <circle
                                  cx={200 / 2}
                                  cy={200 / 2}
                                  strokeWidth="10px"
                                  r="85"
                                  className="progress-circle-2"
                                  style={{
                                    strokeDasharray: dashArray,
                                    strokeDashoffset: dashOffset,
                                  }}
                                  transform={`rotate(-90 ${200 / 2} ${200 /
                                    2})`}
                                />
                                <text
                                  x="50%"
                                  y="50%"
                                  dy="0.3em"
                                  textAnchor="middle"
                                  fill="red"
                                  className="progress-circle-text"
                                >
                                  {battery}%
                                </text>
                              </svg>
                              <div>
                                <input
                                  className="d-none"
                                  type="range"
                                  min={1}
                                  max={100}
                                  step={1}
                                  value={battery}
                                  onChange={(e) =>
                                    setPercentage(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </a>
                    </div> //dsds
                  ) : (
                    <a className="" href="#"  style={{display:'flex', justifyContent:'center'}}>
                      {/* {default_image} */}
                      {userProfileDetails.status ? (
                        <div className="w-100 d-flex justify-content-center h-100 align-items-center text-center ">
                          <div class="loader "></div>
                        </div>
                      ) : (
                        <div 
                        // style={isMobile ? mobileStyle : desktopStyle}
                        style={{width:'130px'}}
                        >
                          <svg width={130} height={130} viewBox={"0 0 200 200"}>
                            <foreignObject
                              x="22.5"
                              y="23.5"
                              width="156.5"
                              height="156.5"
                            >
                              <div className="dp-profile">
                                <img
                                  className="dp-profile"
                                  alt="error"
                                  src={
                                    userProfileDetails?.data.length === 0 ||
                                    userProfileDetails?.data[0]?.fields
                                      ?.image == "undefined"
                                      ? "https://cdn-icons-png.flaticon.com/512/8214/8214212.png"
                                      : userProfileDetails?.data[0]?.fields
                                          ?.image
                                  }
                                />
                              </div>
                            </foreignObject>
                            <circle
                              cx={200 / 2}
                              cy={200 / 2}
                              strokeWidth="15px"
                              r="85"
                              className="progress-circle-1"
                            />

                            <circle
                              cx={200 / 2}
                              cy={200 / 2}
                              strokeWidth="15px"
                              r="85"
                              className="progress-circle-2"
                              style={{
                                strokeDasharray: dashArray,
                                strokeDashoffset: dashOffset,
                              }}
                              transform={`rotate(-90 ${200 / 2} ${200 / 2})`}
                            />
                            <text
                              x="50%"
                              y="50%"
                              dy="0.3em"
                              textAnchor="middle"
                              fill="red"
                              className="progress-circle-text"
                            >
                              {battery}%
                            </text>
                          </svg>
                          <div>
                            <input
                              className="d-none"
                              type="range"
                              min={1}
                              max={100}
                              step={1}
                              value={battery}
                              onChange={(e) => setPercentage(e.target.value)}
                            />
                          </div>
                        </div>
                      )}
                    </a>
                  )}
                  {/* <Button
                    type="button"
                     className="bbuttonmanage"
                     style={{marginTop:"15px"}}
                  >
                    <Link to="/Featured">Manage Add Postings</Link>
                  </Button> */}
                </div>
                <div className="col-md-10 col-xs-8 col-sm-12">
                  <div className="">
                    <div className="row">
                      <div className="col-md-4 col-sm-12 col-xs-12  search-result-item-heading-div">
                        {verified ? (
                          <h4 className="search-result-item-heading">
                            {name}
                            <GoVerified style={{ color: "blue" }} />
                          </h4>
                        ) : (
                          <h4 className="search-result-item-heading">{name}</h4>
                        )}
                        <h7 className="search ">{email}</h7>
                        <p className="info">
                          <span>
                            <Link to="/dashboard/">
                              <i className="fa fa-user " />
                              Profile
                            </Link>
                          </span>
                        </p>
                        <p className="description">
                          You last logged in at :{lastLoginTime}
                        </p>{" "}
                        {/* <span className="label label-warning">
                          {
                            dataUser?.activePlan[
                              dataUser?.activePlan?.length - 1
                            ]?.fields?.category
                          }
                        </span> */}
                        &nbsp;
                        {dataUser?.activePlan[dataUser?.activePlan - 1]?.fields
                          ?.category == "Free" ? (
                          <span className="">
                            <Button type="danger">
                              <Link to="/pricing">
                                Upgrade your plan to Featured Plan
                              </Link>
                            </Button>
                          </span>
                        ) : dataUser?.activePlan[dataUser?.activePlan - 1]
                            ?.fields?.category == "Premium" ? (
                          <span className="">
                            <Button type="danger">
                              <Link to="/pricing">
                                Upgrade your plan to Recommended
                              </Link>
                            </Button>
                          </span>
                        ) : dataUser?.activePlan[dataUser?.activePlan - 1]
                            ?.fields?.category == "Featured" ? (
                          <span className="">
                            <Button type="danger">
                              <Link to="/pricing">
                                Upgrade your plan to Premium
                              </Link>
                            </Button>
                          </span>
                        ) : (
                          <span className="mx-1 hover:bg-violet-600 active:bg-violet-700">
                            <Button
                              className="hover:bg-violet-600 active:bg-violet-700"
                              type="button"
                              style={{
                                // boxShadow: "2px 1px red, -2px -1px red",
                                // background: "	#8a2be2",
                                background:
                                  "linear-gradient(to right, #2e95ff, #0061c6)",
                                color: "white",
                                borderRadius: "15px",
                                border: "0px solid red",
                                fontSize: "16px",
                                // marginLeft: "10px",
                                marginTop: "10px",
                              }}
                            >
                              <Link to="/allCategories/">Change Plan</Link>
                            </Button>
                          </span>
                        )}
                      </div>
                      <div className="col-md-8 col-sm-12 col-xs-12">
                        {loading ? (
                          <div className="w-100 d-flex justify-content-center h-100 align-items-center bg-dark text-center ">
                            <div class="loader "></div>
                          </div>
                        ) : (
                          <>
                            <div>
                              <div className="dp-ud-b">
                                <div className="">
                                  <div className="row ad-history">
                                    <div className="Usertable col-lg-12">
                                      <div className="user-stats pb-0">
                                        <div className="inlineAds text-white">
                                          {business && (
                                            <table className="table ">
                                              <thead>
                                                <tr>
                                                  <th className="text-black py-0 pl-0">
                                                    Plan Type
                                                  </th>
                                                  <th className="text-black py-0 pl-0">
                                                    Total Ads
                                                  </th>
                                                  {/* <th>Response</th>
              <th className="text-black py-0 pl-0">Limit Days</th> */}
                                                  <th className="text-black py-0 pl-0">
                                                    Posted Ads
                                                  </th>
                                                  <th className="text-black py-0 pl-0">
                                                    Left Ads
                                                  </th>
                                                  <th className="text-black py-0 pl-0">
                                                    Validity
                                                  </th>
                                                  {/* <th>Tele Support</th>
              <th>Chat Support</th>
              <th>Dedicated Room</th>
              <th>Hol9 Web</th> */}
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {business.leftPlan.map(
                                                  (plan) => (
                                                    <tr key={plan}>
                                                      <td className="m-0 p-0">
                                                        {plan}
                                                      </td>
                                                      <td className="m-0 p-0">
                                                        {
                                                          business
                                                            .planDataDetails[
                                                            plan
                                                          ].totalAds
                                                        }
                                                      </td>
                                                      {/* <td>{business.planDataDetails[plan].reponse}</td>
                <td>{business.planDataDetails[plan].limitDays}</td> */}
                                                      <td className="m-0 p-0">
                                                        {
                                                          business
                                                            .planDataDetails[
                                                            plan
                                                          ].PostedregualAds
                                                        }
                                                      </td>
                                                      <td className="m-0 p-0">
                                                        {
                                                          business
                                                            .planDataDetails[
                                                            plan
                                                          ].leftRegularAds
                                                        }
                                                      </td>
                                                      <td className="m-0 p-0">
                                                        {
                                                          business
                                                            .planDataDetails[
                                                            plan
                                                          ].validity
                                                        }{" "}
                                                        days
                                                      </td>
                                                      {/* <td>{business.planDataDetails[plan].TeleSupport.toString()}</td>
                <td>{business.planDataDetails[plan].chatSupport.toString()}</td>
                <td>{business.planDataDetails[plan].DeticatedRm.toString()}</td>
                <td>{business.planDataDetails[plan].Hol9Web.toString()}</td> */}
                                                    </tr>
                                                  )
                                                )}
                                              </tbody>
                                            </table>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      {/* <UserDashPlanDetails/> */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <div className="col-xl-9 col-lg-12 col-md-12">
        {active === "Profile" && <Profile />}
        {active === "featured" && <Featured />}
      </div>
    </>
  );
};

export default BusinessProfileDashboard;
