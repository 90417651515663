import React, { useEffect, useState } from 'react'
import {useSelector } from "react-redux";

const Wishlistpopup = () => {
    const wishlistData = useSelector((state) => state.wishlistSlice);
    const [showNotification, setShowNotification] = useState(false);
    const [prevWishlistCount, setPrevWishlistCount] = useState(0);
  
    const [wishlistCount, setWishlistCount] = useState(0);
  
    useEffect(() => {
      const prevCount = localStorage.getItem('prevWishlistCount');
      if (prevCount) {
        setWishlistCount(wishlistData.data.length);
      } else {
        setWishlistCount(0);
      }
    }, [wishlistData]);
  
    useEffect(() => {
      const prevCount = localStorage.getItem('prevWishlistCount');
      if (wishlistCount > parseInt(prevCount)) {
        setShowNotification(true);
        setTimeout(() => {
          setShowNotification(false);
        }, 3000);
      }
  
      localStorage.setItem('prevWishlistCount', wishlistCount.toString());
    }, [wishlistCount]);
  return (

    <div>{showNotification && (
        <div className="popupr">
        {wishlistCount} Item in Your Wishlist
        </div>)}</div>
  )
}

export default Wishlistpopup