// import React, { useEffect, useState } from "react"
// import FeaturedAds from '../../../Ads/FeaturedAds/featuredAds';
import Feature from "../../../Ads/FeaturedAds/Feature"; // Importing the 'Feature' component
import CategoryUiSection from "../CategoryUiScetion/CategoryUiSection"; // Importing the 'CategoryUiSection' component
import SearchBoxMobile from "../searchBox/SearchBoxMobile"; // Importing the 'SearchBox' component
import RealEstateSection from "../section/realEstateSection"; // Importing the 'RealEstateSection' component
import Owldemo1 from "../../../Owldemo1"; // Importing the 'Owldemo1' component
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SearchOutlined } from "@ant-design/icons";
import NewsArticles from "../../../Blogs/NewsArticles"; // Importing the 'NewsArticles' component
import MyGallery from "./MyGallery";
import { useContext, useEffect, useState } from "react"; // Importing the necessary hooks from React
import { UserContext } from "../../../../App"; // Importing the 'UserContext' from the 'App' component
import { Input, Spin } from "antd";
import { useDispatch } from "react-redux";
import { add } from "../../../../store/Track/trackUserSlice";
import { getExtraField } from "../../../../store/ProductFilterSlice"; // Importing getExtraField function from ProductFilterSlice
import apple from "../../../images/apple.png";
import blackberry from "../../../images/blackberry.png";
import blueberry from "../../../images/blueberry.jpeg";
import centric from "../../../images/centric.jpeg";
import creo from "../../../images/creo.png";
import htc from "../../../images/htc.png";
import lava from "../../../images/lava.png";
import redmi from "../../../images/Redmi.png";
import {
  All,
  subcategoryRealEstateBuy, // Imported from "../../../env"
  subcategoryRealEstateRent, // Imported from "../../../env"
  subcategoryType1, // Imported from "../../../env"
} from "../../../env";
import { stateMain, locationData } from "../../../Location";
import "../searchBox/SearchBox.css";
import { Link } from "react-router-dom";
import MobileSlider from "../../../../Shared/CategorySlider/MobileSlider";
import { Carousel } from "antd";
import { FitScreen } from "@mui/icons-material";
import "./allfilter.css";
import { isMobile } from "react-device-detect";
import { searchApi } from "../../../../store/ToggleSearchSlice";
import TrendingAds from "../../../Ads/Trending Ads/TrendingAds";
import Allcatads from "../../../Allcatads";
import { AiOutlineCloseSquare, AiOutlineFilter } from "react-icons/ai";
import { BsFilter } from "react-icons/bs";
import { BiSearch } from "react-icons/bi";

const mobi = {};
const deski = {
  marginLeft: "7rem",
  marginRight: "7rem",
};
const mobstyle = {
  width: "auto",
  marginLeft: "16px",
  marginRight: "16px",
};
const deskstyle = {
  padding: "20px",
  marginBottom: "-10px",
  marginRight: "10rem",
  marginLeft: "10rem",
};
const mobilestyle = {
  display: "flex",
  flexDirection: "column",
  width: "auto",
  margin: "0.5rem",
};
const desktopstyle = {
  width: "90%",
};
const mobilestyle1 = {
  display: "none",
};
const desktopstyle1 = {
  display: "flex",
  flexDirection: "column",
  marginLeft: "2.5rem",
  marginRight: "2.5rem",
};
const mobileimg = {
  width: "100%",
};
const desktopimg = {
  width: "100%",
  padding: "10px",
};
const mob1 = {
  width: "350px",
  marginLeft: "-60px",
};

const desk1 = {
  borderRadius: "20px",
  backgroundColor: "white",
};
const mobsty = {
  display: "flex",
  flexDirection: "column",
};

const desksty = {};

const mobiles = [
  {
    brand: "Apple",
    model: "iPhone 13",
    image:
      "https://images.unsplash.com/photo-1616353071588-708dcff912e2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8YXBwbGUlMjBpcGhvbmV8ZW58MHx8MHx8fDA%3D&w=1000&q=80",
  },
  {
    brand: "Apple",
    model: "iPhone 12",
    image:
      "https://images.unsplash.com/photo-1606041011872-596597976b25?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8YXBwbGUlMjBpcGhvbmV8ZW58MHx8MHx8fDA%3D&w=1000&q=80",
  },
  {
    brand: "One Plus",
    model: "Galaxy S21",
    image:
      "https://www.techadvisor.com/wp-content/uploads/2023/04/Best-OnePlus-phone.jpg?quality=50&strip=all",
  },
  {
    brand: "One Plus",
    model: "Galaxy S22",
    image:
      "https://www.androidauthority.com/wp-content/uploads/2022/12/OnePlus-11-5G-2.jpg",
  },
  {
    brand: "Samsung",
    model: "Galaxy S21",
    image:
      "https://images.unsplash.com/photo-1610945265064-0e34e5519bbf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8c2Ftc3VuZyUyMHBob25lfGVufDB8fDB8fHww&w=1000&q=80",
  },
  {
    brand: "Samsung",
    model: "Galaxy S22",
    image:
      "https://images.unsplash.com/photo-1610792516307-ea5acd9c3b00?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8c2Ftc3VuZyUyMHBob25lfGVufDB8fDB8fHww&w=1000&q=80",
  },

  {
    brand: "OPPO",
    model: "oppo",
    image:
      "https://i.ytimg.com/vi/fQjVDYb5NXQ/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLDdkMxhOLx1DczZN5JcdyxAtY7jow",
  },
  {
    brand: "OPPO",
    model: "oppo",
    image:
      "https://os-wordpress-media.s3.ap-south-1.amazonaws.com/blog/wp-content/uploads/2022/04/13095245/Oppo-F21-Pro-OnSiteGo.jpeg",
  },
  {
    brand: "Vivo",
    model: "vivo",
    image:
      "https://www.91-cdn.com/hub/wp-content/uploads/2023/02/Upcoming-Vivo-phones-in-2023.png",
  },
  {
    brand: "Vivo",
    model: "vivo",
    image:
      "https://www.91-cdn.com/hub/wp-content/uploads/2022/09/vivo-x90-specs-tipped.png.jpeg",
  },
  {
    brand: "MI",
    model: "redmi",
    image:
      "https://w0.peakpx.com/wallpaper/110/397/HD-wallpaper-xiaomi-android-mobile-phone-2019.jpg",
  },
  {
    brand: "MI",
    model: "redmi",
    image:
      "https://www.smartprix.com/bytes/wp-content/uploads/2022/11/Full_Xiaomi_12_series-photoutils.com_.jpeg",
  },
  {
    brand: "Realme",
    model: "Realme",
    image:
      "https://www.techadvisor.com/wp-content/uploads/2022/06/best_realme_phone.jpg?quality=50&strip=all",
  },
  {
    brand: "Realme",
    model: "Realme",
    image:
      "https://www.noypigeeks.com/wp-content/uploads/2022/04/Realme-Price-List-Philippines-NoypiGeeks.jpg",
  },
  {
    brand: "Sony",
    model: "Realme",
    image:
      "https://gagadget.com/media/post_big/xperia-pro-i__cameralineup_16.9-scaled_large_large.jpg",
  },
  {
    brand: "Sony",
    model: "Realme",
    image:
      "https://thepakistanaffairs.com/wp-content/uploads/2022/07/Sony-Xperia-IV.jpg",
  },
  {
    brand: "Nokia",
    model: "Nokia",
    image: "https://nokiamob.net/wp-content/uploads/2020/07/Nokia-8.3.jpg",
  },
  {
    brand: "Nokia",
    model: "Nokia",
    image:
      "https://i0.wp.com/nokiamob.net/wp-content/uploads/2021/11/DSC03272-3.jpg?resize=640%2C359&ssl=1",
  },
];
const Mobilescat = () => {
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [autoSlide, setAutoSlide] = useState(true);
  const handleClosePopup = () => {
    setPopupOpen(false);
  };
  const handleFilterClick = () => {
    setPopupOpen(true);
  };
  useEffect(() => {
    const intervalId = autoSlide ? setInterval(nextSlide, 2000) : null;

    return () => {
      clearInterval(intervalId);
    };
  }, [autoSlide]);

  const brandClickHandler = (brand) => {
    setSelectedBrand(brand);
    setAutoSlide(false);
  };

  const nextSlide = () => {
    setAutoSlide(true);
  };

  const filteredMobiles = selectedBrand
    ? mobiles.filter((mobile) => mobile.brand === selectedBrand)
    : mobiles;
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);

  const [SearchValue, setSearchValue] = useState("");

  const [brandName, setBrandName] = useState(null);
  const [price, setPrice] = useState(null);
  const [condition, setCondition] = useState(null);
  const [model, setModel] = useState(null);

  const searchArray = () => {
    if (SearchValue?.trim()) {
      dispatch(searchApi(SearchValue));
    }
  };

  const handleFilterButtonClick = (filterType) => {
    setSelectedFilter(filterType);
  };
  const greeting = "Mobiles";

  const hola9 = useContext(UserContext); // Accessing the 'UserContext' using the 'useContext' hook
  const [loading, setLoading] = useState(false); // Setting up a state variable 'loading' and its setter function 'setLoading' with initial value 'false'
  const disptach = useDispatch(); // Accessing the Redux dispatch function
  const dispatch = useDispatch();
  // Accessing the Redux dispatch function

  useEffect(() => {
    disptach(add({ view: ["Mobilescat"] })); // Dispatching an action using Redux to add the view to the user's track
  }, []);

  const [searchSending, setsearchSending] = useState({
    subCategoryType: null,
    subCategoryValue: null,
    locationDataMain: null,
    searchValue: null,
    category: "Mobiles",
    minPrice: null,
    maxPrice: null,
  }); // State for search form data

  //   const disptach = useDispatch(); // Initializing useDispatch hook

  // Event handler for input field changes
  const [extraFieldCopy, setExtraFieldCopy] = useState({});
  const [extraFieldCopy1, setExtraFieldCopy1] = useState({});
  const [extraFieldCopy2, setExtraFieldCopy2] = useState({});

  const handleChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    setExtraFieldCopy({ [name]: value }); // Dispatching getExtraField action
  };

  useEffect(() => {
    // Update searchSending state with the selected filter value
    if (selectedFilter !== null) {
      setsearchSending((prevState) => ({
        ...prevState,
        category: selectedFilter,
      }));
    }
  }, [selectedFilter]);

  const [selectedButton, setSelectedButton] = useState("button1", "Bikes");

  // Step 2: Create CSS classes to control modal positioning
  const modalContainerStyle = {
    top: "-50%", // Adjust this value to control the distance from the button

    // width:"100%"
  };

  const modalStyle = {
    // position: "relative",

    // display:"flex",
    // width:"100%",
    top: "-50%", // Adjust this value to control the distance from the button
    left: 0,
    zIndex: 10, // Ensure the modal is above other elements
    backgroundColor: "white",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
  };

  const renderModalContent = () => {
    if (selectedButton === "button1") {
      return (
        <div style={modalStyle} className="brandimg">
          <img
            src={apple}
            alt=""
            height={100}
            width={100}
            className={`${brandName == "Apple" ? "brandName" : ""}`}
            onClick={() => {
              setExtraFieldCopy({ "Brand Name": "Apple" });
              setBrandName("Apple");
            }}
          />
          <img
            src={blackberry}
            alt=""
            className={`${brandName == "blackBerry" ? "brandName" : ""}`}
            height={100}
            width={100}
            onClick={() => {
              setExtraFieldCopy({ "Brand Name": "Blackberry" });
              setBrandName("blackBerry");
            }}
          />
          <img
            src={blueberry}
            alt=""
            className={`${brandName == "blueBerry" ? "brandName" : ""}`}
            height={100}
            width={100}
            onClick={() => {
              setExtraFieldCopy({ "Brand Name": "Blueberry" });
              setBrandName("blueBerry");
            }}
          />
          <img
            src={centric}
            alt=""
            height={100}
            width={100}
            className={`${brandName == "centric" ? "brandName" : ""}`}
            onClick={() => {
              setExtraFieldCopy({ "Brand Name": "Centric" });
              setBrandName("centric");
            }}
          />
          <img
            src={creo}
            alt=""
            height={100}
            width={100}
            className={`${brandName == "creo" ? "brandName" : ""}`}
            onClick={() => {
              setExtraFieldCopy({ "Brand Name": "Creo" });
              setBrandName("creo");
            }}
          />
          <img
            src={htc}
            alt=""
            height={100}
            width={100}
            className={`${brandName == "HTC" ? "brandName" : ""}`}
            onClick={() => {
              setExtraFieldCopy({ "Brand Name": "HTC" });
              setBrandName("HTC");
            }}
          />
          <img
            src={lava}
            alt=""
            height={100}
            width={100}
            className={`${brandName == "lava" ? "brandName" : ""}`}
            onClick={() => {
              setExtraFieldCopy({ "Brand Name": "Lava" });
              setBrandName("lava");
            }}
          />
          <img
            src={redmi}
            alt=""
            height={100}
            width={100}
            className={`${brandName == "MI" ? "brandName" : ""}`}
            onClick={() => {
              setExtraFieldCopy({ "Brand Name": "MI" });
              setBrandName("MI");
            }}
          />
          <img
            src="https://cdn.wallpapersafari.com/68/37/JX8ArI.png"
            alt=""
            height={100}
            width={100}
            className={`${brandName == "One Plus" ? "brandName" : ""}`}
            onClick={() => {
              setExtraFieldCopy({ "Brand Name": "One Plus" });
              setBrandName("One Plus");
            }}
          />
          <img
            src="https://th.bing.com/th/id/R.17baa4296a7530d59a9cce8c83d88424?rik=brdWhxDZENGqyQ&riu=http%3a%2f%2fimg1.mydrivers.com%2fimg%2f20190410%2ff616dd7be271497d839aef6fd579ad6a.jpg&ehk=xhI%2baxA62XCMJclEOPFVVj2duyN%2bx0xqYfqeznoeMfE%3d&risl=&pid=ImgRaw&r=0"
            alt=""
            height={100}
            width={100}
            className={`${brandName == "Oppo" ? "brandName" : ""}`}
            onClick={() => {
              setExtraFieldCopy({ "Brand Name": "Oppo" });
              setBrandName("Oppo");
            }}
          />
          <img
            src="https://logos-world.net/wp-content/uploads/2020/04/Samsung-Logo.png"
            alt=""
            height={100}
            width={100}
            className={`${brandName == "Samsung" ? "brandName" : ""}`}
            onClick={() => {
              setExtraFieldCopy({ "Brand Name": "Samsung" });
              setBrandName("Samsung");
            }}
          />
        </div>
      );
    } else if (selectedButton === "button2") {
      return (
        <div style={modalStyle} className="brandimg">
          <p
            className={`pricep ${price == 10000 ? "price" : ""}`}
            onClick={() => {
              setsearchSending((prev) => ({
                ...prev,
                minPrice: "0",
                maxPrice: "10000",
              }));
              setPrice(10000);
            }}
          >
            Under 10000
          </p>
          <p
            className={`pricep ${price == 20000 ? "price" : ""}`}
            onClick={() => {
              setsearchSending((prev) => ({
                ...prev,
                minPrice: "10000",
                maxPrice: "20000",
              }));
              setPrice(20000);
            }}
          >
            Under 20000
          </p>
          <p
            className={`pricep ${price == 30000 ? "price" : ""}`}
            onClick={() => {
              setsearchSending((prev) => ({
                ...prev,
                minPrice: "20000",
                maxPrice: "30000",
              }));
              setPrice(30000);
            }}
          >
            Under 30000
          </p>
          <p
            className={`pricep ${price == 50000 ? "price" : ""}`}
            onClick={() => {
              setsearchSending((prev) => ({
                ...prev,
                minPrice: "30000",
                maxPrice: "50000",
              }));
              setPrice(50000);
            }}
          >
            Under 50000
          </p>
          <p
            className={`pricep ${price == 80000 ? "price" : ""}`}
            onClick={() => {
              setsearchSending((prev) => ({
                ...prev,
                minPrice: "50000",
                maxPrice: "80000",
              }));
              setPrice(80000);
            }}
          >
            Under 80000
          </p>
          <p
            className={`pricep ${price == 100000 ? "price" : ""}`}
            onClick={() => {
              setsearchSending((prev) => ({
                ...prev,
                minPrice: "80000",
                maxPrice: "100000",
              }));
              setPrice("100000");
            }}
          >
            Under 1 Lakh
          </p>
          <p
            className={`pricep ${price == 120000 ? "price" : ""}`}
            onClick={() => {
              setsearchSending((prev) => ({
                ...prev,
                minPrice: "100000",
                maxPrice: "120000",
              }));
              setPrice(120000);
            }}
          >
            Under 1.2 Lakh
          </p>
          <p
            className={`pricep ${price == 200000 ? "price" : ""}`}
            onClick={() => {
              setsearchSending((prev) => ({
                ...prev,
                minPrice: "120000",
                maxPrice: "200000",
              }));
              setPrice(200000);
            }}
          >
            Above 2Lakh
          </p>
        </div>
      );
    } else if (selectedButton === "button3") {
      return (
        <div style={modalStyle} className="brandimg">
          <p
            className={`pricep ${condition == "Excellent" ? "condition" : ""}`}
            onClick={() => {
              setExtraFieldCopy1({ "Physical Condition": "Excellent" });
              setCondition("Excellent");
            }}
          >
            Excellent
          </p>
          <p
            className={`pricep ${condition == "Good" ? "condition" : ""}`}
            onClick={() => {
              setExtraFieldCopy1({ "Physical Condition": "Good" });
              setCondition("Good");
            }}
          >
            Good
          </p>
          <p
            className={`pricep ${condition == "Fair" ? "condition" : ""}`}
            onClick={() => {
              setExtraFieldCopy1({ "Physical Condition": "Fair" });
              setCondition("Fair");
            }}
          >
            Fair
          </p>

          {/* <img
            src="https://imgd.aeplcdn.com/0x0/bw/static/icons/svg/bike-body-styles/sports.svg?v=20210319"
            height={80}
            width={80}
            alt=""
          />
          <img
            src="https://imgd.aeplcdn.com/0x0/bw/static/icons/svg/bike-body-styles/scooters.svg?v=20210319"
            height={80}
            width={80}
            alt=""
          /> */}
          {/* <img
            src="https://imgd.aeplcdn.com/0x0/bw/static/icons/svg/bike-body-styles/moped.svg?v=20210319"
            title="moped"
            height={80}
            width={80}
            alt=""
          />
          <img
            src="https://imgd.aeplcdn.com/0x0/bw/static/icons/svg/bike-body-styles/cruiser.svg?v=20210319"
            height={80}
            width={80}
            alt=""
          />
          <img
            src="https://imgd.aeplcdn.com/0x0/bw/static/icons/svg/bike-body-styles/tourer.svg?v=20210319"
            height={80}
            width={80}
            alt=""
          />
          <img
            src="https://imgd.aeplcdn.com/0x0/bw/static/icons/svg/bike-body-styles/commuter.svg?v=20210319"
            height={80}
            width={80}
            alt=""
          />
          <img
            src="https://imgd.aeplcdn.com/0x0/bw/static/icons/svg/bike-body-styles/adventure.svg?v=20210319"
            height={80}
            width={80}
            alt=""
          /> */}
        </div>
      );
    } else {
      return (
        <div style={modalStyle} className="brandimg">
          <p
            className={`pricep ${model == "New" ? "Model" : ""}`}
            onClick={() => {
              setExtraFieldCopy2({ Model: "New" });
              setModel("New");
            }}
          >
            New
          </p>
          <p
            className={`pricep ${model == "Old" ? "Model" : ""}`}
            onClick={() => {
              setExtraFieldCopy2({ Model: "Old" });
              setModel("Old");
            }}
          >
            Old
          </p>
        </div>
      );
    }

    return null;
  };


  // AdsCards logic start

  const [advClose, setadvClose] = useState(true);  
    useEffect(() => {
    const isPopupClosed = sessionStorage.getItem('popupClosed');
    const name = localStorage.getItem('name')
    if (isPopupClosed && name) {
      setadvClose(false);
    }
  }, []);
  const handleCloseadv = () => {
    setadvClose(false);
    sessionStorage.setItem('popupClosed', 'true');
  };
  // AdsCards logic end


  

    return (
    <>


     {/* AdsCards logic start ---------------------------------------------------------------------------------------------------*/}
     {advClose ? (
        <div
          style={{
            position: "fixed",
            bottom: "2%",
            right: "2%",
            width: "350px",
            height: "170px",
            zIndex: "3",
            border: "0.5px solid gray",
            background:
              // "url(https://akam.cdn.jdmagicbox.com/images/icontent/newwap/web2022/floatbanner_freelisting.png)",
              'url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTebAzHqb0h7eBS4-T-rUuZElPy8O051IMewW8vraYmmRxCdGBXQdsqfn97qco3g4GgJ8w&usqp=CAU)',
            backgroundSize: "100% 100%",
            borderRadius: '7px'
          }}
        >
          <span style={{ float: 'right', padding: '2px', background: 'transparent', color: 'black', cursor: 'pointer', borderRadius: '10px',fontSize:"18px",  marginRight:'6px'  }} onClick={handleCloseadv}>x</span>
          <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', gap: '20px' }}>
            <div style={{ color: 'white' }}>
              Get the best <br />
              <span style={{ color: 'darkblue' }}>Bussiness Plan in Hola9</span>
            </div>
            <div style={{ color: 'whitesmoke', fontWeight: '600' }}>
              Grow Your Business
            </div>
            <Link to='/Pricing' style={{ background: 'gold', color: 'whitesmoke', width: 'fit-content', padding: '3px 10px', borderRadius: '7px' }}>Get your Business plan</Link>
          </div>
        </div>
      ) : null}
      {/*------------------------------------------------------------------------------- AdsCards logic end */}

  

      <MobileSlider />
      <div className="filmain">
      <div className="fildi">
      <input
        name="Brand Name"
        required
        className=""
        style={{
          textTransform: "uppercase",
          border: "none",
          paddingLeft:"10px"
        }}
        placeholder="Search Here"
        onChange={(e) => setSearchValue(e.target.value.trim())}
      />
      <div className="tildiv">
        <Link to={`/ads-listing1/`} onClick={searchArray}>
          <button className="search-btn"><BiSearch  style={{fontSize: "20px"}}/>Search</button>
        </Link>
          &nbsp;
          &nbsp;
        <button className="filter-btn" onClick={handleFilterClick}>
          <AiOutlineFilter style={{fontSize: "20px"}}/>
         
          Filter
        </button>
      </div>
    </div>
      </div>

      {isPopupOpen && (
        <div className="fildi2" style={isMobile ? mob1 : desk1}>
          <div>
            <button onClick={handleClosePopup} style={{ float: "right" }}>
              <AiOutlineCloseSquare
                style={{ color: "red", fontSize: "20px" }}
              />
            </button>
            <SearchBoxMobile />
          </div>
        </div>
      )}
      <>
        <div className="wholefil mt-0">
          {/*<div>
            <div style={modalContainerStyle} className="bikefilcon">
              <h3
                style={{
                  fontFamily: "sans-serif",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                Find Your Mobile Brand:
              </h3>
              <div
                style={{ width: "90%", display: "flex", flexDirection: "row" }}
              >
                <button
                  onClick={() => setSelectedButton("button1")}
                  className="button123"
                >
                  Brand Name
                </button>{" "}
                &nbsp;&nbsp;&nbsp;
                <button
                  onClick={() => setSelectedButton("button2")}
                  className="button123"
                >
                  Year of Purchase
                </button>{" "}
                &nbsp;&nbsp;&nbsp;
                <button
                  onClick={() => setSelectedButton("button3")}
                  className="button123"
                >
                  Physical Condition
                </button>{" "}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <button
                  onClick={() => setSelectedButton("button4")}
                  className="button123"
                >
                  Model
                </button>
                <Link
                  to={`/ads-listing/`}
                  onClick={() => {
                    debugger;
                    console.log(searchSending);
                    for (const key in searchSending) {
                      if (
                        searchSending[key]?.trim() === "" ||
                        searchSending[key] === null
                      ) {
                        delete searchSending[key];
                      }
                    }
                    searchSending["extraField"] = {
                      ...extraFieldCopy,
                      ...extraFieldCopy1,
                      ...extraFieldCopy2,
                    }; // Object Assign to searchSending object/ Object Assign to searchSending object

                    disptach(getExtraField(searchSending)); // Dispatching getExtraField action
                  }}
                >
                  <button
                    className="btn houzez-search-button elementor-button elementor-size-sm"
                    style={{
                      background: "#a71616bf",
                      color: "white",
                    }}
                    id="serbtn"
                  >
                    Search
                  </button>
                </Link>
              </div>
              <br />
              {selectedButton && renderModalContent()}
            </div>
          </div>*/}
          {/* bike cat head end */}
          {/* search end */}
          {loading && ( // Conditionally rendering a loading spinner based on the 'loading' state
            <div>
              <h5>
                {" "}
                <div className="example">
                  <Spin />
                </div>{" "}
              </h5>{" "}
            </div>
          )}
          {!loading && (
            <>
              <div className="mb-3" style={isMobile ? mobstyle : deskstyle}>
                <Feature value="Mobiles" />{" "}
                <Allcatads greeting={greeting} subcategory={"MobilePhones"} onClick={handleFilterClick} />
              </div>
              <div style={isMobile ? mobi : deski}>
                <TrendingAds />
              </div>
            </>
          )}
          <NewsArticles category={"Mobiles"} />{" "}
          {/* Rendering the 'NewsArticles' component with category "RealEstate" */}
          <MyGallery selectedCategory="Mobiles" />
        </div>
      </>
      {/* <NewsArticles category={"RealEstate"}/> */}
    </>
  );
};

export default Mobilescat;
