
// import React, { useEffect, useState } from 'react';
// import './JobsMainfilter.css'; // Create a CSS file for styling
// import { 
//   Education1,
//   MinExp,
//   MaxExp,
//   Industry1,
//   SelectTenure,
//   PermanentFullTime,
//   PermanentPartTime,
//   Annualsalary,
//   TimingJob,
//   From1,
//   To1,
//   CityMain,
// } from '../../../env';
// import { useDispatch } from 'react-redux';
// import { add } from '../../../../store/wishlistSlice';
// import { Link } from 'react-router-dom';
// import { getExtraField } from '../../../../store/ProductFilterSlice';

// function JobsMainfilter() {
//   const [jobType, setJobType] = useState(null);
//   const [selectedFilters, setSelectedFilters] = useState([]);
//   const [selectedFilters1, setSelectedFilters1] = useState([]);


  
//   const [educationSubFilter, setEducationSubFilter] = useState();
//   const [educationSubFilter1, setEducationSubFilter1] = useState();




//   const [isDropdownVisible, setIsDropdownVisible] = useState({
//     education: false,
//     minExp: false,
//     maxExp: false,
//     industry: false,
//     selectTenure: false,
//     minSalary: false,
//     maxSalary: false,
//     jobType: false,
//     CityMain:false,
//     TimingJob:false,
//     From1:false,
//     To1:false,

//   });
//   const [selectedFilter, setSelectedFilter] = useState(null);

//   const [loading, setLoading] = useState(false); // Setting up a state variable 'loading' and its setter function 'setLoading' with initial value 'false'
//   const disptach = useDispatch();
//    // Accessing the Redux dispatch function


//   const [extraFieldCopy, setExtraFieldCopy] = useState({});
//   const [extraFieldCopy1,setExtraFieldCopy1] = useState({});
  

//   const [searchSending, setsearchSending] = useState({
//     subCategoryType: null,
//     subCategoryValue: null,
//     locationDataMain: null,
//     searchValue: null,
//     category: "Jobs",
//     minPrice: null,
//     maxPrice: null,
//   }); // State for search form data

//   //   const disptach = useDispatch(); // Initializing useDispatch hook

//   // Event handler for input field changes
//   const handleChange = (e) => {
//     var name = e.target.name;
//     var value = e.target.value;
//     setExtraFieldCopy({ [name]: value });
//     // setsearchSending({ ...searchSending, [name]: value });
//     // disptach(getExtraField(searchSending)); // Dispatching getExtraField action
//   };

//   useEffect(() => {
//     // Update searchSending state with the selected filter value
//     if (selectedFilter !== null) {
//       setsearchSending((prevState) => ({
//         ...prevState,
//         category: selectedFilter,
//       }));
//     }
//   }, [selectedFilter]);

//   const handleJobTypeChange = (event) => {
//     setJobType(event.target.value);
//     setSelectedFilters([]);
//     setEducationSubFilter(event);
//     setEducationSubFilter1(event);



//     setIsDropdownVisible((prevState) => ({
//       ...prevState,
//       education: false,
//       minExp: false,
//       maxExp: false,
//       industry: false,
//       selectTenure: false,
//       minSalary: false,
//       maxSalary: false,
//       jobType: false,
//       CityMain:false,
//       TimingJob:false,
//       From1:false,
//       To1:false,
//     }));
//   };

//   const toggleDropdown = (dropdownName) => {
//     setIsDropdownVisible((prevState) => ({
//       ...prevState,
//       [dropdownName]: !prevState[dropdownName],
//     }));
//   };

//   const handleSubFilterChange = (event) => {
//     const value = event.target.value;
//     setSelectedFilters((prevFilters) => {
//       setEducationSubFilter({"education" : event.target.value});
//       if (prevFilters.includes(value)) {
//         return prevFilters.filter((filter) => filter !== value);
//       } else {
//         return [...prevFilters, value];
//       }
//     });
//   };

//   const handleSubFilterChange1 = (event) => {
//     const value = event.target.value;
//     setSelectedFilters1((prevFilters) => {
//       setEducationSubFilter1({"minExp" : event.target.value});
//       if (prevFilters.includes(value)) {
//         return prevFilters.filter((filter) => filter !== value);
//       } else {
//         return [...prevFilters, value];
//       }
//     });
//   };




//   // const handleEducationSubFilterChange = (event) => {
//   //   setEducationSubFilter({"Education" : event.target.value});
//   // };

//   const handleFindButtonClick = () => {
//     // Handle the logic for filtering here based on selectedFilters and educationSubFilter
//     console.log('Selected Filters:', selectedFilters);
//     console.log('Education Sub-Filter:', educationSubFilter);

//   };

//   return (
//     <div className="main-filter">
//       <h1>JOB FILTER</h1>
//       <div className="sub-filter">
//         <label>Select Job Type:</label>
//         <select onChange={handleJobTypeChange}>
//           <option value="">Select...</option>
//           <option value="Contract Job">CONTRACT JOB</option>
//           <option value="Fulltime Job">FULL TIME</option>
//           <option value="Walkin Job">WALK-IN</option>
//         </select>
//       </div>



// {/* Contarct job JobType Start */}

//       {jobType === "Contract Job" && (
//         <div className="sub-filter">
//           <p>
//             <button onClick={() => toggleDropdown('education')}>Education</button>
//             {isDropdownVisible.education && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange} value={selectedFilters}>
//                   {Education1.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//           <p>
//             <button onClick={() => toggleDropdown('minExp')}>Min Exp</button>
//             {isDropdownVisible.minExp && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange1} value={selectedFilters1}>
//                   {MinExp.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//           <p>
//             <button onClick={() => toggleDropdown('maxExp')}>Max Exp</button>
//             {isDropdownVisible.maxExp && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange} value={selectedFilters}>
//                   {MaxExp.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//           <p>
//             <button onClick={() => toggleDropdown('industry')}>Industry</button>
//             {isDropdownVisible.industry && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange} value={selectedFilters}>
//                   {Industry1.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//           <p>
//             <button onClick={() => toggleDropdown('selectTenure')}>Select Tenure</button>
//             {isDropdownVisible.selectTenure && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange} value={selectedFilters}>
//                   {SelectTenure.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//           <p>
//             <button onClick={() => toggleDropdown('minSalary')}>Min Salary</button>
//             {isDropdownVisible.minSalary && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange} value={selectedFilters}>
//                   {PermanentFullTime.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//           <p>
//             <button onClick={() => toggleDropdown('maxSalary')}>Max Salary</button>
//             {isDropdownVisible.maxSalary && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange} value={selectedFilters}>
//                   {PermanentPartTime.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//           <p>
//             <button onClick={() => toggleDropdown('jobType')}>Job Type</button>
//             {isDropdownVisible.jobType && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange} value={selectedFilters}>
//                   {Annualsalary.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>


//           <p>
//             <button onClick={() => toggleDropdown('CityMain')}>City</button>
//             {isDropdownVisible.CityMain && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange} value={selectedFilters}>
//                   {CityMain.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//         </div>
//       )}

// {/* Contract job Jobtype End */}


// {/* Full Time JobType start */}
 
// {jobType === "Fulltime Job" && (
//         <div className="sub-filter">
//           <p>
//             <button onClick={() => toggleDropdown('education')}>Education</button>
//             {isDropdownVisible.education && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange} value={selectedFilters}>
//                   {Education1.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//           <p>
//             <button onClick={() => toggleDropdown('minExp')}>Min Exp</button>
//             {isDropdownVisible.minExp && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange1} value={selectedFilters}>
//                   {MinExp.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//           <p>
//             <button onClick={() => toggleDropdown('maxExp')}>Max Exp</button>
//             {isDropdownVisible.maxExp && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange} value={selectedFilters}>
//                   {MaxExp.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//           <p>
//             <button onClick={() => toggleDropdown('industry')}>Industry</button>
//             {isDropdownVisible.industry && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange} value={selectedFilters}>
//                   {Industry1.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>


//           <p>
//             <button onClick={() => toggleDropdown('minSalary')}>Min Salary</button>
//             {isDropdownVisible.minSalary && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange} value={selectedFilters}>
//                   {PermanentFullTime.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//           <p>
//             <button onClick={() => toggleDropdown('maxSalary')}>Max Salary</button>
//             {isDropdownVisible.maxSalary && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange} value={selectedFilters}>
//                   {PermanentPartTime.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//           <p>
//             <button onClick={() => toggleDropdown('jobType')}>Job Type</button>
//             {isDropdownVisible.jobType && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange} value={selectedFilters}>
//                   {Annualsalary.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

        
//           <p>
//             <button onClick={() => toggleDropdown('CityMain')}>City</button>
//             {isDropdownVisible.CityMain && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange} value={selectedFilters}>
//                   {CityMain.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//         </div>
//       )}

// {/* Full Time JObType End */}
     

// {/* Walk-In JobType Start */}

//       {jobType === "Walkin Job" && (
//         <div className="sub-filter">
//           <p>
//             <button onClick={() => toggleDropdown('education')}>Education</button>
//             {isDropdownVisible.education && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange} value={selectedFilters}>
//                   {Education1.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>


//           <p>
//             <button onClick={() => toggleDropdown('industry')}>Category</button>
//             {isDropdownVisible.industry && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange} value={selectedFilters}>
//                   {Industry1.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>


//           <p>
//             <button onClick={() => toggleDropdown('minExp')}>Min Exp</button>
//             {isDropdownVisible.minExp && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange} value={selectedFilters}>
//                   {MinExp.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//           <p>
//             <button onClick={() => toggleDropdown('maxExp')}>Max Exp</button>
//             {isDropdownVisible.maxExp && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange} value={selectedFilters}>
//                   {MaxExp.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//           <p>
//             <button onClick={() => toggleDropdown('TimingJob')}>Timings</button>
//             {isDropdownVisible.TimingJob && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange} value={selectedFilters}>
//                   {TimingJob.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>


//           <p>
//             <button onClick={() => toggleDropdown('From1')}>From</button>
//             {isDropdownVisible.From1 && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange} value={selectedFilters}>
//                   {From1.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//           <p>
//             <button onClick={() => toggleDropdown('To1')}>To</button>
//             {isDropdownVisible.To1 && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange} value={selectedFilters}>
//                   {To1.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>


//           <p>
//             <button onClick={() => toggleDropdown('CityMain')}>City</button>
//             {isDropdownVisible.CityMain && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange} value={selectedFilters}>
//                   {CityMain.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//         </div>
//       )}

// {/* Walk-in JobType End */}

// <Link
//                 to={`/ads-listing/`}
//                 onClick={() => {
//                   console.log(searchSending);
//                   for (const key in searchSending) {
//                     if (
//                       searchSending[key]?.trim() === "" ||
//                       searchSending[key] === null
//                     ) {
//                       delete searchSending[key];
//                     }
//                   }
//                   searchSending["subCategoryValue"] = jobType;
//                   searchSending["extraField"] = {...educationSubFilter,...educationSubFilter1}; // Object Assign to searchSending object

//                   disptach(getExtraField(searchSending)); // Dispatching getExtraField action
//                 }}
//               >
//                 <button
//                   className="btn houzez-search-button elementor-button elementor-size-sm"
//                   style={{
//                     background: "#a71616bf",
//                     color: "white",
//                   }}
//                   id="serbtn"
//                 >
//                   Search
//                 </button>
//               </Link>
//     </div>
//   );
// }

// export default JobsMainfilter;
































// import React, { useEffect, useState } from 'react';
// import './JobsMainfilter.css'; // Create a CSS file for styling
// import { 
//   Education1,
//   MinExp,
//   MaxExp,
//   Industry1,
//   SelectTenure,
//   PermanentFullTime,
//   PermanentPartTime,
//   Annualsalary,
//   TimingJob,
//   From1,
//   To1,
//   CityMain,
// } from '../../../env';
// import { useDispatch } from 'react-redux';
// import { add } from '../../../../store/wishlistSlice';
// import { Link } from 'react-router-dom';
// import { getExtraField } from '../../../../store/ProductFilterSlice';

// function JobsMainfilter() {
//   const [jobType, setJobType] = useState(null);
//   const [selectedFilters, setSelectedFilters] = useState([]);
//   const [selectedFilters1, setSelectedFilters1] = useState([]);
//   const [selectedFilters2, setSelectedFilters2] = useState([]);
//   const [selectedFilters3, setSelectedFilters3] = useState([]);
//   const [selectedFilters4, setSelectedFilters4] = useState([]);
//   const [selectedFilters5, setSelectedFilters5] = useState([]);
//   const [selectedFilters6, setSelectedFilters6] = useState([]);
//   const [selectedFilters7, setSelectedFilters7] = useState([]);
//   const [selectedFilters8, setSelectedFilters8] = useState([]);


  
//   const [educationSubFilter, setEducationSubFilter] = useState();
//   const [educationSubFilter1, setEducationSubFilter1] = useState();
//   const [educationSubFilter2, setEducationSubFilter2] = useState();
//   const [educationSubFilter3, setEducationSubFilter3] = useState();
//   const [educationSubFilter4, setEducationSubFilter4] = useState();
//   const [educationSubFilter5, setEducationSubFilter5] = useState();
//   const [educationSubFilter6, setEducationSubFilter6] = useState();
//   const [educationSubFilter7, setEducationSubFilter7] = useState();
//   const [educationSubFilter8, setEducationSubFilter8] = useState();




//   const [isDropdownVisible, setIsDropdownVisible] = useState({
//     education: false,
//     minExp: false,
//     maxExp: false,
//     industry: false,
//     selectTenure: false,
//     minSalary: false,
//     maxSalary: false,
//     jobType: false,
//     CityMain:false,
//     TimingJob:false,
//     From1:false,
//     To1:false,

//   });
//   const [selectedFilter, setSelectedFilter] = useState(null);

//   const [loading, setLoading] = useState(false); // Setting up a state variable 'loading' and its setter function 'setLoading' with initial value 'false'
//   const disptach = useDispatch();
//    // Accessing the Redux dispatch function


//   const [extraFieldCopy, setExtraFieldCopy] = useState({});
//   const [extraFieldCopy1,setExtraFieldCopy1] = useState({});
  

//   const [searchSending, setsearchSending] = useState({
//     subCategoryType: null,
//     subCategoryValue: null,
//     locationDataMain: null,
//     searchValue: null,
//     category: "Jobs",
//     minPrice: null,
//     maxPrice: null,
//   }); // State for search form data

//   //   const disptach = useDispatch(); // Initializing useDispatch hook

//   // Event handler for input field changes
//   const handleChange = (e) => {
//     var name = e.target.name;
//     var value = e.target.value;
//     setExtraFieldCopy({ [name]: value });
//     // setsearchSending({ ...searchSending, [name]: value });
//     // disptach(getExtraField(searchSending)); // Dispatching getExtraField action
//   };

//   useEffect(() => {
//     // Update searchSending state with the selected filter value
//     if (selectedFilter !== null) {
//       setsearchSending((prevState) => ({
//         ...prevState,
//         category: selectedFilter,
//       }));
//     }
//   }, [selectedFilter]);

//   const handleJobTypeChange = (event) => {
//     setJobType(event.target.value);
//     setSelectedFilters([]);
//     setEducationSubFilter(event);
//     setEducationSubFilter1(event);
//     setEducationSubFilter2(event);
//     setEducationSubFilter3(event);
//     setEducationSubFilter4(event);
//     setEducationSubFilter5(event);
//     setEducationSubFilter6(event);
//     setEducationSubFilter7(event);
//     setEducationSubFilter8(event);



//     setIsDropdownVisible((prevState) => ({
//       ...prevState,
//       education: false,
//       minExp: false,
//       maxExp: false,
//       industry: false,
//       selectTenure: false,
//       minSalary: false,
//       maxSalary: false,
//       jobType: false,
//       CityMain:false,
//       TimingJob:false,
//       From1:false,
//       To1:false,
//     }));
//   };

//   const toggleDropdown = (dropdownName) => {
//     setIsDropdownVisible((prevState) => ({
//       ...prevState,
//       [dropdownName]: !prevState[dropdownName],
//     }));
//   };

//   const handleSubFilterChange = (event) => {
//     const value = event.target.value;
//     setSelectedFilters((prevFilters) => {
//       setEducationSubFilter({"education" : event.target.value});
//       if (prevFilters.includes(value)) {
//         return prevFilters.filter((filter) => filter !== value);
//       } else {
//         return [...prevFilters, value];
//       }
//     });
//   };

//   const handleSubFilterChange1 = (event) => {
//     const value = event.target.value;
//     setSelectedFilters1((prevFilters) => {
//       setEducationSubFilter1({"minExp" : event.target.value});
//       if (prevFilters.includes(value)) {
//         return prevFilters.filter((filter) => filter !== value);
//       } else {
//         return [...prevFilters, value];
//       }
//     });
//   };

//   const handleSubFilterChange2 = (event) => {
//     const value = event.target.value;
//     setSelectedFilters2((prevFilters) => {
//       setEducationSubFilter2({"maxExp" : event.target.value});
//       if (prevFilters.includes(value)) {
//         return prevFilters.filter((filter) => filter !== value);
//       } else {
//         return [...prevFilters, value];
//       }
//     });
//   };

//   const handleSubFilterChange3 = (event) => {
//     const value = event.target.value;
//     setSelectedFilters3((prevFilters) => {
//       setEducationSubFilter3({"industry" : event.target.value});
//       if (prevFilters.includes(value)) {
//         return prevFilters.filter((filter) => filter !== value);
//       } else {
//         return [...prevFilters, value];
//       }
//     });
//   };

//   const handleSubFilterChange4 = (event) => {
//     const value = event.target.value;
//     setSelectedFilters4((prevFilters) => {
//       setEducationSubFilter4({"selectTenure" : event.target.value});
//       if (prevFilters.includes(value)) {
//         return prevFilters.filter((filter) => filter !== value);
//       } else {
//         return [...prevFilters, value];
//       }
//     });
//   };
  
//   const handleSubFilterChange5 = (event) => {
//     const value = event.target.value;
//     setSelectedFilters5((prevFilters) => {
//       setEducationSubFilter5({"minSalary" : event.target.value});
//       if (prevFilters.includes(value)) {
//         return prevFilters.filter((filter) => filter !== value);
//       } else {
//         return [...prevFilters, value];
//       }
//     });
//   };

//   const handleSubFilterChange6 = (event) => {
//     const value = event.target.value;
//     setSelectedFilters6((prevFilters) => {
//       setEducationSubFilter6({"maxSalary" : event.target.value});
//       if (prevFilters.includes(value)) {
//         return prevFilters.filter((filter) => filter !== value);
//       } else {
//         return [...prevFilters, value];
//       }
//     });
//   };

//   const handleSubFilterChange7 = (event) => {
//     const value = event.target.value;
//     setSelectedFilters7((prevFilters) => {
//       setEducationSubFilter7({"jobType" : event.target.value});
//       if (prevFilters.includes(value)) {
//         return prevFilters.filter((filter) => filter !== value);
//       } else {
//         return [...prevFilters, value];
//       }
//     });
//   };


//   const handleSubFilterChange8 = (event) => {
//     const value = event.target.value;
//     setSelectedFilters8((prevFilters) => {
//       setEducationSubFilter8({"CityMain" : event.target.value});
//       if (prevFilters.includes(value)) {
//         return prevFilters.filter((filter) => filter !== value);
//       } else {
//         return [...prevFilters, value];
//       }
//     });
//   };

  





//   // const handleEducationSubFilterChange = (event) => {
//   //   setEducationSubFilter({"Education" : event.target.value});
//   // };

//   const handleFindButtonClick = () => {
//     // Handle the logic for filtering here based on selectedFilters and educationSubFilter
//     console.log('Selected Filters:', selectedFilters);
//     console.log('Education Sub-Filter:', educationSubFilter);

//   };

//   return (
//     <div className="main-filter">
//       <h1>JOB FILTER</h1>
//       <div className="sub-filter">
//         <label>Select Job Type:</label>
//         <select onChange={handleJobTypeChange}>
//           <option value="">Select...</option>
//           <option value="Contract Job">CONTRACT JOB</option>
//           <option value="Fulltime Job">FULL TIME</option>
//           <option value="Walkin Job">WALK-IN</option>
//         </select>
//       </div>



// {/* Contarct job JobType Start */}

//       {(jobType === 'Contract Job' || jobType === 'Fulltime Job') && (
//         <div className="sub-filter">
//           <p>
//             <button onClick={() => toggleDropdown('education')}>Education</button>
//             {isDropdownVisible.education && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange} value={selectedFilters}>
//                   {Education1.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//           <p>
//             <button onClick={() => toggleDropdown('minExp')}>Min Exp</button>
//             {isDropdownVisible.minExp && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange1} value={selectedFilters1}>
//                   {MinExp.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//           <p>
//             <button onClick={() => toggleDropdown('maxExp')}>Max Exp</button>
//             {isDropdownVisible.maxExp && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange2} value={selectedFilters2}>
//                   {MaxExp.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//           <p>
//             <button onClick={() => toggleDropdown('industry')}>Industry</button>
//             {isDropdownVisible.industry && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange3} value={selectedFilters3}>
//                   {Industry1.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//           <p>
//             <button onClick={() => toggleDropdown('selectTenure')}>Select Tenure</button>
//             {isDropdownVisible.selectTenure && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange4} value={selectedFilters4}>
//                   {SelectTenure.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//           <p>
//             <button onClick={() => toggleDropdown('minSalary')}>Min Salary</button>
//             {isDropdownVisible.minSalary && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange5} value={selectedFilters5}>
//                   {PermanentFullTime.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//           <p>
//             <button onClick={() => toggleDropdown('maxSalary')}>Max Salary</button>
//             {isDropdownVisible.maxSalary && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange6} value={selectedFilters6}>
//                   {PermanentPartTime.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//           <p>
//             <button onClick={() => toggleDropdown('jobType')}>Job Type</button>
//             {isDropdownVisible.jobType && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange7} value={selectedFilters7}>
//                   {Annualsalary.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>


//           <p>
//             <button onClick={() => toggleDropdown('CityMain')}>City</button>
//             {isDropdownVisible.CityMain && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange8} value={selectedFilters8}>
//                   {CityMain.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//         </div>
//       )}

// {/* Contract job Jobtype End */}


// {/* Full Time JobType start */}
 
// {jobType === "Fulltime Job" && (
//         <div className="sub-filter">
//           <p>
//             <button onClick={() => toggleDropdown('education')}>Education</button>
//             {isDropdownVisible.education && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange} value={selectedFilters}>
//                   {Education1.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//           <p>
//             <button onClick={() => toggleDropdown('minExp')}>Min Exp</button>
//             {isDropdownVisible.minExp && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange1} value={selectedFilters}>
//                   {MinExp.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//           <p>
//             <button onClick={() => toggleDropdown('maxExp')}>Max Exp</button>
//             {isDropdownVisible.maxExp && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange} value={selectedFilters}>
//                   {MaxExp.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//           <p>
//             <button onClick={() => toggleDropdown('industry')}>Industry</button>
//             {isDropdownVisible.industry && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange} value={selectedFilters}>
//                   {Industry1.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>


//           <p>
//             <button onClick={() => toggleDropdown('minSalary')}>Min Salary</button>
//             {isDropdownVisible.minSalary && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange} value={selectedFilters}>
//                   {PermanentFullTime.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//           <p>
//             <button onClick={() => toggleDropdown('maxSalary')}>Max Salary</button>
//             {isDropdownVisible.maxSalary && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange} value={selectedFilters}>
//                   {PermanentPartTime.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//           <p>
//             <button onClick={() => toggleDropdown('jobType')}>Job Type</button>
//             {isDropdownVisible.jobType && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange} value={selectedFilters}>
//                   {Annualsalary.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

        
//           <p>
//             <button onClick={() => toggleDropdown('CityMain')}>City</button>
//             {isDropdownVisible.CityMain && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange} value={selectedFilters}>
//                   {CityMain.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//         </div>
//       )}

// {/* Full Time JObType End */}
     

// {/* Walk-In JobType Start */}

//       {jobType === "Walkin Job" && (
//         <div className="sub-filter">
//           <p>
//             <button onClick={() => toggleDropdown('education')}>Education</button>
//             {isDropdownVisible.education && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange} value={selectedFilters}>
//                   {Education1.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>


//           <p>
//             <button onClick={() => toggleDropdown('industry')}>Category</button>
//             {isDropdownVisible.industry && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange} value={selectedFilters}>
//                   {Industry1.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>


//           <p>
//             <button onClick={() => toggleDropdown('minExp')}>Min Exp</button>
//             {isDropdownVisible.minExp && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange} value={selectedFilters}>
//                   {MinExp.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//           <p>
//             <button onClick={() => toggleDropdown('maxExp')}>Max Exp</button>
//             {isDropdownVisible.maxExp && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange} value={selectedFilters}>
//                   {MaxExp.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//           <p>
//             <button onClick={() => toggleDropdown('TimingJob')}>Timings</button>
//             {isDropdownVisible.TimingJob && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange} value={selectedFilters}>
//                   {TimingJob.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>


//           <p>
//             <button onClick={() => toggleDropdown('From1')}>From</button>
//             {isDropdownVisible.From1 && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange} value={selectedFilters}>
//                   {From1.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//           <p>
//             <button onClick={() => toggleDropdown('To1')}>To</button>
//             {isDropdownVisible.To1 && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange} value={selectedFilters}>
//                   {To1.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>


//           <p>
//             <button onClick={() => toggleDropdown('CityMain')}>City</button>
//             {isDropdownVisible.CityMain && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange} value={selectedFilters}>
//                   {CityMain.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//         </div>
//       )}

// {/* Walk-in JobType End */}

// <Link
//                 to={`/ads-listing/`}
//                 onClick={() => {
//                   console.log(searchSending);
//                   for (const key in searchSending) {
//                     if (
//                       searchSending[key]?.trim() === "" ||
//                       searchSending[key] === null
//                     ) {
//                       delete searchSending[key];
//                     }
//                   }
//                   searchSending["subCategoryValue"] = jobType;
//                   searchSending["extraField"] = {
//                     ...educationSubFilter,
//                     ...educationSubFilter1,
//                     ...educationSubFilter2,
//                     ...educationSubFilter3,
//                     ...educationSubFilter4,
//                     ...educationSubFilter5,
//                     ...educationSubFilter6,
//                     ...educationSubFilter7,
//                     ...educationSubFilter8
//                   }; // Object Assign to searchSending object

//                   disptach(getExtraField(searchSending)); // Dispatching getExtraField action
//                 }}
//               >
//                 <button
//                   className="btn houzez-search-button elementor-button elementor-size-sm"
//                   style={{
//                     background: "#a71616bf",
//                     color: "white",
//                   }}
//                   id="serbtn"
//                 >
//                   Search
//                 </button>
//               </Link>
//     </div>
//   );
// }

// export default JobsMainfilter;




















// import React, { useEffect, useState } from 'react';
// import './JobsMainfilter.css';
// import {
//   Education1,
//   MinExp,
//   MaxExp,
//   Industry1,
//   SelectTenure,
//   PermanentFullTime,
//   PermanentPartTime,
//   Annualsalary,
//   TimingJob,
//   From1,
//   To1,
//   CityMain,
// } from '../../../env';
// import { useDispatch } from 'react-redux';
// import { add } from '../../../../store/wishlistSlice';
// import { Link } from 'react-router-dom';
// import { getExtraField } from '../../../../store/ProductFilterSlice';

// function JobsMainfilter() {
//   const [jobType, setJobType] = useState('');
//   const [selectedFilters, setSelectedFilters] = useState([]);
//   const [selectedFilters1, setSelectedFilters1] = useState([]);
//   const [selectedFilters2, setSelectedFilters2] = useState([]);
//   const [selectedFilters3, setSelectedFilters3] = useState([]);
//   const [selectedFilters4, setSelectedFilters4] = useState([]);
//   const [selectedFilters5, setSelectedFilters5] = useState([]);
//   const [selectedFilters6, setSelectedFilters6] = useState([]);
//   const [selectedFilters7, setSelectedFilters7] = useState([]);
//   const [selectedFilters8, setSelectedFilters8] = useState([]);

//   const [educationSubFilter, setEducationSubFilter] = useState({});
//   const [educationSubFilter1, setEducationSubFilter1] = useState({});
//   const [educationSubFilter2, setEducationSubFilter2] = useState({});
//   const [educationSubFilter3, setEducationSubFilter3] = useState({});
//   const [educationSubFilter4, setEducationSubFilter4] = useState({});
//   const [educationSubFilter5, setEducationSubFilter5] = useState({});
//   const [educationSubFilter6, setEducationSubFilter6] = useState({});
//   const [educationSubFilter7, setEducationSubFilter7] = useState({});
//   const [educationSubFilter8, setEducationSubFilter8] = useState({});

//   const [isDropdownVisible, setIsDropdownVisible] = useState({
//     education: false,
//     minExp: false,
//     maxExp: false,
//     industry: false,
//     selectTenure: false,
//     minSalary: false,
//     maxSalary: false,
//     jobType: false,
//     CityMain: false,
//     TimingJob: false,
//     From1: false,
//     To1: false,
//   });
//   const [selectedFilter, setSelectedFilter] = useState(null);

//   const [loading, setLoading] = useState(false);
//   const dispatch = useDispatch();

//   const [extraFieldCopy, setExtraFieldCopy] = useState({});
//   const [extraFieldCopy1, setExtraFieldCopy1] = useState({});

//   const [searchSending, setSearchSending] = useState({
//     subCategoryType: null,
//     subCategoryValue: null,
//     locationDataMain: null,
//     searchValue: null,
//     category: 'Jobs',
//     minPrice: null,
//     maxPrice: null,
//   });

//   const handleChange = (e) => {
//     const name = e.target.name;
//     const value = e.target.value;
//     setExtraFieldCopy({ ...extraFieldCopy, [name]: value });
//   };

//   useEffect(() => {
//     if (selectedFilter !== null) {
//       setSearchSending((prevState) => ({
//         ...prevState,
//         category: selectedFilter,
//       }));
//     }
//   }, [selectedFilter]);

//   const handleJobTypeChange = (event) => {
//     const value = event.target.value;
//     setJobType(value);
//     setSelectedFilters([]);
//     setEducationSubFilter({ education: value });
//     setEducationSubFilter1({ minExp: value });
//     setEducationSubFilter2({ maxExp: value });
//     setEducationSubFilter3({ industry: value });
//     setEducationSubFilter4({ selectTenure: value });
//     setEducationSubFilter5({ minSalary: value });
//     setEducationSubFilter6({ maxSalary: value });
//     setEducationSubFilter7({ jobType: value });
//     setEducationSubFilter8({ CityMain: value });

//     setIsDropdownVisible((prevState) => ({
//       ...prevState,
//       education: false,
//       minExp: false,
//       maxExp: false,
//       industry: false,
//       selectTenure: false,
//       minSalary: false,
//       maxSalary: false,
//       jobType: false,
//       CityMain: false,
//       TimingJob: false,
//       From1: false,
//       To1: false,
//     }));
//   };

//   const toggleDropdown = (dropdownName) => {
//     setIsDropdownVisible((prevState) => ({
//       ...prevState,
//       [dropdownName]: !prevState[dropdownName],
//     }));
//   };

//   const handleSubFilterChange = (event) => {
//     const value = event.target.value;
//     setSelectedFilters((prevFilters) => {
//       setEducationSubFilter({ ...educationSubFilter, education: value });
//       if (prevFilters.includes(value)) {
//         return prevFilters.filter((filter) => filter !== value);
//       } else {
//         return [...prevFilters, value];
//       }
//     });
//   };

//   const handleSubFilterChange1 = (event) => {
//     const value = event.target.value;
//     setSelectedFilters1((prevFilters) => {
//       setEducationSubFilter1({ ...educationSubFilter1, minExp: value });
//       if (prevFilters.includes(value)) {
//         return prevFilters.filter((filter) => filter !== value);
//       } else {
//         return [...prevFilters, value];
//       }
//     });
//   };

//   const handleSubFilterChange2 = (event) => {
//     const value = event.target.value;
//     setSelectedFilters2((prevFilters) => {
//       setEducationSubFilter2({ ...educationSubFilter2, maxExp: value });
//       if (prevFilters.includes(value)) {
//         return prevFilters.filter((filter) => filter !== value);
//       } else {
//         return [...prevFilters, value];
//       }
//     });
//   };

//   const handleSubFilterChange3 = (event) => {
//     const value = event.target.value;
//     setSelectedFilters3((prevFilters) => {
//       setEducationSubFilter3({ ...educationSubFilter3, industry: value });
//       if (prevFilters.includes(value)) {
//         return prevFilters.filter((filter) => filter !== value);
//       } else {
//         return [...prevFilters, value];
//       }
//     });
//   };

//   const handleSubFilterChange4 = (event) => {
//     const value = event.target.value;
//     setSelectedFilters4((prevFilters) => {
//       setEducationSubFilter4({ ...educationSubFilter4, selectTenure: value });
//       if (prevFilters.includes(value)) {
//         return prevFilters.filter((filter) => filter !== value);
//       } else {
//         return [...prevFilters, value];
//       }
//     });
//   };

//   const handleSubFilterChange5 = (event) => {
//     const value = event.target.value;
//     setSelectedFilters5((prevFilters) => {
//       setEducationSubFilter5({ ...educationSubFilter5, minSalary: value });
//       if (prevFilters.includes(value)) {
//         return prevFilters.filter((filter) => filter !== value);
//       } else {
//         return [...prevFilters, value];
//       }
//     });
//   };

//   const handleSubFilterChange6 = (event) => {
//     const value = event.target.value;
//     setSelectedFilters6((prevFilters) => {
//       setEducationSubFilter6({ ...educationSubFilter6, maxSalary: value });
//       if (prevFilters.includes(value)) {
//         return prevFilters.filter((filter) => filter !== value);
//       } else {
//         return [...prevFilters, value];
//       }
//     });
//   };

//   const handleSubFilterChange7 = (event) => {
//     const value = event.target.value;
//     setSelectedFilters7((prevFilters) => {
//       setEducationSubFilter7({ ...educationSubFilter7, jobType: value });
//       if (prevFilters.includes(value)) {
//         return prevFilters.filter((filter) => filter !== value);
//       } else {
//         return [...prevFilters, value];
//       }
//     });
//   };

//   const handleSubFilterChange8 = (event) => {
//     const value = event.target.value;
//     setSelectedFilters8((prevFilters) => {
//       setEducationSubFilter8({ ...educationSubFilter8, CityMain: value });
//       if (prevFilters.includes(value)) {
//         return prevFilters.filter((filter) => filter !== value);
//       } else {
//         return [...prevFilters, value];
//       }
//     });
//   };

//   const handleFindButtonClick = () => {
//     console.log('Selected Filters:', selectedFilters);
//     console.log('Education Sub-Filter:', educationSubFilter);
//   };

//   return (
//     <div className="main-filter">
//       <h1>JOB FILTER</h1>
//       <div className="sub-filter">
//         <label>Select Job Type:</label>
//         <select onChange={handleJobTypeChange}>
//           <option value="">Select...</option>
//           <option value="Contract Job">CONTRACT JOB</option>
//           <option value="Fulltime Job">FULL TIME</option>
//           <option value="Walkin Job">WALK-IN</option>
//         </select>
//       </div>

//       {jobType === 'Contract Job' && (
//         <div className="sub-filter">
//           <p>
//             <button onClick={() => toggleDropdown('education')}>Education</button>
//             {isDropdownVisible.education && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange} value={selectedFilters}>
//                   {Education1.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//           <p>
//             <button onClick={() => toggleDropdown('minExp')}>Min Exp</button>
//             {isDropdownVisible.minExp && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange1} value={selectedFilters1}>
//                   {MinExp.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//           <p>
//             <button onClick={() => toggleDropdown('maxExp')}>Max Exp</button>
//             {isDropdownVisible.maxExp && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange2} value={selectedFilters2}>
//                   {MaxExp.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//           <p>
//             <button onClick={() => toggleDropdown('industry')}>Industry</button>
//             {isDropdownVisible.industry && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange3} value={selectedFilters3}>
//                   {Industry1.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//           <p>
//             <button onClick={() => toggleDropdown('selectTenure')}>Select Tenure</button>
//             {isDropdownVisible.selectTenure && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange4} value={selectedFilters4}>
//                   {SelectTenure.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//           <p>
//             <button onClick={() => toggleDropdown('minSalary')}>Min Salary</button>
//             {isDropdownVisible.minSalary && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange5} value={selectedFilters5}>
//                   {PermanentFullTime.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//           <p>
//             <button onClick={() => toggleDropdown('maxSalary')}>Max Salary</button>
//             {isDropdownVisible.maxSalary && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange6} value={selectedFilters6}>
//                   {PermanentPartTime.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//           <p>
//             <button onClick={() => toggleDropdown('jobType')}>Job Type</button>
//             {isDropdownVisible.jobType && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange7} value={selectedFilters7}>
//                   {Annualsalary.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//           <p>
//             <button onClick={() => toggleDropdown('CityMain')}>City</button>
//             {isDropdownVisible.CityMain && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange8} value={selectedFilters8}>
//                   {CityMain.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>
//         </div>
//       )}





// {jobType === "Fulltime Job" && (
//   <div className="sub-filter">
//     <p>
//       <button onClick={() => toggleDropdown('education')}>Education</button>
//       {isDropdownVisible.education && (
//         <div className="dropdown11">
//           <select onChange={handleSubFilterChange} value={selectedFilters}>
//             {Education1.map((option, index) => (
//               <option key={index} value={option}>
//                 {option}
//               </option>
//             ))}
//           </select>
//         </div>
//       )}
//     </p>

//     <p>
//       <button onClick={() => toggleDropdown('minExp')}>Min Exp</button>
//       {isDropdownVisible.minExp && (
//         <div className="dropdown11">
//           <select onChange={handleSubFilterChange1} value={selectedFilters1}>
//             {MinExp.map((option, index) => (
//               <option key={index} value={option}>
//                 {option}
//               </option>
//             ))}
//           </select>
//         </div>
//       )}
//     </p>

//     <p>
//       <button onClick={() => toggleDropdown('maxExp')}>Max Exp</button>
//       {isDropdownVisible.maxExp && (
//         <div className="dropdown11">
//           <select onChange={handleSubFilterChange2} value={selectedFilters2}>
//             {MaxExp.map((option, index) => (
//               <option key={index} value={option}>
//                 {option}
//               </option>
//             ))}
//           </select>
//         </div>
//       )}
//     </p>

//     <p>
//       <button onClick={() => toggleDropdown('industry')}>Industry</button>
//       {isDropdownVisible.industry && (
//         <div className="dropdown11">
//           <select onChange={handleSubFilterChange3} value={selectedFilters3}>
//             {Industry1.map((option, index) => (
//               <option key={index} value={option}>
//                 {option}
//               </option>
//             ))}
//           </select>
//         </div>
//       )}
//     </p>

//     <p>
//       <button onClick={() => toggleDropdown('minSalary')}>Min Salary</button>
//       {isDropdownVisible.minSalary && (
//         <div className="dropdown11">
//           <select onChange={handleSubFilterChange4} value={selectedFilters4}>
//             {PermanentFullTime.map((option, index) => (
//               <option key={index} value={option}>
//                 {option}
//               </option>
//             ))}
//           </select>
//         </div>
//       )}
//     </p>

//     <p>
//       <button onClick={() => toggleDropdown('maxSalary')}>Max Salary</button>
//       {isDropdownVisible.maxSalary && (
//         <div className="dropdown11">
//           <select onChange={handleSubFilterChange5} value={selectedFilters5}>
//             {PermanentPartTime.map((option, index) => (
//               <option key={index} value={option}>
//                 {option}
//               </option>
//             ))}
//           </select>
//         </div>
//       )}
//     </p>

//     <p>
//       <button onClick={() => toggleDropdown('jobType')}>Job Type</button>
//       {isDropdownVisible.jobType && (
//         <div className="dropdown11">
//           <select onChange={handleSubFilterChange7} value={selectedFilters7}>
//             {Annualsalary.map((option, index) => (
//               <option key={index} value={option}>
//                 {option}
//               </option>
//             ))}
//           </select>
//         </div>
//       )}
//     </p>

//     <p>
//       <button onClick={() => toggleDropdown('CityMain')}>City</button>
//       {isDropdownVisible.CityMain && (
//         <div className="dropdown11">
//           <select onChange={handleSubFilterChange8} value={selectedFilters8}>
//             {CityMain.map((option, index) => (
//               <option key={index} value={option}>
//                 {option}
//               </option>
//             ))}
//           </select>
//         </div>
//       )}
//     </p>
//   </div>
// )}





//       {/* <Link
//         to="/ads-listing/"
//         onClick={() => {
//           console.log(searchSending);
//           for (const key in searchSending) {
//             if (searchSending[key]?.trim() === '' || searchSending[key] === null) {
//               delete searchSending[key];
//             }
//           }
//           searchSending['subCategoryValue'] = jobType;
//           searchSending['extraField'] = {
//             ...educationSubFilter,
//             ...educationSubFilter1,
//             ...educationSubFilter2,
//             ...educationSubFilter3,
//             ...educationSubFilter4,
//             ...educationSubFilter5,
//             ...educationSubFilter6,
//             ...educationSubFilter7,
//             ...educationSubFilter8,
//           };
//           dispatch(getExtraField(searchSending));
//         }}
//       >
//         <button
//           className="btn houzez-search-button elementor-button elementor-size-sm"
//           style={{
//             background: '#a71616bf',
//             color: 'white',
//           }}
//           id="serbtn"
//         >
//           Search
//         </button>
//       </Link> */}




      
//     <Link
//       to="/ads-listing/"
//       onClick={() => {
//         console.log(searchSending);
//         for (const key in searchSending) {
//           if (searchSending[key]?.trim() === '' || searchSending[key] === null) {
//             delete searchSending[key];
//           }
//         }
//         searchSending['subCategoryValue'] = jobType;
//         searchSending['extraField'] = {
//           ...educationSubFilter,
//           ...educationSubFilter1,
//           ...educationSubFilter2,
//           ...educationSubFilter3,
//           ...educationSubFilter4,
//           ...educationSubFilter5,
//           ...educationSubFilter6,
//           ...educationSubFilter7,
//           ...educationSubFilter8,
//         };
//         dispatch(getExtraField(searchSending));
//       }}
//     >
//       <button
//         className="btn houzez-search-button elementor-button elementor-size-sm"
//         style={{
//           background: '#a71616bf',
//           color: 'white',
//         }}
//         id="serbtn"
//       >
//         Search
//       </button>
//     </Link>
//     </div>
//   );
// }

// export default JobsMainfilter;





















// import React, { useEffect, useState } from 'react';
// import './JobsMainfilter.css';
// import {
//   Education1,
//   MinExp,
//   MaxExp,
//   Industry1,
//   SelectTenure,
//   PermanentFullTime,
//   PermanentPartTime,
//   Annualsalary,
//   TimingJob,
//   From1,
//   To1,
//   CityMain,
// } from '../../../env';
// import { useDispatch } from 'react-redux';
// import { add } from '../../../../store/wishlistSlice';
// import { Link } from 'react-router-dom';
// import { getExtraField } from '../../../../store/ProductFilterSlice';

// function JobsMainfilter() {
//   const [jobType, setJobType] = useState('');
//   const [selectedFilters, setSelectedFilters] = useState([]);
//   const [selectedFilters1, setSelectedFilters1] = useState([]);
//   const [selectedFilters2, setSelectedFilters2] = useState([]);
//   const [selectedFilters3, setSelectedFilters3] = useState([]);
//   const [selectedFilters4, setSelectedFilters4] = useState([]);
//   const [selectedFilters5, setSelectedFilters5] = useState([]);
//   const [selectedFilters6, setSelectedFilters6] = useState([]);
//   const [selectedFilters7, setSelectedFilters7] = useState([]);
//   const [selectedFilters8, setSelectedFilters8] = useState([]);

//   const [educationSubFilter, setEducationSubFilter] = useState({});
//   const [educationSubFilter1, setEducationSubFilter1] = useState({});
//   const [educationSubFilter2, setEducationSubFilter2] = useState({});
//   const [educationSubFilter3, setEducationSubFilter3] = useState({});
//   const [educationSubFilter4, setEducationSubFilter4] = useState({});
//   const [educationSubFilter5, setEducationSubFilter5] = useState({});
//   const [educationSubFilter6, setEducationSubFilter6] = useState({});
//   const [educationSubFilter7, setEducationSubFilter7] = useState({});
//   const [educationSubFilter8, setEducationSubFilter8] = useState({});

//   const [isDropdownVisible, setIsDropdownVisible] = useState({
//     education: false,
//     minExp: false,
//     maxExp: false,
//     industry: false,
//     selectTenure: false,
//     minSalary: false,
//     maxSalary: false,
//     jobType: false,
//     CityMain: false,
//     TimingJob: false,
//     From1: false,
//     To1: false,
//   });
//   const [selectedFilter, setSelectedFilter] = useState(null);

//   const [loading, setLoading] = useState(false);
//   const dispatch = useDispatch();

//   const [extraFieldCopy, setExtraFieldCopy] = useState({});
//   const [extraFieldCopy1, setExtraFieldCopy1] = useState({});

//   const [searchSending, setSearchSending] = useState({
//     subCategoryType: null,
//     subCategoryValue: null,
//     locationDataMain: null,
//     searchValue: null,
//     category: 'Jobs',
//     minPrice: null,
//     maxPrice: null,
//   });

//   const handleChange = (e) => {
//     const name = e.target.name;
//     const value = e.target.value;
//     setExtraFieldCopy({ ...extraFieldCopy, [name]: value });
//   };

//   useEffect(() => {
//     if (selectedFilter !== null) {
//       setSearchSending((prevState) => ({
//         ...prevState,
//         category: selectedFilter,
//       }));
//     }
//   }, [selectedFilter]);

//   const handleJobTypeChange = (event) => {
//     const value = event.target.value;
//     setJobType(value);
//     setSelectedFilters([]);
//     setEducationSubFilter({ education: value });
//     setEducationSubFilter1({ minExp: value });
//     setEducationSubFilter2({ maxExp: value });
//     setEducationSubFilter3({ industry: value });
//     setEducationSubFilter4({ selectTenure: value });
//     setEducationSubFilter5({ minSalary: value });
//     setEducationSubFilter6({ maxSalary: value });
//     setEducationSubFilter7({ jobType: value });
//     setEducationSubFilter8({ CityMain: value });

//     setIsDropdownVisible((prevState) => ({
//       ...prevState,
//       education: false,
//       minExp: false,
//       maxExp: false,
//       industry: false,
//       selectTenure: false,
//       minSalary: false,
//       maxSalary: false,
//       jobType: false,
//       CityMain: false,
//       TimingJob: false,
//       From1: false,
//       To1: false,
//     }));
//   };

//   const toggleDropdown = (dropdownName) => {
//     setIsDropdownVisible((prevState) => ({
//       ...prevState,
//       [dropdownName]: !prevState[dropdownName],
//     }));
//   };

//   const handleSubFilterChange = (event) => {
//     const value = event.target.value;
//     setSelectedFilters((prevFilters) => {
//       setEducationSubFilter({ ...educationSubFilter, education: value });
//       if (prevFilters.includes(value)) {
//         return prevFilters.filter((filter) => filter !== value);
//       } else {
//         return [...prevFilters, value];
//       }
//     });
//   };

//   const handleSubFilterChange1 = (event) => {
//     const value = event.target.value;
//     setSelectedFilters1((prevFilters) => {
//       setEducationSubFilter1({ ...educationSubFilter1, minExp: value });
//       if (prevFilters.includes(value)) {
//         return prevFilters.filter((filter) => filter !== value);
//       } else {
//         return [...prevFilters, value];
//       }
//     });
//   };

//   const handleSubFilterChange2 = (event) => {
//     const value = event.target.value;
//     setSelectedFilters2((prevFilters) => {
//       setEducationSubFilter2({ ...educationSubFilter2, maxExp: value });
//       if (prevFilters.includes(value)) {
//         return prevFilters.filter((filter) => filter !== value);
//       } else {
//         return [...prevFilters, value];
//       }
//     });
//   };

//   const handleSubFilterChange3 = (event) => {
//     const value = event.target.value;
//     setSelectedFilters3((prevFilters) => {
//       setEducationSubFilter3({ ...educationSubFilter3, industry: value });
//       if (prevFilters.includes(value)) {
//         return prevFilters.filter((filter) => filter !== value);
//       } else {
//         return [...prevFilters, value];
//       }
//     });
//   };

//   const handleSubFilterChange4 = (event) => {
//     const value = event.target.value;
//     setSelectedFilters4((prevFilters) => {
//       setEducationSubFilter4({ ...educationSubFilter4, selectTenure: value });
//       if (prevFilters.includes(value)) {
//         return prevFilters.filter((filter) => filter !== value);
//       } else {
//         return [...prevFilters, value];
//       }
//     });
//   };

//   const handleSubFilterChange5 = (event) => {
//     const value = event.target.value;
//     setSelectedFilters5((prevFilters) => {
//       setEducationSubFilter5({ ...educationSubFilter5, minSalary: value });
//       if (prevFilters.includes(value)) {
//         return prevFilters.filter((filter) => filter !== value);
//       } else {
//         return [...prevFilters, value];
//       }
//     });
//   };

//   const handleSubFilterChange6 = (event) => {
//     const value = event.target.value;
//     setSelectedFilters6((prevFilters) => {
//       setEducationSubFilter6({ ...educationSubFilter6, maxSalary: value });
//       if (prevFilters.includes(value)) {
//         return prevFilters.filter((filter) => filter !== value);
//       } else {
//         return [...prevFilters, value];
//       }
//     });
//   };

//   const handleSubFilterChange7 = (event) => {
//     const value = event.target.value;
//     setSelectedFilters7((prevFilters) => {
//       setEducationSubFilter7({ ...educationSubFilter7, jobType: value });
//       if (prevFilters.includes(value)) {
//         return prevFilters.filter((filter) => filter !== value);
//       } else {
//         return [...prevFilters, value];
//       }
//     });
//   };

//   const handleSubFilterChange8 = (event) => {
//     const value = event.target.value;
//     setSelectedFilters8((prevFilters) => {
//       setEducationSubFilter8({ ...educationSubFilter8, CityMain: value });
//       if (prevFilters.includes(value)) {
//         return prevFilters.filter((filter) => filter !== value);
//       } else {
//         return [...prevFilters, value];
//       }
//     });
//   };

//   const handleFindButtonClick = () => {
//     console.log('Selected Filters:', selectedFilters);
//     console.log('Education Sub-Filter:', educationSubFilter);
//   };

//   return (
//     <div className="main-filter">
//       <h1>JOB FILTER</h1>
//       <div className="sub-filter">
//         <label>Select Job Type:</label>
//         <select onChange={handleJobTypeChange}>
//           <option value="">Select...</option>
//           <option value="Contract Job">CONTRACT JOB</option>
//           <option value="Fulltime Job">FULL TIME</option>
//           <option value="Walkin Job">WALK-IN</option>
//         </select>
//       </div>

//       {(jobType === 'Contract Job' || jobType === 'Fulltime Job') && (
//         <div className="sub-filter">
        
//           <p>
//             <button onClick={() => toggleDropdown('education')}>Education</button>
//             {isDropdownVisible.education && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange} value={selectedFilters}>
//                   {Education1.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//           <p>
//             <button onClick={() => toggleDropdown('minExp')}>Min Exp</button>
//             {isDropdownVisible.minExp && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange1} value={selectedFilters1}>
//                   {MinExp.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//           <p>
//             <button onClick={() => toggleDropdown('maxExp')}>Max Exp</button>
//             {isDropdownVisible.maxExp && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange2} value={selectedFilters2}>
//                   {MaxExp.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//           <p>
//             <button onClick={() => toggleDropdown('industry')}>Industry</button>
//             {isDropdownVisible.industry && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange3} value={selectedFilters3}>
//                   {Industry1.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//           <p>
//             <button onClick={() => toggleDropdown('selectTenure')}>Select Tenure</button>
//             {isDropdownVisible.selectTenure && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange4} value={selectedFilters4}>
//                   {SelectTenure.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//           <p>
//             <button onClick={() => toggleDropdown('minSalary')}>Min Salary</button>
//             {isDropdownVisible.minSalary && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange5} value={selectedFilters5}>
//                   {PermanentFullTime.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//           <p>
//             <button onClick={() => toggleDropdown('maxSalary')}>Max Salary</button>
//             {isDropdownVisible.maxSalary && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange6} value={selectedFilters6}>
//                   {PermanentPartTime.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//           <p>
//             <button onClick={() => toggleDropdown('jobType')}>Job Type</button>
//             {isDropdownVisible.jobType && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange7} value={selectedFilters7}>
//                   {Annualsalary.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//           <p>
//             <button onClick={() => toggleDropdown('CityMain')}>City</button>
//             {isDropdownVisible.CityMain && (
//               <div className="dropdown11">
//                 <select onChange={handleSubFilterChange8} value={selectedFilters8}>
//                   {CityMain.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>
//         </div>
//       )}

    
//       <Link
//         to="/ads-listing/"
//         onClick={() => {
//           console.log(searchSending);
//           for (const key in searchSending) {
//             if (searchSending[key]?.trim() === '' || searchSending[key] === null) {
//               delete searchSending[key];
//             }
//           }
//           searchSending['subCategoryValue'] = jobType;
//           searchSending['extraField'] = {
//             ...educationSubFilter,
//             ...educationSubFilter1,
//             ...educationSubFilter2,
//             ...educationSubFilter3,
//             ...educationSubFilter4,
//             ...educationSubFilter5,
//             ...educationSubFilter6,
//             ...educationSubFilter7,
//             ...educationSubFilter8,
//           };
//           dispatch(getExtraField(searchSending));
//         }}
//       >
//         <button
//           className="btn houzez-search-button elementor-button elementor-size-sm"
//           style={{
//             background: '#a71616bf',
//             color: 'white',
//           }}
//           id="serbtn"
//         >
//           Search
//         </button>
//       </Link>
//     </div>
//   );
// }

// export default JobsMainfilter;




























import React, { useEffect, useState } from 'react';
import './JobsMainfilter.css';
import {
  Education1,
  MinExp,
  MaxExp,
  Industry1,
  SelectTenure,
  PermanentFullTime,
  PermanentPartTime,
  Annualsalary,
  TimingJob,
  From1,
  To1,
  CityMain,
} from '../../../env';
import { useDispatch } from 'react-redux';
import { add } from '../../../../store/wishlistSlice';
import { Link } from 'react-router-dom';
import { getExtraField } from '../../../../store/ProductFilterSlice';

function JobsMainfilter() {
  const [jobType, setJobType] = useState('');
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedFilters1, setSelectedFilters1] = useState([]);
  const [selectedFilters2, setSelectedFilters2] = useState([]);
  const [selectedFilters3, setSelectedFilters3] = useState([]);
  const [selectedFilters4, setSelectedFilters4] = useState([]);
  const [selectedFilters5, setSelectedFilters5] = useState([]);
  const [selectedFilters6, setSelectedFilters6] = useState([]);
  const [selectedFilters7, setSelectedFilters7] = useState([]);
  const [selectedFilters8, setSelectedFilters8] = useState([]);
  const [selectedFilters9, setSelectedFilters9] = useState([]);
  const [selectedFilters10, setSelectedFilters10] = useState([]);
  const [selectedFilters11, setSelectedFilters11] = useState([]);
  const [selectedFilters12, setSelectedFilters12] = useState([]);




  const [educationSubFilter, setEducationSubFilter] = useState({});
  const [educationSubFilter1, setEducationSubFilter1] = useState({});
  const [educationSubFilter2, setEducationSubFilter2] = useState({});
  const [educationSubFilter3, setEducationSubFilter3] = useState({});
  const [educationSubFilter4, setEducationSubFilter4] = useState({});
  const [educationSubFilter5, setEducationSubFilter5] = useState({});
  const [educationSubFilter6, setEducationSubFilter6] = useState({});
  const [educationSubFilter7, setEducationSubFilter7] = useState({});
  const [educationSubFilter8, setEducationSubFilter8] = useState({});
  const [educationSubFilter9, setEducationSubFilter9] = useState({});
  const [educationSubFilter10, setEducationSubFilter10] = useState({});
  const [educationSubFilter11, setEducationSubFilter11] = useState({});
  const [educationSubFilter12, setEducationSubFilter12] = useState({});


  const [isDropdownVisible, setIsDropdownVisible] = useState({
    education: false,
    minExp: false,
    maxExp: false,
    industry: false,
    selectTenure: false,
    minSalary: false,
    maxSalary: false,
    jobType: false,
    CityMain: false,
    TimingJob: false,
    From1: false,
    To1: false,
  });
  const [selectedFilter, setSelectedFilter] = useState(null);

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const [extraFieldCopy, setExtraFieldCopy] = useState({});
  const [extraFieldCopy1, setExtraFieldCopy1] = useState({});

  const [searchSending, setSearchSending] = useState({
    subCategoryType: null,
    subCategoryValue: null,
    locationDataMain: null,
    searchValue: null,
    category: 'Jobs',
    minPrice: null,
    maxPrice: null,
  });

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setExtraFieldCopy({ ...extraFieldCopy, [name]: value });
  };

  useEffect(() => {
    if (selectedFilter !== null) {
      setSearchSending((prevState) => ({
        ...prevState,
        category: selectedFilter,
      }));
    }
  }, [selectedFilter]);

  const handleJobTypeChange = (event) => {
    const value = event.target.value;
    setJobType(value);
    setSelectedFilters([]);
    setEducationSubFilter({ education: value(null) });
    setEducationSubFilter1({ minExp: value(null) });
    setEducationSubFilter2({ maxExp: value(null) });
    setEducationSubFilter3({ industry: value(null) });
    setEducationSubFilter4({ selectTenure: value (null)});
    setEducationSubFilter5({ minSalary: value(null)});
    setEducationSubFilter6({ maxSalary: value(null) });
    setEducationSubFilter7({ jobType: value(null) });
    setEducationSubFilter8({ CityMain: value(null) });
    setEducationSubFilter9({ Category: value(null) });
    setEducationSubFilter10({ TimingJob: value(null) });
    setEducationSubFilter11({ From1: value(null)});
    setEducationSubFilter12({ To1: value(null) });


    setIsDropdownVisible((prevState) => ({
      ...prevState,
      education: false,
      minExp: false,
      maxExp: false,
      industry: false,
      selectTenure: false,
      minSalary: false,
      maxSalary: false,
      jobType: false,
      CityMain: false,
      TimingJob: false,
      From1: false,
      To1: false,
    }));
  };

  const toggleDropdown = (dropdownName) => {
    setIsDropdownVisible((prevState) => ({
      ...prevState,
      [dropdownName]: !prevState[dropdownName],
    }));
  };

  const handleSubFilterChange = (event) => {
    const value = event.target.value;
    setSelectedFilters((prevFilters) => {
      setEducationSubFilter({ ...educationSubFilter, education: value });
     
    });
  };

  const handleSubFilterChange1 = (event) => {
    const value = event.target.value;
    setSelectedFilters1((prevFilters) => {
      setEducationSubFilter1({ ...educationSubFilter1, minExp: value });
      if (prevFilters.includes(value)) {
        return prevFilters.filter((filter) => filter !== value);
      } else {
        return [...prevFilters, value];
      }
    });
  };

  const handleSubFilterChange2 = (event) => {
    const value = event.target.value;
    setSelectedFilters2((prevFilters) => {
      setEducationSubFilter2({ ...educationSubFilter2, maxExp: value });
      if (prevFilters.includes(value)) {
        return prevFilters.filter((filter) => filter !== value);
      } else {
        return [...prevFilters, value];
      }
    });
  };

  const handleSubFilterChange3 = (event) => {
    const value = event.target.value;
    setSelectedFilters3((prevFilters) => {
      setEducationSubFilter3({ ...educationSubFilter3, industry: value });
      if (prevFilters.includes(value)) {
        return prevFilters.filter((filter) => filter !== value);
      } else {
        return [...prevFilters, value];
      }
    });
  };

  const handleSubFilterChange4 = (event) => {
    const value = event.target.value;
    setSelectedFilters4((prevFilters) => {
      setEducationSubFilter4({ ...educationSubFilter4, selectTenure: value });
      if (prevFilters.includes(value)) {
        return prevFilters.filter((filter) => filter !== value);
      } else {
        return [...prevFilters, value];
      }
    });
  };

  const handleSubFilterChange5 = (event) => {
    const value = event.target.value;
    setSelectedFilters5((prevFilters) => {
      setEducationSubFilter5({ ...educationSubFilter5, minSalary: value });
      if (prevFilters.includes(value)) {
        return prevFilters.filter((filter) => filter !== value);
      } else {
        return [...prevFilters, value];
      }
    });
  };

  const handleSubFilterChange6 = (event) => {
    const value = event.target.value;
    setSelectedFilters6((prevFilters) => {
      setEducationSubFilter6({ ...educationSubFilter6, maxSalary: value });
      if (prevFilters.includes(value)) {
        return prevFilters.filter((filter) => filter !== value);
      } else {
        return [...prevFilters, value];
      }
    });
  };

  const handleSubFilterChange7 = (event) => {
    const value = event.target.value;
    setSelectedFilters7((prevFilters) => {
      setEducationSubFilter7({ ...educationSubFilter7, jobType: value });
      if (prevFilters.includes(value)) {
        return prevFilters.filter((filter) => filter !== value);
      } else {
        return [...prevFilters, value];
      }
    });
  };

  const handleSubFilterChange8 = (event) => {
    const value = event.target.value;
    setSelectedFilters8((prevFilters) => {
      setEducationSubFilter8({ ...educationSubFilter8, CityMain: value });
      if (prevFilters.includes(value)) {
        return prevFilters.filter((filter) => filter !== value);
      } else {
        return [...prevFilters, value];
      }
    });
  };


  const handleSubFilterChange9 = (event) => {
    const value = event.target.value;
    setSelectedFilters9((prevFilters) => {
      setEducationSubFilter9({ ...educationSubFilter9, Category: value });
      if (prevFilters.includes(value)) {
        return prevFilters.filter((filter) => filter !== value);
      } else {
        return [...prevFilters, value];
      }
    });
  };

  const handleSubFilterChange10 = (event) => {
    const value = event.target.value;
    setSelectedFilters10((prevFilters) => {
      setEducationSubFilter10({ ...educationSubFilter10, TimingJob: value });
      if (prevFilters.includes(value)) {
        return prevFilters.filter((filter) => filter !== value);
      } else {
        return [...prevFilters, value];
      }
    });
  };

  const handleSubFilterChange11 = (event) => {
    const value = event.target.value;
    setSelectedFilters11((prevFilters) => {
      setEducationSubFilter11({ ...educationSubFilter11, From1: value });
      if (prevFilters.includes(value)) {
        return prevFilters.filter((filter) => filter !== value);
      } else {
        return [...prevFilters, value];
      }
    });
  };

  const handleSubFilterChange12 = (event) => {
    const value = event.target.value;
    setSelectedFilters12((prevFilters) => {
      setEducationSubFilter12({ ...educationSubFilter12, To1: value });
      if (prevFilters.includes(value)) {
        return prevFilters.filter((filter) => filter !== value);
      } else {
        return [...prevFilters, value];
      }
    });
  };



  const handleFindButtonClick = () => {
    console.log('Selected Filters:', selectedFilters);
    console.log('Education Sub-Filter:', educationSubFilter);
  };

  return (
    <div className='job-filter' style={{marginTop:"15px"}}>
      {/* <h1>JOB FILTER</h1> */}
      <div className="sub-filter ">
        {/* <label>Select Job Type:</label> */}
        <select onChange={handleJobTypeChange}>
          <option value="">Select Job Type...</option>
          <option value="Contract Job">CONTRACT JOB</option>
          <option value="Full Time Jobs">FULL TIME</option>
          <option value="Walk-In">WALK-IN</option>
        </select>
      </div>

      {(jobType === 'Contract Job' || jobType === 'Full Time Jobs') && (
        <div className="sub-filter">
        
          <p>
            <button onClick={() => toggleDropdown('education')}>Education</button>
            {isDropdownVisible.education && (
              <div className="dropdown11">
                <select onChange={handleSubFilterChange} value={selectedFilters}>
                  {Education1.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </p>

          <p>
            <button onClick={() => toggleDropdown('minExp')}>Min Exp</button>
            {isDropdownVisible.minExp && (
              <div className="dropdown11">
                <select onChange={handleSubFilterChange1} value={selectedFilters1}>
                  {MinExp.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </p>

          <p>
            <button onClick={() => toggleDropdown('maxExp')}>Max Exp</button>
            {isDropdownVisible.maxExp && (
              <div className="dropdown11">
                <select onChange={handleSubFilterChange2} value={selectedFilters2}>
                  {MaxExp.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </p>

        
          <p>
            <button onClick={() => toggleDropdown('industry')}>Industry</button>
            {isDropdownVisible.industry && (
              <div className="dropdown11">
                <select onChange={handleSubFilterChange3} value={selectedFilters3}>
                  {Industry1.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </p>
       
    

          {jobType === 'Contract Job' && ( // Only render for Contract Job
      <p>
        <button onClick={() => toggleDropdown('selectTenure')}>Select Tenure</button>
        {isDropdownVisible.selectTenure && (
          <div className="dropdown11">
            <select onChange={handleSubFilterChange4} value={selectedFilters4}>
              {SelectTenure.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        )}
      </p>
    )}


          <p>
            <button onClick={() => toggleDropdown('minSalary')}>Min Salary</button>
            {isDropdownVisible.minSalary && (
              <div className="dropdown11">
                <select onChange={handleSubFilterChange5} value={selectedFilters5}>
                  {PermanentFullTime.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </p>

          <p>
            <button onClick={() => toggleDropdown('maxSalary')}>Max Salary</button>
            {isDropdownVisible.maxSalary && (
              <div className="dropdown11">
                <select onChange={handleSubFilterChange6} value={selectedFilters6}>
                  {PermanentPartTime.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </p>

          <p>
            <button onClick={() => toggleDropdown('jobType')}>Job Type</button>
            {isDropdownVisible.jobType && (
              <div className="dropdown11">
                <select onChange={handleSubFilterChange7} value={selectedFilters7}>
                  {Annualsalary.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </p>

          <p>
            <button onClick={() => toggleDropdown('CityMain')}>City</button>
            {isDropdownVisible.CityMain && (
              <div className="dropdown11">
                <select onChange={handleSubFilterChange8} value={selectedFilters8}>
                  {CityMain.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </p>
        </div>
      )}



       {jobType === "Walk-In" && (
        <div className="sub-filter">
          <p>
            <button onClick={() => toggleDropdown('education')}>Education</button>
            {isDropdownVisible.education && (
              <div className="dropdown11">
                <select onChange={handleSubFilterChange} value={selectedFilters}>
                  {Education1.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </p>


          <p>
            <button onClick={() => toggleDropdown('Category')}>Category</button>
            {isDropdownVisible.Category && (
              <div className="dropdown11">
                <select onChange={handleSubFilterChange9} value={selectedFilters9}>
                  {Industry1.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </p>


   
          <p>
            <button onClick={() => toggleDropdown('minExp')}>Min Exp</button>
            {isDropdownVisible.minExp && (
              <div className="dropdown11">
                <select onChange={handleSubFilterChange1} value={selectedFilters1}>
                  {MinExp.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </p>

          <p>
            <button onClick={() => toggleDropdown('maxExp')}>Max Exp</button>
            {isDropdownVisible.maxExp && (
              <div className="dropdown11">
                <select onChange={handleSubFilterChange2} value={selectedFilters2}>
                  {MaxExp.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </p>

          <p>
            <button onClick={() => toggleDropdown('TimingJob')}>Timings</button>
            {isDropdownVisible.TimingJob && (
              <div className="dropdown11">
                <select onChange={handleSubFilterChange10} value={selectedFilters10}>
                  {TimingJob.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </p>


          <p>
            <button onClick={() => toggleDropdown('From1')}>From</button>
            {isDropdownVisible.From1 && (
              <div className="dropdown11">
                <select onChange={handleSubFilterChange11} value={selectedFilters11}>
                  {From1.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </p>

          <p>
            <button onClick={() => toggleDropdown('To1')}>To</button>
            {isDropdownVisible.To1 && (
              <div className="dropdown11">
                <select onChange={handleSubFilterChange12} value={selectedFilters12}>
                  {To1.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </p>


    

        <p>
          <button onClick={() => toggleDropdown('CityMain')}>City</button>
            {isDropdownVisible.CityMain && (
              <div className="dropdown11">
                <select onChange={handleSubFilterChange8} value={selectedFilters8}>
                  {CityMain.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </p>

        </div>
      )}




      <Link
        to="/ads-listing/"
        onClick={() => {
          console.log(searchSending);
          for (const key in searchSending) {
            if (searchSending[key]?.trim() === '' || searchSending[key] === null) {
              delete searchSending[key];
            }
          }
          searchSending['subCategoryValue'] = jobType;
          searchSending['extraField'] = {
            ...educationSubFilter,
            ...educationSubFilter1,
            ...educationSubFilter2,
            ...educationSubFilter3,
            ...educationSubFilter4,
            ...educationSubFilter5,
            ...educationSubFilter6,
            ...educationSubFilter7,
            ...educationSubFilter8,
            ...educationSubFilter9,
            ...educationSubFilter10,
            ...educationSubFilter11,
            ...educationSubFilter12,


          };
          dispatch(getExtraField(searchSending));
        }}
      >
        <button
          className="btn houzez-search-button elementor-button elementor-size-sm"
          style={{
            background: '#a71616bf',
            color: 'white',
            display:'flex',
            justifyContent:"center",alignItems:'center'
          }}
          id="serbtn"
        >
          Search
          </button>
        </Link>
    </div>
  );
}

export default JobsMainfilter;
































































