
import React, { useEffect, useState } from "react";
import "./electronicsSlider.css";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

const images = [
  "https://www.adobe.com/content/dam/cc/us/en/creative-cloud/photography/discover/dslr-camera/desktop/DSLR_P1_900x420.jpg.img.jpg",
  "https://www.lg.com/levant_en/images/AC/features/rac-normal-ac-mea-white-01-fast-cooling-mobile.jpg",
  "https://media3.bosch-home.com/Images/600x/19739335_Bosch_Cooling_All_Products_1200x676.jpg",
  "https://images.unsplash.com/photo-1604335399105-a0c585fd81a1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8d2FzaGluZyUyMG1hY2hpbmV8ZW58MHx8MHx8fDA%3D&w=1000&q=80",
  "https://www.intel.com/content/dam/www/central-libraries/us/en/images/2022-05/laptop-marquee-16x9.png.rendition.intel.web.864.486.png",
  "https://img.freepik.com/free-photo/television-houseplants-room-scene-generative-ai_188544-12145.jpg",
  "https://www.kent.co.in/blog/wp-content/uploads/2017/03/How-KENT-Water-Purifiers-Can-Help-You-Live-a-Better-Healthy-Life.jpg",
  "https://img.freepik.com/premium-photo/induction-hob-modern-kitchen-closeup_189959-4374.jpg",
  
];

const MobileSlider = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [visible1, setVisible1] = useState(false);
  const handleDropdownVisibleChange = (open) => {
    setVisible1(open);
  };
  const [visible2, setVisible2] = useState(false);
  const handleDropdownVisibleChange2 = (open) => {
    setVisible2(open);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 2000);
    return () => clearInterval(interval);
  }, [images.length]);

  const mobilestyle = {
    height: "200px",
    width: "100%",
  };
  const desktopstyle = {
    height: "340px",
    width: "90%",
    marginLeft: "1.5cm",
    marginRight: "2.5cm",
    borderRadius: "0px",
    border: "2px solid gray"
  };
  const mobstyle = {
    display: "flex", flexDirection: "column"
  };
  const deskstyle = {
    display: "flex", flexDirection: "row"
  };
  const mob = {
   display: "none",
  };
  const desk= {

  };

  const [selectedFilter, setSelectedFilter] = useState(null);
  const [loading, setLoading] = useState(false); // Setting up a state variable 'loading' and its setter function 'setLoading' with initial value 'false'
  const disptach = useDispatch();
  const dispatch = useDispatch();
  // Accessing the Redux dispatch function
  const [errorMessage, setErrorMessage] = useState(null);
  const [extraFieldCopy, setExtraFieldCopy] = useState({});
  const [city, setCity] = useState();
  const [category, setCategory] = useState();
  const [cityError, setCityError] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [searchSending, setsearchSending] = useState({
    subCategoryType: null,
    subCategoryValue: null,
    City: null,
    searchValue: null,
    category: "Mobiles",
    minPrice: null,
    maxPrice: null,
  }); // State for search form data

  //   const disptach = useDispatch(); // Initializing useDispatch hook

  // Event handler for input field changes
  const handleChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    setExtraFieldCopy({ [name]: value });
    // setsearchSending({ ...searchSending, [name]: value });
    // disptach(getExtraField(searchSending)); // Dispatching getExtraField action
  };

  useEffect(() => {
    // Update searchSending state with the selected filter value
    if (selectedFilter !== null) {
      setsearchSending((prevState) => ({
        ...prevState,
        category: selectedFilter,
      }));
    }
  }, [selectedFilter]);
  return (
    <>
      <div
        className="servicesslide mt-4 mb-3"
        // style={isMobile ? mobstyle : deskstyle}
        style={{display:'flex', gap:'1%', width:'97%', margin:'auto'}}
     
      >

        <div className="carousel-inner2">
          {images.map((image, index) => (
            <div
              key={index}
              className={`carousel-item${
                index === activeIndex ? " active" : ""
              }`}
            >
              <img
                src={image}
                alt={`Slide ${index}`}
                style={{width:'100%', height:'100%'}}
                // style={isMobile ? mobilestyle : desktopstyle}
              />
            </div>
          ))}
        </div>
        <div className="custom-banner2" 
        // style={ isMobile ? mob : desk }
        >
                <div className="custom-banner-content2">
                  <h3>Post Your Ads Now</h3>
                  <p>Get Better Prices More Than You Expected</p>
                  <Link to="/add-product/Electronics" className="postnowbtn1">Post Now</Link>
                </div>
              </div>
      </div>
    </>
  );
};

export default MobileSlider;

