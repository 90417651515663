import React ,{useState,useEffect} from "react";
import "./Careercards.css";
import { Link } from "react-router-dom";
import CareerForm from "./CareerForm";
import { localUrl } from "../../env";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { isMobile } from "react-device-detect";
import TextField from '@mui/material/TextField';
import { AutoComplete,  Form, Input, Select, message } from "antd";
import { add } from "../../../store/Track/trackUserSlice";
import { useDispatch } from "react-redux";


const mobileStyle = {
  width: "100%",
  padding: "10px",
  justifyContent: "center",
};

const desktopStyle = {
  width: "100%",
  paddingRight: "2rem",
  paddingLeft: "-3rem",
};

const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};


const Card = (props) => {

  return (
    <>
      <div className="Careercard1">
        <div className="Careercard-image1">
          <img src={props.image} alt={props.title} style={{ height: "10rem" }} />
        </div>
        <div className="Careercard-content1">
          <p className="Careercard-title1">{props.title}</p>
          <button 
            className="Careercard-button1 text-white" onClick={props.onClick}>
            {props.button}
          </button>
        </div>
      </div>
    </>
  );
};
const Careercards = () => {
  // Array of card data objects defining the properties for each card

  const dispatch = useDispatch(); // Initialize the 'dispatch' function from the 'useDispatch' hook
  const [values, setValues] = useState(); // Initialize the 'values' state and the 'setValues' function with the 'useState' hook
  const [submitted, setSubmitted] = useState(false); // Initialize the 'submitted' state and the 'setSubmitted' function with the 'useState' hook
  const [form] = Form.useForm(); // Create a form instance with the 'useForm' hook from the 'Form' component in the 'antd' library

  const onFinish = (values) => {
    // Function called when the form is submitted
    dispatch(add({ form: ["contact", "contactPage", values] })); // Dispatch the 'add' action with the form values

    form.resetFields(); // Reset the form fields
    setSubmitted(true); // Set the 'submitted' state to true

    var requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
      redirect: "follow",
    };

    fetch(`https://databytess.com/api/user/jobDetails`,requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // Handle the response/result of the fetch request
         console.log('result',result);
        const options = {
          method: "POST",
          headers: {
            "content-type": "application/json",
            "X-RapidAPI-Key":
              "7fded38e7emsh3c4fb60f3b8017cp1c084bjsn32180c499f5f",
            "X-RapidAPI-Host": "hourmailer.p.rapidapi.com",
          },
        };

        // Fetch data from a different API endpoint
        // fetch('https://hourmailer.p.rapidapi.com/send', options)
        //   .then(response => response.json())
        //   .then(response => { console.log(response) })
        //   .catch(err => console.error(err));
      })
      .catch((error) => console.log("@@error", error));

    console.log('values',values);
  };

  const successMessage = () => {
    // Function to display a success message
    return (
      <div
        className="success"
        style={{
          display: submitted ? "" : "none",
        }}
      >
        <h5 className="text-success bg-white" style={{ textAlign: "center" }}>
          Submitted
        </h5>
      </div>
    );
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        style={{
          width: 70,
        }}
      >
        <Option value="91">+91</Option>
      </Select>
    </Form.Item>
  );

  const [autoCompleteResult, setAutoCompleteResult] = useState([]);

  const [open,setOpen] = useState(false);
  const [jobData,setJobData] = useState([])

 const openModalHandler=()=>{
  console.log('hello guys')
  setOpen(true);
  console.log('open state',open);
 }

 useEffect(() => {
  // setLoading(true);
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };
  fetch(localUrl + "user/jobsRequired", requestOptions)
    .then((response) => response.json())
    .then((result) => {
      // setLoading(false);
      setJobData(result);
      console.log("result jobData",jobData);
    })
    .catch((error) => console.log("error", error));
  // setLoading(false);
}, []);

 const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: '85%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
 
  const cardsData = [
    {
      title: "REACT Developer",
      image:"https://www.pngitem.com/pimgs/m/616-6169316_react-react-js-icon-white-hd-png-download.png ",
      button: "Apply Now",
      //   link: "/contact",
    },
    {
      title: "Angular Developer", // Title of the card
      image:"https://robertleggett.blog/wp-content/uploads/2013/12/angular.jpg", // Image URL for the card
      button: "Apply Now", // Button text for the card
      //  link: "/contact", // Link to redirect when the button is clicked
    },
    {
      title: "PHP Developer",
      image: "https://th.bing.com/th/id/OIP.N4xReed330W4FNpaKWpC8AHaHa?pid=ImgDet&rs=1",
      button: "Apply Now",
      //  link: "/contact",
    },
    {
      title: "Backend Developer ",
      image:
        "https://th.bing.com/th/id/OIP.XVRl3sE0sijSEc3wy944awAAAA?pid=ImgDet&rs=1",
      button: "Apply Now",
      //  link: "/contact",
    },
    {
      title: "Marketing Associate",
      image: "https://www.thebmag.gr/wp-content/uploads/2019/06/marketing.jpg",
      button: "Apply Now", 
      //   link: "/contact",
    },
  ];
  return (
    <>
    <div>
      <div className="card-container1" style={{ background: "white" }}>
        {cardsData.map((Careercard, index) => (
          <Card key={index} {...Careercard}  onClick={openModalHandler}/>
        ))}
      </div>
      {/* <Modal
                    title="Apply For The Position"
                    centered
                    open={open}
                    onOk={() => setOpen(false)}
                    onCancel={() => setOpen(false)}
                    width={1000}
                    style={{ marginTop: "70px" }}
                  >
                    <CareerForm jobs={12} />
                  </Modal> */}
                   <Modal
        open={open}
        onClose={()=> setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="career-pop">
          <Typography id="modal-modal-title" variant="h6" component="h2">
          <>
      {" "}
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={onFinish}
        scrollToFirstError
        style={isMobile ? mobileStyle : desktopStyle}
      >
        <Form.Item
          name="Email"
          label="Email"
          rules={[
            {
              type: "email",
              message: "It is not a valid E-mail!",
            },
            {
              required: true,
              message: "Please input your E-mail!",
            },
          ]}
        >
          <Input placeholder="Enter Your Email" />
        </Form.Item>
        <Form.Item
          name="name"
          label="name"
          rules={[
            {
              required: true,
              message: "Please input your name",
              whitespace: true,
            },
            {
              pattern: new RegExp("^[A-Za-z ]*$"),
              message: "Wrong format!",
            },
          ]}
        >
          {/* <Input /> */}
          <Input placeholder="Enter your Name" />
        </Form.Item>
        <Form.Item
          name="PhoneNumber"
          label="Phone Number"
          rules={[
            {
              required: true,
              message: "Please input your phone number!",
            },
            {
              pattern: new RegExp("^[0-9]*$"),
              message: "Wrong format!",
            },
          ]}
        >
          <Input
            placeholder="Enter Phone Number"
            maxLength={10}
            minLength={10}
            // addonBefore={prefixSelector}
            style={{
              width: "100%",
            }}
          />
        </Form.Item>

        <Form.Item
          name="Title"
          label="Title"
          rules={[
            {
              required: true,
              message: "Please input title",
              whitespace: true,
            }
          ]}
        >
          {/* <Input /> */}
          <Input placeholder="Enter Title Here" />
        </Form.Item>
        <Form.Item
          name="Introduction"
          label="Introduction"
          
        >
          <Input.TextArea
            showCount
            maxLength={100}
            placeholder="Enter Introduction Here"
          />
        </Form.Item>
     

        <Form.Item {...tailFormItemLayout}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginLeft: "0px" }}
          >
            Submit
          </Button>
        </Form.Item>

        <h4 className="text-success">{successMessage()}</h4>
      </Form>
    </>
          </Typography>
          
        </Box>
      </Modal>
</div>
    </>
  );
};

export default Careercards;
