// import { DatePicker } from "antd";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Category, localUrl, url } from "../env";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { isMobile } from "react-device-detect";
import { Card, Row, Col } from "antd";
// import axios from "axios";
// import React, { useState, useEffect } from "react";
import "antd/dist/antd.less";
import { FaBloggerB } from "react-icons/fa";
import { add } from "../../store/Track/trackUserSlice";
import YoutubeMagic1 from "../ContentLoader/YoutubeMagic1";
import NoDataFound from "../datanotfound/NoDataFound";
import { LocationCity, Map } from "@mui/icons-material";
import Owldemo2 from "../Owldemo2";
import YoutubeMagic from "../ContentLoader/YoutubeMagic";

const { Meta } = Card;
// const categoryColor = styled.li`
//   background: red;
//   color: palevioletred;
// `;
const current = new Date().toGMTString();
// const date = `${current.getDate()}-${current.getMonth() + 1
//   }-${current.getFullYear()}`;
const date = `${current.slice(0, 16)}`;

const mobileStyle = {
  width: 180,
  display: "flex",
  borderRadius: "20px",
  flexDirection: "column",
  margin: "5px",
  gap: "5px",
  boxShadow: "1px 2px 2px 0px #efefef",
  justifyContent: "center",
};
const desktopStyle = {
  display: "flex",
  flexDirection: "column",
  width: 200,
  margin: "20px",
  // border: "2px solid gray",
  borderRadius: "20px",
  boxShadow: "1px 2px 2px 0px #efefef"
};
const mobileStyleimg = {
  width: "100%",
  height: "120px",
  borderRadius: "20px 20px 0px 0px"
}

const desktopStyleimg = {
  width: "100%",
  height: "180px",
  borderRadius: "20px 20px 0px 0px"
}



const mobuse = {
  marginLeft: "5px", marginRight: "5px"
}
const deskuse = {
  marginLeft: "11.5rem", marginRight: "11.5rem"
}



// allAds geetings start
const greeting = "RealEstate";
const greeting1 = "Furniture";
const greeting2 = "Electronics";
const greeting3 = "Cars";
const greeting4 = "Bikes";
const greeting5 = "Pets";
const greeting6 = "Mobiles";
const greeting7 = "Services";
const greeting8 = "Education";
const greeting9 = "Jobs";
//  allAds geetings end

const ShowBlog = () => {
  // const isMobile = true;
  // const [currentPageData, setCurrentPageData] = useState(new Array(2).fill());
  // const [blogs, setBlogs] = useState([]);
  const [category, setCategory] = useState(null);
  const [isloading, setIsLoading] = useState(true);
  const [image, setImage] = useState();

  // const [error, setError] = useState(null);
  const [showPerPage, setShowPerPage] = useState(3);
  const [blogs, setBlogs] = useState([]);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const [tempBlog, setTempBlog] = useState([]);
  const [itemsToLoad, setItemsToLoad] = useState(25); // Holds the number of items to load initially and increases when "Load More" is clicked
  const [limitIncreaseCount, setLimitIncreaseCount] = useState(0);
  const getBlogs = async () => {
    try {
      const response = await axios(localUrl + `blogsapi/?limit=${itemsToLoad}`);
      setBlogs([...response.data]);
      setTempBlog(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching blogs:", error);
      setError("Could not fetch the data");
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getBlogs();
  }, [itemsToLoad]);
  const handleLoadMore = () => {
    setItemsToLoad(itemsToLoad + 25);
    setLimitIncreaseCount((prevCount) => prevCount + 1);

  };
  const [prevDataLength, setPrevDataLength] = useState(0);
  const [cardCount, setCardCount] = useState(20);

  const loadMoreCards = () => {
    // Increment the card count by 30.
    setCardCount(cardCount + 20);
      setLimitIncreaseCount(0);

  };
  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (!isloading && limitIncreaseCount != 3) {
        const newDataLength = blogs.length;
  
        if (newDataLength !== prevDataLength) {
          handleLoadMore();
          setPrevDataLength(newDataLength);
        } else {
          clearInterval(intervalId); // Stop further calls to handleLoadMore
        }
      }
    }, 2000);
  
    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [isloading, blogs, prevDataLength,limitIncreaseCount]);

  useEffect(() => {
    dispatch(add({ view: ["ShowBlog"] }));
  }, []);

  if (category != null) {
    let value1 = blogs.map((result, index) => {
      if (category == null ? false : result.category === category) {
        return "exists";
      }
      return;
    });
    if (value1.includes("exists")) {
      var valuemain = 3;
    }
  }

  useEffect(() => {
    let temp = [];
    temp = tempBlog.map((blog) => {
      // if(blog.category === category){
      if (category == null ? blog : blog.category === category) {
        return blog;
      } else {
        return "null";
      }
    });
    temp.push("null");
    temp = temp.filter(function (e) {
      return temp.indexOf(e) == temp.lastIndexOf(e);
    });

    setBlogs(temp);
  }, [category]);

  useEffect(() => {
    const t = setTimeout(() => {
      setIsLoading(false);
    }, 3000);
    return () => {
      clearTimeout(t);
    };
  }, []);

  document.title = "Hola9 - ShowBlogs";
  return (
    <>
      {isloading ? (
        <YoutubeMagic1 />
      ) : (
        <>
        <div className="container-fluid mt-1"
        >
          {error && <p>{error}</p>}
          <div className="row">
            <div className="col-lg-9 col-md-9 col-sm-12"
            >
              {/* <Ant/> */}
              <span>
                <Row className="justify-content-center"
                >
                  {blogs.length > 0 ? (
                    blogs
                       ?.slice(0,cardCount).sort((a, b) => b.id - a.id)
                      .map((blog) => {
                        const imageUrl = blog.image && blog.image.replace("http://64.227.150.79:8000", "");
                        return (
                          // <h2>{blog.category}</h2>
                          <div key={blog.id} >
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}  className="g-3">
                              <Link to={`/blogs-listing/${blog.id}/`}>
                                <Card
                                  style={isMobile ? mobileStyle : desktopStyle}
                                  cover={
                                    blog.video===null?(
                                    <img
                                      alt="example"
                                     src={blog.image}
                                     style={isMobile ? mobileStyleimg : desktopStyleimg}
                                    />
                                    ):(<video
                                    controls
                                    muted
                                    loop
                                    playsInline
                                    src={blog.video}
                                    style={isMobile ? mobileStyleimg : desktopStyleimg}
                                  />)
                                  }
                                >
                                  <p><LocationCity />{blog.city}</p>
                                  <p><Map />{blog.title}</p>
                                  <Meta
                                    description={blog.description && blog.description.length > 30
                                      ? blog.description.slice(0, 15) + "..."
                                      : blog.description
                                    }
                                  />

                                </Card>
                              </Link>
                            </Col>
                          </div>
                        );
                      })
                  ) : (
                    <div
                      style={{
                        margin: "auto",
                        padding: "auto",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      <div>
                        <NoDataFound />
                      </div>
                    </div>
                  )}
                  {cardCount > blogs.length && (
      <>
      {isloading && <p className="loading-message"> <YoutubeMagic/> </p>}
      </>
      )}
      {cardCount < blogs.length && (
                    <div
                    className="loadbt text-center"
                    style={{
                      display: 'inline-block',
                      backgroundColor: '#0a2b3f',
                      color: '#fff',
                      padding: '10px 20px',
                      textDecoration: 'none',
                      borderRadius: '20px',
                      marginTop: '10px',
                      fontWeight: '500',
                      textAlign:"center",
                      justifyContent:"center",
                      border: '2px solid gray'
                    }}
                  >
                    <button onClick={loadMoreCards}>
                      Load More
                    </button>
                  </div>

                  )}
                </Row>
              </span>
            </div>

            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h6 className="m-auto">Register Your Business With Us</h6>
                </div>
                <div className="card-body p-2">
                  <p
                    className="card-text text-center"
                    style={{ background: "none" }}
                  >
                    Having a Business in mind. We will promote you
                  </p>
                  <h5 className="text-center " style={{ marginright: "60px" }}>
                    <Link
                      to="/contact"
                      className="bg-info d-block text-white rounded-pill w-60"
                    >
                      Contact Us
                    </Link>
                  </h5>
                </div>
              </div>
              <div className="card1">
                <div className="card-header">
                  <h3 className="card-title">Categories</h3>
                </div>
                <div className="card-body p-0">
                  <div className="list-catergory">
                    <div className="item-list">
                      <ul className="list-group mb-0">
                        {Category.map((catItem) => {
                          console.log(catItem, "#@#@#");
                          return (
                            <li
                              className={"list-group-item  "}
                              style={
                                category == catItem
                                  ? { background: "	#C8C8C8", color: "red" }
                                  : { background: "white" }
                              }
                            >
                              <Link
                                to="#"
                                value={catItem}
                                className="text-dark "
                                onClick={(e) => {
                                  setCategory(catItem);
                                }}
                              >
                                {/* <i className="fa fa-car bg-primary text-primary "  /> */}
                                <FaBloggerB /> &nbsp;
                                <span style={{ fontSize: "15px" }}>
                                  {catItem}
                                </span>
                                &nbsp;
                                <span className="badgetext badge badge-pill badge-light mb-0 mt-1 mt-1">
                                  {/* {catItem.length} */}
                                </span>
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                      {/* <button onClick={() => { setCategory(null) }}>clear filter</button> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card1">
                <div className="card-header">
                  <h3 className="card-title">Popular Tags</h3>
                </div>
                <div className="card-body">
                  <div className="product-tags clearfix">
                    <ul className="list-unstyled mb-0">
                      <li>
                        <Link
                          to="#"
                          // onClick={(e) => {
                          //   setCategory("Food");
                          onClick={(e) => {
                            setCategory("Food");
                          }}
                        >
                          Food blogs
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          onClick={(e) => {
                            setCategory("Fashion");
                          }}
                        >
                          Fashion blogs
                        </Link>
                      </li>
                      <li>
                        <Link to="#">Lifestyle blogs</Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          onClick={(e) => {
                            setCategory("Joureney");
                          }}
                        >
                          Travel blogs
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          onClick={(e) => {
                            setCategory("Art");
                          }}
                        >
                          Art and Craft blogs
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          onClick={(e) => {
                            setCategory("Book");
                          }}
                        >
                          Book and writing blogs
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          onClick={(e) => {
                            setCategory("photography");
                          }}
                        >
                          Photography
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          onClick={(e) => {
                            setCategory("Interview");
                          }}
                        >
                          Interviews Preparation
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          onClick={(e) => {
                            setCategory("Inspirational");
                          }}
                        >
                          Inspirational Stories
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          onClick={(e) => {
                            setCategory("Interior");
                          }}
                        >
                          Interior Design
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          onClick={(e) => {
                            setCategory("Weedings");
                          }}
                        >
                          Destination Weddings
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          className="mb-0"
                          onClick={(e) => {
                            setCategory("RealEstate");
                          }}
                        >
                          Real Estate
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </>
      )}

      

    </>
  );
};

export default ShowBlog;
