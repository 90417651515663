import React, { useContext, useEffect, useState } from "react";
import { Modal } from "antd";
import {
  freePlanDetails,
  goldPlanDetails,
  platinumPlanDetails,
  silverPlanDetails,
  url,
  localUrl,
  Free,
  Gold,
  Silver,
  Platinum,
  access_token,
  Category,
} from "../../env";
// import "./Pricing.module.css";
import "./newprice.css";
import { Link, useParams } from "react-router-dom";
import Axios from "axios";
import { UserContext } from "../../../App";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { add } from "../../../store/Track/trackUserSlice";
import BiziverseCRM from "../../Dashboard/BiziverseCRM";
import { SuccessMdoel } from "./SuccessModel";
import { Alert } from "antd";
import { getUserPlan } from "../../../store/allPlanDetails";
import { UserProfile } from "../../../store/userIdSlice";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Col, Row } from "antd";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
const Pricing = () => {
  const user1 = useContext(UserContext);
  const { loading, data } = useSelector((state) => state.planData);
  console.log("resultapi ", data);
  const [userId, setUserId] = useState(null);
  const userData = useSelector((state) => state.userIdSlice);

  const navigate = useNavigate();

  const [phone, setPhoneNumber] = useState(null);
  const [phoneError, setPhoneError] = useState("");

  const [planValue, setPlanValue] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPlan, setCurrentPlan] = useState(null);
  const { id } = useParams();
  const changeAds = useParams();

  //after sucess
  const [modalAfterSuccess, setModalAfterSuccess] = useState(false);
  const [successObj, setSuccessObj] = useState([]);
  useEffect(() => {
    console.log(id);
    if (id && localStorage.getItem("planValue") != null) {
      let data1 = document.getElementById("plan").value;
      let data = JSON.parse(localStorage.getItem("planValue"));
      localStorage.removeItem("planValue");
      console.log(data, "HELOWORLD", data1);
      debugger;
      // let data= document.getElementById("plan").value
      var bodyData = new FormData();
      bodyData.append("layer_pay_token_id", data.data["token_id"]);
      bodyData.append("tranid", data.data["txnid"]);
      bodyData.append("layer_order_amount", data.data["amount"]);
      bodyData.append("layer_payment_id", id);
      bodyData.append("hash", data.data["hash"]);
      console.log("callback calling", bodyData);

      var requestOptions = {
        method: "POST",
        body: bodyData,
        redirect: "follow",
      };

      fetch(url + "api/payment/callback", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          debugger;
          console.log("aftersuccess", result);
          setModalAfterSuccess(true);
          setSuccessObj(result);
          // navigate("/dashboard");
        })
        .catch((error) => console.log("error", error));
    }
  }, []);

  // const showModal = (e) => {
  //   setIsModalOpen(true);
  //   setCurrentPlan(e);
  //   if (e == "Gold") {
  //     setPlanValue(299);
  //   } else if (e == "Silver") {
  //     setPlanValue(99);
  //   } else if (e == "Platinum") {
  //     setPlanValue(799);
  //   }

  //   console.log(e);
  // };
  const handleOk = () => {
    if (currentPlan != null) {
      handleSubmit(currentPlan);
    }
  };
  const handleCancel = () => {
    setOpen(false);
    setIsModalOpen(false);
  };
  useEffect(() => {
    console.log(data?.totalPlan);

    if (localStorage.getItem("access_token") != null) {
      if (userData.data == null) {
        dispatch(UserProfile(localStorage.getItem("access_token")));
      } else {
        setUserId(userData.data?.id);
        dispatch(getUserPlan(userData.data?.id));
      }
    }
  }, [userData.data]);

  useEffect(() => {}, [data]);

  ////razorpay payment gateway

  const [name, setName] = useState("");
  const [amount, setAmount] = useState("");
  const [order, setOrder] = useState(null);
  const [finalObj, setFinalObj] = useState(null);
  const [success, setSuccess] = useState(false);
  const [successResult, setResult] = useState(null);
  const [alertShow, setAlertShow] = useState(false);
  const [dataValue, setDataValue] = useState(null);
  const [category, setCategory] = useState();
  const [open, setOpen] = useState(false);
  const [plan, setPlan] = useState();


  const handleChange = (event) => {
    setCategory(event.target.value);
  };

  const showFirstModal = (e) => {
    setOpen(true);

    setPlan(e);
  };

  const showModal = () => {
    setOpen(false);

    setIsModalOpen(true);

    setCurrentPlan(plan);

    if (plan == "Gold") {
      setPlanValue(499);
    } else if (plan == "Silver") {
      setPlanValue(99);
    } else if (plan == "Platinum") {
      setPlanValue(999);
    }
  };

  useEffect(() => {}, [successResult]);
  var finalObjOnce;
  const finalSuccess = (v1) => {
    console.log(v1);
    let finalObj = v1;
    console.log(finalObjOnce);
    finalObj["order"] = finalObjOnce;
    setFinalObj(finalObj);
    console.log("final call happen");
    console.log(order);
    setSuccess(true);
    finalDetailsPayment(v1, finalObjOnce);
  };

  const finalDetailsPayment = (finalData) => {
    let objFinalDetails = {};
    objFinalDetails["UserID"] = finalData["user"];
    objFinalDetails["orderid"] = finalData["id"];
    objFinalDetails["plan"] = finalData["plan"];
    var formdata = new FormData();
    formdata.append("paymentDetails", JSON.stringify(objFinalDetails));

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(url + "api/user/paymentDetails", requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
    console.log(objFinalDetails);
  };
  const handlePaymentSuccess = async (response, tempFinalObj) => {
    try {
      let bodyData = new FormData();
      // we will send the response we've got from razorpay to the backend to validate the payment
      bodyData.append("response", JSON.stringify(response));

      await Axios({
        url: localUrl + "payment/razorpay/payment/success/",
        method: "POST",
        data: bodyData,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          console.log("!!92", res);
          finalSuccess(tempFinalObj);
          console.log("Everything is OK!");
          setName("");
          setAmount("");
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(console.error());
    }
  };

  // this will load a script tag which will open up Razorpay payment card to make transactions
  const loadScript = () => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    document.body.appendChild(script);
  };

  const showRazorpay = async (v1, v2, tempFinalObj, planValue) => {
    console.log("temp final", tempFinalObj);
    const res = await loadScript();
    let amount = v1;
    let name = v2;
    console.log(v1, v2, amount, name);
    let bodyData = new FormData();
    const userid = localStorage.getItem("userid");

    // we will pass the amount and product name to the backend using form data
    // bodyData.append("amount", amount);
    bodyData.append("IdValue", userid);
    bodyData.append("planValue", currentPlan);
    bodyData.append("Phone", phone);
    // bodyData.append('userID','5')
    // bodyData.append('PlanID','12')
    const data = await Axios({
      // url: `${server}/razorpay/pay/`,
      url: localUrl + "payment/create_token",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: bodyData,
    }).then((res) => {
      // finalObjOnce = res.data.order;
      document.getElementById("plan").value = JSON.stringify(res);
      localStorage.setItem("planValue", JSON.stringify(res));
      setDataValue(res);
      console.log(res);
      document.getElementById("paymentCalling").click();
      debugger;

      // setOrder(res?.data?.order);
      return res;
    });

    // console.log(data)

    // in data we will receive an object from the backend with the information about the payment
    //that has been made by the user

    // var options = {
    //   key_id: `** your razorpay public key id **`,
    //   key_secret: `** your razorpay secret key id **`,
    //   amount: data.data.payment.amount,
    //   currency: "INR",
    //   name: "Hola9",
    //   description: "Test teansaction",
    //   image: "", // add image url
    //   order_id: data.data.payment.id,
    //   handler: function (response) {
    //     // we will handle success by calling handlePayment method and
    //     // will pass the response that we've got from razorpay
    //     handlePaymentSuccess(response, tempFinalObj);
    //   },
    //   prefill: {
    //     name: "User's name",
    //     email: "User's email",
    //     contact: "User's phone",
    //   },
    //   notes: {
    //     address: "Razorpay Corporate Office",
    //   },
    //   theme: {
    //     color: "#3399cc",
    //   },
    // };

    // var rzp1 = new window.Razorpay(options);
    // rzp1.open();
  };
  //end of razorpay payment gateway
  //after successpayment modal

  const finalSuccess1 = (v1) => {
    let finalObj = v1;
    setFinalObj(finalObj);
    finalDetailsPayment1(v1);
  };
  const finalDetailsPayment1 = (finalData) => {
    let objFinalDetails = {
      UserID: finalData.user,
      plan: finalData.plan,
      orderid: Math.floor(Math.random() * 1000000).toString(),
    };
    console.log("finalObj", JSON.stringify(objFinalDetails));

    var formdata = new FormData();
    formdata.append("paymentDetails", JSON.stringify(objFinalDetails));

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    fetch(url + "api/user/paymentDetails", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        if (result === "Success") {
          navigate("/dashboard");
        }
      })
      .catch((error) => console.log("error", error));
    console.log(objFinalDetails);
  };
  const useridst = localStorage.getItem("userid");
  
  const handleFreePlan = (props) => {
    console.log("props", props);
    let idValue = useridst ? useridst : navigate("/login");
    let tempFinalObj = { plan: props, price: 1, user: idValue };
    finalSuccess1(tempFinalObj);
    // finalDetailsPayment1(props,idValue)
  };
  const handleSubmit = (props) => {
    if (phoneError) {
      console.error("Please fix the errors before submitting the form.");
      return;
    }
    
    console.log("props", props);
    let idValue = useridst ? useridst : navigate("/login");
    setIsModalOpen(false);

    var formdata = new FormData();
    formdata =
      props === "Free"
        ? Free
        : props === "Silver"
        ? Silver
        : props === "Gold"
        ? Gold
        : Platinum;
    formdata["price"] = 5;
    let priceValue = formdata["price"];
    setName(idValue);
    setAmount(priceValue);
    let tempFinalObj = {};
    tempFinalObj["plan"] = formdata;
    tempFinalObj["price"] = priceValue;
    tempFinalObj["user"] = idValue;
    setFinalObj(tempFinalObj);
    let planValue = props;
    let outputValue = showRazorpay(
      priceValue,
      idValue,
      tempFinalObj,
      planValue
    );
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(add({ view: ["Pricing"] }));
  }, []);

  const error = () => {
    Modal.error({
      title: "Free Plan",
      content: "You can not buy free plan multiple times",
    });
  };
  const validatePhoneNumber = (value) => {
    const phoneNumberRegex = /^\d{12}$/;
    return phoneNumberRegex.test(value);
  };

  // Handler for phone number input change
  const handlePhoneChange = (value) => {
    setPhoneNumber(value);
    if (validatePhoneNumber(value)) {
      setPhoneError("");
    } else {
      setPhoneError("Please enter a valid 10-digit phone number.");
    }
  };

  return (
    <>
      {modalAfterSuccess && <SuccessMdoel props={successObj}/>}
      

      <div className="cardcomp">
    <div className="wrappercard">
      <input type="radio" name="slider" id="tab-1" defaultChecked="" />
      <input type="radio" name="slider" id="tab-2"  />
      <input type="radio" name="slider" id="tab-3" />
      <input type="radio" name="slider" id="tab-4" />
      <header>
        <label htmlFor="tab-1" className="tab-1">
          Free
        </label>

        <label htmlFor="tab-2" className="tab-2">
          Silver
        </label>

        <label htmlFor="tab-3" className="tab-3">
          Gold
        </label>

        <label htmlFor="tab-4" className="tab-4">
          Platinum
        </label>

        <div className="slider" />
      </header>
      <div className="card-area">
        <div className="cardsd">
          <div className="rowsd row-1">
            <div className="price-details"><br/>
              <span className="price" style={{fontSize:'30px'}}>₹0</span>
            </div>

            <ul className="detail d-grid justify-content-center">
                                        {freePlanDetails?.map((val, index) =>
                                          index < 3 ? (
                                            <li key={val} className="p-1 fs-14" >
                                              <i className="fas fa-check text-success" style={{color:"green"}}/>
                                              {val}
                                            </li>
                                          ) : (
                                            <li key={val} className="p-1 fs-14">
                                              <i className="fas fa-times text-danger" />
                                              {val}
                                            </li>
                                          )
                                        )}
                                      </ul>
                                      {data?.totalPlan?.includes("Free") ||
                                      !data?.totalPlan ? (
                                        <button type="button" onClick={error} >
                                        Choose plan
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          onClick={() => handleFreePlan(Free)}
                                        >
                                          Buy Now
                                        </button>
                                      )}
          </div>
          <div className="rowsd  ">
            <div className="price-details"><br/>
            <span className="orinalPrice">₹499</span> &nbsp;
              <span className="price">₹99</span>
              <div style={{color: 'green', fontWeight: 'bold', fontSize:'10px', marginTop:'-5px'}}>55% Off</div>
            </div>

            <ul className="detail d-grid justify-content-center">
                                        {silverPlanDetails?.map((val, index) =>
                                          index < 6 ? (
                                            <li key={val} className="p-1 fs-14">
                                              <i className="fas fa-check text-success" />
                                              {val}
                                            </li>
                                          ) : (
                                            <li key={val} className="p-1 fs-14">
                                              <i className="fas fa-times text-danger" />
                                              {val}
                                            </li>
                                          )
                                        )}
                                      </ul>
                          <button  onClick={() => showFirstModal("Silver")}>Choose plan</button>
          </div>
          <div className="rowsd">
            <div className="price-details"><br/>
              {" "}
            <span className="orinalPrice">₹999</span> &nbsp;
              <span className="price">₹499</span>
              <div style={{color: 'green', fontWeight: 'bold', fontSize:'10px', marginTop:'-5px'}}>55% Off</div>
            </div>

            <ul className="detail d-grid justify-content-center">
                                        {goldPlanDetails.map((val, index) =>
                                          index < 8 ? (
                                            <li
                                              key={val}
                                              className="p-1 fs-14 "
                                            >
                                              <i className="fas fa-check text-success" />
                                              {val}
                                            </li>
                                          ) : (
                                            <li key={val} className="p-1 fs-14">
                                              <i className="fas fa-times text-danger" />
                                              {val}
                                            </li>
                                          )
                                        )}
                                      </ul>
           <button id="plan" onClick={() => showFirstModal("Gold")}>Choose plan</button>
          </div>
          <div className="rowsd">
            <div className="price-details"><br/>
              {" "}
            <span className="orinalPrice">₹1499</span> &nbsp;
              <span className="price">₹999</span>
              <div style={{color: 'green', fontWeight: 'bold', fontSize:'10px', marginTop:'-5px'}}>55% Off</div>
            </div>

            <ul className="detail d-grid justify-content-center">
                                        {platinumPlanDetails.map((val) => (
                                          <li key={val} className="p-1 fs-14">
                                            <i className="fas fa-check fs-14 text-success" />
                                            {val}
                                          </li>
                                        ))}
                                      </ul>
           <button onClick={() => showFirstModal("Platinum")}>Choose plan</button>
          </div>
        </div>
      </div>
     
    </div>
    </div>

      <BiziverseCRM />
      <Modal
        title="Select Categories"
        open={open}
        okText="Proceed"
        onOk={showModal}
        onCancel={handleCancel}
      >
        <Row>
          <Col span={8}> Categories</Col>

          <Col span={12} style={{marginLeft: "-3rem"}}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label ">Category List</InputLabel>

              <Select style={{width: "12rem", }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={category}
                label="category"
                onChange={handleChange}
              >
                <MenuItem value={10}>Bikes</MenuItem>
                <MenuItem value={20}>Cars</MenuItem>
                <MenuItem value={30}>Education</MenuItem>
                <MenuItem value={40}>Electronics</MenuItem>
                <MenuItem value={50}>Furniture</MenuItem>
                <MenuItem value={60}>Jobs</MenuItem>
                <MenuItem value={70}>Mobiles</MenuItem>
                <MenuItem value={80}>Pets</MenuItem>
                <MenuItem value={90}>Real Estate</MenuItem>
                <MenuItem value={100}>Services</MenuItem>
              </Select>
            </FormControl>
          </Col>
        </Row>
      </Modal>
      <Modal
        title="Payment Now"
        open={isModalOpen}
        onOk={handleSubmit}
        onCancel={handleCancel}
        okText="Pay Now"
      >
        <Row>
          <Col span={8}> Phone Number</Col>
          <Col span={16}>
            {" "}
            <PhoneInput
              country={"in"}
              onlyCountries={["in"]}
              value={phone}
              onChange={handlePhoneChange}            />
                        {phoneError && <span style={{ color: "red" }}>{phoneError}</span>}

          </Col>
          <Col span={12}>Plan</Col>
          <Col span={12}>{planValue}</Col>
          <Col span={12}>GST</Col>
          <Col span={12}>18%</Col>
          <Col span={12}>Total</Col>
          <Col span={12}>
            {Math.floor(planValue + (planValue * 18) / 100)}
            .00
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default Pricing;
