import React, { useContext, createContext, useEffect, useState } from "react";
import "./Header.css";

import { FaPlus } from "react-icons/fa";
import Hola9logo from "../images/Hola9-logo.jpg";
import TopHeader from "./TopHeader/topHeader";
import { Link, useNavigate } from "react-router-dom";
import { location1 } from "../Location";
import Mobileheader from "./TopHeader/Mobileheader";
import Autolocation from "./TopHeader/Autolocation";

const ReachableContext = createContext(null);
const UnreachableContext = createContext(null);


const Header = (props) => {
 
  return (
    <>
      {/* <TopHeader /> */}
      <Mobileheader />
      <nav
        className="navbar sticky-top navbar-expand-lg navbar-light  p-4 hidden-sm hidden-xs visible-md-block visible-lg-block"
        style={{ height: "45px", marginTop: "-14px", background: "white" }}
      >
        <div className="container-fluid">
        <div>
          <Link title="Logo" to="/" className="site__brand__logo">
            <img
              src={Hola9logo}
              style={{ width: "90px", height: "40px" }}
              alt="Golo"
            />
          </Link>
          </div>
          <div className="collapse navbar-collapse" id="navbarSupportedContent" style={{float:"right",justifyContent:"end"}}>
            <div className="dropdown show">
              <Autolocation />
            </div>&nbsp;&nbsp;&nbsp;
            <div>
              {localStorage.getItem("access_token") == null ? (
                <Link to="/login/">
                  <button id="testbutton">
                    <FaPlus
                      className="text-white fs-12"
                      style={{ marginTop: "-2px" }}
                    />
                    &nbsp;Post Ads
                  </button>
                </Link>
              ) : (
                <Link to="/add-real/RealEstate">
                  <button id="testbutton">
                    <FaPlus
                      className="text-white fs-12"
                      style={{ marginTop: "-2px" }}
                    />
                    &nbsp;Post Ads
                  </button>
                </Link>
              )}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};
export const callingloginCheck = () => {
  console.log("callinglogin system");
};

export default Header;
