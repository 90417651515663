

import React, { useState } from 'react';
import './AutoPlay.css';
import { FaHome, FaPaw, FaCouch, FaMobileAlt, FaTv, FaBicycle, FaCar, FaBriefcase, FaTools, FaGraduationCap } from 'react-icons/fa';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { getExtraField } from '../store/ProductFilterSlice';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt, faHandshake, faLifeRing } from '@fortawesome/free-solid-svg-icons';

const categories = [
  {
    title: 'Real Estate',
    description: 'Find your dream home or property. Explore a variety of listings and housing options.',
    icon: <FaHome color="#FF6347" />,
  },

  {
    title: 'Pets',
    description: 'Discover lovable pets for adoption or sale. Find your new furry friend today!',
    icon: <FaPaw color="#FFD700" />,
  },
  {
    title: 'Furniture',
    description: 'Furnish your home with stylish furniture items. Choose from a wide range of designs and styles.',
    icon: <FaCouch color="#00BFFF" />,
  },
  {
    title: 'Mobiles',
    description: 'Explore the latest mobile phones and accessories. Stay connected with cutting-edge technology.',
    icon: <FaMobileAlt color="#32CD32" />,
  },
  {
    title: 'Electronics',
    description: 'Browse a wide range of electronic gadgets. From smartphones to home appliances, we have it all.',
    icon: <FaTv color="#9370DB" />,
  },
  {
    title: 'Bikes',
    description: 'Find the perfect bicycle for your outdoor adventures. Enjoy biking in style and comfort.',
    icon: <FaBicycle color="#1E90FF" />,
  },
  {
    title: 'Cars',
    description: 'Buy, sell, or trade automobiles and accessories. Your dream car is just a click away!',
    icon: <FaCar color="#1E90FF" />,
  },
  {
    title: 'Jobs',
    description: 'Search for job opportunities in your area. Find the career that suits your skills and interests.',
    icon: <FaBriefcase color="#4B0082" />,
  },
  {
    title: 'Services',
    description: 'Access various services for your needs. From home maintenance to professional services, we ve got you covered.',
    icon: <FaTools color="#8A2BE2" />,
  },
  {
    title: 'Education',
    description: 'Explore educational programs and institutions. Invest in your future with quality education.',
    icon: <FaGraduationCap color="#1E90FF" />,
  },
  // ... Other categories ...
];

export const AutoPlay = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = (category) => {
    const obj = { category, extraField: {} };
    dispatch(getExtraField(obj));
  };

  return (
    <div className="AutoPlay">
      <h2 className="headrev">Find Your Next Adventure</h2>
      {isMobile ? (
        <div className="sliderautop-container">
          <div className="sliderautop">
            {categories.map((category, index) => (
              <div className="cardfmob" key={index} onClick={() => {
                handleClick(category.title);
                navigate(`/ads-listing/category/${category.title}/`);
              }}>
                <div className="card-header">
                  <span style={{ fontSize: "30px", marginTop: "-10px" }}>{category.icon}</span> &nbsp;&nbsp;
                  <h4>{category.title}</h4>
                </div>
                <div className="card-body">
                  <p>{category.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="card-container">
          {categories.map((category, index) => (
            <div className="card" key={index} onClick={() => {
              // Open the filter for the category here
              handleClick(category.title);
              navigate(`/ads-listing/category/${category.title}/`);
            }}>
              <div className="card-header">
                <span style={{ fontSize: "30px", marginTop: "-10px" }}>{category.icon}</span> &nbsp;&nbsp;
                <h4>{category.title}</h4>
              </div>
              <div className="card-body">
                <p>{category.description}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}






export const Banner = () => {
  const images = [
     'https://www.sheepbuy.com/blog/wp-content/uploads/2020/07/job-3790033_640.jpg',
     'https://c1india.com/wp-content/uploads/2020/06/B2B_Marketplace_Should_Be_Using_Twitter.jpg',
   "https://tse2.mm.bing.net/th?id=OIP.oW0AcRy-0CtQav-EYvGEaQHaEU&pid=Api&P=0&h=180",
    "https://tse2.mm.bing.net/th?id=OIP.nP3PxUhCjHzNI0dmxG72_AHaE8&pid=Api&P=0&h=180",
    "https://tse3.mm.bing.net/th?id=OIP.cXb3e_2WHK4727Y6m5EXrQHaD4&pid=Api&P=0&h=180",
  ];

  const [activeImageIndex, setActiveImageIndex] = useState(0);

  const nextImage = () => {
    setActiveImageIndex((activeImageIndex + 1) % images.length);
  };

  const previousImage = () => {
    setActiveImageIndex((activeImageIndex - 1 + images.length) % images.length);
  };

  return (
    <div className="banner-container">
      <div className="banner-content">
        <div className="left-side">
          <h2>We interconnect</h2>
          <p>Buyers & Sellers</p>
          <p>Hola9 Where buyers and suppliers come together for successful business ventures.</p>
          <p>India's most extensive online marketplace</p>
          <div className="icon-text">
            <p>
              <FontAwesomeIcon icon={faHandshake} color="#8A2BE2" /> Trusted Platform
            </p>
            <p>
              <FontAwesomeIcon icon={faShieldAlt} color="#1E90FF" /> Safe & Secure
            </p>
            <p>
              <FontAwesomeIcon icon={faLifeRing} color="#FFD700" /> Quick Assistance
            </p>
          </div>
        </div>
        <div className="right-side">
          <div className="image-slider">
            <img src={images[activeImageIndex]} alt="Slider" height="350px" width="450px" />
            <button onClick={previousImage}>Previous</button>
            <button onClick={nextImage}>Next</button>
          </div>
        </div>
      </div>
    </div>
  );
};

