// CityMap.js
import React from 'react';
import "./CityMap.css"
import { LocationCity } from '@mui/icons-material';
import { MdLocationPin } from "react-icons/md";



const CityMap = () => {
  return (
    <div className="city-map">
      <img src="https://st2.depositphotos.com/1636602/5642/v/950/depositphotos_56420735-stock-illustration-map-of-india.jpg"
       alt="India Map" />


      <div className="city-marker mumbai"><MdLocationPin style={{color:'red'}}/> </div>
      <div className="city-marker delhi"><MdLocationPin style={{color:'red'}}/> </div>
      <div className="city-marker bangalore"><MdLocationPin style={{color:'red'}}/> </div>
      <div className="city-marker hyderabad"><MdLocationPin style={{color:'red'}}/> </div>
      <div className="city-marker chennai"><MdLocationPin style={{color:'red'}}/> </div>
      <div className="city-marker pune"><MdLocationPin style={{color:'red'}}/> </div>
      <div className="city-marker ahmedabad"><MdLocationPin style={{color:'red'}}/> </div>
      <div className="city-marker jaipur"><MdLocationPin style={{color:'red'}}/> </div>
      <div className="city-marker indore"><MdLocationPin style={{color:'red'}}/> </div>
      <div className="city-marker noida"><MdLocationPin style={{color:'red'}}/> </div>
      <div className="city-marker lucknow"><MdLocationPin style={{color:'red'}}/> </div>
      <div className="city-marker chandigarh"><MdLocationPin style={{color:'red'}}/> </div>
      <div className="city-marker vadodara"><MdLocationPin style={{color:'red'}}/> </div>
      <div className="city-marker kolkata"><MdLocationPin style={{color:'red'}}/> </div>
      {/* Add more city markers as needed */}
    </div>
  );
};

export default CityMap;
