import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { url } from "../../env";
import { Link, useNavigate } from "react-router-dom";
import { CheckCircleFilled } from "@ant-design/icons";
import { Modal } from "antd";
import { UserContext } from "../../../App";

function EditProfile() {

  const localmail=localStorage.getItem("email");
  const localname=localStorage.getItem("name");
  const [name, setName] = useState(localname);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState(localmail);
  const [companyName, setCompanyName] = useState("");
  const [aadharCard, setAadharCard] = useState(null);
  const [panCard, setPanCard] = useState(null);
  const [companyDocument, setCompanyDocument] = useState(null);
  const [companyDocumenttype, setCompanyDocumenttype] = useState("");
  const [openNewModal, setOpenNewModal] = useState(false);
  const [loaderProfile, setLoaderProfile] = useState(false);
  const [loginTemp, setLoginTemp] = useState(false);
  const user1 = useContext(UserContext);

  const handlePhoneNumberChange = (event) => {
    const regex = /^[0-9 ]*$/;
    const input = event.target.value;
    if (regex.test(input)) {
      setPhoneNumber(input);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const regex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,})$/;

    if (!regex.test(email)) {
      // Handle email validation error
      return;
    } else {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("phone_number", phoneNumber);
      formData.append("email", email);
      formData.append("company_name", companyName);
      formData.append("aadhar_card", aadharCard);
      formData.append("pan_card", panCard);
      formData.append("company_document", companyDocument);
      formData.append("company_document_type", companyDocumenttype);

      setOpenNewModal(true);

      try {
        const response = await axios.post(
          url + "api/adsapi/businessProfile",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        console.log("response1", response);

        resetForm();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const resetForm = () => {
    setName("");
    setPhoneNumber("");
    setEmail("");
    setCompanyName("");
    setAadharCard(null);
    setPanCard(null);
    setCompanyDocument(null);
    setCompanyDocumenttype("");
  };

  const navigate = useNavigate();

  

  return (
    <form
      onSubmit={handleSubmit}
      className="business-profile-form col-lg-6 mt-2 px-4 pt-2"
    >
      <div>
        <h3>For Business</h3>
      </div>

      <label>
        Name:
        <input
          type="text"
          className="col-lg-12"
          value={name}
          onChange={(event) => {
            const regex = /^[a-zA-Z ]*$/;
            const input = event.target.value;
            if (regex.test(input)) {
              setName(input);
            }
          }}
          required
          readOnly
        />
      </label>

      <label>
        Phone Number:
        <input
          type="text"
          className="col-lg-12"
          value={phoneNumber}
          maxLength="10"
          onChange={handlePhoneNumberChange}
        />
      </label>

      <label>
        Email:
        <input
          type="email"
          className="col-lg-12"
          value={email}
          onChange={(event) => {
            setEmail(event.target.value);
          }}
          required
          readOnly
        />
      </label>

      <label>
        Company Name:
        <input
          type="text"
          className="col-lg-12"
          value={companyName}
          onChange={(event) => setCompanyName(event.target.value)}
          required
        />
      </label>
      <label>
        Aadhar Card:
        <input
          type="file"
          className="col-lg-12"
          accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
          onChange={(event) => setAadharCard(event.target.files[0])}
          required
        />
      </label>
      <label>
        PAN Card:
        <input
          type="file"
          className="col-lg-12"
          accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
          onChange={(event) => setPanCard(event.target.files[0])}
          required
        />
      </label>
      <label>
        Company Document:
        <input
          type="file"
          className="col-lg-12"
          accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
          onChange={(event) => setCompanyDocument(event.target.files[0])}
          required
        />
      </label>
      <label>
        Company Type:
        <input
          type="text"
          className="col-lg-12"
          value={companyDocumenttype}
          onChange={(event) => setCompanyDocumenttype(event.target.value)}
          required
        />
      </label>
      <button type="submit" className="btnhead">
        Submit
      </button>
      {openNewModal && (
        <Modal
          title="Bussiness form submitted successfully"
          open={openNewModal}
          icon={<CheckCircleFilled />}
          fill="green"
          onOk={() => {
            navigate("/");
          }}
          onCancel={() => navigate("/businessform")}
        >
          <p className="p-0 m-0">Your Bussiness form Submitted successfully.</p>
          <p className="p-0 m-0">
            It will take some time for{" "}
            <span className="text-warning">verification</span>
          </p>
          <p className="p-0 m-0"> It will approve within 24 hours.</p>
        </Modal>
      )}
    </form>
  );
}

export default EditProfile;
