import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux/es/exports";
import { add } from "../../../store/adminLoginSlice";
import { url } from "../../env";

const AdminLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [loginform, setLoginform] = useState({ username: "", password: "" });
  const [loading, setLoading] = useState(false); // New loading state

  const handleChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    setLoginform({ ...loginform, [name]: value });
  };

  const handleSuccessfulLogin = () => {
    // Save authentication status in local storage
    localStorage.setItem("adminLoggedIn", "true");
    // Dispatch action to update Redux store
    dispatch(add("true"));
    // Redirect to the admin page
    navigate("/admin/");
  };

  const handleSubmit = () => {
    if (loginform.username && loginform.password) {
      setLoading(true); // Set loading to true while waiting for the response
      const requestOptions = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(loginform),
        redirect: "follow",
      };

      fetch(url + "api/adsapi/adminAuth", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setLoading(false); // Set loading to false once you get a response
          if (result === true) {
            handleSuccessfulLogin();
          } else {
            setError("Username or password is wrong");
          }
        })
        .catch((error) => {
          setLoading(false); // Set loading to false in case of an error
          setError(error.message);
        });
    }
  };

  return (
    <div className="row">
      <div className="col-lg-3"></div>
      <div className="col-lg-6  col-sm-12 border border-rounded mt-5 ">
        <div className="form">
          <div className="form-body p-3">
            <div className="email">
              <label className="form__label" htmlFor="username">
                Username
              </label>
              <input
                type="text"
                id="username"
                name="username"
                onChange={handleChange}
                className="form-control"
                placeholder="Username"
              />
            </div>
            <div className="password mt-2">
              <label className="form__label" htmlFor="password">
                Password
              </label>
              <input
                className="form-control"
                type="password"
                id="password"
                name="password"
                onChange={handleChange}
                placeholder="Password"
              />
            </div>
          </div>
          <div className="p-1 mx-2">
            <button
              type="submit"
              onClick={handleSubmit}
              className="btn text-light fs-16 p-2"
            >
              {loading ? "Processing..." : "Login"}
            </button>
          </div>
          {error && <div style={{ color: "red" }}>{error}</div>}
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
