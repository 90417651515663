import React from 'react'

const JobsSlider = () => {
  return (
    <div>
      jobslider
    </div>
  )
}

export default JobsSlider
