import React, { useContext, useEffect, useState } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  setDoc,
  onSnapshot,
  doc,
  updateDoc,
  serverTimestamp,
  getDoc,
  addDoc,
} from "firebase/firestore";
import Navbar from "./Navbar";
import Search from "./Search";
import Chats from "./Chats";
import { AuthContext } from "../context/AuthContext";
import { db } from "../../../../../firebase";
import { useSelector } from "react-redux";
import { ChatContext } from "../context/ChatContext";
import { useLocation } from "react-router-dom";
import { BsChatLeftText } from "react-icons/bs";

const Sidebar = ({ loading, setLoading }) => {
  const location = useLocation();
  const [chats, setChats] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const { dispatch } = useContext(ChatContext);
  const uid = useSelector((state) => state.ChatSlice);

  const handleSelect = async (user) => {
    //check whether the group(chats in firestore) exists, if not create
    setLoading(true);
    const combinedId =
      currentUser.uid > user.uid
        ? currentUser.uid + user.uid
        : user.uid + currentUser.uid;
    try {
      setLoading(true);
      const res = await getDoc(doc(db, "chats", combinedId));
      if (!res.exists()) {
        setLoading(true);
        //create a chat in chats collection
        await setDoc(doc(db, "chats", combinedId), { messages: [] });
        //create user chats

        const snapshot = await getDoc(doc(db, "userChats", currentUser.uid));
        if (snapshot.exists()) {
          await updateDoc(doc(db, "userChats", currentUser.uid), {
            [combinedId + ".userInfo"]: {
              uid: user.uid,
              displayName: user.displayName || user?.email,
              photoURL: user.photoURL,
            },
            [combinedId + ".date"]: serverTimestamp(),
          });
        } else {
          await setDoc(doc(db, "userChats", currentUser.uid), {
            [combinedId]: {
              userInfo: {
                uid: user.uid,
                displayName: user.displayName || user?.email,
                photoURL: user.photoURL,
              },
              date: serverTimestamp(),
            },
          });
        }

        const snapshot2 = await getDoc(doc(db, "userChats", user.uid));
        if (snapshot2.exists()) {
          await updateDoc(doc(db, "userChats", user.uid), {
            [combinedId + ".userInfo"]: {
              uid: currentUser.uid,
              displayName: currentUser.displayName || currentUser.email,
              photoURL: currentUser.photoURL,
            },
            [combinedId + ".date"]: serverTimestamp(),
          });
        } else {
          await setDoc(doc(db, "userChats", user.uid), {
            [combinedId]: {
              userInfo: {
                uid: currentUser.uid,
                displayName: currentUser.displayName || currentUser.email,
                photoURL: currentUser.photoURL,
              },
              date: serverTimestamp(),
            },
          });
        }
        dispatch({ type: "CHANGE_USER", payload: user });
      } else {
        dispatch({ type: "CHANGE_USER", payload: user });
      }
    } catch (err) {
      console.error(err);
    } finally {
      getChats();

      setLoading(false);
    }
  };

  const getdata = async () => {
    setLoading(true);
    const q = query(collection(db, "users"), where("uid", "==", uid.data));

    try {
      setLoading(true);
      const querySnapshot = await getDocs(q);
      // check if that particular user exists in users table or not
      if (querySnapshot.size === 0) {
        const newUser = {
          uid: uid.data,
          displayName: location?.state?.displayName,
          email: location?.state?.email,
          photoURL: "",
        };
        await addDoc(collection(db, "users"), newUser);
        handleSelect(newUser);
      } else {
        querySnapshot.forEach((doc) => {
          const user = doc.data();
          handleSelect(user);
        });
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (uid?.data) {
      getdata();
    }
  }, []);

  useEffect(() => {
    if (!showSearch) {
      setSearchResults([]);
    }
  }, [showSearch]);

  const getChats = () => {
    setLoading(true);
    const unsub = onSnapshot(doc(db, "userChats", currentUser.uid), (doc) => {
      console.log(doc.data(), "*** bb");
      setChats(doc.data());
      setLoading(false);
    });

    return () => {
      unsub();
    };
  };
  useEffect(() => {
    currentUser?.uid && getChats();
  }, [currentUser?.uid]);

  const searchForUsers = (name) => {
    const lowerCaseName = name.toLowerCase();

    const results = Object.entries(chats)
      .filter(([userId, user]) =>
        user.userInfo.displayName.toLowerCase().includes(lowerCaseName)
      )
      ?.sort((a, b) => b[1].date - a[1].date)
      .map(([userId, user]) => ({
        userId,
        user,
      }));
    setSearchResults(results);
  };

  return (
    <div className="sidebar">
    <h4 style={{backgroundColor: "#0084b8", color: "white", textAlign: "center", padding: "15px"}}>Your Chat List&nbsp;<BsChatLeftText /></h4>
      <Navbar {...{ searchForUsers, showSearch, setShowSearch, setLoading }} />
     
      <Chats
        {...{
          chats,
          loading,
          searchResults,
          showSearch,
          setShowSearch,
        }}
      />
    </div>
  );
};

export default Sidebar;
