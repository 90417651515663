import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import HomePage from './Googleanyl';
import { location, locationcurrent } from './component/env';

const MyComponent = () => {
  useEffect(() => {
    // Initialize Google Analytics
    ReactGA.initialize('G-298L83FTPW');
    // Send page view event to Google Analytics
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const [data, setData] = useState([]);

  useEffect(() => {
    // Fetch data from your API
    fetch('https://script.google.com/macros/s/'+ locationcurrent)
      .then(response => response.json())
      .then(data => setData(data.data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

return (
      <HomePage data={data} />
);};
export default MyComponent;
