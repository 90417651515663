import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getExtraField } from "../../../../store/ProductFilterSlice"; // Importing getExtraField function from ProductFilterSlice
import { addSearchData } from "../../../../store/SearchCategory/searchCategorySlice"; // Importing addSearchData function from searchCategorySlice
import {
  All,

  Pets,
} from "../../../env";
import { stateMain, locationData } from "../../../Location";
import "./SearchBox.css";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { Checkbox, Row, Col, Select, Input } from "antd";

const { Option } = Select;

const SearchBox = (props) => {
  // State variables
  const [subCategoryType, setsubCategoryType] = useState(null); // State for subcategory type
  const [subCategoryValue, setsubCategoryValue] = useState(null);
  const [rangeValues, setRangeValues] = useState({ min: 100, max: 1000000000 }); // State for subcategory value
  const [searchSending, setsearchSending] = useState({
    subCategoryType: null,
    subCategoryValue: null,
    locationDataMain: null,
    minPrice: null,
    maxPrice: null,
    searchValue: null,
    category: "Pets",
  });
  const [ExtraFieldCopy, setExtraFieldCopy] = useState({});
  const [ExtraFieldCopy1, setExtraFieldCopy1] = useState({});
  const [ExtraFieldCopy2, setExtraFieldCopy2] = useState({});
  const [ExtraFieldCopy3, setExtraFieldCopy3] = useState({});
  const [ExtraFieldCopy4, setExtraFieldCopy4] = useState({});
  const [ExtraFieldCopy5, setExtraFieldCopy5] = useState({});
  const [ExtraFieldCopy6, setExtraFieldCopy6] = useState({});
  const [selectedage, setselectedage] = useState([]);
  // const [buildup, setbuildup] = useState();
  // const [rent, setrent] = useState();
  // const [deposite, setdeposite] = useState();
  // const [carpet, setcarpet] = useState();

  // const handleDropdownChange = (value11) => {
  //   setbuildup({ "": value11 });
  // };
  // const handleDropdownChange2 = (value11) => {
  //   setrent({ "": value11 });
  // };
  // const handleDropdownChange3 = (value11) => {
  //   setdeposite({ "": value11 });
  // };
  // const handleDropdownChange1 = (value11) => {
  //   setcarpet({ "": value11 });
  // };

  const handleTypeage = (selectedValues) => {
    setselectedage(selectedValues);
  };

  const disptach = useDispatch(); // Initializing useDispatch hook

  // Event handler for input field changes
  const handleChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    setsearchSending({ ...searchSending, [name]: value });
    disptach(getExtraField(searchSending)); // Dispatching getExtraField action
  };

  return (
    <div style={{ backgroundColor: "white", paddingTop: "20px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <h6
          name="subCategoryType"
          style={{
            padding: "10px 30px",
            color: "white",

            fontWeight: "bold",
            background: "#2c4354",
          }}
        >
          Select Pets Category
        </h6>
      </div>
      <br />
      <div className="dropdown bootstrap-select show-tick houzez-field-textual form-control elementor-size-sm status-js mt-2">
        {
          <div style={{ backgroundColor: "white" }}>
            <input
              className="inpstyle"
              placeholder="Select Any Category"
              type="text"
              list="subcategoryRealEstateRent"
              style={{
                padding: "10px 20px",
                width: "100%",
                zIndex: "-50px",
                marginBottom: "10px",
              }}
              name="subCategoryValue"
              onChange={handleChange}
            />
            <datalist id="subcategoryRealEstateRent">
              {!searchSending.subCategoryType ||
              searchSending.subCategoryType !== "Accessories"
                ? Pets.map((result) => {
                    return (
                      <option value={result} key={result}>
                        {result}
                      </option>
                    );
                  })
                : null}
            </datalist>
          </div>
        }
      </div>
      <br />

      {searchSending.subCategoryValue === "Pet Food" ||
      searchSending.subCategoryValue === "Pet Shop" ||
      searchSending.subCategoryValue === "Pet Clinics" ||
      searchSending.subCategoryValue ===   "Pet Training & Grooming​​" ||
      searchSending.subCategoryValue ===   "Pet Care - Accessories​"
      
      ? (
        <>
          <div className="topcheck ml-2">
            <div>
              <b style={{ color: "#0a1e40" }}>Pet Type</b>
              <Checkbox.Group style={{ marginTop: "10px" }}>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Checkbox
                      value="Bird"
                      onClick={() => {
                        setExtraFieldCopy({ "Pet Type": "Bird " });
                      }}
                    >
                      Bird
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Cat"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Pet Type": "Cat",
                        });
                      }}
                    >
                      Cat
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Dog"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Pet Type": "Dog",
                        });
                      }}
                    >
                      Dog
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Fish"
                      onClick={() => {
                        setExtraFieldCopy({ "Pet Type": "Fish" });
                      }}
                    >
                      Fish
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Other Pets"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Pet Type": "Other Pets",
                        });
                      }}
                    >
                      Other Pets
                    </Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </div>
          </div>
        </>
      ) : null}

      <div
        style={{
          display: "flex",
          marginTop: "15px",
          justifyContent: "space-around",
        }}
      >
        <input
          type="number"
          style={{ borderRadius: "10px" }}
          value={searchSending.minPrice}
          name="minPrice"
          id="form-field-field-min-area"
          className="elementor-field form-control elementor-size-sm elementor-field-textual"
          placeholder="Min Price"
          onChange={handleChange}
        />
        <input
          style={{ borderRadius: "10px" }}
          type="number"
          onChange={handleChange}
          value={searchSending.maxPrice}
          name="maxPrice"
          id="form-field-field-max-area"
          className="elementor-field form-control elementor-size-sm elementor-field-textual"
          placeholder="Max Price"
        />
      </div>
      <br />
      <input
        className="inpstyle"
        placeholder="location"
        type="text"
        list="state"
        style={{
          padding: "12px",
          width: "100%",
          zIndex: "-50px",
        }}
        name="City"
        onChange={handleChange}
      />
      <datalist id="state">
        {locationData.map((result) => {
          return <option value={result}>{result}</option>;
        })}
      </datalist>
      <br/>
      <div
        className="col-lg-3 col-sm-12"
        style={{ float: "right", marginLeft: "-20px" }}
      >
        <Link
          to={`/ads-listing/`}
          onClick={() => {
            console.log(searchSending);
            for (const key in searchSending) {
              if (
                searchSending[key]?.trim() === "" ||
                searchSending[key] === null
              ) {
                delete searchSending[key];
              }
            }
            searchSending["extraField"] = {
              ...ExtraFieldCopy,
              ...ExtraFieldCopy1,
              ...ExtraFieldCopy2,
              ...ExtraFieldCopy3,
              ...ExtraFieldCopy4,
              ...ExtraFieldCopy5,
              ...ExtraFieldCopy6,
         
            }; // Object Assign to searchSending object

            disptach(getExtraField(searchSending)); // Dispatching getExtraField action
          }}
        >
          <Button varient="outlined" className="">
            Search
          </Button>
        </Link>
      </div>
      <br />
    </div>
  );
};

export default SearchBox;
