// import React, { useEffect, useState } from "react";
// import "./MobileSlider.css";
// import { Select } from "antd";
// import { Citys, cities, location1 } from "../../component/Location";
// import { Mobiles } from "../../component/env";
// import { isMobile } from "react-device-detect";
// import { useDispatch } from "react-redux";
// import { Link } from "react-router-dom";
// import { getExtraField } from "../../store/ProductFilterSlice";
// const images = [
//   "https://images.unsplash.com/photo-1616353071588-708dcff912e2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8YXBwbGUlMjBpcGhvbmV8ZW58MHx8MHx8fDA%3D&w=1000&q=80",
//   "https://images.unsplash.com/photo-1606041011872-596597976b25?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8YXBwbGUlMjBpcGhvbmV8ZW58MHx8MHx8fDA%3D&w=1000&q=80",
//   "https://www.techadvisor.com/wp-content/uploads/2023/04/Best-OnePlus-phone.jpg?quality=50&strip=all",
//   "https://www.androidauthority.com/wp-content/uploads/2022/12/OnePlus-11-5G-2.jpg",
//   "https://images.unsplash.com/photo-1610945265064-0e34e5519bbf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8c2Ftc3VuZyUyMHBob25lfGVufDB8fDB8fHww&w=1000&q=80",
//   "https://images.unsplash.com/photo-1610945265064-0e34e5519bbf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8c2Ftc3VuZyUyMHBob25lfGVufDB8fDB8fHww&w=1000&q=80",
//   "https://i.ytimg.com/vi/fQjVDYb5NXQ/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLDdkMxhOLx1DczZN5JcdyxAtY7jow",
//   "https://os-wordpress-media.s3.ap-south-1.amazonaws.com/blog/wp-content/uploads/2022/04/13095245/Oppo-F21-Pro-OnSiteGo.jpeg",
//   "https://www.91-cdn.com/hub/wp-content/uploads/2023/02/Upcoming-Vivo-phones-in-2023.png",
//   "https://www.91-cdn.com/hub/wp-content/uploads/2022/09/vivo-x90-specs-tipped.png.jpeg",
//   "https://w0.peakpx.com/wallpaper/110/397/HD-wallpaper-xiaomi-android-mobile-phone-2019.jpg",
//   "https://www.smartprix.com/bytes/wp-content/uploads/2022/11/Full_Xiaomi_12_series-photoutils.com_.jpeg",
//   "https://www.techadvisor.com/wp-content/uploads/2022/06/best_realme_phone.jpg?quality=50&strip=all",
//   "https://www.noypigeeks.com/wp-content/uploads/2022/04/Realme-Price-List-Philippines-NoypiGeeks.jpg",
//   "https://gagadget.com/media/post_big/xperia-pro-i__cameralineup_16.9-scaled_large_large.jpg",
//   "https://thepakistanaffairs.com/wp-content/uploads/2022/07/Sony-Xperia-IV.jpg",
//   "https://nokiamob.net/wp-content/uploads/2020/07/Nokia-8.3.jpg",
//   "https://i0.wp.com/nokiamob.net/wp-content/uploads/2021/11/DSC03272-3.jpg?resize=640%2C359&ssl=1",
// ];

// const MobileSlider = () => {
//   const [activeIndex, setActiveIndex] = useState(0);
//   const [visible1, setVisible1] = useState(false);
//   const handleDropdownVisibleChange = (open) => {
//     setVisible1(open);
//   };
//   const [visible2, setVisible2] = useState(false);
//   const handleDropdownVisibleChange2 = (open) => {
//     setVisible2(open);
//   };
//   useEffect(() => {
//     const interval = setInterval(() => {
//       setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
//     }, 2000);
//     return () => clearInterval(interval);
//   }, [images.length]);

//   const mobilestyle = {
//     height: "200px",
//     width: "100%",
//   };
//   const desktopstyle = {
//     height: "340px",
//     width: "90%",
//     marginLeft: "1.5cm",
//     marginRight: "2.5cm",
//     borderRadius: "20px",
//   };
//   const mobstyle = {
//     display: "flex",
//     flexDirection: "column",
//   };
//   const deskstyle = {
//     display: "flex",
//     flexDirection: "row",
//   };

//   const [selectedFilter, setSelectedFilter] = useState(null);

//   const [loading, setLoading] = useState(false); // Setting up a state variable 'loading' and its setter function 'setLoading' with initial value 'false'
//   const disptach = useDispatch();
//   const dispatch = useDispatch();
//   // Accessing the Redux dispatch function
//   const [errorMessage, setErrorMessage] = useState(null);
//   const [extraFieldCopy, setExtraFieldCopy] = useState({});
//   const [city, setCity] = useState();
//   const [category, setCategory] = useState();
//   const [cityError, setCityError] = useState("");
//   const [categoryError, setCategoryError] = useState("");
//   const [searchSending, setsearchSending] = useState({
//     subCategoryType: null,
//     subCategoryValue: null,
//     City: null,
//     searchValue: null,
//     category: "Mobiles",
//     minPrice: null,
//     maxPrice: null,
//   }); // State for search form data

//   //   const disptach = useDispatch(); // Initializing useDispatch hook

//   // Event handler for input field changes
//   const handleChange = (e) => {
//     var name = e.target.name;
//     var value = e.target.value;
//     setExtraFieldCopy({ [name]: value });
//     // setsearchSending({ ...searchSending, [name]: value });
//     // disptach(getExtraField(searchSending)); // Dispatching getExtraField action
//   };

//   useEffect(() => {
//     // Update searchSending state with the selected filter value
//     if (selectedFilter !== null) {
//       setsearchSending((prevState) => ({
//         ...prevState,
//         category: selectedFilter,
//       }));
//     }
//   }, [selectedFilter]);
//   return (
//     <>
//       <div
//         className="servicesslide mt-4 mb-3"
//         style={isMobile ? mobstyle : deskstyle}
//       >
//         <div className="search-form">  
//           <h1
//             style={{
//               fontSize: "20px",
//               fontFamily: "times",
//               borderBottom: "1px double white",
//               borderTop: "1px double white",
//               fontWeight: "bold",
//               color: "brown",
//             }}
//           >
//             SEARCH FOR MOBILES
//           </h1>
//           <div className="form-group mt-3">
//             <h6
//               style={{
//                 fontFamily: "verdana",
//                 color: "gray",
//                 fontWeight: "bold",
//                 fontSize: "14px",
//               }}
//             >
//               Select City :
//             </h6>
//             <Select
//               open={visible1}
//               onDropdownVisibleChange={handleDropdownVisibleChange}
//               getPopupContainer={(triggerNode) => triggerNode.parentNode}
//               style={{
//                 width: "15rem",
//                 padding: "5px 0px",
//               }}
//               placeholder="Select City"
//               onChange={(value) => setCity(value)}
//               options={Citys.map((value) => ({
//                 value: value,
//                 label: value,
//               }))}
//               showSearch={true}
//               filterOption={(inputValue, option) =>
//                 option.label.toLowerCase().includes(inputValue.toLowerCase())
//               }
//             />
//             {cityError && <p className="error-message">{cityError}</p>}
//           </div>
//           <div className="form-group">
//             <h6
//               style={{
//                 fontFamily: "verdana",
//                 color: "gray",
//                 fontWeight: "bold",
//                 fontSize: "14px",
//               }}
//             >
//               Select Category :
//             </h6>
//             <Select
//               open={visible2}
//               onDropdownVisibleChange={handleDropdownVisibleChange2}
//               getPopupContainer={(triggerNode) => triggerNode.parentNode}
//               style={{ width: "15rem" }}
//               placeholder="select category"
//               value={category || null}
//               onChange={(value) => setCategory(value)}
//               options={Mobiles.map((value) => ({
//                 value: value,
//                 label: value,
//               }))}
//             />
//             {categoryError && <p className="error-message">{categoryError}</p>}
//           </div>
//           <Link
//             to={`/ads-listing/`}
//             onClick={(e) => {
//               if (!city || !category) {
//                 e.preventDefault(); // Prevent the default navigation behavior
//                 console.log("Please select both City and Category.");
//                 setErrorMessage("Please select both City and Category."); // Set an error message
//               } else {
//                 setErrorMessage(null); // Clear any previous error message

//                 for (const key in searchSending) {
//                   if (
//                     searchSending[key]?.trim() === "" ||
//                     searchSending[key] === null
//                   ) {
//                     delete searchSending[key];
//                   }
//                 }
//                 searchSending["extraField"] = { ...extraFieldCopy };
//                 searchSending["subCategoryValue"] = category;
//                 searchSending["City"] = city;
//                 disptach(getExtraField(searchSending));
//               }
//             }}
//           >
//             <button className="buttonno1">Search</button>
//           </Link>
//           {errorMessage && <p className="error-message">{errorMessage}</p>}
//         </div>

//         <div className="carousel-inner1">
//           {images.map((image, index) => (
//             <div
//               key={index}
//               className={`carousel-item${
//                 index === activeIndex ? " active" : ""
//               }`}
//             >
//               <img
//                 src={image}
//                 alt={`Slide ${index}`}
//                 style={isMobile ? mobilestyle : desktopstyle}
//               />
//             </div>
//           ))}
//         </div>
//       </div>
//     </>
//   );
// };

// export default MobileSlider;


import React, { useEffect, useState } from "react";
import "./MobileSlider.css";
import { Select } from "antd";
import { Citys, cities, location1 } from "../../component/Location";
import { Mobiles } from "../../component/env";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getExtraField } from "../../store/ProductFilterSlice";
const images = [
  "https://images.unsplash.com/photo-1616353071588-708dcff912e2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8YXBwbGUlMjBpcGhvbmV8ZW58MHx8MHx8fDA%3D&w=1000&q=80",
  "https://images.unsplash.com/photo-1606041011872-596597976b25?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8YXBwbGUlMjBpcGhvbmV8ZW58MHx8MHx8fDA%3D&w=1000&q=80",
  "https://www.techadvisor.com/wp-content/uploads/2023/04/Best-OnePlus-phone.jpg?quality=50&strip=all",
  "https://www.androidauthority.com/wp-content/uploads/2022/12/OnePlus-11-5G-2.jpg",
  "https://images.unsplash.com/photo-1610945265064-0e34e5519bbf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8c2Ftc3VuZyUyMHBob25lfGVufDB8fDB8fHww&w=1000&q=80",
  "https://images.unsplash.com/photo-1610945265064-0e34e5519bbf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8c2Ftc3VuZyUyMHBob25lfGVufDB8fDB8fHww&w=1000&q=80",
  "https://i.ytimg.com/vi/fQjVDYb5NXQ/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLDdkMxhOLx1DczZN5JcdyxAtY7jow",
  "https://os-wordpress-media.s3.ap-south-1.amazonaws.com/blog/wp-content/uploads/2022/04/13095245/Oppo-F21-Pro-OnSiteGo.jpeg",
  "https://www.91-cdn.com/hub/wp-content/uploads/2023/02/Upcoming-Vivo-phones-in-2023.png",
  "https://www.91-cdn.com/hub/wp-content/uploads/2022/09/vivo-x90-specs-tipped.png.jpeg",
  "https://w0.peakpx.com/wallpaper/110/397/HD-wallpaper-xiaomi-android-mobile-phone-2019.jpg",
  "https://www.smartprix.com/bytes/wp-content/uploads/2022/11/Full_Xiaomi_12_series-photoutils.com_.jpeg",
  "https://www.techadvisor.com/wp-content/uploads/2022/06/best_realme_phone.jpg?quality=50&strip=all",
  "https://www.noypigeeks.com/wp-content/uploads/2022/04/Realme-Price-List-Philippines-NoypiGeeks.jpg",
  "https://gagadget.com/media/post_big/xperia-pro-i__cameralineup_16.9-scaled_large_large.jpg",
  "https://thepakistanaffairs.com/wp-content/uploads/2022/07/Sony-Xperia-IV.jpg",
  "https://nokiamob.net/wp-content/uploads/2020/07/Nokia-8.3.jpg",
  "https://i0.wp.com/nokiamob.net/wp-content/uploads/2021/11/DSC03272-3.jpg?resize=640%2C359&ssl=1",
];

const MobileSlider = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [visible1, setVisible1] = useState(false);
  const handleDropdownVisibleChange = (open) => {
    setVisible1(open);
  };
  const [visible2, setVisible2] = useState(false);
  const handleDropdownVisibleChange2 = (open) => {
    setVisible2(open);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 2000);
    return () => clearInterval(interval);
  }, [images.length]);

  const mobilestyle = {
    height: "200px",
    width: "100%",
  };
  const desktopstyle = {
    height: "340px",
    width: "90%",
    marginLeft: "1.5cm",
    marginRight: "2.5cm",
    borderRadius: "20px",
  };
  const mobstyle = {
    display: "flex",
    flexDirection: "column",
  };
  const deskstyle = {
    display: "flex",
    flexDirection: "row",
  };

  const [selectedFilter, setSelectedFilter] = useState(null);

  const [loading, setLoading] = useState(false); // Setting up a state variable 'loading' and its setter function 'setLoading' with initial value 'false'
  const disptach = useDispatch();
  const dispatch = useDispatch();
  // Accessing the Redux dispatch function
  const [errorMessage, setErrorMessage] = useState(null);
  const [extraFieldCopy, setExtraFieldCopy] = useState({});
  const [city, setCity] = useState();
  const [category, setCategory] = useState();
  const [cityError, setCityError] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [searchSending, setsearchSending] = useState({
    subCategoryType: null,
    subCategoryValue: null,
    City: null,
    searchValue: null,
    category: "Mobiles",
    minPrice: null,
    maxPrice: null,
  }); // State for search form data

  //   const disptach = useDispatch(); // Initializing useDispatch hook

  // Event handler for input field changes
  const handleChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    setExtraFieldCopy({ [name]: value });
    // setsearchSending({ ...searchSending, [name]: value });
    // disptach(getExtraField(searchSending)); // Dispatching getExtraField action
  };

  useEffect(() => {
    // Update searchSending state with the selected filter value
    if (selectedFilter !== null) {
      setsearchSending((prevState) => ({
        ...prevState,
        category: selectedFilter,
      }));
    }
  }, [selectedFilter]);
  return (
    <>
      <div
        className="servicesslide servicesslide-awi-24 mt-4 mb-3"
        // style={isMobile ? mobstyle : deskstyle}
      >
        <div className="search-form-awi-24">
          <h1
            style={{
              fontSize: "20px",
              fontFamily: "times",
              borderBottom: "1px double white",
              borderTop: "1px double white",
              fontWeight: "bold",
              color: "brown",
            }}
          >
            SEARCH FOR MOBILES
          </h1>
          <div className="form-group mt-3">
            <h6
              style={{
                fontFamily: "verdana",
                color: "gray",
                fontWeight: "bold",
                fontSize: "14px",
              }}
            >
              Select City :
            </h6>
            <Select
              open={visible1}
              onDropdownVisibleChange={handleDropdownVisibleChange}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              style={{
                width: "15rem",
                padding: "5px 0px",
              }}
              placeholder="Select City"
              onChange={(value) => setCity(value)}
              options={Citys.map((value) => ({
                value: value,
                label: value,
              }))}
              showSearch={true}
              filterOption={(inputValue, option) =>
                option.label.toLowerCase().includes(inputValue.toLowerCase())
              }
            />
            {cityError && <p className="error-message">{cityError}</p>}
          </div>
          <div className="form-group">
            <h6
              style={{
                fontFamily: "verdana",
                color: "gray",
                fontWeight: "bold",
                fontSize: "14px",
              }}
            >
              Select Category :
            </h6>
            <Select
              open={visible2}
              onDropdownVisibleChange={handleDropdownVisibleChange2}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              style={{ width: "15rem" }}
              placeholder="select category"
              value={category || null}
              onChange={(value) => setCategory(value)}
              options={Mobiles.map((value) => ({
                value: value,
                label: value,
              }))}
            />
            {categoryError && <p className="error-message">{categoryError}</p>}
          </div>
          <Link
            to={`/ads-listing/`}
            onClick={(e) => {
              if (!city || !category) {
                e.preventDefault(); // Prevent the default navigation behavior
                console.log("Please select both City and Category.");
                setErrorMessage("Please select both City and Category."); // Set an error message
              } else {
                setErrorMessage(null); // Clear any previous error message

                for (const key in searchSending) {
                  if (
                    searchSending[key]?.trim() === "" ||
                    searchSending[key] === null
                  ) {
                    delete searchSending[key];
                  }
                }
                searchSending["extraField"] = { ...extraFieldCopy };
                searchSending["subCategoryValue"] = category;
                searchSending["City"] = city;
                disptach(getExtraField(searchSending));
              }
            }}
          >
            <button className="buttonno1">Search</button>
          </Link>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </div>

        <div className="carousel-inner24-awi-24">
          {images.map((image, index) => (
            <div
              key={index}
              className={`carousel-item${
                index === activeIndex ? " active" : ""
              }`}
            >
              <img
                src={image}
                alt={`Slide ${index}`}
                style={isMobile ? mobilestyle : desktopstyle}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default MobileSlider;
