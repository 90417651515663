


// import React, { useEffect, useState } from 'react';
// // import './JobsMainfilter.css';
// import "./PetsMainfilter.css"


// import { CityMain, Petstypes } from '../../../env';
// import { useDispatch } from 'react-redux';
// import { add } from '../../../../store/wishlistSlice';
// import { Link } from 'react-router-dom';
// import { getExtraField } from '../../../../store/ProductFilterSlice';

// function PetsMainfilter() {
//   const [jobType, setJobType] = useState('');
//   const [selectedFilters, setSelectedFilters] = useState([]);
//   const [selectedFilters1, setSelectedFilters1] = useState([]);
//   const [selectedFilters2, setSelectedFilters2] = useState([]);
//   const [selectedFilters3, setSelectedFilters3] = useState([]);





//   const [PetsSubFilter, setPetsSubFilter] = useState({});
//   const [PetsSubFilter1, setPetsSubFilter1] = useState({});
//   const [PetsSubFilter2, setPetsSubFilter2] = useState({});
//   const [PetsSubFilter3, setPetsSubFilter3] = useState({});
 


//   const [isDropdownVisible, setIsDropdownVisible] = useState({
//     PetType: false,
//     minPrice: false,
//     maxPrice: false,
//     CityMain: false,
  
//   });
//   const [selectedFilter, setSelectedFilter] = useState(null);

//   const [loading, setLoading] = useState(false);
//   const dispatch = useDispatch();

//   const [extraFieldCopy, setExtraFieldCopy] = useState({});
//   const [extraFieldCopy1, setExtraFieldCopy1] = useState({});

//   const [searchSending, setSearchSending] = useState({
//     subCategoryType: null,
//     subCategoryValue: null,
//     locationDataMain: null,
//     searchValue: null,
//     category: 'Jobs',
//     minPrice: null,
//     maxPrice: null,
//   });

//   const handleChange = (e) => {
//     const name = e.target.name;
//     const value = e.target.value;
//     setExtraFieldCopy({ ...extraFieldCopy, [name]: value });
//   };

//   useEffect(() => {
//     if (selectedFilter !== null) {
//       setSearchSending((prevState) => ({
//         ...prevState,
//         category: selectedFilter,
//       }));
//     }
//   }, [selectedFilter]);

//   const handleJobTypeChange = (event) => {
//     const value = event.target.value;
//     setJobType(value);
//     setSelectedFilters([]);
//     setPetsSubFilter({ PetType: value(null) });
//     setPetsSubFilter1({ minPrice: value(null) });
//     setPetsSubFilter2({ maxPrice: value(null) });
//     setPetsSubFilter3({ CityMain: value(null) });



//     setIsDropdownVisible((prevState) => ({
//       ...prevState,
//       PetType: false,
//       minPrice: false,
//       maxPrice: false,
//       CityMain: false,
    
//     }));
//   };

//   const toggleDropdown = (dropdownName) => {
//     setIsDropdownVisible((prevState) => ({
//       ...prevState,
//       [dropdownName]: !prevState[dropdownName],
//     }));
//   };

//   const handleSubFilterChange = (event) => {
//     const value = event.target.value;
//     setSelectedFilters((prevFilters) => {
//       setPetsSubFilter({ ...PetsSubFilter, PetType: value });
     
//     });
//   };

//   const handleSubFilterChange1 = (event) => {
//     const value = event.target.value;
//     setSelectedFilters1((prevFilters) => {
//       setPetsSubFilter1({ ...PetsSubFilter1, minPrice: value });
//       if (prevFilters.includes(value)) {
//         return prevFilters.filter((filter) => filter !== value);
//       } else {
//         return [...prevFilters, value];
//       }
//     });
//   };

//   const handleSubFilterChange2 = (event) => {
//     const value = event.target.value;
//     setSelectedFilters2((prevFilters) => {
//       setPetsSubFilter2({ ...PetsSubFilter2, maxPrice: value });
//       if (prevFilters.includes(value)) {
//         return prevFilters.filter((filter) => filter !== value);
//       } else {
//         return [...prevFilters, value];
//       }
//     });
//   };

  
//   const handleSubFilterChange3 = (event) => {
//     const value = event.target.value;
//     setSelectedFilters3((prevFilters) => {
//       setPetsSubFilter3({ ...PetsSubFilter3, CityMain: value });
//       if (prevFilters.includes(value)) {
//         return prevFilters.filter((filter) => filter !== value);
//       } else {
//         return [...prevFilters, value];
//       }
//     });
//   };



//   const handleFindButtonClick = () => {
//     console.log('Selected Filters:', selectedFilters);
//     console.log('Pets Sub-Filter:', PetsSubFilter);
//   };

//   return (
//     <div className="main-filter2">
//       <h1>PETS FILTER</h1>
//       <div className="sub-filter2">
//         <label>Select Job Type:</label>
//         <select onChange={handleJobTypeChange}>
//           <option value="">Select...</option>
//           <option value="Pet Food">Pet Food</option>
//           <option value="Pet Shop">Pet Shop</option>
//           <option value="Pet Clinics">Pet Clinics</option>
//           <option value="Pet Training">Pet Training & Grooming</option>
//           <option value="Pet Care">Pet Care - Accessorie</option>

//         </select>
//       </div>

//       {(jobType === 'Pet Food' || jobType === 'Pet Shop' || 
//       jobType === 'Pet Clinics' || jobType === 'Pet Training' || jobType === 'Pet Care' ) && (
//         <div className="sub-filter">
        
//         <p>
//             <button onClick={() => toggleDropdown('PetType')}>Pet Type</button>
//             {isDropdownVisible.PetType && (
//               <div className="dropdown2">
//                 <select onChange={handleSubFilterChange} value={selectedFilters}>
//                   {Petstypes.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>
//           <p>
//             <button onClick={() => toggleDropdown('minPrice')}>Min Price</button>
//             {isDropdownVisible.minPrice && (
//               <div className="dropdown2">
//                 <select onChange={handleSubFilterChange1} value={selectedFilters1}>
//                   {Petstypes.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

//           <p>
//             <button onClick={() => toggleDropdown('maxPrice')}>Max price</button>
//             {isDropdownVisible.maxPrice && (
//               <div className="dropdown2">
//                 <select onChange={handleSubFilterChange2} value={selectedFilters2}>
//                   {Petstypes.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>

        
//           <p>
//             <button onClick={() => toggleDropdown('CityMain')}>City</button>
//             {isDropdownVisible.CityMain && (
//               <div className="dropdown2">
//                 <select onChange={handleSubFilterChange3} value={selectedFilters3}>
//                   {CityMain.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>
//         </div>
//       )}




//       <Link
//         to="/ads-listing/"
//         onClick={() => {
//           console.log(searchSending);
//           for (const key in searchSending) {
//             if (searchSending[key]?.trim() === '' || searchSending[key] === null) {
//               delete searchSending[key];
//             }
//           }
//           searchSending['subCategoryValue'] = jobType;
//           searchSending['extraField'] = {
//             ...PetsSubFilter,
//             ...PetsSubFilter1,
//             ...PetsSubFilter2,
//             ...PetsSubFilter3,
           

//           };
//           dispatch(getExtraField(searchSending));
//         }}
//       >
//         <button
//           className="btn houzez-search-button elementor-button elementor-size-sm"
//           style={{
//             background: '#a71616bf',
//             color: 'white',
//           }}
//           id="serbtn2"
//         >
//           Search
//           </button>
//         </Link>
//     </div>
//   );
// }

// export default PetsMainfilter;




























// import React, { useEffect, useState } from 'react';
// import "./PetsMainfilter.css"; // Import your custom CSS file

// import { CityMain, Petstypes } from '../../../env';
// import { useDispatch } from 'react-redux';
// import { add } from '../../../../store/wishlistSlice';
// import { Link } from 'react-router-dom';
// import { getExtraField } from '../../../../store/ProductFilterSlice';

// function PetsMainfilter() {
//   const [PetType, setPetType] = useState('');
//   const [selectedFilters, setSelectedFilters] = useState([]);
//   const [selectedFilters1, setSelectedFilters1] = useState([]);
//   const [selectedFilters2, setSelectedFilters2] = useState([]);
//   const [selectedFilters3, setSelectedFilters3] = useState([]);

//   const [PetsSubFilter, setPetsSubFilter] = useState({});
//   const [PetsSubFilter1, setPetsSubFilter1] = useState({});
//   const [PetsSubFilter2, setPetsSubFilter2] = useState({});
//   const [PetsSubFilter3, setPetsSubFilter3] = useState({});

//   const [isDropdownVisible, setIsDropdownVisible] = useState({
//     PetType: false,
//     minPrice: false,
//     maxPrice: false,
//     CityMain: false,
//   });

//   const [selectedFilter, setSelectedFilter] = useState(null);

//   const [loading, setLoading] = useState(false);
//   const dispatch = useDispatch();

//   const [extraFieldCopy, setExtraFieldCopy] = useState({});
//   const [extraFieldCopy1, setExtraFieldCopy1] = useState({});

//   const [searchSending, setSearchSending] = useState({
//     subCategoryType: null,
//     subCategoryValue: null,
//     locationDataMain: null,
//     searchValue: null,
//     category: 'Pets',
//     minPrice: '', // Changed to string
//     maxPrice: '', // Changed to string
//   });

//   const handleChange = (e) => {
//     const name = e.target.name;
//     const value = e.target.value;
//     setExtraFieldCopy({ ...extraFieldCopy, [name]: value });
//   };

//   useEffect(() => {
//     if (selectedFilter !== null) {
//       setSearchSending((prevState) => ({
//         ...prevState,
//         category: selectedFilter,
//       }));
//     }
//   }, [selectedFilter]);

//   const handlPetTypeChange = (event) => {
//     const value = event.target.value;
//     setPetType(value);
//     setSelectedFilters([]);
//     setPetsSubFilter({ PetType: value });
//     setPetsSubFilter1({ minPrice: '' }); // Reset to empty string
//     setPetsSubFilter2({ maxPrice: '' }); // Reset to empty string
//     setPetsSubFilter3({ CityMain: value });

//     setIsDropdownVisible((prevState) => ({
//       ...prevState,
//       PetType: false,
//       minPrice: false,
//       maxPrice: false,
//       CityMain: false,
//     }));
//   };

//   const toggleDropdown = (dropdownName) => {
//     setIsDropdownVisible((prevState) => ({
//       ...prevState,
//       [dropdownName]: !prevState[dropdownName],
//     }));
//   };

//   const handleSubFilterChange = (event) => {
//     const value = event.target.value;
//     setSelectedFilters((prevFilters) => {
//       setPetsSubFilter({ ...PetsSubFilter, PetType: value });
//     });
//   };

//   const handleSubFilterChange1 = (event) => {
//     const value = event.target.value;
//     setSelectedFilters1((prevFilters) => {
//       setPetsSubFilter1({ ...PetsSubFilter1, minPrice: value });
//       if (prevFilters.includes(value)) {
//         return prevFilters.filter((filter) => filter !== value);
//       } else {
//         return [...prevFilters, value];
//       }
//     });
//   };

//   const handleSubFilterChange2 = (event) => {
//     const value = event.target.value;
//     setSelectedFilters2((prevFilters) => {
//       setPetsSubFilter2({ ...PetsSubFilter2, maxPrice: value });
//       if (prevFilters.includes(value)) {
//         return prevFilters.filter((filter) => filter !== value);
//       } else {
//         return [...prevFilters, value];
//       }
//     });
//   };

//   const handleSubFilterChange3 = (event) => {
//     const value = event.target.value;
//     setSelectedFilters3((prevFilters) => {
//       setPetsSubFilter3({ ...PetsSubFilter3, CityMain: value });
//       if (prevFilters.includes(value)) {
//         return prevFilters.filter((filter) => filter !== value);
//       } else {
//         return [...prevFilters, value];
//       }
//     });
//   };

//   const handleFindButtonClick = () => {
//     console.log('Selected Filters:', selectedFilters);
//     console.log('Pets Sub-Filter:', PetsSubFilter);
//   };

//   return (
//     <div className="custom-main-filter">
//       <h1>PETS FILTER</h1>
//       <div className="custom-sub-filter">
//         <label>Select Pet Type:</label>
//         <select onChange={handlPetTypeChange}>
//           <option value="">Select...</option>
//           <option value="Pet Food">Pet Food</option>
//           <option value="Pet Shop">Pet Shop</option>
//           <option value="Pet Clinics">Pet Clinics</option>
//           <option value="Pet Training">Pet Training & Grooming</option>
//           <option value="Pet Care">Pet Care - Accessories</option>
//         </select>
//       </div>

//       {(PetType === 'Pet Food' || PetType === 'Pet Shop' || 
//       PetType === 'Pet Clinics' || PetType === 'Pet Training' || PetType === 'Pet Care' ) && (
//         <div className="custom-sub-filter">
//           <p>
//             <button onClick={() => toggleDropdown('PetType')}>Pet Type</button>
//             {isDropdownVisible.PetType && (
//               <div className="custom-dropdown">
//                 <select onChange={handleSubFilterChange} value={selectedFilters}>
//                   {Petstypes.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>
//           <p>
//             <label htmlFor="minPrice">Min Price</label>
//             <input
//               type="text"
//               id="minPrice"
//               name="minPrice"
//               value={searchSending.minPrice}
//               onChange={(e) => setSearchSending({ ...searchSending, minPrice: e.target.value })}
//               placeholder="Enter Min Price"
//             />
//           </p>

//           <p>
//             <label htmlFor="maxPrice">Max Price</label>
//             <input
//               type="text"
//               id="maxPrice"
//               name="maxPrice"
//               value={searchSending.maxPrice}
//               onChange={(e) => setSearchSending({ ...searchSending, maxPrice: e.target.value })}
//               placeholder="Enter Max Price"
//             />
//           </p>

//           <p>
//             <button onClick={() => toggleDropdown('CityMain')}>City</button>
//             {isDropdownVisible.CityMain && (
//               <div className="custom-dropdown">
//                 <select onChange={handleSubFilterChange3} value={selectedFilters3}>
//                   {CityMain.map((option, index) => (
//                     <option key={index} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </p>
//         </div>
//       )}

//       <Link
//         to="/ads-listing/"
//         onClick={() => {
//           console.log(searchSending);
//           for (const key in searchSending) {
//             if (searchSending[key]?.trim() === '' || searchSending[key] === null) {
//               delete searchSending[key];
//             }
//           }
//           searchSending['subCategoryValue'] = PetType;
//           searchSending['extraField'] = {
//             ...PetsSubFilter,
//             ...PetsSubFilter1,
//             ...PetsSubFilter2,
//             ...PetsSubFilter3,
//           };
//           dispatch(getExtraField(searchSending));
//         }}
//       >
//         <button
//           className="custom-search-button"
//           style={{
//             background: '#a71616bf',
//             color: 'white',
//           }}
//         >
//           Search
//         </button>
//       </Link>
//     </div>
//   );
// }

// export default PetsMainfilter;




















import React, { useState } from 'react';
import './PetsMainfilter.css'; // Import your custom CSS file
import { CityMain, Petstypes } from '../../../env';
import { useDispatch } from 'react-redux';
import { getExtraField } from '../../../../store/ProductFilterSlice';
import { Link } from 'react-router-dom';

function PetsMainfilter() {
  const [PetType, setPetType] = useState(''); // State for the main dropdown
  const [SelectedPetType, setSelectedPetType] = useState(''); // State for the selected Pet Type
  const [PetsSubFilter, setPetsSubFilter] = useState({});
  const [isDropdownVisible, setIsDropdownVisible] = useState({
    PetType: true,
    CityMain: true,
  });
  const [searchSending, setSearchSending] = useState({
    category: 'Pets',
    subCategoryValue: null,
    extraField: {
      PetType: null,
      minPrice: null,
      maxPrice: null,
      CityMain: null,
    },
  });

  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearchSending((prevState) => ({
      ...prevState,
      extraField: {
        ...prevState.extraField,
        [name]: value,
      },
    }));
  };

  const handlePetTypeChange = (event) => {
    const value = event.target.value;
    setPetType(value); // Update the main PetType state
    setSelectedPetType(''); // Clear the selected Pet Type
    setSearchSending((prevState) => ({
      ...prevState,
      subCategoryValue: value,
    }));
  };

  const handleSelectedPetTypeChange = (event) => {
    const value = event.target.value;
    setSelectedPetType(value); // Update the selected Pet Type
    setSearchSending((prevState) => ({
      ...prevState,
      extraField: {
        ...prevState.extraField,
        PetType: value,
      },
    }));
  };

  const toggleDropdown = (dropdownName) => {
    setIsDropdownVisible((prevState) => ({
      ...prevState,
      [dropdownName]: !prevState[dropdownName],
    }));
  };

  const handleFindButtonClick = () => {
    const { category, subCategoryValue, extraField } = searchSending;
    console.log('Category:', category);
    console.log('Pet Type:', subCategoryValue);
    console.log('Selected Pet Type:', extraField.PetType);
    console.log('Min Price:', extraField.minPrice);
    console.log('Max Price:', extraField.maxPrice);
    console.log('City:', extraField.CityMain);
    dispatch(getExtraField(searchSending));
  };

  return (
    <div className="custom-main-filter">
      <h1>PETS FILTER</h1>
      <div className="custom-sub-filter">
        <label>Select Category:</label>
        <select onChange={handlePetTypeChange} value={PetType}>
          <option value="">Select...</option>
          <option value="Pet Food">Pet Food</option>
          <option value="Pet Shop">Pet Shop</option>
          <option value="Pet Clinics">Pet Clinics</option>
          <option value="Pet Training">Pet Training & Grooming</option>
          <option value="Pet Care">Pet Care - Accessories</option>
        </select>
      </div>

      {PetType && (
        <div className="custom-sub-filter">
          <p>
            <button onClick={() => toggleDropdown('PetType')}>Pet Type</button>
            {isDropdownVisible.PetType && (
              <div className="custom-dropdown">
                <select
                  onChange={handleSelectedPetTypeChange}
                  name="PetType"
                  value={SelectedPetType}
                >
                  {Petstypes.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </p>

          <p>
            <label>Min Price:</label>
            <input
              type="text"
              name="minPrice"
              onChange={handleChange}
              value={searchSending.extraField.minPrice || ''}
            />
          </p>

          <p>
            <label>Max Price:</label>
            <input
              type="text"
              name="maxPrice"
              onChange={handleChange}
              value={searchSending.extraField.maxPrice || ''}
            />
          </p>

          <p>
            <button onClick={() => toggleDropdown('CityMain')}>City</button>
            {isDropdownVisible.CityMain && (
              <div className="custom-dropdown">
                <select
                  onChange={(e) => handleChange(e)}
                  name="CityMain"
                  value={searchSending.extraField.CityMain || ''}
                >
                  <option value="">Select...</option>
                  {CityMain.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </p>
        </div>
      )}

      <Link to="/ads-listing/" onClick={handleFindButtonClick}>
        <button className="custom-search-button" style={{ background: '#a71616bf', color: 'white' }}>
          Search
        </button>
      </Link>
    </div>
  );
}

export default PetsMainfilter;














































