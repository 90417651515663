import React, { useState, useEffect } from "react";
import { url } from "../../env";
import Jobs from "./Jobs";
import { decrypt } from "../../Base/encryptDecrypt/encryptDecrypt";
import FileViewer from "react-file-viewer";
import "./applyjob.css"
import DocumentViewer from "./Docview";
import AppliedYou from "./AppliedYou";
import PostedYou from "./PostedYou";
const AppliedJobs = () => {
  const [name, setName] = useState();
  const [name1, setName1] = useState();
  const [error, setError] = useState();
  const [results, setResults] = useState([]);
  const [activeTab, setActiveTab] = useState("tab-111");
  const [loading, setLoading] = useState(true);

  let barererToken = "Bearer " + localStorage.getItem("access_token");

  useEffect(() => {
    if (localStorage.getItem("access_token") != null) {
      let result = decrypt("userdata");
      setName1(result.name);
      setName(result.email);
    }
  }, []);


  const [data, setData] = useState([]);

  const handleTabChange = (event) => {
    setActiveTab(event.target.id);
  };

  return (
    <>   
      <div>
        <div className="cardcomp2">
          <div className="wrappercard2">
            <input
              type="radio"
              name="slider"
              id="tab-111"
              defaultChecked=""
              onChange={handleTabChange}
            />
            <input
              type="radio"
              name="slider"
              id="tab-211"
              onChange={handleTabChange}
            />

            <header>
              <label
                htmlFor="tab-111"
                className={`tab-111 ${activeTab === "tab-111" ? "active-tab" : ""
                  }`}
                style={{
                  backgroundColor: activeTab === "tab-111" ? "#082144" : "",
                  color: activeTab === "tab-111" ? "white" : "",
                  borderRadius: activeTab === "tab-111" ? "20px" : "",
                }}
              >
                Applied By {name1}
              </label>

              <label
                htmlFor="tab-211"
                className={`tab-211 ${activeTab === "tab-211" ? "active-tab" : ""
                  }`}
                style={{
                  backgroundColor: activeTab === "tab-211" ? "#082144" : "",
                  color: activeTab === "tab-211" ? "white" : "",
                  borderRadius: activeTab === "tab-211" ? "20px" : "",
                }}
              >
                Job seeker
              </label>

              <div className="slider1122" />
            </header>
            <div className="card-area1">
              <div className="cardsd1">
                <div className="rowsd1 row-1">
                  {<AppliedYou />}
                </div>
                <div className="rowsd1  ">
                  {<PostedYou />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppliedJobs;
