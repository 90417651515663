import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BrowseNotifications = () => {
  const [details, setDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const userid = localStorage.getItem("userid");

  useEffect(() => {
    if(userid){
    const fetchData = () => {
      fetch(`https://databytess.com/api/adsapi/notifications?user=${userid}`)

        .then((response) => response.json())
        .then((data) => {
          setDetails(data);
          setLoading(false);
          const storedLength = localStorage.getItem("apiDataLength3");
          if (storedLength && data.length > parseInt(storedLength)) {
            const increasedLength = data.length - parseInt(storedLength);
            localStorage.setItem("apiDataLength3", data.length);
            handleClick1(increasedLength);
          } else {
            localStorage.setItem("apiDataLength3", data.length);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    };

    // Fetch data initially
    fetchData()
    // Fetch data every one minute
    const interval = setInterval(fetchData, 60000);

    // Cleanup function to clear interval
    return () => clearInterval(interval);
}
  }, [userid]);
 


  const handleClick1 = (massage) => {
    if (Notification.permission === 'granted') {
      new Notification('Hola9 Notification ', { body: massage });
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          new Notification('Notification message 1!', { body: 'This is the body of the notification.' });
        }
      });
    }
}


};

export default BrowseNotifications;



