// import React, { useState } from 'react';
// import './bikeSlider.css';

// const SlideshowGallery = () => {
//   const [slideIndex, setSlideIndex] = useState(0);

//   const plusSlides = (n) => {
//     showSlides(slideIndex + n);
//   };

//   const currentSlide = (n) => {
//     showSlides(n);
//   };

//   const showSlides = (n) => {
//     const slides = document.getElementsByClassName('mySlides');
//     const dots = document.getElementsByClassName('demo');
//     const captionText = document.getElementById('caption');

//     if (n > slides.length) {
//       n = 1;
//     }
//     if (n < 1) {
//       n = slides.length;
//     }

//     for (let i = 0; i < slides.length; i++) {
//       slides[i].style.display = 'none';
//     }
//     for (let i = 0; i < dots.length; i++) {
//       dots[i].className = dots[i].className.replace(' active', '');
//     }

//     slides[n - 1].style.display = 'block';
//     dots[n - 1].className += ' active';
//     captionText.innerHTML = dots[n - 1].alt;

//     setSlideIndex(n);
//   };

//   return (
//     <div>
//     {/*  onClick={() => plusSlides(-1)} */}
//       <div className="container12">
//         <div className="mySlides">
//           <div className="numbertext">1 / 6</div>
//           <img className='imgsli' src="https://wallpaperaccess.com/full/3460583.jpg" style={{ width: '100%',height:"10cm" }} alt="The Woodss" />
//         </div>
//         <div className="mySlides">
//           <div className="numbertext">2 / 6</div>
//           <img className='imgsli' src="https://i.pinimg.com/originals/39/12/5d/39125d1baa1a730e8612928be3eae97f.jpg" style={{ width: '100%',height:"10cm" }} alt="The Woodss" />
//         </div>
//         <div className="mySlides">
//           <div className="numbertext">3 / 6</div>
//           <img className='imgsli' src="https://wallpapercave.com/wp/wp2007621.jpg" style={{ width: '100%',height:"10cm" }} alt="The Woodss" />
//         </div>
//         <div className="mySlides">
//           <div className="numbertext">4 / 6</div>
//           <img className='imgsli' src="https://th.bing.com/th/id/OIP.MludgFQ0LOuLG-oa_4gaDgHaEK?pid=ImgDet&rs=1" style={{ width: '100%',height:"10cm" }} alt="The Woodss" />
//         </div>
//         <div className="mySlides">
//           <div className="numbertext">5 / 6</div>
//           <img className='imgsli' src="https://th.bing.com/th/id/OIP.mJPCoeOO7bYuMpwFJVlbaQHaEK?pid=ImgDet&rs=1" style={{ width: '100%',height:"10cm" }} alt="The Woodss" />
//         </div>
//         <div className="mySlides">
//           <div className="numbertext">6 / 6</div>
//           <img className='imgsli' src="https://teja8.kuikr.com/cars/images/desktop/used-bikes.jpeg" style={{ width: '100%',height:"10cm" }} alt="The Woodss" />
//         </div>

//         {/* ... Repeat the remaining image slides ... */}

//         <div className="row">
//           <div className="column">
//             <img
//               className="demo cursor"
//               src="https://wallpaperaccess.com/full/3460583.jpg"
//               style={{ width: '100%',height:"3cm" }}
//               onClick={() => currentSlide(1)}
//               alt="The Woods"
//             />
//           </div>
//           <div className="column">
//             <img
//               className="demo cursor"
//               src="https://i.pinimg.com/originals/39/12/5d/39125d1baa1a730e8612928be3eae97f.jpg"
//               style={{ width: '100%',height:"3cm" }}
//               onClick={() => currentSlide(2)}
//               alt="The Woods"
//             />
//           </div>
//           <div className="column">
//             <img
//               className="demo cursor"
//               src="https://wallpapercave.com/wp/wp2007621.jpg"
//               style={{ width: '100%',height:"3cm" }}
//               onClick={() => currentSlide(3)}
//               alt="The Woods"
//             />
//           </div>
//           <div className="column">
//             <img
//               className="demo cursor"
//               src="https://th.bing.com/th/id/OIP.MludgFQ0LOuLG-oa_4gaDgHaEK?pid=ImgDet&rs=1"
//               style={{ width: '100%',height:"3cm" }}
//               onClick={() => currentSlide(4)}
//               alt="The Woods"
//             />
//           </div>
//           <div className="column">
//             <img
//               className="demo cursor"
//               src="https://th.bing.com/th/id/OIP.mJPCoeOO7bYuMpwFJVlbaQHaEK?pid=ImgDet&rs=1"
//               style={{ width: '100%',height:"3cm" }}
//               onClick={() => currentSlide(5)}
//               alt="The Woods"
//             />
//           </div>
//           <div className="column">
//             <img
//               className="demo cursor"
//               src="https://teja8.kuikr.com/cars/images/desktop/used-bikes.jpeg"
//               style={{ width: '100%',height:"3cm" }}
//               onClick={() => currentSlide(6)}
//               alt="The Woods"
//             />
//           </div>
//           {/* ... Repeat the remaining thumbnail images ... */}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SlideshowGallery;


import React,  { useState } from 'react';
import './bikeSlider.css';



const SlideshowGallery = () => {
  const [slideIndex, setSlideIndex] = useState(0);

  const plusSlides = (n) => {
    showSlides(slideIndex + n);
  };

  const currentSlide = (n) => {
    showSlides(n);
  };

  const showSlides = (n) => {
    const slides = document.getElementsByClassName('mySlides');
    const dots = document.getElementsByClassName('demo');
    const captionText = document.getElementById('caption');

    if (n > slides.length) {
      n = 1;
    }
    if (n < 1) {
      n = slides.length;
    }

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = 'none';
    }
    for (let i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace(' active', '');
    }

    slides[n - 1].style.display = 'block';
    dots[n - 1].className += ' active';
    captionText.innerHTML = dots[n - 1].alt;

    setSlideIndex(n);
  };


  return (
    <>
      <div className="container12">
        <div className="mySlides">
          <div className="numbertext">1 / 5</div>
          <img className='imgsli' src="https://wallpaperaccess.com/full/3460583.jpg" style={{ width: '100%', height: "10cm" }} alt="The Woodss" />
        </div>
        <div className="mySlides">
          <div className="numbertext">2 / 5</div>
          <img className='imgsli' src="https://i.pinimg.com/originals/39/12/5d/39125d1baa1a730e8612928be3eae97f.jpg" style={{ width: '100%', height: "10cm" }} alt="The Woodss" />
        </div>
        <div className="mySlides">
          <div className="numbertext">3 / 5</div>
          <img className='imgsli' src="https://wallpapercave.com/wp/wp2007621.jpg" style={{ width: '100%', height: "10cm" }} alt="The Woodss" />
        </div>
        <div className="mySlides">
          <div className="numbertext">4 / 5</div>
          <img className='imgsli' src="https://th.bing.com/th/id/OIP.MludgFQ0LOuLG-oa_4gaDgHaEK?pid=ImgDet&rs=1" style={{ width: '100%', height: "10cm" }} alt="The Woodss" />
        </div>
        <div className="mySlides">
          <div className="numbertext">5 / 5</div>
          <img className='imgsli' src="https://th.bing.com/th/id/OIP.mJPCoeOO7bYuMpwFJVlbaQHaEK?pid=ImgDet&rs=1" style={{ width: '100%', height: "10cm" }} alt="The Woodss" />
        </div>
   
      </div>

      <div className="logos">
        <div className="logos-slide">

          <img src="https://wallpaperaccess.com/full/3460583.jpg" alt="The Woodss" onClick={() => currentSlide(1)} />
          <img src="https://i.pinimg.com/originals/39/12/5d/39125d1baa1a730e8612928be3eae97f.jpg" alt="The Woodss" onClick={() => currentSlide(2)} />
          <img src="https://wallpapercave.com/wp/wp2007621.jpg" alt="The Woodss" onClick={() => currentSlide(3)} />
          <img src="https://th.bing.com/th/id/OIP.MludgFQ0LOuLG-oa_4gaDgHaEK?pid=ImgDet&rs=1" alt="The Woodss" onClick={() => currentSlide(4)} />
          <img src="https://th.bing.com/th/id/OIP.mJPCoeOO7bYuMpwFJVlbaQHaEK?pid=ImgDet&rs=1" alt="The Woodss" onClick={() => currentSlide(5)} />

        </div>  {/* Duplicate the images */}
        <div className="logos-slide">

          <img src="https://wallpaperaccess.com/full/3460583.jpg" alt="The Woodss" onClick={() => currentSlide(1)} />
          <img src="https://i.pinimg.com/originals/39/12/5d/39125d1baa1a730e8612928be3eae97f.jpg" alt="The Woodss" onClick={() => currentSlide(2)} />
          <img src="https://wallpapercave.com/wp/wp2007621.jpg" onClick={() => currentSlide(3)} alt="The Woodss" />
          <img src="https://th.bing.com/th/id/OIP.MludgFQ0LOuLG-oa_4gaDgHaEK?pid=ImgDet&rs=1" alt="The Woodss" onClick={() => currentSlide(4)} />
          <img src="https://th.bing.com/th/id/OIP.mJPCoeOO7bYuMpwFJVlbaQHaEK?pid=ImgDet&rs=1" alt="The Woodss" onClick={() => currentSlide(5)} />

        </div>
      </div>

    </>

  );
}

export default SlideshowGallery;



{/* <img src="https://cdn1.cycletrader.com/v1/media/64df46003023ee7daf6ec0b1.jpg?width=400&height=300&quality=60&bestfit=true&upsize=true&blurBackground=true&blurValue=100" alt="The Woodss" />
//           <img src="https://assets.thehansindia.com/h-upload/2022/04/28/1289305-bike.webp" alt="The Woodss" />
//           <img src="https://5.imimg.com/data5/VR/YS/GLADMIN-66548982/hero-hf-deluxe-bike-500x500.png" alt="The Woodss" />
//           <img src="https://gaadiwaadi.com/wp-content/uploads/2023/11/All-New-Royal-Enfield-Himalayan-450-2-1280x640.jpg" alt="The Woodss" /> 

  <img src="https://imgd.aeplcdn.com/370x208/n/cw/ec/124013/hunter-350-right-front-three-quarter.jpeg?isig=0&q=80" alt="The Woodss" onClick={() => currentSlide(1)} />
          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTWFkdxRfArZlW9BJCJuDwsQtBuWgQNItSj6g&usqp=CAU" alt="The Woodss" onClick={() => currentSlide(2)} />
          <img src="https://e0.pxfuel.com/wallpapers/283/658/desktop-wallpaper-kev-barker-on-twitter-duke-bike-bike-pic-super-bikes-duke-390-thumbnail.jpg" alt="The Woodss" onClick={() => currentSlide(3)} />
          <img src="https://gaadiwaadi.com/wp-content/uploads/2023/11/All-New-Royal-Enfield-Himalayan-450-2-1280x640.jpg" alt="The Woodss" onClick={() => currentSlide(4)} />
          <img src="https://assets.thehansindia.com/h-upload/2022/04/28/1289305-bike.webp" alt="The Woodss" onClick={() => currentSlide(5)} />
*/}