import React, { useState, useRef } from 'react';
import axios from 'axios';
import "./review.css"
import { AiOutlineStar } from "react-icons/ai"
import logo from "../../images/yoga.jpg"
import { BsStarFill } from 'react-icons/bs';

const ReviewForm = () => {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    profile: '',
    role: '',
    rating: null,
    image: null,
  });

  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [roleOptions, setRoleOptions] = useState([]);

  const fileInputRef = useRef(null);

  const handleRatingChange = (rating) => {
    setFormData({ ...formData, rating });
  };

  const handleChange = (e) => {
    const { name, value, type } = e.target;

    if (name === 'profile') {
      // Update role options based on the selected profile
      const options = getRoleOptions(value);
      setRoleOptions(options);
    }

    if (type === 'file') {
      setFormData({ ...formData, [name]: e.target.files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const getRoleOptions = (selectedProfile) => {
    // Define your conditional options based on the selected profile
    switch (selectedProfile) {
      case 'mobile':
      case 'electronic':
      case 'peta':
      case 'furniture':
        return ['Store', 'Individual'];
      // Add more cases as needed for other profiles
      default:
        return [];
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      const username = localStorage.getItem("name")
      const userid = localStorage.getItem("userid")

      const formDataToSend = new FormData();

      formDataToSend.append("username", username)
      formDataToSend.append("userid", userid)
      for (const key in formData) {
        if (key === 'image' && formData[key] === null) {
          // If no image is uploaded, use the default image
          const defaultImageURL = logo;

          // Fetch the default image and convert it to a Blob
          const response = await fetch(defaultImageURL);
          const defaultImageBlob = await response.blob();

          // Create a File object from the Blob
          const defaultImageFile = new File([defaultImageBlob], 'default_image.png');

          formDataToSend.append(key, defaultImageFile);
        } else {
          formDataToSend.append(key, formData[key]);
        }
      }

      // Replace 'YOUR_API_URL' with your actual API URL
      const response = await axios.post('https://databytess.com/api/user/reviewSection/', formDataToSend);

      // Handle successful submission here
      console.log('Review submitted successfully');

      setFormData({
        title: '',
        description: '',
        profile: '',
        role: '',
        rating: null,
        image: null,
      });

      setSubmitted(true);
    } catch (error) {
      // Handle error here
      console.error('Error submitting review:', error);
    } finally {
      setLoading(false);
    }
  };

  const renderStarRating = () => {
    const stars = [];
    const emojis = ["😢", "😟", "😐", "😊", "😄"]; // Add corresponding emojis
    const ratingTexts = ["Poor", "Fair", "Average", "Good", "Excellent"];

    for (let i = 1; i <= 5; i++) {
      stars.push(
        <label key={i}>
          <input
            type="radio"
            name="rating"
            value={i}
            checked={formData.rating === i}
            onChange={() => handleRatingChange(i)}
            required
          />
          <BsStarFill
            className="star"
            color={i <= formData.rating ? 'gold' : 'gray'}
            size={25}
          />
        </label>
      );
    }

    // Add a div to display the selected emoji
    stars.push(
      <div key="emoji" className="emoji-container">
        {formData.rating !== null && <span>{emojis[formData.rating - 1]}</span>}
        {formData.rating !== null && <span>{ratingTexts[formData.rating - 1]}</span>}
      </div>
    );

    return stars;
  };
  const handleFileInputClick = () => {
    // Trigger the file input click event when the user clicks on a custom button
    fileInputRef.current.click();
  };


  return (
    <div className="review-form-container">

      {submitted ? (
        <div>
          <p style={{ color: 'green' }}>Thank you for your Support!</p>
          {/* You can optionally add a button to allow the user to submit another review */}
        </div>
      ) : (
        <>
          <h3>Submit a Review</h3>
          <form onSubmit={handleSubmit} encType="multipart/form-data" className="review-form" >
            <div>

              <input
                type="text"
                id="title"
                className='inp'
                name="title"
                maxLength={20}
                placeholder="Enter Title Here"
                value={formData.title}
                onChange={handleChange}
                required
              />
            </div>
            <div>

              <textarea type='text'
                id="description"
                name="description"
                className='inp'
                maxLength={50}
                placeholder="Description"
                value={formData.description}
                onChange={handleChange}
                required
              />
            </div>

            <div>
              {/* <label htmlFor="profile">Category:</label> */}
              <select
                id="profile"
                name="profile"
                className='inp'
                value={formData.profile}
                onChange={handleChange}
                required
              >
                <option value="" disabled>Select a category</option>
                <option value="mobile">Mobile</option>
                <option value="electronic">Electronic</option>
                <option value="peta">Peta</option>
                <option value="furniture">Furniture</option>
                <option value="jobs">Jobs</option>
                <option value="real-estate">Real Estate</option>
              </select>
            </div>
            {loading && (
              <div className="loading-overlay">
                <div className="loading-spinner" />
              </div>
            )}
            <div style={{marginTop:"10px"}}>
              {/* <label htmlFor="role">Your Role:</label> */}
              <select
                id="role"
                name="role"
                className='inp'
                maxLength={20}
                placeholder="Your Role"
                value={formData.role}
                onChange={handleChange}
                required
              >
                <option value="" disabled>Select your role</option>
                {formData.profile === "mobile" || formData.profile === "electronic" || formData.profile === "peta" || formData.profile === "furniture" ? (
                  <>
                    <option value="store">Store</option>
                    <option value="individual">Individual</option>
                  </>
                ) : formData.profile === "jobs" ? (
                  <>
                    <option value="employer">Employer</option>
                    <option value="recruiter">Recruiter</option>
                  </>
                ) : formData.profile === "real-estate" ? (
                  <>
                    <option value="owner">Owner</option>
                    <option value="agent">Agent</option>
                  </>
                ) : null}
              </select>
            </div>
            <div>
              <h6 htmlFor="rating" className='fw-bold'>Rating: {renderStarRating()}</h6>

            </div>
            {/* <div>
          <label htmlFor="image">Image:</label>
          <input
            type="file"
            id="image"
            name="image"
            onChange={handleChange}
            accept="image/*"
            required
          />
        </div> */}
            <center>
              <button type="submit" style={{ color: "white" }} className="submit-button" disabled={loading}>
                {loading ? 'Submitting...' : 'Submit Review'}
              </button>
            </center>
          </form>
        </>)}
    </div>
  );
};

export default ReviewForm;
