const optionsData = [
  {
    value: "Career Counseling",
    label: " Career Counseling ",
    subcategories: [
      {
        label: "Career Stream",
        items: [
          "Defense",
          "Administrative Services",
          "Engineering and Medical",
          "Teaching",
          "Other Staff",
        ],
      },
      {
        label: "Delivery Mode",
        items: ["Classroom", "Correspondence", "Home Tution", "Online"],
      },
      {
        label: "Listed By",
        items: ["Institutions", "Freelancers"],
      },
    ],
  },
  {
    value: "Competative Exams Coaching",
    label: "Competative Exams Coaching",
    subcategories: [
      {
        label: "Career Stream",
        items: [
          "Defense",
          "Administrative Services",
          "Engineering and Medical",
          "Teaching",
          "Other Staff",
        ],
      },
      {
        label: "Delivery Mode",
        items: ["Classroom", "Correspondence", "Home Tution", "Online"],
      },
      {
        label: "Listed By",
        items: ["Institutions", "Freelancers"],
      },
    ],
  },
  {
    value: "Certification & Training",
    label: "Certification & Training",
    subcategories: [
      {
        label: "Career Stream",
        items: [
          "Defense",
          "Administrative Services",
          "Engineering and Medical",
          "Teaching",
          "Other Staff",
        ],
      },
      {
        label: "Delivery Mode",
        items: ["Classroom", "Correspondence", "Home Tution", "Online"],
      },
      {
        label: "Listed By",
        items: ["Institutions", "Freelancers"],
      },
    ],
  },
  {
    value: "Distance Learning Courses",
    label: "Distance Learning Courses",
    subcategories: [
      {
        label: "Program Type",
        items: [
          "Postgraduate Distance Program(PG)",
          "Undergraduate Distance Program(UG)",
        ],
      },
      {
        label: "Delivery Mode",
        items: [  "Distance",
        "Correspondence",
        "Hybrid",
        "Online Only",
        "Regular",
        "Part Time",],
      },
      {
        label: "Listed By",
        items: ["Institutions", "Freelancers"],
      },
    ],
  },
  {
    value: "Entrance Exams Coaching",
    label: "Entrance Exams Coaching",
    subcategories: [
      {
        label: "Career Stream",
        items: [
          "Accounting & Finance",
          "Fashion and Design",
          "Engineering",
          "Law",
          "Management",
          "Medical",
          "Study Abroad",
          "Other Exam",
        ],
      },
      {
        label: "Delivery Mode",
        items: ["Classroom", "Correspondence", "Home Tution", "Online"],
      },
      {
        label: "Listed By",
        items: ["Institutions", "Freelancers"],
      },
    ],
  },
  {
    value: "Hobby Classes",
    label: "Hobby Classes",
    subcategories: [
      {
        label: "Hobby",
        items: [
          "Chocolate Making",
  "Cooking Class",
  "Dance Class",
  "Embroidery",
  "Foreign Language Classes",
  "Gardening",
  "Gift Wrapping & Decoration",
  "Guitar Classes",
  "Handwriting Classes",
  "Jewelery Making",
  "Knitting",
  "Music Class",
  "Painting Class",
  "Piano Classes",
  "Pottery Class",
  "Singing Class",
  "Other Hobbies",
        ],
      },
      {
        label: "Eligibility",
        items: [  "Kids Only",
        "Kids and Ladies",
        "Ladies Only",
        "Open for all",],
      },
      {
        label: "Classes Held On",
        items: [ "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",],
      },
      {
        label: "Class Timings",
        items: [ "Morning", "Afternoon", "Evening"],
      },
      {
        label: "Listed By",
        items: [ "Institutions", "Freelancers"],
      },
    ],
  },
  {
    value: "Play Schools-Creche",
    label: "Play Schools-Creche",
    subcategories: [
      {
        label: "Programs Offered",
        items: [
          "Full time day care",
          "Lower Kindergarten",
          "Nursery",
          "Part time Day care",
          "Play group with parent",
          "Pre Nursery",
          "Upper Kindergarten",
        ],
      },
      {
        label: "Education Method",
        items: [   "Integrated",
        "Montessori",
        "Multiple Intelligence",
        "Play way",
        "Reggio Emilia",
        "Waldorf Steiner",],
      },
      {
        label: "Min Age",
        items: ["5years",
        "10years",
        "15years",
        "20years",
        "25years",
        "30years",
        "35years",
        "40years",
        "45years",
        "50years",
        "55years",
        "60years", ],
      },
      {
        label: "Max Age",
        items: [ 
        "10years",
        "15years",
        "20years",
        "25years",
        "30years",
        "35years",
        "40years",
        "45years",
        "50years",
        "55years",
        "60years",],
      },
      {
        label: "Student Teacher Ratio Facilities",
        items: [ 
          "Meals & Snacks",
  "Medical Assistance",
  "Transportation  Facility",
  "Toilet Training",],
      },
      {
        label: "Listed By",
        items: [ "Institutions", "Freelancers"],
      },
    ],
  },
  {
    value: "School & School Tutions",
    label: "School & School Tutions",
    subcategories: [
      {
        label: "School Board",
        items: [
          "CBSE",
          "ICSE",
          "IGCSE",
          "ISC",
          "STATE BOARD",
          "INTERNATIONAL BACCALAUREATE",
        ],
      },
      {
        label: "Subjects",
        items: [  "Accounting",
        "Biology",
        "Business studies",
        "Chemistry",
        "Commerce",
        "Computer Science",
        "Economics",
        "English Grammar",
        "English Literature",
        "Geography",
        "Hindi",
        "History",
        "Maths",
        "Physics",
        "Political science",
        "Regional Indian Languages",
        "Sociology",
        "Other Subjects", ],
      },
      {
        label: "Standard",
        items: ["1-5th", "5-7th", "7-10th", "1st PU", "2nd PU"],
      },
     {
      label: "Delivery Mode",
      items: ["Classroom", "Correspondence", "Home Tution", "Online"],
     },
      {
        label: "Listed By",
        items: [ "Institutions", "Freelancers"],
      },
    ],
  },
 
  {
    value: "Study Abroad Consultants",
    label: "Study Abroad Consultants",
    subcategories: [
      {
        label: "Country",
        items: [
          "Australia",
          "Canada",
          "Europe",
          "New Zealand",
          "Singapore",
          "UK",
          "USA",
          "Others",
        ],
      },
      {
        label: "Services Offered",
        items: [ 
          "Accommodation Assistance",
          "College Admission Assistance",
          "Entrance Examination Assistance",
          "Student Loan Assistance",
          "Student Visa Assistance",
          "Travel Assistance",
        ],
      },
      {
        label: "Education Stream",
        items: [ 
          "Design",
          "Engineering",
          "MBA",
          "Medical",
          "Others",
        ],
      },
      {
        label: "Listed By",
        items: ["Institutions", "Freelancers"],
      },
    ],
  },
  {
    value: "Text books & Study Materials",
    label: "Text books & Study Materials",
    subcategories: [
      {
        label: "Product Type",
        items: [
          "Book", "Calculator", "Study Material", "Others"
        ],
      },
      {
        label: "Listed By",
        items: ["Institutions", "Freelancers"],
      },
    ],
  },


  {
    value: "Vocational Skills Training",
    label: "Vocational Skills Training",
    subcategories: [
      {
        label: "Industry",
        items: [
          "Aviation",
          "Computer & IT",
          "Electronics& Appliances Repair",
          "Health & Beauty",
          "Industrial Training",
          "Medical",
          "Other Industries",
        ],
      },
      {
        label: "Key Features",
        items: [ 
          "Apprenticeship Support",
          "Job Placement Support",
          "(PMKVYA) Pradhan Mantri Kaushal Vikas Yojana Authorized",
        ],
      },
      {
        label: "Listed By",
        items: ["Institutions", "Freelancers"],
      },
    ],
  },
];

export default optionsData;
