// import { Key, Pets } from '@mui/icons-material';

// The FilterBy component is a functional component that represents a filter functionality for a classified ads website.
import { Button, Col, Row, Modal } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HolaAutoComplete } from "./component/Ads/components/HolaAutoComplete";
import { HolaAutoCompletePrice } from "./component/Ads/components/HolaAutoComplete";
import {
  allSubcategory,
  Bikes,
  Pets,
  Category,
  Electronics,
  subcategoryRealEstateBuy,
  Furniture,
  subcategoryType1,
  Services,
  // Jobs,
  Mobiles,
  subcategoryRealEstateRent,
  Cars,
  Education,
  Jobs,
  subcategoryFurniture,
  
} from "./component/env";
import { filterInsertData } from "./Model/SeachFIlterCons";
import { subCategoryInput } from "./Model/SubCategoryCons";
import { getExtraField } from "../src/store/ProductFilterSlice";
import { GiEvilFork } from "react-icons/gi";
import { UserContext } from "./App";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { reset } from "./store/ToggleSearchSlice";
import ShowProductsSearch from "./component/Ads/Allads/ShowProductsSearch";
import ShowProduct1 from "./component/Ads/Allads/ShowProduct1";
import ShowProduct from "./component/Ads/Allads/ShowProduct";
import { Citys } from "./component/Location";
// import "./filterby.css";
// The component defines two styles mobileStyle and desktopStyle that are used to style the filter button based on the device type (mobile or desktop).
const mobileStyle = {
  border: "1px solid purple",
  borderRadius: "3px",
  padding: "4px",
  margin: "12px",
  justifyContent: "center",
  backgroundColor: "#2b5876",
  color: "white",
};

const desktopStyle = {
  width: "120px",
  border: "2px solid black",
  borderRadius: "15px",
  padding: "4px",
  backgroundColor: "#2b5876",
  color: "white",
};

const mobileStyle1 = {};
const desktopStyle1 = {
  // boxShadow: "1px 2px 5px 3px lightgray",
  // padding: "10px",
  // width: "80%",
  // margin: "15px auto",
  // background: "white",
};

// The component uses React hooks such as useContext, useEffect, and useState for managing state and side effects.
export const FilterBy = (props) => {
  const user = useContext(UserContext);
  const dispatch = useDispatch();
  const filterSlice = useSelector((state) => state.Filter);

  useEffect(() => {
    if (props?.value) {
      setCategory(props.value);
    }
  }, []);

  let CategoryList = Category;
  const [open, setOpen] = useState(false);
  const [finalObj, SetFinalObj] = useState({});
  const [changeTrigger, setChangeTrigger] = useState(1);
  const [priceErrorFlag, setPriceErrorFlag] = useState(false);
  const [categorysubCategoryFlag, setcategorysubCategoryFlag] = useState(false);
  const [subCategoryType, setsubCategoryType] = useState(null);
  const [extraFeildToggle, setExtraFeildToggle] = useState(false);
  //  const [subCategoryType, setsubCategoryType] = useState(null)

  const [subCategoryValue, setsubCategoryValue] = useState(null); //11
  const [category, setCategory] = useState(null);
  const [city, setCity] = useState(null);
  const [obj, setObj] = useState(null); //13
  const [finalObjSub, SetFinalObjsub] = useState({});

  const [objJson, setobjJson] = useState(null);

  const [finalJsonData, setFinalJsonData] = useState(null);
  const [isContentVisible, setContentVisible] = useState(false);
  useEffect(() => {
    if (Number(finalObj?.minPrice) > Number(finalObj?.maxPrice)) {
      setPriceErrorFlag(true);
    } else {
      setPriceErrorFlag(false);
    }
    console.log("final obj data ", finalObj);
  }, [changeTrigger]);

  useEffect(() => {
    console.log("~~~", subCategoryValue);
    // if (subCategoryValue)
    if (subCategoryValue && subCategoryInput[category] !== undefined) {
      let subcategoryy = subCategoryInput[category];
      if (subcategoryy[subCategoryValue] !== undefined) {
        setExtraFeildToggle(true);
        let values = subcategoryy[subCategoryValue];
        let obj1 = values;

        Object.keys(obj1).forEach((key) => {
          let varkey = [];
          varkey = obj1[key].map((result) => {
            if (typeof result === "object" && result !== null) {
              return result;
            } else {
              return { value: result };
            }
          });
          obj1[key] = varkey;
        });
        // debugger

        setObj(obj1);
      } else {
        setExtraFeildToggle(false);
      }
    } else {
      setExtraFeildToggle(false);
    }
  }, [subCategoryValue]);

  const navigate = useNavigate();
  useEffect(() => {
    if (subCategoryValue) {
    }

    var dataStringy = JSON.stringify(finalObj);
    setobjJson(dataStringy);
  }, [finalObj?.category]);
  // The submitMethod function is defined, which is called when the user clicks the "Filter" button. It dispatches an action with the selected filter options and closes the modal.
  const submitMethod = () => {
    navigate("/ads-listing");
    dispatch(reset());
    setOpen(false);
    user.setFilterBy(true);
    let extraField = JSON.stringify(finalObjSub);
    const finalData = {
      city,
      category,
      subCategoryValue,
      extraField,
    };
    setFinalJsonData(finalData);
    setContentVisible(!isContentVisible);
    let obj = {};
    for (var empty in finalObj) {
      if (
        finalObj[empty]?.trim() === "" ||
        finalObj[empty]?.trim() === undefined
      ) {
        delete finalObj[empty];
      }
    }
    obj = Object.assign(obj, finalObj);

    if (category) {
      obj["category"] = category;
    }
    if (city) {
      obj["City"] = city;
    }
    if (subCategoryValue) {
      obj["subCategoryValue"] = subCategoryValue;
    }
    // for revomve key from obj which has emtpy or dash srting
    for (var emptyKeyValue in obj) {
      if (
        obj[emptyKeyValue]?.trim() === "" ||
        obj[emptyKeyValue]?.trim() === undefined
      ) {
        delete obj[emptyKeyValue];
      }
    }

    for (var emptykey in finalObjSub) {
      if (
        finalObjSub[emptykey]?.trim() === "" ||
        finalObjSub[emptykey]?.trim() === undefined
      ) {
        delete finalObjSub[emptykey];
      }
    }
    if (finalObjSub) {
      obj["extraField"] = finalObjSub;
    }

    dispatch(getExtraField(obj));
    if (props?.value) {
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      {!props?.value ? (
        <div>
          <Button onClick={() => setOpen(true)} className="bytn1">
            Filter By <i className="fas fa-filter"></i>
          </Button>
          <Modal
            title="Filter"
            centered
            open={open}
            onOk={() => {
              submitMethod();
            }}
            onCancel={() => setOpen(false)}
            okButtonProps={{ disabled: priceErrorFlag }}
            width={500}
          >
            <div
              style={{
                paddingBottom: "1%",
                minHeight: "xxx",
                overflow: "hidden",
              }}
            >
              <div className="col-lg-12 col-sm-12">
                <label className="mb-0 ">Select Your City: &nbsp;</label>
                <input
                  className="inpstyle"
                  placeholder="Select City"
                  type="text"
                  list="city"
                  onChange={(e) => {
                    setCity(e.target.value);
                  }}
                />
                <datalist id="city">
                  {Citys.map((result) => {
                    return (
                      <option value={result} key={result}>
                        {result}
                      </option>
                    );
                  })}
                </datalist>
              </div>
              {/* filterinsert exported from searfiltercons */}
              {Object?.keys(filterInsertData)?.map((key) => {
                let valueone = filterInsertData[key];

                return Object?.keys(filterInsertData[key])?.map((key1) => {
                  return (
                    <Col
                      className="w-100 d-inline-flex mx-0 justify-content-center col-lg-6"
                      span={12}
                      style={{ marginTop: "3px" }}
                    >
                      {/* The price range input fields are implemented using the HolaAutoCompletePrice component, which is a custom component. */}
                      <HolaAutoCompletePrice
                        style={{
                          width: 300,
                        }}
                        options={valueone[key1]}
                        placeholder={
                          key1 === "minPrice"
                            ? "Min Price "
                            : key1 === "maxPrice"
                            ? "Max Price"
                            : null
                        }
                        autoFocus={true}
                        onChange={(e) => {
                          finalObj[key1] = e;
                          SetFinalObj(finalObj);
                          setChangeTrigger(changeTrigger + 1);
                        }}
                        filterOption={(inputValue, option) =>
                          option.value
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                        onKeyPress={(e) => {
                          // Allow only numbers, backspace, and arrow keys (for navigation)
                          const charCode = e.which ? e.which : e.keyCode;
                          if (
                            charCode > 31 &&
                            (charCode < 48 || charCode > 57) &&
                            charCode !== 8 &&
                            charCode !== 37 &&
                            charCode !== 39
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />

                      {key1 === "maxPrice" && priceErrorFlag ? (
                        <div
                          style={{
                            display: "flex",
                            position: "absolute",
                            marginTop: " 40px",
                            color: "red",
                          }}
                        >
                          Choose Greater Price{" "}
                        </div>
                      ) : null}
                    </Col>
                  );
                });
              })}

              <br />
              <div style={{ display: "flex" }}>
                <div className="col-lg-6 col-sm-12">
                  <label className="mb-0 mt-2">Category</label>
                  <input
                    className="inpstyle"
                    placeholder="Category"
                    type="text"
                    list="category"
                    style={{ width: "100%", zIndex: "-50px" }}
                    onChange={(e) => {
                      setCategory(e.target.value);
                      setcategorysubCategoryFlag(
                        e.target.value === "RealEstate" ? true : false
                      );
                      SetFinalObjsub({});
                      if (category === "RealEstate") {
                        setsubCategoryValue(null);
                        setsubCategoryType(null);
                      } else {
                        setsubCategoryValue("");
                      }
                    }}
                  />
                  <datalist id="category">
                    {CategoryList.map((result) => {
                      return <option value={result}>{result}</option>;
                    })}
                  </datalist>
                </div>
                {category !== "RealEstate" ? (
                  <div className="col-lg-6 col-sm-12">
                    <label className="mb-0 mt-2">Sub Category</label>
                    <input
                      className="inpstyle"
                      value={subCategoryValue}
                      placeholder="Sub Category"
                      type="text"
                      list="subcategoryForAll"
                      style={{ width: "100%", zIndex: "-40px" }}
                      onChange={(e) => {
                        setsubCategoryValue(e.target.value);
                        SetFinalObjsub({});
                      }}
                    />
                    {/* The CategoryList variable is assigned the value of the Category constant imported from the env.js file. */}
                    <datalist id="subcategoryForAll">
                      {category === "Furniture"
                        ? Furniture.map((result) => {
                            return <option value={result}>{result}</option>;
                          })
                        : category === "Electronics"
                        ? Electronics.map((result) => {
                            return <option value={result}>{result}</option>;
                          })
                        : category === "Furniture"
                        ? Furniture.map((result) => {
                            return <option value={result}>{result}</option>;
                          })
                        : category === "RealEstate"
                        ? subcategoryRealEstateRent.map((result) => {
                            return <option value={result}>{result}</option>;
                          })
                        : category === "Pets"
                        ? Pets.map((result) => {
                            return <option value={result}>{result}</option>;
                          })
                        : category === "Cars"
                        ? Cars.map((result) => {
                            return <option value={result}>{result}</option>;
                          })
                        : category === "Mobiles"
                        ? Mobiles.map((result) => {
                            return <option value={result}>{result}</option>;
                          })
                        : category === "Bikes"
                        ? Bikes.map((result) => {
                            return <option value={result}>{result}</option>;
                          })
                        : category === "Services"
                        ? Services.map((result) => {
                            return <option value={result}>{result}</option>;
                          })
                        : category == "Education"
                        ? Education.map((result) => {
                            return <option>{result}</option>;
                          })
                        : category == "Jobs"
                        ? Jobs.map((result) => {
                            return <option>{result}</option>;
                          })
                        : null}
                    </datalist>
                  </div>
                ) : null}
                {/* The Categorysubcategoryflag variable is assigned the value of the Category constant imported from the env.js file. */}

                {categorysubCategoryFlag ? (
                  <div className="col-lg-6 col-sm-12">
                    <label style={{ marginBottom: "0px", marginTop: "8px" }}>
                      SubCategory
                    </label>
                    <input
                      className="inpstyle"
                      placeholder="Sub Category"
                      type="text"
                      list="subcategoryType"
                      style={{ width: "100%", zIndex: "-50px" }}
                      onChange={(e) => {
                        setsubCategoryType(e.target.value);
                        setExtraFeildToggle(false);
                        SetFinalObjsub({});
                      }}
                    />
                    <datalist id="subcategoryType">
                      {subcategoryType1.map((result) => {
                        return <option value={result}>{result}</option>;
                      })}
                    </datalist>
                  </div>
                ) : null}
              </div>
              {subCategoryType == "Residential" ? (
                <div className="col-lg-12 col-sm-12">
                  <label style={{ marginBottom: "0px", marginTop: "8px" }}>
                    Type
                  </label>

                  <input
                    className="inpstyle"
                    placeholder="subcategoryRealEstateBuy"
                    type="text"
                    list="subcategoryRealEstateBuy"
                    style={{ width: "100%", zIndex: "-50px" }}
                    onChange={(e) => {
                      setsubCategoryValue(e.target.value);
                      SetFinalObjsub({});
                    }}
                  />
                  <datalist id="subcategoryRealEstateBuy">
                    {subcategoryRealEstateBuy.map((result) => {
                      return <option value={result}>{result}</option>;
                    })}
                  </datalist>
                </div>
              ) : subCategoryType == "Commercial" ? (
                <div className="col-lg-12 col-sm-12">
                  <label style={{ marginBottom: "0px", marginTop: "15px" }}>
                    Type
                  </label>

                  <input
                    className="inpstyle"
                    placeholder="subcategoryRealEstateRent"
                    type="text"
                    list="subcategoryRealEstateRent"
                    style={{ width: "100%", zIndex: "-50px" }}
                    onChange={(e) => {
                      setsubCategoryValue(e.target.value);
                      SetFinalObjsub({});
                    }}
                  />
                  <datalist id="subcategoryRealEstateRent">
                    {subcategoryRealEstateRent.map((result) => {
                      return <option value={result}>{result}</option>;
                    })}
                  </datalist>
                </div>
              ) : subCategoryType == "Residential" ? (
                <div className="col-lg-12 col-sm-12">
                  <label>Enter Here</label>

                  <input
                    className="inpstyle"
                    placeholder="subcategoryRealEstateRent"
                    type="text"
                    list="Others"
                    style={{ padding: "12px", width: "100%", zIndex: "-50px" }}
                    onChange={(e) => {
                      setsubCategoryValue(e.target.value);
                      SetFinalObjsub({});
                    }}
                  />
                </div>
              ) : null}
              <br />
              {subCategoryValue && obj && extraFeildToggle
                ? Object?.keys(obj)?.map((key) => {
                    return (
                      <Col
                        className="w-100 d-inline-flex mx-0 justify-content-center gx-3 col-sm-6 col-md-4 col-lg-6"
                        span={24} // Set the span to 24 for mobile view
                        style={{
                          marginTop: "15px",
                          flex: "1",
                          maxWidth: "100%",
                        }}
                      >
                        {/* The price range input fields are implemented
                            using the HolaAutoCompletePrice component,
                            which is a custom component. */}
                        <HolaAutoComplete
                          style={{
                            width: "100%", // Make sure the input takes the full width
                          }}
                          options={obj[key]}
                          placeholder={key.slice(0, 19)}
                          autoFocus={true}
                          onChange={(e) => {
                            console.log(e);
                            // finalObjSub[key] = e;
                            SetFinalObjsub({
                              ...finalObjSub,
                              [key]: e,
                            });
                          }}
                          filterOption={(inputValue, option) =>
                            option.value
                              .toUpperCase()
                              .indexOf(inputValue.toUpperCase()) !== -1
                          }
                        />
                      </Col>
                    );
                  })
                : null}
              {/* <button onClick={()=>{submitMethod()}}>click me </button> */}
            </div>
          </Modal>
        </div>
      ) : (
        <div>
          <div
            style={{
              paddingBottom: "1%",
              minHeight: "xxx",
              overflow: "hidden",
              border: "1px solid white",
              borderRadius: "3px",
              boxShadow: "1px 2px 5px 3px lightgray",
              padding: "10px 10px 25px 10px",
              width: "95%",
              margin: "15px auto 15px auto",
              background: "white",
            }}
          >
            {Object?.keys(filterInsertData)?.map((key) => {
              let valueone = filterInsertData[key];

              return Object?.keys(filterInsertData[key])?.map((key1) => {
                return (
                  <Col
                    className="w-100 d-inline-flex justify-content-center"
                    span={5}
                    style={{ marginTop: "30px" }}
                  >
                    {/* The price range input fields are implemented using the HolaAutoCompletePrice component, which is a custom component. */}
                    <HolaAutoCompletePrice
                      style={{
                        width: 300,
                      }}
                      options={valueone[key1]}
                      placeholder={
                        key1 === "minPrice"
                          ? "Min Price"
                          : key1 === "maxPrice"
                          ? "Max price"
                          : key1
                      }
                      autoFocus={true}
                      onChange={(e) => {
                        finalObj[key1] = e;
                        SetFinalObj(finalObj);
                      }}
                      filterOption={(inputValue, option) =>
                        option.value
                          .toUpperCase()
                          .indexOf(inputValue.toUpperCase()) !== -1
                      }
                      onKeyPress={(e) => {
                        // Allow only numbers, backspace, and arrow keys (for navigation)
                        const charCode = e.which ? e.which : e.keyCode;
                        if (
                          charCode > 31 &&
                          (charCode < 48 || charCode > 57) &&
                          charCode !== 8 &&
                          charCode !== 37 &&
                          charCode !== 39
                        ) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Col>
                );
              });
            })}
            {/* <div
            className="col-lg-4 col-sm-12"
            style={isMobile ? mobileStyle1 : desktopStyle1}
          >
            <label className="mb-0 mt-2" style={{display:"none"}}>Category</label>
            <input className="inpstyle" 
              value={props?.value}
              placeholder="category"
              type="text"
              list="category"
              style={{
                display:"none",
                width: "100%",
                zIndex: "-50px",
                backgroundColor: "white",
              }}
              onChange={(e) => {
                setCategory(e.target.value);
                setcategorysubCategoryFlag(
                  e.target.value === "RealEstate" ? true : false
                );
                if (category === "RealEstate") {
                  setsubCategoryValue(null);
                  setsubCategoryType(null);
                } else {
                  setsubCategoryValue("");
                }
              }}
              disabled
            />
            <datalist id="category">
              {CategoryList.map((result) => {
                return <option value={result}>{result}</option>;
              })}
            </datalist>
          </div> */}
            {category !== "RealEstate" ? (
              <div className="col-lg-3 col-sm-12">
                <label className="mb-1 ">Sub Category</label>
                <input
                  className="inpstyle"
                  value={subCategoryValue}
                  placeholder="Sub Category"
                  type="text"
                  list="subcategoryForAll"
                  style={{ zIndex: "-50px", width: "100%", height: "32px" }}
                  onChange={(e) => {
                    setsubCategoryValue(e.target.value);
                  }}
                />
                <datalist id="subcategoryForAll">
                  {category === "Furniture"
                    ? Furniture.map((result) => {
                        return <option value={result}>{result}</option>;
                      })
                    : category === "Electronics"
                    ? Electronics.map((result) => {
                        return <option value={result}>{result}</option>;
                      })
                    : category === "Furniture"
                    ? Furniture.map((result) => {
                        return <option value={result}>{result}</option>;
                      })
                    : category === "RealEstate"
                    ? subcategoryRealEstateRent.map((result) => {
                        return <option value={result}>{result}</option>;
                      })
                    : category === "Pets"
                    ? Pets.map((result) => {
                        return <option value={result}>{result}</option>;
                      })
                    : category === "Cars"
                    ? Cars.map((result) => {
                        return <option value={result}>{result}</option>;
                      })
                    : category === "Mobiles"
                    ? Mobiles.map((result) => {
                        return <option value={result}>{result}</option>;
                      })
                    : category === "Bikes"
                    ? Bikes.map((result) => {
                        return <option value={result}>{result}</option>;
                      })
                    : category === "Services"
                    ? Services.map((result) => {
                        return <option value={result}>{result}</option>;
                      })
                    : category == "Education"
                    ? Education.map((result) => {
                        return <option>{result}</option>;
                      })
                    : category == "Jobs"
                    ? Jobs.map((result) => {
                        return <option>{result}</option>;
                      })
                    : null}
                </datalist>
              </div>
            ) : null}
            {categorysubCategoryFlag ? (
              <div className="col-lg-4 col-sm-12">
                <label style={{ marginBottom: "0px", marginTop: "8px" }}>
                  SubCategory
                </label>
                <input
                  className="inpstyle"
                  placeholder="Sub Category"
                  type="text"
                  list="subcategoryType"
                  style={{ width: "100%", zIndex: "-50px" }}
                  onChange={(e) => {
                    setsubCategoryType(e.target.value);
                    setExtraFeildToggle(false);
                  }}
                />
                <datalist id="subcategoryType">
                  {subcategoryType1.map((result) => {
                    return <option value={result}>{result}</option>;
                  })}
                </datalist>
              </div>
            ) : null}
            {subCategoryType == "Residential" ? (
              <div className="col-lg-4 col-sm-12">
                <label style={{ marginBottom: "0px", marginTop: "8px" }}>
                  Type
                </label>

                <input
                  className="inpstyle"
                  placeholder="subcategoryRealEstateBuy"
                  type="text"
                  list="subcategoryRealEstateBuy"
                  style={{ width: "100%", zIndex: "-50px" }}
                  onChange={(e) => {
                    setsubCategoryValue(e.target.value);
                  }}
                />
                <datalist id="subcategoryRealEstateBuy">
                  {subcategoryRealEstateBuy.map((result) => {
                    return <option value={result}>{result}</option>;
                  })}
                </datalist>
              </div>
            ) : subCategoryType == "Commercial" ? (
              <div className="col-lg-4 col-sm-12">
                <label style={{ marginBottom: "0px", marginTop: "15px" }}>
                  Type
                </label>

                <input
                  className="inpstyle"
                  placeholder="subcategoryRealEstateRent"
                  type="text"
                  list="subcategoryRealEstateRent"
                  style={{ width: "100%", zIndex: "-50px" }}
                  onChange={(e) => {
                    setsubCategoryValue(e.target.value);
                    SetFinalObjsub({});
                  }}
                />
                {/* The datalist elements provide the options for the category and sub-category input fields, which are populated based on the predefined constants imported from the env.js file. */}
                <datalist id="subcategoryRealEstateRent">
                  {subcategoryRealEstateRent.map((result) => {
                    return <option value={result}>{result}</option>;
                  })}
                </datalist>
              </div>
            ) : subCategoryType == "Residential" ? (
              <div className="col-lg-4 col-sm-12">
                <label>Enter Here</label>

                <input
                  className="inpstyle"
                  placeholder="subcategoryRealEstateRent"
                  type="text"
                  list="Others"
                  style={{ padding: "12px", width: "100%", zIndex: "-50px" }}
                  onChange={(e) => {
                    setsubCategoryValue(e.target.value);
                    SetFinalObjsub({});
                  }}
                />
              </div>
            ) : null}

            {subCategoryValue && obj && extraFeildToggle
              ? Object?.keys(obj)?.map((key) => {
                  return (
                    <Col
                      className="d-inline-flex justify-content-center gx-3 col-sm-6 col-md-4 col-lg-6"
                      span={12}
                      style={{ marginTop: "15px" }}
                    >
                      {/* The price range input fields are implemented using the HolaAutoCompletePrice component, which is a custom component. */}
                      <HolaAutoComplete
                        style={{
                          width: 300,
                        }}
                        options={obj[key]}
                        placeholder={key}
                        autoFocus={true}
                        onChange={(e) => {
                          SetFinalObjsub({
                            ...finalObjSub,
                            [key]: e,
                          });
                          // finalObjSub[key] = e;
                          // SetFinalObjsub(finalObjSub);
                        }}
                        filterOption={(inputValue, option) =>
                          option.value
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                      />
                    </Col>
                  );
                })
              : null}
            <button
              onClick={() => {
                submitMethod();
              }}
              style={isMobile ? mobileStyle : desktopStyle}
            >
              Searchq
            </button>
          </div>
          <div>
            {isContentVisible && (
              <div>
                <ShowProduct style={{ margin: "10px 50px 10px 50px" }} />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

// : category === "Jobs"
//                     {/* ? Jobs.map((result) => { */}
//                     return <option value={result}>{result}</option>;
//                   })
