// // import { ContactMailOutlined } from '@mui/icons-material';
// import React, { useEffect, useState } from "react";
// import Login from "../Login/Login";
// import { useParams, useLocation } from "react-router";
// // import { Link } from 'react-router-dom';
// import { TbHeartMinus } from "react-icons/tb";
// import { useNavigate } from "react-router";
// import { url } from "../../env";
// import { decrypt, encrypt } from "../../Base/encryptDecrypt/encryptDecrypt";
// import Spiner from "../../Spiner";
// import { useDispatch, useSelector } from "react-redux";
// import { add } from "../../../store/Track/trackUserSlice";
// import { Link, NavLink } from "react-router-dom";
// import YoutubeMagic1 from "../../ContentLoader/YoutubeMagic1";
// import { curentValue, remove } from "../../../store/wishlistSlice";
// import { Tooltip } from "antd";
// import { isMobile } from "react-device-detect";


// import {
//   BsBuilding,
//   BsFillBookmarkFill,
//   BsFillCheckSquareFill,
//   BsFillTagFill,
//   BsGeoAltFill,
//   BsNewspaper,
// } from "react-icons/bs";
// import DynamicFont from "react-dynamic-font";
// import NoDataFound from "../../datanotfound/NoDataFound";
// const Wishlist = (props) => {
//   const mob = {
//     width: "auto",
//     marginRight: "16px",
//     marginLeft: "16px",
//   };
//   const desk = {
//     marginRight: "0.5rem",
//     marginLeft: "0.5rem",
 

//   };
//   // const[product,setProduct]=useState();
//   var [wishlistmain, setWishlist] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [isloading, setIsLoading] = useState(true);
//   const [temp, setTemp] = useState(true);
//   const wishlistData = useSelector((state) => state.wishlistSlice);
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const { id } = useParams();
//   const idValue = useParams().id;
//   const location = useLocation();

//   useEffect(() => {
//     if (localStorage.getItem("access_token") != null) {
//       if (localStorage.getItem("wishlist") == null) {
//         var wishlist = [];
//         if (idValue != undefined) {
//           wishlist.push(idValue);
//         }
//         encrypt({
//           key: "wishlist",
//           value: JSON.stringify([...new Set(wishlist)]),
//         });
//         //  localStorage.setItem("wishlist",JSON.stringify([...new Set(wishlist)]))
//       } else {
//         let newArray = [];
//         var storedWishlist = JSON.parse(decrypt("wishlist"));
//         storedWishlist.map((result) => {
//           if (result != null) {
//             newArray.push(result);
//           }
//         });
//         if (idValue != undefined) {
//           newArray.push(idValue);
//         }

//         // storedWishlist.push(useParams().id)
//         encrypt({
//           key: "wishlist",
//           value: JSON.stringify([...new Set(newArray)]),
//         });
//         // localStorage.setItem("wishlist",JSON.stringify([...new Set(newArray)]))
//       }
//       if (localStorage.getItem("access_token") != null) {
//         let barererToken = "Bearer " + localStorage.getItem("access_token");
//         var myHeaders = new Headers();
//         myHeaders.append("Authorization", barererToken);
//         var formdata = new FormData();
//         formdata.append("wishlist", wishlistData?.data);

//         var requestOptions = {
//           method: "POST",
//           headers: myHeaders,
//           body: formdata,
//           redirect: "follow",
//         };

//         fetch(url + "api/user/wishlist/", requestOptions)
//           .then((response) => response.json())
//           .then((result) => {
//             setLoading(true);
//             setWishlist(result);
//           })

//           .catch((error) => {
//             setLoading(false);
//           });
//       }
//     }
//     setTemp(false);
//   }, [temp, wishlistData?.data]);

//   useEffect(() => {
//     dispatch(add({ view: ["Wishlist"] }));
//   }, []);

//   useEffect(() => {
//     const t = setTimeout(() => {
//       setIsLoading(false);
//     }, 3000);
//     return () => {
//       clearTimeout(t);
//     };
//   }, []);
//   document.title = "Hola9 - YourWishlisht";

//   return (
//     <>
//       {isloading ? (
//         <YoutubeMagic1 />
//       ) : (
//         <div>
//           {wishlistmain?.length == 0 ? (
//             <div class=" mt-1" role="alert">
//               <NoDataFound />
//             </div>
//           ) : (
//             !loading && <Spiner />
//           )}
//           {loading && (
//             <div className="row mt-2"  style={isMobile ? mob : desk}>
//             <h3>Your Interests</h3>
//               {localStorage.getItem("access_token") ? (
//                 <div class="row mx-1 " >
//                   {wishlistmain
//                     ?.sort((a, b) => b.pk - a.pk)
//                     .map((product, index) =>
//                       // products.map((product, index) => (


                      
//                       true ? (
//                         <div
//                           class="col-lg-4 col-sm-12"
                        
//                         >
//                           {/* <div className='col-sm-12 col-xs-12'> */}
//                           <NavLink to={`/ads-listing/${product.pk}/`}>
//                             <div
//                               className="product-card mt-3 "
//                               style={{
//                                 borderRadius: "3px",
//                                 boxShadow: "1px 2px 6px 3px gray",
//                               }}
//                             >
//                               <div className="product-media ">
//                                 <div
//                                   className="product-img"
//                                   style={{ weight: "500px", backgroundColor: "grey" }}
//                                 >
//                                   <img
//                                     src={
//                                       !product.fields.image
//                                         ? "https://th.bing.com/th/id/OIP.aV3_1sg9QEdADlu5byNWbwAAAA?pid=ImgDet&rs=1"
//                                         : product.fields.image
//                                     }
//                                     alt="Product Ima"
//                                     style={{
//                                       height: "165px",
//                                       width: "350px",
//                                     }}
//                                   />
//                                 </div>

//                                 <div className="product-type">
//                                   <span className="flat-badge booking">
//                                     {product.fields.is_featured ? (
//                                       <span className="flat-badge booking">
//                                         Premium
//                                       </span>
//                                     ) : null}
//                                   </span>
//                                 </div>

//                                 <ul className="product-action">
//                                   <li className="view">
//                                     <i
//                                       className="fas fa-eye"
//                                       style={{ color: "white" }}
//                                     />
//                                     <span style={{ color: "white" }}>
//                                       {product.fields.viewsproduct}
//                                     </span>
//                                   </li>
//                                   <li className="rating">
//                                     <i
//                                       className=""
//                                       style={{ color: "white" }}
//                                     />
//                                     <span style={{ color: "white" }}>
//                                       <BsNewspaper />
//                                       &nbsp; {product?.fields.subCategoryValue?.length > 12
//                                         ? product?.fields.subCategoryValue?.slice(
//                                             0,
//                                             12
//                                           ) + "..."
//                                         : product?.fields.subCategoryValue}
//                                     </span>
//                                   </li>
//                                 </ul>
//                               </div>
//                               <div
//                                 className="product-content"
//                                 style={{ background: "white" }}
//                               >
//                                 <div className="d-flex justify-content-between  ">
//                                   {/* DESKTOP VIEW */}
//                                   <p className="owlFont fs-14 fw-normal">
//                                     <Tooltip
//                                       placement="topLeft"
//                                       title={product.fields.title}
//                                     >
//                                       {product.fields.title.length > 16 ? (
//                                         <p className="owlFont mt-2"  
//                                         style={{
//                                           color: "#01031c",
//                                           textTransform: "uppercase",
//                                           fontWeight: "bold",
//                                         }}>

//                                           {product.fields?.title?.slice(0, 16)}
//                                           {product.fields.title.length > 16
//                                             ? "..."
//                                             : null}
//                                         </p>
//                                       ) : (
//                                         <div>
//                                           <p className="owlFont mt-2"  
//                                           style={{
//                                             color: "#01031c",
//                                             textTransform: "uppercase",
//                                             fontWeight: "bold",
//                                           }}>
//                                             {" "}
//                                             <DynamicFont
//                                               content={product.fields.title}
//                                             />
//                                           </p>
//                                         </div>
//                                       )}
//                                     </Tooltip>
//                                   </p>
//                                   {/* DESKTOP VIEW */}

//                                   {/* <p className="fs-14 fw-normal"><b>{new Date(product.fields.date_created).toGMTString()?.slice(0, 12)}</b></p> */}
                                  
//                                 </div>

//                                 <div
//                                   className="d-flex justify-content-between"
//                                   style={{ marginTop: "-25px" }}
//                                 >
//                                   {product.fields.category == "RealEstate" ? (
//                                     <p className="fs-14">
//                                     <BsFillCheckSquareFill
//                                       className="mr-1"
//                                       style={{ color: "green" }}
//                                     />
//                                     {product.fields.condition?.slice(0, 9)}
//                                   </p>
//                                   ) : product.fields.category ==
//                                     "Jobs" ? null
                                    
//                                     : (
//                                       <p className="fs-14">
//                                       <BsFillCheckSquareFill
//                                         className="mr-1"
//                                         style={{ color: "green" }}
//                                       />
//                                       {product.fields.condition?.slice(0, 9)}
//                                     </p>
//                                   )}
//                                   {product.fields.category == "RealEstate" ? (
//                                  ""
//                                   ) : (
//                                     <p
//                                     className="owlFont fs-14 fw-normal"
//                                     title="Add to cart"
//                                   >
//                                   </p>


//                                   )}
//                                 </div>

//                                 <div
//                                   className="pricelocate d-flex justify-content-between "
//                                   style={{ marginTop: "-10px" }}
//                                 >
//                                   <p className="autoFont fs-14">
//                                   <BsGeoAltFill
//                                   className="mr-1"
//                                   style={{ color: "red" }}
//                                 />
//                                     <Tooltip
//                                       placement="topLeft"
//                                       title={product.locality}
//                                     >
//                                       <DynamicFont
//                                         content={product.fields.City?.slice(0,8)+"..."}
//                                       />
//                                     </Tooltip>
//                                   </p>
//                                   {product.fields.category == "Jobs" ? null : (
//                                     <p className="fs-14 ">
//                                       <strong> ₹{product.fields.price}</strong>
//                                     </p>
//                                   )}
//                                 </div>


//                                 <div
//                                   className="product-btn"
//                                   style={{ marginRight: "15px" }}
//                                 >
//                                   <button
//                                     onClick={(e) => {
//                                       e.stopPropagation();
//                                       e.preventDefault();
//                                       dispatch(
//                                         remove({
//                                           value: Number(e.target.value),
//                                         })
//                                       );
//                                     }}
//                                     value={product.pk}
//                                     className="btn-sm w-100 text-white mb-2 fs-10"
//                                     style={{
//                                       background:
//                                         "linear-gradient(60deg,#0f1c54,#2b224c)",
//                                     }}
//                                   >
//                                     Remove
//                                   </button>
//                                 </div>
//                               </div>
//                             </div>
//                           </NavLink>
//                         </div>
//                       ) : null
//                     )}
//                 </div>
//               ) : (
//                 <div>
//                   <Login />
//                 </div>
//               )}

//             </div>
//           )}
//         </div>
//       )}
//       {/* <button type="button" class="btn btn-secondary" >View More</button> */}
//     </>
//   );
// };

// export default Wishlist;


















import React, { useEffect, useState } from "react";
import Login from "../Login/Login";
import { useParams, useLocation } from "react-router";
import { TbHeartMinus } from "react-icons/tb";
import { useNavigate } from "react-router";
import { url } from "../../env";
import { decrypt, encrypt } from "../../Base/encryptDecrypt/encryptDecrypt";
import Spiner from "../../Spiner";
import { useDispatch, useSelector } from "react-redux";
import { add } from "../../../store/Track/trackUserSlice";
import { Link, NavLink } from "react-router-dom";
import YoutubeMagic1 from "../../ContentLoader/YoutubeMagic1";
import { curentValue, remove } from "../../../store/wishlistSlice";
import { Tooltip } from "antd";
import { isMobile } from "react-device-detect";
import {
  BsBuilding,
  BsFillBookmarkFill,
  BsFillCheckSquareFill,
  BsFillTagFill,
  BsGeoAltFill,
  BsNewspaper,
} from "react-icons/bs";
import DynamicFont from "react-dynamic-font";
import NoDataFound from "../../datanotfound/NoDataFound";
// import { useNavigate } from "react-router-dom";

const Wishlist = (props) => {
  const mob = {
    width: "auto",
    marginRight: "16px",
    marginLeft: "16px",
  };
  const desk = {
    marginRight: "0.5rem",
    marginLeft: "0.5rem",
  };

  var [wishlistmain, setWishlist] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isloading, setIsLoading] = useState(true);
  const [temp, setTemp] = useState(true);
  const [revealAllCards, setRevealAllCards] = useState(false);
  // const [showViewMore, setShowViewMore] = useState(true);
  const [showViewMore, setShowViewMore] = useState(false);
  // const navigate = useNavigate();


  const wishlistData = useSelector((state) => state.wishlistSlice);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const idValue = useParams().id;
  const location = useLocation();
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (localStorage.getItem("access_token")) {
          const barererToken = "Bearer " + localStorage.getItem("access_token");
          const myHeaders = new Headers();
          myHeaders.append("Authorization", barererToken);
  
          const formdata = new FormData();
          formdata.append("wishlist", wishlistData?.data);
  
          const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
          };
  
          const response = await fetch(url + "api/user/wishlist/", requestOptions);
          const result = await response.json();
  
          setLoading(true);
          setWishlist(result);
        }
      } catch (error) {
        setLoading(false);
      }
    };
  
    fetchData();
  
    dispatch(add({ view: ["Wishlist"] }));
  
    const timeoutId = setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  
    return () => {
      clearTimeout(timeoutId);
    };
  }, [wishlistData?.data]);

  useEffect(() => {
    dispatch(add({ view: ["Wishlist"] }));
  }, []);

  // useEffect(() => {
  //   const t = setTimeout(() => {
  //     setIsLoading(false);
  //   }, 3000);
  //   return () => {
  //     clearTimeout(t);
  //   };
  // }, []);

  document.title = "Hola9 - YourWishlist";

  // const handleViewMoreClick = () => {
  //   setRevealAllCards(true);
  // };
  const handleViewMoreClick = () => {
    setRevealAllCards(true);
    navigate("/dashboard/wishlist/");
  };

  return (
    <>
      {isloading ? (
        <YoutubeMagic1 />
      ) : (
        <div style={{ height: "400px", overflowY: 'scroll' }}>
          {wishlistmain?.length == 0 ? (
            <div class=" mt-1" role="alert">
              <NoDataFound />
            </div>
          ) : (
            !loading && <Spiner />
          )}

          {loading && (
            <div className="row mt-2" style={isMobile ? mob : desk}>
              <h3>Your Interests</h3>
              {localStorage.getItem("access_token") ? (
                <div class="row mx-1 ">
                  {wishlistmain
                    ?.sort((a, b) => b.pk - a.pk)
                    .map((product, index) =>
                      // true ? (
                         
                        <div class="col-lg-4 col-sm-12">
                          <NavLink to={`/ads-listing/${product.pk}/`}>
                            <div
                              className="product-card mt-3 "
                              style={{
                                borderRadius: "3px",
                                boxShadow: "1px 2px 6px 3px gray",
                              }}
                            >
                              <div className="product-media ">
                                <div
                                  className="product-img"
                                  style={{ weight: "500px", backgroundColor: "grey" }}
                                >
                                  <img
                                    src={
                                      !product.fields.image
                                        ? "https://th.bing.com/th/id/OIP.aV3_1sg9QEdADlu5byNWbwAAAA?pid=ImgDet&rs=1"
                                        : product.fields.image
                                    }
                                    alt="Product Ima"
                                    style={{
                                      height: "165px",
                                      width: "350px",
                                    }}
                                  />
                                </div>

                                <div className="product-type">
                                  <span className="flat-badge booking">
                                    {product.fields.is_featured ? (
                                      <span className="flat-badge booking">
                                        Premium
                                      </span>
                                    ) : null}
                                  </span>
                                </div>

                                <ul className="product-action">
                                  <li className="view">
                                    <i
                                      className="fas fa-eye"
                                      style={{ color: "white" }}
                                    />
                                    <span style={{ color: "white" }}>
                                      {product.fields.viewsproduct}
                                    </span>
                                  </li>
                                  <li className="rating">
                                    <i
                                      className=""
                                      style={{ color: "white" }}
                                    />
                                    <span style={{ color: "white" }}>
                                      <BsNewspaper />
                                      &nbsp; {product?.fields.subCategoryValue?.length > 12
                                        ? product?.fields.subCategoryValue?.slice(
                                            0,
                                            12
                                          ) + "..."
                                        : product?.fields.subCategoryValue}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                              <div
                                className="product-content"
                                style={{ background: "white" }}
                              >
                                <div className="d-flex justify-content-between  ">
                                  <p className="owlFont fs-14 fw-normal">
                                    <Tooltip
                                      placement="topLeft"
                                      title={product.fields.title}
                                    >
                                      {product.fields.title.length > 16 ? (
                                        <p className="owlFont mt-2"  
                                        style={{
                                          color: "#01031c",
                                          textTransform: "uppercase",
                                          fontWeight: "bold",
                                        }}>

                                          {product.fields?.title?.slice(0, 16)}
                                          {product.fields.title.length > 16
                                            ? "..."
                                            : null}
                                        </p>
                                      ) : (
                                        <div>
                                          <p className="owlFont mt-2"  
                                          style={{
                                            color: "#01031c",
                                            textTransform: "uppercase",
                                            fontWeight: "bold",
                                          }}>
                                            {" "}
                                            <DynamicFont
                                              content={product.fields.title}
                                            />
                                          </p>
                                        </div>
                                      )}
                                    </Tooltip>
                                  </p>
                                </div>

                                <div
                                  className="d-flex justify-content-between"
                                  style={{ marginTop: "-25px" }}
                                >
                                  {product.fields.category == "RealEstate" ? (
                                    <p className="fs-14">
                                    <BsFillCheckSquareFill
                                      className="mr-1"
                                      style={{ color: "green" }}
                                    />
                                    {product.fields.condition?.slice(0, 9)}
                                  </p>
                                  ) : product.fields.category ==
                                    "Jobs" ? null
                                    
                                    : (
                                      <p className="fs-14">
                                      <BsFillCheckSquareFill
                                        className="mr-1"
                                        style={{ color: "green" }}
                                      />
                                      {product.fields.condition?.slice(0, 9)}
                                    </p>
                                  )}
                                  {product.fields.category == "RealEstate" ? (
                                 ""
                                  ) : (
                                    <p
                                    className="owlFont fs-14 fw-normal"
                                    title="Add to cart"
                                  >
                                  </p>


                                  )}
                                </div>

                                <div
                                  className="pricelocate d-flex justify-content-between "
                                  style={{ marginTop: "-10px" }}
                                >
                                  <p className="autoFont fs-14">
                                  <BsGeoAltFill
                                  className="mr-1"
                                  style={{ color: "red" }}
                                />
                                    <Tooltip
                                      placement="topLeft"
                                      title={product.locality}
                                    >
                                      <DynamicFont
                                        content={product.fields.City?.slice(0,8)+"..."}
                                      />
                                    </Tooltip>
                                  </p>
                                  {product.fields.category == "Jobs" ? null : (
                                    <p className="fs-14 ">
                                      <strong> ₹{product.fields.price}</strong>
                                    </p>
                                  )}
                                </div>


                                <div
                                  className="product-btn"
                                  style={{ marginRight: "15px" }}
                                >
                                  <button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      e.preventDefault();
                                      dispatch(
                                        remove({
                                          value: Number(e.target.value),
                                        })
                                      );
                                    }}
                                    value={product.pk}
                                    className="btn-sm w-100 text-white mb-2 fs-10"
                                    style={{
                                      background:
                                        "linear-gradient(60deg,#0f1c54,#2b224c)",
                                    }}
                                  >
                                    Remove
                                  </button>
                                </div>
                              </div>
                            </div>
                          </NavLink>
                        </div>
                   
                    )}
                </div>
              ) : (
                <div>
                  <Login />
                </div>
              )}
            </div>
          )}

          {revealAllCards && (
            <div className="row mt-2" style={isMobile ? mob : desk}>
              {/* Render all cards here */}
            </div>
          )}

          {/* {showViewMore && ( */}
          {showViewMore && wishlistmain?.length > 3 && (
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleViewMoreClick}
              style={{marginLeft:"230px" , width:"350px"}}
            >
              View More
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default Wishlist;

