import React from 'react'
import "./AboveFooter.css"
import TrendingAds from '../Ads/Trending Ads/TrendingAds'
import { isMobile } from 'react-device-detect'
import { Link } from 'react-router-dom'

const mobstyle = {
    width: "auto"
}
const deskstyle = {
    marginLeft: "11.5rem",
    marginRight: "11.5rem"
}
const AboveFooter = () => {
    return (
        <div>
            <div style={isMobile ? mobstyle : deskstyle}>
                <TrendingAds />
            </div>
            <div className="new-banner">
            <div className="content-wrapper">
              <div className="free-tag">Free</div>
              <div className="banner-content">
                <h1 className="title">POST YOUR ADS FOR FREE</h1>
              </div>
              <div>
                <Link to="/add-product/"><button className="clicklink">Post Ads</button></Link>
              </div>
            </div>
          </div>
        </div>
    )
}
export default AboveFooter
