import React, { useContext } from "react";

import Messages from "./Messages";
import Input from "./Input";
import { ChatContext } from "../context/ChatContext";
import { BsFillTelephoneFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import Loader from "../../../../../Loader/Loader";

const Chat = ({ loading }) => {
  const { data } = useContext(ChatContext);
  const uuid = useSelector((state) => state.ChatSlice);
  const photoURL = data.user?.photoURL;
  const name = data.user?.displayName;
  return loading ? (
    <div className="chat h-100 w-60">
      <div className="chatInfo w-100" style={{ height: "45px" }}></div>
      <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100 bg-light">
        <Loader />
      </div>
    </div>
  ) : (
    <div className="chat">
      {data.chatId !== "null" && (
        <>
          <div className="chatInfo">
            <div>
              <img
                className="user-avatar"
                src={
                  Boolean(photoURL)
                    ? photoURL
                    : `https://ui-avatars.com/api/?name=${name}&background=random`
                }
                alt={"No img"}
              />
              <span style={{ fontWeight: "bold" }}>
                {data.user?.displayName}
              </span>
            </div>
            <div className="chatIcons">
              {/* <input type="tel" id="phone" name="phone" /> */}
              <a href={`tel:+91${uuid?.phoneNumber}`}>
                <BsFillTelephoneFill />
              </a>

              {/* <img src={Cam} alt="" /> */}
              {/* <img src={Add} alt="" /> */}
              {/* <img src={More} alt="" /> */}
            </div>
          </div>
          <Messages />
          <Input />
        </>
      )}
    </div>
  );
};

export default Chat;
