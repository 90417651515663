import React, { useContext, useEffect, useRef, useState } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { AuthContext } from "../context/AuthContext";
import { ChatContext } from "../context/ChatContext";
import { db } from "../../../../../firebase";

const Message = ({ message, chatId }) => {
  const { currentUser } = useContext(AuthContext);
  const { data } = useContext(ChatContext);
  const ref = useRef();

  const [time, setTime] = useState("");

  let currentTime = new Date().toLocaleTimeString();

  useEffect(() => {
    // ref.current?.scrollIntoView({ behavior: "smooth" });
    const unixTimestamp = message?.date?.seconds; // March 24, 2021 10:00:00 AM GMT+0
    const date = new Date(unixTimestamp * 1000);
    const istTime = date.toLocaleString("en-IN", { timeZone: "Asia/Kolkata" });
    setTime(istTime.split(","));
  }, [message]);

  return (
    <div
      ref={ref}
      className={`message ${message.senderId === currentUser.uid && "owner"}`}
    >
      <div className="messageInfo">
        <img
          src={
            message.senderId === currentUser.uid
              ? Boolean(currentUser.photoURL)
                ? currentUser.photoURL
                : `https://ui-avatars.com/api/?name=${currentUser.email}&background=random`
              : Boolean(data.user.photoURL)
              ? data.user.photoURL
              : `https://ui-avatars.com/api/?name=${data.user.displayName}&background=random`
          }
          alt=""
        />

        {/* <span>just now</span> */}
      </div>
      <div className="messageContent">
        {message.text !== "" && (
          <p
            style={{
              maxWidth: "100%",
            }}
          >
            {message.text}
            <sub>{time[1]?.slice(0, 5) + "  " + time[1]?.slice(-2)}</sub>
          </p>
        )}
        {message.imageUrl && (
          <>
            <img src={message.imageUrl} alt="" />
            <sub>{time[1]?.slice(0, 5) + "  " + time[1]?.slice(-2)}</sub>
          </>
        )}
      </div>
    </div>
  );
};

export default Message;
