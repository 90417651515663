// import React from "react";
// import "./Footer.css";
// import Hola9logo from "../images/Hola9-logo.jpg";
// import appstore from "../images/app-store.png";
// import googleplay from "../images/google-play.png";
// import { BsFacebook } from "react-icons/bs";
// import { BsInstagram } from "react-icons/bs";
// import { BsLinkedin } from "react-icons/bs";
// import MessengerCustomerChat from "react-messenger-customer-chat/lib/MessengerCustomerChat";
// import { Link } from "react-router-dom";
// import { isMobile } from "react-device-detect";
// // import Bottom from '../Bottom';

// const mobfoot = {
//   marginTop: "-70px",
// };
// const deskfoot = {

// };
// const mob = {

// };
// const desk = {
//   paddingTop:"0"
// };

// export const Footer = () => {
//   return (
//     <>
//       <footer id="footer" className="footer" style={{paddingTop:"-70px"}}>
//         <div className="container" style={isMobile ? mob : desk}>
//           <div className="footer__top pt-0" >
//             <div style={isMobile ? mobfoot : deskfoot}>
//               <div className="col-lg-5 mt-2" style={{ paddingTop: "30px" }}>
//                 <div className="footer__top__info">
//                   <Link title="Logo" to="/" className="footer__top__info__logo">
//                     <img src={Hola9logo} alt="Golo" />
//                   </Link>
//                   <p
//                     className="footer__top__info__desc"
//                     style={{ color: "black", marginTop: "-30px" }}
//                   >
//                     #12 Second Floor 3rd Cross <br />
//                     Patel Narayana Reddy Layout
//                     <br />
//                     6th Block Koramangala
//                     <br />
//                     Bengaluru -560095
//                   </p>
//                   <div className="footer__top__info__app">
//                     <Link
//                       title="App Store"
//                       to="/updatingSoon"
//                       className="banner-apps__download__iphone"
//                     >
//                       <img src={appstore} alt="App Store" />
//                     </Link>
//                     <Link
//                       title="Google Play"
//                       to="/updatingSoon"
//                       className="banner-apps__download__android"
//                     >
//                       <img src={googleplay} alt="Google Play" />
//                     </Link>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-lg-2 mt-2 d-flex d-lg-none hidden-md visible-xs-block visible-sm-block d-flex">
//                 <aside className="footer__top__nav">
//                   <ul className="dropdown">
//                     <h3 className="footerFont" style={{ marginLeft: "-10px" }}>
//                       My Account
//                     </h3>
//                     <li>
//                       <Link
//                         to="/signup/"
//                         className="footerFont"
//                         title="  Signup"
//                       >
//                         {" "}
//                         Signup
//                       </Link>
//                     </li>
//                     <li>
//                       <Link to="/login/" className="footerFont" title="Login">
//                         {" "}
//                         Login
//                       </Link>
//                     </li>
//                     {/* <li>
//                       <Link to="/faqs/" className="footerFont">
//                         Faq
//                       </Link>
//                     </li> */}
//                     <li>
//                       <Link
//                         title="Contact Us"
//                         to="/contact/"
//                         className="footerFont"
//                       >
//                         Contact Us
//                       </Link>
//                     </li>
//                     <li>
//                       <Link
//                         title="About Us"
//                         to="/aboutUs/"
//                         className="footerFont"
//                       >
//                         About Us
//                       </Link>
//                     </li>
//                   </ul>
//                 </aside>
//                 <aside className="footer__top__nav ml-5">
//                   <h3 className="footerFont">Helpful Links</h3>
//                   <ul>
//                     {/* <li>
//                       <Link
//                         title="About Us"
//                         to="/refund/"
//                         className="footerFont"
//                       >
//                         Refund Policy
//                       </Link>
//                     </li> */}
//                     <li>
//                       <Link
//                         title=" Listing Policy"
//                         to="/listingPolicy/"
//                         className="footerFont"
//                       >
//                         Listing Policy
//                       </Link>
//                     </li>
//                     <li>
//                       <Link
//                         title=" Copyright Policy"
//                         to="/copyrightPolicy/"
//                         className="footerFont"
//                       >
//                         Copyright Policy
//                       </Link>
//                     </li>
//                     <li>
//                       <Link
//                         title="  Privacy Policy"
//                         to="/privacyPolicy/"
//                         className="footerFont"
//                       >
//                         Privacy Policy
//                       </Link>
//                     </li>
//                     <li>
//                       <Link
//                         title=" Terms's & Conditions"
//                         to="/terms-of-service/"
//                         className="footerFont"
//                       >
//                         Terms's & Conditions
//                       </Link>
//                     </li>
//                     <li>
//                       <Link
//                         title="Blogs"
//                         to="/blogs-listing/"
//                         className="footerFont"
//                       >
//                         Blogs
//                       </Link>
//                     </li>
//                     <li>
//                       <Link title="Blogs" to="/pricing/" className="footerFont">
//                         Pricing
//                       </Link>
//                     </li>
//                   </ul>
//                 </aside>
//               </div>
//               <div
//                 className="col-lg-2 mt-2  hidden-sm hidden-xs visible-md-block visible-lg-block"
//                 style={{ paddingTop: "30px" }}
//               >
//                 <aside className="footer__top__nav">
//                   <ul className="dropdown">
//                     <h3 className="footerFont">My Account</h3>
//                     <li>
//                       <Link
//                         to="/signup/"
//                         className="footerFont"
//                         title="  Signup"
//                       >
//                         {" "}
//                         Signup
//                       </Link>
//                     </li>
//                     <li>
//                       <Link to="/login/" className="footerFont" title="Login">
//                         {" "}
//                         Login
//                       </Link>
//                     </li>
//                     {/* <li>
//                       <Link to="/faqs/" className="footerFont" title="FAQ's">
//                         Faq
//                       </Link>
//                     </li> */}
//                     <li>
//                       <Link
//                         title="Contact Us"
//                         to="/contact/"
//                         className="footerFont"
//                       >
//                         Contact Us
//                       </Link>
//                     </li>
//                     <li>
//                       <Link
//                         title="About Us"
//                         to="/aboutUs/"
//                         className="footerFont"
//                       >
//                         About Us
//                       </Link>
//                     </li>
//                   </ul>
//                 </aside>
//               </div>
//               <div
//                 className="col-lg-2 mt-2  hidden-sm hidden-xs visible-md-block visible-lg-block"
//                 style={{ paddingTop: "30px" }}
//               >
//                 <aside className="footer__top__nav">
//                   <h3 className="footerFont">Helpful Links</h3>
//                   <ul>
//                     {/* <li>
//                       <Link
//                         title="About Us"
//                         to="/refundPolicy/"
//                         className="footerFont"
//                       >
//                         Refund Policy
//                       </Link>
//                     </li> */}
//                     <li>
//                       <Link
//                         title=" Listing Policy"
//                         to="/listingPolicy/"
//                         className="footerFont"
//                       >
//                         Listing Policy
//                       </Link>
//                     </li>
//                     <li>
//                       <Link
//                         title=" Copyright Policy"
//                         to="/copyrightPolicy/"
//                         className="footerFont"
//                       >
//                         Copyright Policy
//                       </Link>
//                     </li>
//                     <li>
//                       <Link
//                         title="  Privacy Policy"
//                         to="/privacyPolicy/"
//                         className="footerFont"
//                       >
//                         Privacy Policy
//                       </Link>
//                     </li>
//                     <li>
//                       <Link
//                         title=" Terms's & Conditions"
//                         to="/terms-of-service/"
//                         className="footerFont"
//                       >
//                         Terms & Condition
//                       </Link>
//                     </li>
//                     <li>
//                       <Link
//                         title="Blogs"
//                         to="/blogs-listing/"
//                         className="footerFont"
//                       >
//                         Blogs
//                       </Link>
//                     </li>
//                   </ul>
//                 </aside>
//               </div>
//               <div
//                 className="col-lg-3 mt-2 visible-md visible-lg-block"
//                 style={{ paddingTop: "30px" }}
//               >
//                 <aside className="footer__top__nav footer__top__nav--contact">
//                   <h3 className="footerFont">Security</h3>
//                   <p className="footerFont">
//                     {" "}
//                     <b>Email </b>: hr@hola9.com
//                   </p>
//                   <p className="footerFont">
//                     <b>Mobile No </b>: +919606451628
//                   </p>
//                   <ul>
//                     <li className="facebook">
//                       <a
//                         title="Facebook"
//                         target="_blank"
//                         href="https://www.facebook.com/Hola9-Technologies-103386172328157"
//                       >
//                         <BsFacebook
//                           style={{
//                             fontSize: "30px",
//                             color: "#3b5998",
//                             margin: "5px",
//                           }}
//                         />
//                       </a>
//                     </li>
//                     <li className="twitter">
//                       <a
//                         title="Linkdin"
//                         target="_blank"
//                         href="https://www.linkedin.com/company/hola9-classifieds-india-private-limited/mycompany/"
//                       >
//                         <BsLinkedin
//                           style={{
//                             fontSize: "30px",
//                             color: "#0077b5",
//                             margin: "5px",
//                           }}
//                         />
//                       </a>
//                     </li>
//                     <li className="instagram">
//                       <a
//                         title="Instagram"
//                         target="_blank"
//                         href="https://www.instagram.com/hola9.official/"
//                       >
//                         <BsInstagram
//                           style={{
//                             fontSize: "30px",
//                             color: "#bc2a8d",
//                             margin: "5px",
//                           }}
//                         />
//                       </a>
//                     </li>
//                   </ul>
//                 </aside>
//               </div>
//             </div>
//           </div>
//           {/* .top-footer */}

//           {/* .top-footer */}
//         </div>
//         <div className="footer__bottom mt-4">
//             <p className="footer__bottom__copyright ">
//               <Link title="Hola 9" to="/">
//                 2022 © Hola9 Classifieds India Private Limited
//               </Link>
//               . All rights reserved.
//             </p>
//           </div>
//       </footer>
//       {/* <Bottom/> */}
//     </>
//   );
// };

// export default Footer;

// Footer.js

// Footer.js

import React from "react";
import styled from "styled-components";
import "./Footer.css";
import appstore from "../images/app-store.png";
import googleplay from "../images/google-play.png";
import { isMobile } from "react-device-detect";


const FooterContainer = styled.footer`
  background: linear-gradient(to right, #0f1c54,#2b224c);
  padding: 20px 0;
`;


const DividerLine = styled.div`
  width: 90%;
  height: 1px;
  background-color: #fff;
  marginleft: 5%;
  marginright: 5%;
 
`;
const DividerLine1 = styled.div`
  height: 1px;
  width: 70%;
  background-color: gray;
  margin-bottom: 10px;
`;


const FooterText = styled.div`
  text-align: center;
  background-color: gray;
  color: white;
  padding: 10px 0;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1250px;
  margin: 0 auto;
`;

const Column = styled.div`
  flex: 1;
  margin: 10px;
`;

const Heading = styled.h3`
  font-size: 20px;
  margin-bottom: 5px;
   color: whitesmoke;

`;

const List = styled.ul`
  list-style: arrow;
`;

const ListItem = styled.li`
  margin-bottom: 2px;
`;

const Link = styled.a`
  text-decoration: none;
  transition: color 0.3s ease;
  &:hover {
    color: #f0ca4d;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
`;

const Button = styled.button`
  background-color: #f0ca4d;
 color: #03203b ;
  font-weight: bold;
  padding: 4px 10px;
  margin: 5px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #faaa28;
  }
`;
const mobilestyle={
  display: "flex",
  flexDirection: "column",
  margin: "auto 0.5rem"

}
const desktopstyle={
  display: "flex",
flexDirection: "row",


}
const mobilestyle1={
width: "100%"

}
const desktopstyle1={
  width: "18rem"
}
const Footer = () => {
  return (
    <div>
      <FooterContainer>
        <Content>
        <div style={isMobile? mobilestyle :desktopstyle}>
        <div>
          <Column style={isMobile? mobilestyle1 :desktopstyle1}>
            <Heading>Our Quick Links</Heading>
            <DividerLine1 />
            <List>
            {localStorage.getItem("access_token") == null ? (
              <>
              <ListItem>
                <Link
                  href="/signup/"
                  style={{ color: "#fce0b1", fontSize: "18px" }}
                >
                <span className="dfdf"> Sign Up</span>
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  href="/login/"
                  style={{ color: "#fce0b1", fontSize: "18px" }}
                >
                <span className="dfdf">  Login</span>
                </Link>
              </ListItem></>):null}
              <ListItem>
                <Link
                  href="/aboutUs/"
                  style={{ color: "#fce0b1", fontSize: "18px" }}
                >
                <span className="dfdf"> About Us</span>
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  href="/contact/"
                  style={{ color: "#fce0b1", fontSize: "18px" }}
                >
                <span className="dfdf">   Contact Us</span>
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  href="/blogs-listing/"
                  style={{ color: "#fce0b1", fontSize: "18px" }}
                >
                <span className="dfdf">  Blogs</span>
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  href="/career/"
                  style={{ color: "#fce0b1", fontSize: "18px" }}
                >
                <span className="dfdf">  Careers</span>
                </Link>
              </ListItem>
            </List>
          </Column>
          </div>
          <div>
          <Column style={isMobile? mobilestyle1 :desktopstyle1}>
            <Heading>Address Information</Heading>
            <DividerLine1 />
            <address
              style={{ color: "#fce0b1", lineHeight: "25px", fontSize: "15px" }}
            >
              #12 Second Floor 3rd Cross <br />
              Patel Narayana Reddy Layout
              <br />
              6th Block Koramangala
              <br />
              Bengaluru - 560095
              <br></br>
              <b style={{ color: "#fce0b1", fontWeight: "bold" }}>Email </b>:
              hello@hola9.com
              <br></br>
              <b style={{ color: "#fce0b1", fontWeight: "bold" }}>
                Contact No{" "}
              </b>
              : +919606451628
            </address>
          </Column>
          </div>
           <div>
          <Column style={isMobile? mobilestyle1 :desktopstyle1}>
            <Heading>Helpful Links</Heading>
            <DividerLine1 />
            <ButtonsContainer>
            <Link href="/terms-of-service/" style={{ color: "#fce0b1", fontSize: "15px" }}>
            <span className="dfdf">Terms & Conditions</span>
            </Link>
              &nbsp;&nbsp;&nbsp;
              <Link
                href="/listingPolicy/"
                style={{ color: "#fce0b1", fontSize: "15px" }} 
              >
              <span className="dfdf">Listing-Policy</span>
              </Link>
              &nbsp;&nbsp;&nbsp;
              <Link
                href="/copyrightPolicy/"
                style={{ color: "#fce0b1", fontSize: "15px" }}
              >
              <span className="dfdf">Copyright-Policy</span>
              </Link>
              &nbsp;&nbsp;&nbsp;
              <Link
                href="/privacyPolicy/"
                style={{ color: "#fce0b1", fontSize: "15px" }}
              >
              <span className="dfdf">Privacy-Policy</span>
              </Link>
            </ButtonsContainer>
            <br></br>
            <Heading>Social Media Links</Heading>
            <DividerLine1 />
            <Link href=" https://www.facebook.com/Hola9-Technologies-103386172328157" target="_blank">
               <div className="hexagon-wrapper">
                <div className="hexagon">
                <i className="fab fa-facebook" />
                 </div>
                </div>
          </Link>
          &nbsp;
            <Link href="https://www.linkedin.com/company/hola9-classifieds-india-private-limited/mycompany/" target="_blank">
               <div className="hexagon-wrapper">
                <div className="hexagon">
                <i className="fab fa-linkedin" />
                 </div>
                </div>
          </Link>
          &nbsp;
            <Link href="https://www.instagram.com/hola9.official/" target="_blank">
               <div className="hexagon-wrapper">
                <div className="hexagon">
                <i className="fab fa-instagram" />
                 </div>
                </div>
          </Link>
          
          <div className="footer__top__info__app mt-1" style={{width:"16rem"}}>
                 <Link
                      title="App Store"
                      href="/updatingSoon"
                      className=""
                      style={{width:"7.5rem", border: "1px solid gray", borderRadius: "5px"}}
                    >
                      <img src={appstore} alt="App Store" />
                    </Link>
                    &nbsp;
                    <Link
                      title="Google Play"
                      href="/updatingSoon"
                      className="banner-apps__download__android"
                      style={{width:"7.5rem", border: "1px solid gray", borderRadius: "5px"}}
                    >
                      <img src={googleplay} alt="Google Play" />
                    </Link>
                  </div>
             
         
          </Column>
          </div>
          <div>
          <Column style={isMobile? mobilestyle1 :desktopstyle1}>
            <Heading>Our Categories</Heading>
            <DividerLine1 />
            <ButtonsContainer>
              <Link href="/cat/education1">
                <Button>Education</Button>
              </Link>
              <Link href="/cat/Bikes">
                <Button>Bikes</Button>
              </Link>
              <Link href="/cat/Cars">
                <Button>Cars</Button>
              </Link>
              <Link href="/cat/RealEstate">
                <Button>Real Estate</Button>
              </Link>
              <Link href="/cat/electronics">
                {" "}
                <Button>Electronics</Button>
              </Link>
              <Link href="/Cat/Services1">
                <Button>Services</Button>
              </Link>
              <Link href="/cat/furniture">
                <Button>Furniture</Button>
              </Link>
              <Link href="/cat/jobs">
                <Button>Jobs</Button>
              </Link>
              <Link href="/cat/Pets">
                <Button>Pets</Button>
              </Link>
              <Link href="/cat/Mobiles">
                <Button>Mobiles</Button>
              </Link>
            </ButtonsContainer>
          </Column>
          </div>
          </div>
        </Content>
      </FooterContainer>
      <DividerLine />
      <FooterText>2022 © Hola9 Classifieds India Private Limited</FooterText>
    </div>
  );
};

export default Footer;
