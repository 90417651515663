import React from "react";
import MessengerCustomerChat from "react-messenger-customer-chat/lib/MessengerCustomerChat";
import { Link } from "react-router-dom";
import Chatbot from "../../Pages/ChatBot/ChatBot";
import { Divider } from "antd";
import "./FootCat.css";
import { Title } from "../../../component/StyledComponent/StyledComponents";
import { useDispatch } from "react-redux";
import { getExtraField } from "../../../store/ProductFilterSlice";
import {
  Electronics,
  Jobs,
  Furniture,
  Pets,
  Mobiles,
  Services,
  Education,
  Cars,
  Bikes,
} from "../../env";
const Footcat = () => {
  const dispatch = useDispatch();
  const handleClick = (category, subCategoryValue) => {
    let obj = { category, subCategoryValue, extraField: {} };
    dispatch(getExtraField(obj));
  };
  return (
    <>
      <div className="">
        {/* <Chatbot></Chatbot> */}
        <MessengerCustomerChat
          pageId="112411668244304"
          appId="902489581139147"
          themeColor={"#8753F0"}
        />

        <div  className="hidden-sm hidden-xs visible-md-block visible-lg-block" style={{marginTop:'100px', backgroundColor:'red'}}>
          <div className="FootcatBg col-lg-12">
            <span className="heading heading-display">
              <h6>Real Estate &nbsp;</h6>
              <Link
                onClick={() => {
                  handleClick("RealEstate", "Rent-Commercial");
                }}
                to={`/ads-listing/category/${"RealEstate"}/subcategory/${"Rent - Commercial"}`}
              >
                <div className="footcat mx-2"> Rent - Commercial</div>
              </Link>{" "}
              <Divider
                type="vertical"
                style={{
                  backgroundColor: "grey",
                  
                }}
              />
             
              <Link
                onClick={() => {
                  handleClick("RealEstate", "Buy-Commercial");
                }}
                to={`/ads-listing/category/${"RealEstate"}/subcategory/${"Buy - Commercial"}`}
              >
                <div className="footcat">Buy - Commercial</div>
              </Link>
              <Divider
                type="vertical"
                style={{
                  backgroundColor: "grey",
                  
                }}
              />
              
              {/* add two more fields */}
              <Link
                onClick={() => {
                  handleClick("RealEstate", "Rent-Residential");
                }}
                to={`/ads-listing/category/${"RealEstate"}/subcategory/${"Rent - Residential"}`}
              >
                <div className="footcat">Rent - Residential</div>
              </Link>
              <Divider
                type="vertical"
                style={{
                  backgroundColor: "grey",
                  
                }}
              />
              
              <Link
                onClick={() => {
                  handleClick("RealEstate", "Buy-Residential");
                }}
                to={`/ads-listing/category/${"RealEstate"}/subcategory/${"Buy - Residential"}`}
              >
                <div className="footcat">Buy - Residential</div>
              </Link>
              <Divider
                type="vertical"
                style={{
                  backgroundColor: "grey",
                  
                }}
              />
              
              <Link
                onClick={() => {
                  handleClick("RealEstate", "PG-Hostel");
                }}
                to={`/ads-listing/category/${"RealEstate"}/subcategory/${"hostels"}`}
              >
                <div className="footcat">PG-Hostels</div>
              </Link>{" "}
            </span>
          </div>


          <div className="FootcatBg col-lg-12">
            <span className="heading heading-display">
              <h6>Electronics &nbsp;</h6>
              
              
              {Electronics.map((value, index) => (
                <>
                  <Link
                    key={index}
                    onClick={() => {
                      handleClick("Electronics", value);
                    }}
                    to={`/ads-listing/category/${"Electronics"}/subcategory/${value}`}
                  >
                    <div className="footcat mx-1">{value}</div>
                  </Link>
                  {index < Electronics.length - 1 && (
          <Divider
            type="vertical"
            style={{
              backgroundColor: "grey",
              margin:'0'
            }}
          />
        )}
                </>
              ))}
            </span>
          </div>

          
          <div className="FootcatBg col-lg-12">
            <span className="heading heading-display">
              <h6>Furnitures &nbsp;</h6>
              {Furniture.map((value, index) => (
                <>
                  <Link
                    key={index}
                    onClick={() => {
                      handleClick("Furniture", value);
                    }}
                    to={`/ads-listing/category/${"Furniture"}/subcategory/${value}`}
                  >
                    <div className="footcat mx-2">{value}</div>
                  </Link>
                  {index < Furniture.length - 1 && (
          <Divider
            type="vertical"
            style={{
              backgroundColor: "grey",
              margin:'0'
            }}
          />
        )}
                </>
              ))}
            </span>
          </div>

          <div className="FootcatBg col-lg-12">
            <span className="heading heading-display">
              <h6>Mobiles &nbsp;</h6>
              {Mobiles.map((value, index) => (
                <>
                  <Link
                    key={index}
                    onClick={() => {
                      handleClick("Mobiles", value);
                    }}
                    to={`/ads-listing/category/${"Mobiles"}/subcategory/${value}`}
                  >
                    <div className="footcat mx-2">{value}</div>
                  </Link>
                  {index < Mobiles.length - 1 && (
          <Divider
            type="vertical"
            style={{
              backgroundColor: "grey",
              margin:'0'
            }}
          />
        )}
                </>
              ))}
            </span>
          </div>

          <div className="FootcatBg col-lg-12">
            <span className="heading heading-display">
              <h6>Pets &nbsp;</h6>
              {Pets.map((value, index) => (
                <>
                  <Link
                    key={index}
                    onClick={() => {
                      handleClick("Pets", value);
                    }}
                    to={`/ads-listing/category/${"Pets"}/subcategory/${value}`}
                  >
                    <div className="footcat mx-2">{value}</div>
                  </Link>
                  {index < Pets.length - 1 && (
          <Divider
            type="vertical"
            style={{
              backgroundColor: "grey",
              margin:'0'
            }}
          />
        )}
                </>
              ))}
            </span>
          </div>
          {/* <div className="FootcatBg col-lg-12">
            <span className="heading heading-display">
              Jobs
              <Divider
                type="vertical"
                style={{
                  backgroundColor: "grey",
                  margin:'0'
                  
                  marginLeft: "60px",
                }}
              />
              {Jobs.map((value, index) => (
                <>
                  <Link
                    key={index}
                    onClick={() => {
                      handleClick("Jobs", value);
                    }}
                    to={`/ads-listing/category/${"Jobs"}/subcategory/${value}`}
                  >
                    <div className="footcat mx-2">{value}</div>
                  </Link>
                </>
              ))}
            </span>
          </div> */}

          <div className="FootcatBg col-lg-12">
            <span className="heading heading-display">
              <h6>Bikes &nbsp;</h6>
              {Bikes.map((value, index) => (
                <>
                  <Link
                    key={index}
                    onClick={() => {
                      handleClick("Bikes", value);
                    }}
                    to={`/ads-listing/category/${"Bikes"}/subcategory/${value}`}
                  >
                    <div className="footcat mx-2">{value}</div>
                  </Link>
                  {index < Bikes.length - 1 && (
          <Divider
            type="vertical"
            style={{
              backgroundColor: "grey",
              margin:'0'
            }}
          />
        )}
                </>
              ))}
            </span>
          </div>

          <div className="FootcatBg col-lg-12">
            <span className="heading heading-display">
              <h6>Cars &nbsp;</h6>
              {Cars.map((value, index) => (
                <>
                  <Link
                    key={index}
                    onClick={() => {
                      handleClick("Cars", value);
                    }}
                    to={`/ads-listing/category/${"Cars"}/subcategory/${value}`}
                  >
                    <div className="footcat mx-2">{value}</div>
                  </Link>
                  {index < Cars.length - 1 && (
          <Divider
            type="vertical"
            style={{
              backgroundColor: "grey",
              margin:'0'
            }}
          />
        )}
                </>
              ))}
            </span>
          </div>

          <div className="FootcatBg col-lg-12">
            <span className="heading heading-display">
              <h6>Services &nbsp;</h6>
              {Services.map((value, index) => (
                <>
                  <Link
                    key={index}
                    onClick={() => {
                      handleClick("Services", value);
                    }}
                    to={`/ads-listing/category/${"Services"}/subcategory/${value}`}
                  >
                    <div className="footcat mx-2">{value}</div>
                  </Link>
                  {index < Services.length - 1 && (
          <Divider
            type="vertical"
            style={{
              backgroundColor: "grey",
              margin:'0'
            }}
          />
        )}
                </>
              ))}
            </span>
          </div>

          <div className="FootcatBg col-lg-12">
            <span className="heading heading-display">
              <h6>Education &nbsp;</h6>
              {Education.map((value, index) => (
                <>
                  <Link
                    key={index}
                    onClick={() => {
                      handleClick("Education", value);
                    }}
                    to={`/ads-listing/category/${"Education"}/subcategory/${value}`}
                  >
                    <div className="footcat mx-2">{value}</div>
                  </Link>
                  {index < Education.length - 1 && (
          <Divider
            type="vertical"
            style={{
              backgroundColor: "grey",
              margin:'0'
            }}
          />
        )}
                </>
              ))}
            </span>
          </div>

          <div className="FootcatBg col-lg-12">
            <span className="heading heading-display">
              <h6>Jobs &nbsp;</h6>
              {Jobs.map((value, index) => (
                <>
                  <Link
                    key={index}
                    onClick={() => {
                      handleClick("Jobs", value);
                    }}
                    to={`/ads-listing/category/${"Jobs"}/subcategory/${value}`}
                  >
                    <div className="footcat mx-2">{value}</div>
                  </Link>
                  {index < Jobs.length - 1 && (
          <Divider
            type="vertical"
            style={{
              backgroundColor: "grey",
              margin:'0'
            }}
          />
        )}
                </>
              ))}
            </span>
          </div>
        </div>





        
        {/* MOBILE VIEW */}
        <div className="d-lg-none hidden-md visible-xs-block visible-sm-block">
          <div className="FootcatBg col-lg-12 FootcatBg-mob">
            <span className="heading1">
              Real Estate{""}
              &nbsp;&nbsp;

              <Link
                onClick={() => {
                  handleClick("RealEstate", "Rent-Commercial");
                }}
                to={`/ads-listing/category/${"RealEstate"}/subcategory/${"Rent - Commercial"}`}
              >
                <div className="footcat">Rent - Commercial</div>
              </Link>{" "}
          <Divider
            type="vertical"
            style={{
              backgroundColor: "grey",
              margin:'4px',
            }}
          />
              <Link
                onClick={() => {
                  handleClick("RealEstate", "Buy-Commercial");
                }}
                to={`/ads-listing/category/${"RealEstate"}/subcategory/${"Buy - Commercial"}`}
              >
                <div className="footcat">Buy - Commercial</div>
              </Link>
          <Divider
            type="vertical"
            style={{
              backgroundColor: "grey",
              margin:'4px',
            }}
          />
              {/* add two more fields */}
              <Link
                onClick={() => {
                  handleClick("RealEstate", "Rent-Residential");
                }}
                to={`/ads-listing/category/${"RealEstate"}/subcategory/${"Rent - Residential"}`}
              >
                <div className="footcat">Rent - Residential</div>
              </Link>
          <Divider
            type="vertical"
            style={{
              backgroundColor: "grey",
              margin:'4px',
            }}
          />
              <Link
                onClick={() => {
                  handleClick("RealEstate", "Buy-Residential");
                }}
                to={`/ads-listing/category/${"RealEstate"}/subcategory/${"Buy - Residential"}`}
              >
                <div className="footcat">Buy - Residential</div>
              </Link>
          <Divider
            type="vertical"
            style={{
              backgroundColor: "grey",
              margin:'4px',
            }}
          />
              <Link
                onClick={() => {
                  handleClick("RealEstate", "PG-Hostel");
                }}
                to={`/ads-listing/category/${"RealEstate"}/subcategory/${"hostels"}`}
              >
                <div className="footcat">PG-Hostels</div>
              </Link>{" "}
            </span>
          </div>

          <div className="FootcatBg col-lg-12 FootcatBg-mob">
            <span className="heading1">
              Electronics{" "} &nbsp;&nbsp;
              {Electronics.slice(0, 8).map((value, index) => (
                <>
                  <Link
                    key={index}
                    onClick={() => {
                      handleClick("Electronics", value);
                    }}
                    to={`/ads-listing/category/${"Electronics"}/subcategory/${value}`}
                  >
                    <div className="footcat marginleft-2">{value}</div>
                  </Link>
                  {index < Electronics.length - 1 && (
          <Divider
            type="vertical"
            style={{
              backgroundColor: "grey",
              margin:'4px',
            }}
          />
        )}
                </>
              ))}
            </span>
          </div>

          <div className="FootcatBg col-lg-12 FootcatBg-mob">
            <span className="heading1">
              Mobiles{" "} &nbsp;&nbsp;

              {Mobiles.map((value, index) => (
                <>
                  <Link
                    key={index}
                    onClick={() => {
                      handleClick("Mobiles", value);
                    }}
                    to={`/ads-listing/category/${"Mobiles"}/subcategory/${value}`}
                  >
                    <div className="footcat">{value}</div>
                  </Link>
                  {index < Mobiles.length - 1 && (
          <Divider
            type="vertical"
            style={{
              backgroundColor: "grey",
              margin:'4px',
            }}
          />
        )}
                </>
              ))}
            </span>
          </div>

          <div className="FootcatBg col-lg-11 FootcatBg-mob">
            <span className="heading1">
              Furnitures{""} &nbsp;&nbsp;

              {Furniture.map((value, index) => (
                <>
                  <Link
                    key={index}
                    onClick={() => {
                      handleClick("Furniture", value);
                    }}
                    to={`/ads-listing/category/${"Furniture"}/subcategory/${value}`}
                  >
                    <div className="footcat">{value}</div>
                  </Link>
                  {index < Furniture.length - 1 && (
          <Divider
            type="vertical"
            style={{
              backgroundColor: "grey",
              margin:'4px',
            }}
          />
        )}
                </>
              ))}
            </span>
          </div>

          {/* <div className="FootcatBg col-lg-12 FootcatBg-mob">
            <span className="heading1">Jobs{""}
            <Divider
            type="vertical"
            style={{
              background:"grey",
              height:"20px",
              }}
              />
            {Jobs.map((value, index) => (
              <>
                <Link
                  key={index}
                  onClick={() => {
                    handleClick("Jobs", value);
                  }}
                  to={`/ads-listing/category/${"Jobs"}/subcategory/${value}`}
                >
                  <div className="footcat mx-2">{value}</div>
                </Link>
              </>
            ))}
            </span>
          </div> */}

          <div className="FootcatBg col-lg-12 FootcatBg-mob">
            <span className="heading1">
              Bikes{""} &nbsp;&nbsp;

              <Link
                onClick={() => {
                  handleClick("Bike", "Bikes");
                }}
                to={`/ads-listing/category/${"Bike"}/subcategory/${"Bikes"}`}
              >
                <div className="footcat">Bikes</div>{" "}
              </Link>{" "}
              <Divider
            type="vertical"
            style={{
              backgroundColor: "grey",
              margin:'4px',
            }}
          />
              <Link
                onClick={() => {
                  handleClick("Bike", "Spare Parts - Accessories");
                }}
                to={`/ads-listing/category/${"Bike"}/subcategory/${"Spare Parts"}`}
              >
                <div className="footcat">Spare Parts - Accessories</div>
              </Link>{" "}
            </span>
          </div>

          <div className="FootcatBg col-lg-12 FootcatBg-mob">
            <span className="heading1">
              Cars{""}
              &nbsp;&nbsp;

              <Link
                onClick={() => {
                  handleClick("Cars", "Cars");
                }}
                to={`/ads-listing/category/${"Car"}/subcategory/${"Cars"}`}
              >
                <div className="footcat">Cars</div>{" "}
              </Link>{" "}
          <Divider
            type="vertical"
            style={{
              backgroundColor: "grey",
              margin:'4px',
            }}
          />
              <Link
                onClick={() => {
                  handleClick("Cars", "Spare Parts - Accessories");
                }}
                to={`/ads-listing/category/${"Car"}/subcategory/${"Spare Parts"}`}
              >
                <div className="footcat">Spare Parts - Accessories</div>
              </Link>
            </span>
          </div>

          <div className="FootcatBg col-lg-12 FootcatBg-mob">
            <span className="heading1">
              Pets{""} &nbsp;&nbsp;

              {Pets.map((value, index) => (
                <>
                  <Link
                    key={index}
                    onClick={() => {
                      handleClick("Pets", value);
                    }}
                    to={`/ads-listing/category/${"Pets"}/subcategory/${value}`}
                  >
                    <div className="footcat">{value}</div>
                  </Link>
                  {index < Pets.length - 1 && (
          <Divider
            type="vertical"
            style={{
              backgroundColor: "grey",
              margin:'4px',
            }}
          />
        )}
                </>
              ))}
            </span>
          </div>

          <div className="FootcatBg col-lg-12 FootcatBg-mob">
            <span className="heading1">
              Services{""} &nbsp;&nbsp;

              {Services.map((value, index) => (
                <>
                  <Link
                    key={index}
                    onClick={() => {
                      handleClick("Services", value);
                    }}
                    to={`/ads-listing/category/${"Services"}/subcategory/${value}`}
                  >
                    <div className="footcat">{value}</div>
                  </Link>
                  {index < Services.length - 1 && (
          <Divider
            type="vertical"
            style={{
              backgroundColor: "grey",
              margin:'4px',
            }}
          />
        )}
                </>
              ))}
            </span>
          </div>
          <div className="FootcatBg col-lg-12 FootcatBg-mobs">
            <span className="heading1">
              Education &nbsp;&nbsp;
              {Education.map((value, index) => (
                <>
                  <Link
                    key={index}
                    onClick={() => {
                      handleClick("Education", value);
                    }}
                    to={`/ads-listing/category/${"Education"}/subcategory/${value}`}
                  >
                    <div className="footcat">{value}</div>
                  </Link>
                  {index < Education.length - 1 && (
          <Divider
            type="vertical"
            style={{
              backgroundColor: "grey",
              margin:'4px',
            }}
          />
        )}
                </>
              ))}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footcat;
