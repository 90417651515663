import React from 'react';
import "./pop.css"
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { BsCoin } from 'react-icons/bs'; // Import the gold coin icon

export const Pop = () => {
  return (
    <div className="ad-card" style={{padding:"10px",background: "linear-gradient(to right, #007BFF, #00BFFF)"}}>
      <div className="glassmorphism" style={{
        border:"solid 1px gold",borderRadius:"16px"
      }}>
      <div className="start-selling-content">
        <div className='ad-image'>
        <p style={{color:"white"}}>Reach a global audience and grow your business.</p>

        <p style={{color:"gold"}}>Join our platform and start selling today!</p>
        </div>
        
        {isMobile ?(
        <Link to="/allcatforpost/"><button className="start-selling-button" style={{border:"solid 1px white"}}>Sell</button></Link>):(<Link to="/allcatforpost/"><button className="start-selling-button" style={{border:"solid 1px white"}}>Start Selling</button></Link>)}
      </div>
    </div>
    </div>
  );
};
export const Pop2 = () => {
  return (
    <div className="ad-card" style={{padding:"10px",background: "linear-gradient(to right, #003366, #336699)"
  }}>
      <div className="glassmorphism" style={{
        border:"solid 1px gold",borderRadius:"16px"
      }}>
      <div className="start-selling-content">
        <div className='ad-image'>
        <p style={{color:"white"}}>Upgrade Your Unlock Premium Benefits Today</p>
        <BsCoin style={{color:"gold",fontSize:"30px"}} />
        <p style={{color:"gold"}}>Get Your Basic plan in just ₹99</p>
        </div>
        {isMobile ?(
        <Link to="/pricing"><button className="start-selling-button" style={{border:"solid 1px white" ,backgroundColor:"gold", color:"black"}}>Buy</button></Link>):(<Link to="/pricing"><button className="start-selling-button" style={{border:"solid 1px white",backgroundColor:"gold", color:"black"}}>Buy Now</button></Link>)}
      </div>
    </div>
    </div>
  );
};

