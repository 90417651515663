// import React, { useState, useEffect } from 'react';
import './Leads.css';
import { Checkbox } from 'antd';
// import { localUrl, url } from "../../../src/component/env";
// import { getUserPlan } from '../../store/allPlanDetails';
// import { userProfileDataApi } from "../../../src/store/UserProfileDetailsSlice";
// import { UserContext } from '../../App';
// import { useContext } from 'react';
// import { useDispatch } from 'react-redux';



import React, { useContext, useEffect, useState } from "react";
import "../../../src/component/Account/Dashboard/profileDashboard/profileDashboard.css";
import Profile from "../../../src/component/Account/Profile/Profile";
// import { decrypt } from "../../../Base/encryptDecrypt/encryptDecrypt";
import { localUrl, url } from "../../../src/component/env";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../../../src/App";
import { Button, Form } from "antd";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { getUserPlan } from "../../../src/store/allPlanDetails";
import { userProfileDataApi } from "../../../src/store/UserProfileDetailsSlice";
import { isMobile } from "react-device-detect";
import Featured from "./Featured";
import { AiOutlineCloseSquare } from "react-icons/ai";
import { GoVerified } from "react-icons/go";
import Userdash from "./Userdash";
import "./Bussinessdash.css"



const FeaturedLead =()=> {

  const [tblData, setTblData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const dispatch = useDispatch();


  const handleCheckboxChange = (event, rowIndex) => {
    if (event.target.checked) {
      setSelectedRows([...selectedRows, rowIndex]);
    } else {
      setSelectedRows(selectedRows.filter((index) => index !== rowIndex));
    }
  };
  // var [wishlistmain , setWishlist] = useState([]);
  const dataUser = useContext(UserContext);
  // let barererToken = "Bearer " + localStorage.getItem("access_token");
  // const [lastLoginTime, setLastLoginTime] = useState(null);
  // const imageValue = useSelector((state) => state.profile);

  let userId = localStorage.getItem("userid");

  const handleDownload = () => {
    const selectedData = selectedRows.map((rowIndex) => {
      return tblData[rowIndex];
    });
    // create a file to download
    const fileData = JSON.stringify(selectedData);
    const blob = new Blob([fileData], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "table-data.json";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  // useEffect(() => {
  //   var formdata = new FormData();
  //   formdata.append("userid", "46");

  //   var requestOptions = {
  //     method: 'POST',
  //     body: formdata,
  //     redirect: 'follow'
  //   };
  const [loading2, setLoading2] = useState(false);
  const getUserData = async (id) => {
    setLoading2(true);
    try {
      await dispatch(getUserPlan(userId));
      setLoading2(false);
    } catch {
      setLoading2(false);
    }
  };

  useEffect(() => {
    dispatch(userProfileDataApi(userId));
  }, [userId]);

  useEffect(() => {
    getUserData(userId);
  }, [userId]);
  const [business, setBusiness] = useState(); // Initialize with null

  useEffect(() => {
    var formdata = new FormData();
    formdata.append("userid",userId);
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch(url + "api/adsapi/checkBusinessPlan", requestOptions)
      .then(response => response.json())
      .then(data => {
        setBusiness(data);

      })
      .catch(error => {
        console.log('error', error);
      });
  }, []);

  console.log(tblData,"tbl");

  return (
    <>
      <h5 style={{fontFamily: 'Arial'}}><ins>Lead Details :</ins></h5>
      <div className="table-container123">
      {business && (

        <table className="table123">
          <thead>
            <tr>
              <th className='text-black'>Plan Types</th>
              <th className='text-black'>Total Ads</th>
              <th className='text-black'>Response</th>
              <th className='text-black'>Days Limit</th>
              <th className='text-black'>Posted Ads</th>
              <th className='text-black'>Left Ads</th>
              <th className='text-black'>Validity</th>
              <th className='text-black'>TeleSupport</th>
              <th className='text-black'>Chat Support</th>
              <th className='text-black'>Dedicated RM</th>
              <th className='text-black'>Website</th>
            </tr>
          </thead>
          <tbody>
            {business.leftPlan.map((plan, index) => (
              <tr key={plan}>
                <td className="text-black m-0 p-0">{plan}</td>
                {/* <Checkbox   
            checked={selectedRows.includes(index)}
            onChange={(event) => handleCheckboxChange(event, index)} /> */}
                

                <td className="text-black m-0 p-0">{business.planDataDetails[plan]?.totalAds}</td>
                <td className="text-black m-0 p-0">{business.planDataDetails[plan]?.reponse}</td>
                <td className="text-black m-0 p-0">{business.planDataDetails[plan]?.limitDays}</td>
                <td className="text-black m-0 p-0">{business.planDataDetails[plan]?.PostedregualAds}</td>
                <td className="text-black m-0 p-0">{business.planDataDetails[plan]?.leftRegularAds}</td>
                <td className="text-black m-0 p-0">{business.planDataDetails[plan]?.validity} days</td>
                <td className="text-black m-0 p-0">{business.planDataDetails[plan]?.TeleSupport?.toString()}</td>
                <td className="text-black m-0 p-0">{business.planDataDetails[plan]?.chatSupport?.toString()}</td>
                <td className="text-black m-0 p-0">{business.planDataDetails[plan]?.DeticatedRm?.toString()}</td>
                <td className="text-black m-0 p-0">{business.planDataDetails[plan]?.Hol9Web?.toString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
        )}
        {/* <button onClick={handleDownload} className="btnhead">Download Selected Rows</button> */}
      </div>
    </>
  );
}

export default FeaturedLead;
