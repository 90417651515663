import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getExtraField } from "../../../../store/ProductFilterSlice"; // Importing getExtraField function from ProductFilterSlice
import { addSearchData } from "../../../../store/SearchCategory/searchCategorySlice"; // Importing addSearchData function from searchCategorySlice
import {
  All,
  Education,
} from "../../../env";
import { stateMain, locationData } from "../../../Location";
import "./SearchBox.css";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { Checkbox, Row, Col, Select, Input } from "antd";

const { Option } = Select;

const SearchBox = (props) => {
  // State variables
  const [subCategoryType, setsubCategoryType] = useState(null); // State for subcategory type
  const [subCategoryValue, setsubCategoryValue] = useState(null);
  const [rangeValues, setRangeValues] = useState({ min: 100, max: 1000000000 }); // State for subcategory value
  const [searchSending, setsearchSending] = useState({
    subCategoryType: null,
    subCategoryValue: null,
    locationDataMain: null,
    minPrice: null,
    maxPrice: null,
    searchValue: null,
    category: "Education",
  });
  const [ExtraFieldCopy, setExtraFieldCopy] = useState({});
  const [ExtraFieldCopy1, setExtraFieldCopy1] = useState({});
  const [ExtraFieldCopy2, setExtraFieldCopy2] = useState({});
  const [ExtraFieldCopy3, setExtraFieldCopy3] = useState({});
  const [ExtraFieldCopy4, setExtraFieldCopy4] = useState({});
  const [ExtraFieldCopy5, setExtraFieldCopy5] = useState({});
  const [ExtraFieldCopy6, setExtraFieldCopy6] = useState({});
  const [selectedage, setselectedage] = useState([]);
  const [buildup, setbuildup] = useState();
  const [rent, setrent] = useState();
  const [deposite, setdeposite] = useState();
  const [carpet, setcarpet] = useState();

  const handleDropdownChange = (value11) => {
    setbuildup({ "": value11 });
  };
  const handleDropdownChange2 = (value11) => {
    setrent({ "": value11 });
  };
  const handleDropdownChange3 = (value11) => {
    setdeposite({ "": value11 });
  };
  const handleDropdownChange1 = (value11) => {
    setcarpet({ "": value11 });
  };

  const handleTypeage = (selectedValues) => {
    setselectedage(selectedValues);
  };

  const disptach = useDispatch(); // Initializing useDispatch hook

  // Event handler for input field changes
  const handleChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    setsearchSending({ ...searchSending, [name]: value });
    disptach(getExtraField(searchSending)); // Dispatching getExtraField action
  };

  return (
    <div style={{ backgroundColor: "white", paddingTop: "20px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <h6
          name="subCategoryType"
          style={{
            padding: "10px 30px",
            color: "white",

            fontWeight: "bold",
            background: "#2c4354",
          }}
        >
          Select Your Education Mode
        </h6>
      </div>
      <br />
      <div className="dropdown bootstrap-select show-tick houzez-field-textual form-control elementor-size-sm status-js mt-2">
        {
          <div style={{ backgroundColor: "white" }}>
            <input
              className="inpstyle"
              placeholder="Select Any Category"
              type="text"
              list="subcategoryRealEstateRent"
              style={{
                padding: "10px 20px",
                width: "100%",
                zIndex: "-50px",
                marginBottom: "10px",
              }}
              name="subCategoryValue"
              onChange={handleChange}
            />
            <datalist id="subcategoryRealEstateRent">
              {!searchSending.subCategoryType ||
              searchSending.subCategoryType !== "Accessories"
                ? Education.map((result) => {
                    return (
                      <option value={result} key={result}>
                        {result}
                      </option>
                    );
                  })
                : null}
            </datalist>
          </div>
        }
      </div>
      <br />

      {searchSending.subCategoryValue === "Career Counseling" ||
      searchSending.subCategoryValue === "Certification & Training" ||
      searchSending.subCategoryValue === "Competative Exams Coaching" ? (
        <>
          <div className="topcheck">
            <div>
              <b style={{ color: "#0a1e40" }}>Career Stream</b>
              <Checkbox.Group style={{ marginTop: "10px" }}>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Checkbox
                      value="Defense"
                      onClick={() => {
                        setExtraFieldCopy({ "Career Stream": "Defense " });
                      }}
                    >
                      Defense
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Administrative Services"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Career Stream": "Administrative Services",
                        });
                      }}
                    >
                      Administrative Services
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Engineering and Medical"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Career Stream": "Engineering and Medical",
                        });
                      }}
                    >
                      Engineering and Medical
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Teaching"
                      onClick={() => {
                        setExtraFieldCopy({ "Career Stream": "Teaching" });
                      }}
                    >
                      Teaching
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Other Staff"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Career Stream": "Other Staff",
                        });
                      }}
                    >
                      Other Staff
                    </Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </div>
            <div>
              <div>
                <b style={{ color: "#0a1e40" }}>Delivery Mode</b>
                <Checkbox.Group
                  value={selectedage}
                  onChange={handleTypeage}
                  style={{ marginTop: "10px" }}
                >
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Checkbox
                        value="Classroom"
                        onClick={() => {
                          setExtraFieldCopy1({
                            "Delivery Mode": "Classroom",
                          });
                        }}
                      >
                        Classroom
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="Correspondence"
                        onClick={() => {
                          setExtraFieldCopy1({
                            "Delivery Mode": "Correspondence",
                          });
                        }}
                      >
                        Correspondence
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="Home Tution"
                        onClick={() => {
                          setExtraFieldCopy1({
                            "Delivery Mode": "Home Tution",
                          });
                        }}
                      >
                        Home Tution
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="Online"
                        onClick={() => {
                          setExtraFieldCopy1({
                            "Delivery Mode": "Online",
                          });
                        }}
                      >
                        Online
                      </Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </div>
              <div>
                <b style={{ color: "#0a1e40" }}>Listed By</b>
                <Checkbox.Group style={{ marginTop: "10px" }}>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Checkbox
                        value="Institutions"
                        onClick={() => {
                          setExtraFieldCopy2({
                            "Listed By": "Institutions",
                          });
                        }}
                      >
                        Institutions
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="Freelancers"
                        onClick={() => {
                          setExtraFieldCopy2({
                            "Listed By": "Freelancers",
                          });
                        }}
                      >
                        Freelancers
                      </Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {searchSending.subCategoryValue === "Distance Learning Courses" ? (
        <>
          <div className="topcheck">
            <div>
              <div>
                <b style={{ color: "#0a1e40" }}>Program Type</b>
                <Checkbox.Group style={{ marginTop: "10px" }}>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Checkbox
                        value="Postgraduate Distance Program(PG)"
                        onClick={() => {
                          setExtraFieldCopy({
                            "Program Type": "Postgraduate Distance Program(PG)",
                          });
                        }}
                      >
                        Postgraduate Distance Program(PG)
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="Undergraduate Distance Program(UG)"
                        onClick={() => {
                          setExtraFieldCopy({
                            "Program Type":
                              "Undergraduate Distance Program(UG)",
                          });
                        }}
                      >
                        Undergraduate Distance Program(UG)
                      </Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </div>
              <div>
                <b style={{ color: "#0a1e40" }}>Listed By</b>
                <Checkbox.Group style={{ marginTop: "10px" }}>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Checkbox
                        value="Institutions"
                        onClick={() => {
                          setExtraFieldCopy2({
                            "Listed By": "Institutions",
                          });
                        }}
                      >
                        Institutions
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="Freelancers"
                        onClick={() => {
                          setExtraFieldCopy2({
                            "Listed By": "Freelancers",
                          });
                        }}
                      >
                        Freelancers
                      </Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </div>
            </div>
            <div>
              <div>
                <b style={{ color: "#0a1e40" }}>Delivery Mode</b>
                <Checkbox.Group
                  value={selectedage}
                  onChange={handleTypeage}
                  style={{ marginTop: "10px" }}
                >
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Checkbox
                        value="Distance"
                        onClick={() => {
                          setExtraFieldCopy1({
                            "Property Age": "Distance",
                          });
                        }}
                      >
                        Distance
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="Correspondence"
                        onClick={() => {
                          setExtraFieldCopy1({
                            "Property Age": "Correspondence",
                          });
                        }}
                      >
                        Correspondence
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="Hybrid"
                        onClick={() => {
                          setExtraFieldCopy1({
                            "Property Age": "Hybrid",
                          });
                        }}
                      >
                        Hybrid
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="Online Only"
                        onClick={() => {
                          setExtraFieldCopy1({
                            "Property Age": "Online Only",
                          });
                        }}
                      >
                        Online Only
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="Regular"
                        onClick={() => {
                          setExtraFieldCopy1({
                            "Property Age": "Regular",
                          });
                        }}
                      >
                        Regular
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="Part Time"
                        onClick={() => {
                          setExtraFieldCopy1({
                            "Property Age": "Part Time",
                          });
                        }}
                      >
                        Part Time
                      </Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {searchSending.subCategoryValue === "Entrance Exam Coaching" ? (
        <>
          <div className="topcheck">
            <div>
              <b style={{ color: "#0a1e40" }}>Career Stream</b>
              <Checkbox.Group style={{ marginTop: "10px" }}>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Checkbox
                      value="Accounting & Finance"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Career Stream": "Accounting & Finance ",
                        });
                      }}
                    >
                      Accounting & Finance
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Fashion and Design"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Career Stream": "Fashion and Design",
                        });
                      }}
                    >
                      Fashion and Design
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Engineering"
                      onClick={() => {
                        setExtraFieldCopy({ "Career Stream": "Engineering" });
                      }}
                    >
                      Engineering
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Law"
                      onClick={() => {
                        setExtraFieldCopy({ "Career Stream": "Law" });
                      }}
                    >
                      Law
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Management"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Career Stream": "Management",
                        });
                      }}
                    >
                      Management
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Medical"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Career Stream": "Medical",
                        });
                      }}
                    >
                      Medical
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Study Abroad"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Career Stream": "Study Abroad",
                        });
                      }}
                    >
                      Study Abroad
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Other Exam"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Career Stream": "Other Exam",
                        });
                      }}
                    >
                      Other Exam
                    </Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </div>
            <div>
              <div>
                <b style={{ color: "#0a1e40" }}>Delivery Mode</b>
                <Checkbox.Group
                  value={selectedage}
                  onChange={handleTypeage}
                  style={{ marginTop: "10px" }}
                >
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Checkbox
                        value="Classroom"
                        onClick={() => {
                          setExtraFieldCopy1({
                            "Delivery Mode": "Classroom",
                          });
                        }}
                      >
                        Classroom
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="Correspondence"
                        onClick={() => {
                          setExtraFieldCopy1({
                            "Delivery Mode": "Correspondence",
                          });
                        }}
                      >
                        Correspondence
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="Home Tution"
                        onClick={() => {
                          setExtraFieldCopy1({
                            "Delivery Mode": "Home Tution",
                          });
                        }}
                      >
                        Home Tution
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="Online"
                        onClick={() => {
                          setExtraFieldCopy1({
                            "Delivery Mode": "Online",
                          });
                        }}
                      >
                        Online
                      </Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </div>
              <div>
                <b style={{ color: "#0a1e40" }}>Listed By</b>
                <Checkbox.Group style={{ marginTop: "10px" }}>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Checkbox
                        value="Institutions"
                        onClick={() => {
                          setExtraFieldCopy2({
                            "Listed By": "Institutions",
                          });
                        }}
                      >
                        Institutions
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="Freelancers"
                        onClick={() => {
                          setExtraFieldCopy2({
                            "Listed By": "Freelancers",
                          });
                        }}
                      >
                        Freelancers
                      </Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {searchSending.subCategoryValue === "Hobby Classes" ? (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              zIndex: 10000,
            }}
          >
            <div>
              <b style={{ color: "#0a1e40" }}>Hobby</b>
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                className="classclass w-100 mb-3 mt-2"
                placeholder="Hobbies"
                options={[
                  "Chocolate Making",
                  "Cooking Class",
                  "Dance Class",
                  "Embroidery",
                  "Foreign Language Classes",
                  "Gardening",
                  "Gift Wrapping & Decoration",
                  "Guitar Classes",
                  "Handwriting Classes",
                  "Jewelery Making",
                  "Knitting",
                  "Music Class",
                  "Painting Class",
                  "Piano Classes",
                  "Pottery Class",
                  "Singing Class",
                  "Other Hobbies",
                ].map((result) => ({
                  value: result,
                  label: result,
                }))}
                onChange={handleDropdownChange}
              />
            </div>
            <div>
              <b style={{ color: "#0a1e40" }}>Eligibility</b>
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                className="classclass w-100 mb-3 mt-2"
                placeholder="Eligibility"
                options={[
                  "Kids Only",
                  "Kids and Ladies",
                  "Ladies Only",
                  "Open for all",
                ].map((result) => ({
                  value: result,
                  label: result,
                }))}
                onChange={handleDropdownChange1}
              />
            </div>
          </div>
          <div className="topcheck">
            <div>
              <b style={{ color: "#0a1e40" }}>Classes Held On</b>
              <Checkbox.Group style={{ marginTop: "10px" }}>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Checkbox
                      value="Sunday"
                      onClick={() => {
                        setExtraFieldCopy({ "Classes Held On": "Sunday " });
                      }}
                    >
                      Sunday
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Monday"
                      onClick={() => {
                        setExtraFieldCopy({ "Classes Held On": "Monday" });
                      }}
                    >
                      Monday
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Tuesday"
                      onClick={() => {
                        setExtraFieldCopy({ "Classes Held On": "Tuesday" });
                      }}
                    >
                      Tuesday
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Wednesday"
                      onClick={() => {
                        setExtraFieldCopy({ "Classes Held On": "Wednesday" });
                      }}
                    >
                      Wednesday
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Thrusday"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Classes Held On": "Thrusday",
                        });
                      }}
                    >
                      Thrusday
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Friday"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Classes Held On": "Friday",
                        });
                      }}
                    >
                      Friday
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Saturday"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Classes Held On": "Saturday",
                        });
                      }}
                    >
                      Saturday
                    </Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </div>
            <div>
              <div>
                <b style={{ color: "#0a1e40" }}>Class Timings</b>
                <Checkbox.Group
                  value={selectedage}
                  onChange={handleTypeage}
                  style={{ marginTop: "10px" }}
                >
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Checkbox
                        value="Morning"
                        onClick={() => {
                          setExtraFieldCopy1({
                            "Class Timings": "Morning",
                          });
                        }}
                      >
                        Morning
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="Afternoon"
                        onClick={() => {
                          setExtraFieldCopy1({
                            "Class Timings": "Afternoon",
                          });
                        }}
                      >
                        Afternoon
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="Evening"
                        onClick={() => {
                          setExtraFieldCopy1({
                            "Class Timings": "Evening",
                          });
                        }}
                      >
                        Evening
                      </Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </div>
              <div>
                <b style={{ color: "#0a1e40" }}>Listed By</b>
                <Checkbox.Group style={{ marginTop: "10px" }}>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Checkbox
                        value="Institutions"
                        onClick={() => {
                          setExtraFieldCopy2({
                            "Listed By": "Institutions",
                          });
                        }}
                      >
                        Institutions
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="Freelancers"
                        onClick={() => {
                          setExtraFieldCopy2({
                            "Listed By": "Freelancers",
                          });
                        }}
                      >
                        Freelancers
                      </Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {searchSending.subCategoryValue === "Play Schools-Creche" ? (
        <>
          <div className="topcheck">
            <div>
              <b style={{ color: "#0a1e40" }}>Programs Offered</b>
              <Checkbox.Group style={{ marginTop: "10px" }}>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Checkbox
                      value="After School Care"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Programs Offered": "After School Care ",
                        });
                      }}
                    >
                      After School Care
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Full Time Day Care"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Programs Offered": "Full Time Day Care",
                        });
                      }}
                    >
                      Full Time Day Care
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Lower Kindergarten"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Programs Offered": "Lower Kindergarten",
                        });
                      }}
                    >
                      Lower Kindergarten
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Nursery"
                      onClick={() => {
                        setExtraFieldCopy({ "Programs Offered": "Nursery" });
                      }}
                    >
                      Nursery
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Part time Day care"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Programs Offered": "Part time Day care",
                        });
                      }}
                    >
                      Part time Day care
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Play group with parent"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Programs Offered": "Play group with parent",
                        });
                      }}
                    >
                      Play group with parent
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Pre Nursery"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Programs Offered": "Pre Nursery",
                        });
                      }}
                    >
                      Pre Nursery
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Upper Kindergarten"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Programs Offered": "Upper Kindergarten",
                        });
                      }}
                    >
                      Upper Kindergarten
                    </Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </div>
            <div>
              <div>
                <b style={{ color: "#0a1e40" }}>Education Method</b>
                <Checkbox.Group
                  value={selectedage}
                  onChange={handleTypeage}
                  style={{ marginTop: "10px" }}
                >
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Checkbox
                        value="Integrated"
                        onClick={() => {
                          setExtraFieldCopy1({
                            "Education Method": "Integrated",
                          });
                        }}
                      >
                        Integrated
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="Montessori"
                        onClick={() => {
                          setExtraFieldCopy1({
                            "Education Method": "Montessori",
                          });
                        }}
                      >
                        Montessori
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="Multiple Intelligence"
                        onClick={() => {
                          setExtraFieldCopy1({
                            "Education Method": "Multiple Intelligence",
                          });
                        }}
                      >
                        Multiple Intelligence
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="Play way"
                        onClick={() => {
                          setExtraFieldCopy1({
                            "Education Method": "Play way",
                          });
                        }}
                      >
                        Play way
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="Reggio Emilia"
                        onClick={() => {
                          setExtraFieldCopy1({
                            "Education Method": "Reggio Emilia",
                          });
                        }}
                      >
                        Reggio Emilia
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="Waldorf Steiner"
                        onClick={() => {
                          setExtraFieldCopy1({
                            "Education Method": "Waldorf Steiner",
                          });
                        }}
                      >
                        Waldorf Steiner
                      </Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              zIndex: 10000,
            }}
          >
            <div>
              <b style={{ color: "#0a1e40" }}>Min Age</b>
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                className="classclass mt-2 mb-2"
                style={{ width: "9rem" }}
                placeholder="Min Age"
                options={[
                  "5years",
                  "10years",
                  "15years",
                  "20years",
                  "25years",
                  "30years",
                  "35years",
                  "40years",
                  "45years",
                  "50years",
                  "55years",
                  "60years",
                ].map((result) => ({
                  value: result,
                  label: result,
                }))}
                onChange={handleDropdownChange}
              />
            </div>
            <div>
              <b style={{ color: "#0a1e40" }}>Max Age</b>

              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                className="classclass mt-2 mb-2"
                style={{ width: "9rem" }}
                placeholder="Max Age"
                options={[
                  "10years",
                  "15years",
                  "20years",
                  "25years",
                  "30years",
                  "35years",
                  "40years",
                  "45years",
                  "50years",
                  "55years",
                  "60years",
                ].map((result) => ({
                  value: result,
                  label: result,
                }))}
                onChange={handleDropdownChange1}
              />
            </div>
          </div>
          <div className="topcheck">
            <div>
              <b style={{ color: "#0a1e40" }}>
                Student Teacher Ratio Facilities
              </b>
              <Checkbox.Group style={{ marginTop: "10px" }}>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Checkbox
                      value="Meals & Snacks"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Student Teacher Ratio Facilities": "Meals & Snacks ",
                        });
                      }}
                    >
                      Meals & Snacks
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Medical Assistance"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Student Teacher Ratio Facilities":
                            "Medical Assistance",
                        });
                      }}
                    >
                      Medical Assistance
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Home tuition"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Student Teacher Ratio Facilities": "Home tuition",
                        });
                      }}
                    >
                      Transportation Facility
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Toilet Training"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Student Teacher Ratio Facilities": "Toilet Training",
                        });
                      }}
                    >
                      Toilet Training
                    </Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </div>
            <div>
              <div>
                <b style={{ color: "#0a1e40" }}>Listed By</b>
                <Checkbox.Group style={{ marginTop: "10px" }}>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Checkbox
                        value="Institutions"
                        onClick={() => {
                          setExtraFieldCopy2({
                            "Listed By": "Institutions",
                          });
                        }}
                      >
                        Institutions
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="Freelancers"
                        onClick={() => {
                          setExtraFieldCopy2({
                            "Listed By": "Freelancers",
                          });
                        }}
                      >
                        Freelancers
                      </Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {searchSending.subCategoryValue === "Schools & School Tuitions" ? (
        <>
          <div className="topcheck">
            <div>
              <b style={{ color: "#0a1e40" }}>School Board</b>
              <Checkbox.Group style={{ marginTop: "10px" }}>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Checkbox
                      value="CBSE"
                      onClick={() => {
                        setExtraFieldCopy({ "School Board": "CBSE " });
                      }}
                    >
                      CBSE
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="ICSE"
                      onClick={() => {
                        setExtraFieldCopy({ "School Board": "ICSE" });
                      }}
                    >
                      ICSE
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="IGCSE"
                      onClick={() => {
                        setExtraFieldCopy({ "School Board": "IGCSE" });
                      }}
                    >
                      IGCSE
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="ISC"
                      onClick={() => {
                        setExtraFieldCopy({ "School Board": "ISC" });
                      }}
                    >
                      ISC
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="STATE BOARD"
                      onClick={() => {
                        setExtraFieldCopy({
                          "School Board": "STATE BOARD",
                        });
                      }}
                    >
                      STATE BOARD
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="INTERNATIONAL BACCALAUREATE"
                      onClick={() => {
                        setExtraFieldCopy({
                          "School Board": "INTERNATIONAL BACCALAUREATE",
                        });
                      }}
                    >
                      INTERNATIONAL BACCALAUREATE
                    </Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </div>
            <div>
              <div>
                <b style={{ color: "#0a1e40" }}>Standard</b>
                <Checkbox.Group
                  value={selectedage}
                  onChange={handleTypeage}
                  style={{ marginTop: "10px" }}
                >
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Checkbox
                        value="1-5th"
                        onClick={() => {
                          setExtraFieldCopy1({
                            Standard: "1-5th",
                          });
                        }}
                      >
                        1-5th
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="5-7th"
                        onClick={() => {
                          setExtraFieldCopy1({
                            Standard: "5-7th",
                          });
                        }}
                      >
                        5-7th
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="7-10th"
                        onClick={() => {
                          setExtraFieldCopy1({
                            Standard: "7-10th",
                          });
                        }}
                      >
                        7-10th
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="1st PU"
                        onClick={() => {
                          setExtraFieldCopy1({
                            Standard: "1st PU",
                          });
                        }}
                      >
                        1st PU
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="2nd PU"
                        onClick={() => {
                          setExtraFieldCopy1({
                            Standard: "2nd PU",
                          });
                        }}
                      >
                        2nd PU
                      </Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              zIndex: 10000,
            }}
          >
            <div>
              <b style={{ color: "#0a1e40" }}>Subjects</b>
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                className="classclass w-100 mt-3 mb-2"
                placeholder="Subjects"
                options={[
                  "Accounting",
                  "Biology",
                  "Business studies",
                  "Chemistry",
                  "Commerce",
                  "Computer Science",
                  "Economics",
                  "English Grammar",
                  "English Literature",
                  "Geography",
                  "Hindi",
                  "History",
                  "Maths",
                  "Physics",
                  "Political science",
                  "Regional Indian Languages",
                  "Sociology",
                  "Other Subjects",
                ].map((result) => ({
                  value: result,
                  label: result,
                }))}
                onChange={handleDropdownChange}
              />
            </div>
          </div>
          <div className="topcheck">
            <div>
              <b style={{ color: "#0a1e40" }}>Delivery Mode</b>
              <Checkbox.Group style={{ marginTop: "10px" }}>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Checkbox
                      value="Classroom"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Delivery Mode": "Classroom ",
                        });
                      }}
                    >
                      Classroom
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Correspondence"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Delivery Mode": "Correspondence",
                        });
                      }}
                    >
                      Correspondence
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Home tuition"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Delivery Mode": "Home tuition",
                        });
                      }}
                    >
                      Home tuition
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Online"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Delivery Mode": "Online",
                        });
                      }}
                    >
                      Online
                    </Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </div>
            <div>
              <div>
                <b style={{ color: "#0a1e40" }}>Listed By</b>
                <Checkbox.Group style={{ marginTop: "10px" }}>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Checkbox
                        value="Institutions"
                        onClick={() => {
                          setExtraFieldCopy2({
                            "Listed By": "Institutions",
                          });
                        }}
                      >
                        Institutions
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="Freelancers"
                        onClick={() => {
                          setExtraFieldCopy2({
                            "Listed By": "Freelancers",
                          });
                        }}
                      >
                        Freelancers
                      </Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {searchSending.subCategoryValue === "Study Abroad Consultants" ? (
        <>
          <div className="topcheck">
            <div>
              <b style={{ color: "#0a1e40" }}>Country</b>
              <Checkbox.Group style={{ marginTop: "10px" }}>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Checkbox
                      value="Australia"
                      onClick={() => {
                        setExtraFieldCopy({ Country: "Australia " });
                      }}
                    >
                      Australia
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Canada"
                      onClick={() => {
                        setExtraFieldCopy({ Country: "Canada" });
                      }}
                    >
                      Canada
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Europe"
                      onClick={() => {
                        setExtraFieldCopy({ Country: "Europe" });
                      }}
                    >
                      Europe
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="New Zealand"
                      onClick={() => {
                        setExtraFieldCopy({ Country: "New Zealand" });
                      }}
                    >
                      New Zealand
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Singapore"
                      onClick={() => {
                        setExtraFieldCopy({
                          Country: "Singapore",
                        });
                      }}
                    >
                      Singapore
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="UK"
                      onClick={() => {
                        setExtraFieldCopy({
                          Country: "UK",
                        });
                      }}
                    >
                      UK
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="USA"
                      onClick={() => {
                        setExtraFieldCopy({
                          Country: "USA",
                        });
                      }}
                    >
                      USA
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Others"
                      onClick={() => {
                        setExtraFieldCopy({
                          Country: "Others",
                        });
                      }}
                    >
                      Others
                    </Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </div>
            <div>
              <div>
                <b style={{ color: "#0a1e40" }}>Services Offered</b>
                <Checkbox.Group
                  value={selectedage}
                  onChange={handleTypeage}
                  style={{ marginTop: "10px" }}
                >
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Checkbox
                        value="Accommodation Assistance"
                        onClick={() => {
                          setExtraFieldCopy1({
                            "Services Offered": "Accommodation Assistance",
                          });
                        }}
                      >
                        Accommodation Assistance
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="College Admission Assistance"
                        onClick={() => {
                          setExtraFieldCopy1({
                            "Services Offered": "College Admission Assistance",
                          });
                        }}
                      >
                        College Admission Assistance
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="Entrance Examination Assistance"
                        onClick={() => {
                          setExtraFieldCopy1({
                            "Services Offered":
                              "Entrance Examination Assistance",
                          });
                        }}
                      >
                        Entrance Examination Assistance
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="Student Loan Assistance"
                        onClick={() => {
                          setExtraFieldCopy1({
                            "Services Offered": "Student Loan Assistance",
                          });
                        }}
                      >
                        Student Loan Assistance
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="Student Visa Assistance"
                        onClick={() => {
                          setExtraFieldCopy1({
                            "Services Offered": "Student Visa Assistance",
                          });
                        }}
                      >
                        Student Visa Assistance
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="Travel Assistance"
                        onClick={() => {
                          setExtraFieldCopy1({
                            "Services Offered": "Travel Assistance",
                          });
                        }}
                      >
                        Travel Assistance
                      </Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </div>
            </div>
          </div>
          <div className="topcheck">
            <div>
              <b style={{ color: "#0a1e40" }}>Education Stream</b>
              <Checkbox.Group style={{ marginTop: "10px" }}>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Checkbox
                      value="Design"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Education Stream": "Design ",
                        });
                      }}
                    >
                      Design
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Engineering"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Education Stream": "Engineering",
                        });
                      }}
                    >
                      Engineering
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="MBA"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Education Stream": "MBA",
                        });
                      }}
                    >
                      MBA
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Medical"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Education Stream": "Medical",
                        });
                      }}
                    >
                      Medical
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Others"
                      onClick={() => {
                        setExtraFieldCopy({
                          "Education Stream": "Others",
                        });
                      }}
                    >
                      Others
                    </Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </div>
            <div>
              <div>
                <b style={{ color: "#0a1e40" }}>Listed By</b>
                <Checkbox.Group style={{ marginTop: "10px" }}>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Checkbox
                        value="Institutions"
                        onClick={() => {
                          setExtraFieldCopy2({
                            "Listed By": "Institutions",
                          });
                        }}
                      >
                        Institutions
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="Freelancers"
                        onClick={() => {
                          setExtraFieldCopy2({
                            "Listed By": "Freelancers",
                          });
                        }}
                      >
                        Freelancers
                      </Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {searchSending.subCategoryValue === "Text books & Study Material" ? (
        <>
          <div className="topcheck">
            <div>
              <b style={{ color: "#0a1e40" }}>Product Type</b>
              <Checkbox.Group style={{ marginTop: "10px" }}>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Checkbox
                      value="Book"
                      onClick={() => {
                        setExtraFieldCopy({ "Product Type": "Book " });
                      }}
                    >
                      Book
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Calculator"
                      onClick={() => {
                        setExtraFieldCopy({ "Product Type": "Calculator" });
                      }}
                    >
                      Calculator
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Study Material"
                      onClick={() => {
                        setExtraFieldCopy({ "Product Type": "Study Material" });
                      }}
                    >
                      Study Material
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Others"
                      onClick={() => {
                        setExtraFieldCopy({ "Product Type": "Others" });
                      }}
                    >
                      Others
                    </Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </div>
            <div>
              <div>
                <b style={{ color: "#0a1e40" }}>Listed By</b>
                <Checkbox.Group style={{ marginTop: "10px" }}>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Checkbox
                        value="Institutions"
                        onClick={() => {
                          setExtraFieldCopy2({
                            "Listed By": "Institutions",
                          });
                        }}
                      >
                        Institutions
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="Freelancers"
                        onClick={() => {
                          setExtraFieldCopy2({
                            "Listed By": "Freelancers",
                          });
                        }}
                      >
                        Freelancers
                      </Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {searchSending.subCategoryValue === "Vocational Skill Training" ? (
        <>
          <div className="topcheck">
            <div>
              <b style={{ color: "#0a1e40" }}>Industry</b>
              <Checkbox.Group style={{ marginTop: "10px" }}>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Checkbox
                      value="Aviation"
                      onClick={() => {
                        setExtraFieldCopy({ Industry: "Aviation " });
                      }}
                    >
                      Aviation
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Computer & IT"
                      onClick={() => {
                        setExtraFieldCopy({ Industry: "Computer & IT" });
                      }}
                    >
                      Computer & IT
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Electronics& Appliances Repair"
                      onClick={() => {
                        setExtraFieldCopy({
                          Industry: "Electronics& Appliances Repair",
                        });
                      }}
                    >
                      Electronics& Appliances Repair
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Health & Beauty"
                      onClick={() => {
                        setExtraFieldCopy({ Industry: "Health & Beauty" });
                      }}
                    >
                      Health & Beauty
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Industrial Training"
                      onClick={() => {
                        setExtraFieldCopy({
                          Industry: "Industrial Training",
                        });
                      }}
                    >
                      Industrial Training
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Medical"
                      onClick={() => {
                        setExtraFieldCopy({
                          Industry: "Medical",
                        });
                      }}
                    >
                      Medical
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      value="Other Industries"
                      onClick={() => {
                        setExtraFieldCopy({
                          Industry: "Other Industries",
                        });
                      }}
                    >
                      Other Industries
                    </Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </div>
            <div>
              <div>
                <b style={{ color: "#0a1e40" }}>Key Features</b>
                <Checkbox.Group
                  value={selectedage}
                  onChange={handleTypeage}
                  style={{ marginTop: "10px" }}
                >
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Checkbox
                        value="Apprenticeship Support"
                        onClick={() => {
                          setExtraFieldCopy1({
                            "Key Features": "Apprenticeship Support",
                          });
                        }}
                      >
                        Apprenticeship Support
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="Job Placement Support"
                        onClick={() => {
                          setExtraFieldCopy1({
                            "Key Features": "Job Placement Support",
                          });
                        }}
                      >
                        Job Placement Support
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="(PMKVYA) Pradhan Mantri Kaushal Vikas Yojana Authorized"
                        onClick={() => {
                          setExtraFieldCopy1({
                            "Key Features":
                              "(PMKVYA) Pradhan Mantri Kaushal Vikas Yojana Authorized",
                          });
                        }}
                      >
                        (PMKVYA) Pradhan Mantri Kaushal Vikas Yojana Authorized
                      </Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </div>
              <div>
                <b style={{ color: "#0a1e40" }}>Listed By</b>
                <Checkbox.Group style={{ marginTop: "10px" }}>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Checkbox
                        value="Institutions"
                        onClick={() => {
                          setExtraFieldCopy2({
                            "Listed By": "Institutions",
                          });
                        }}
                      >
                        Institutions
                      </Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox
                        value="Freelancers"
                        onClick={() => {
                          setExtraFieldCopy2({
                            "Listed By": "Freelancers",
                          });
                        }}
                      >
                        Freelancers
                      </Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </div>
            </div>
          </div>
        </>
      ) : null}
      <div
        style={{
          display: "flex",
          marginTop: "15px",
          justifyContent: "space-around",
        }}
      >
        <input
          type="number"
          style={{ borderRadius: "10px" }}
          value={searchSending.minPrice}
          name="minPrice"
          id="form-field-field-min-area"
          className="elementor-field form-control elementor-size-sm elementor-field-textual"
          placeholder="Min Price"
          onChange={handleChange}
        />
        <input
          style={{ borderRadius: "10px" }}
          type="number"
          onChange={handleChange}
          value={searchSending.maxPrice}
          name="maxPrice"
          id="form-field-field-max-area"
          className="elementor-field form-control elementor-size-sm elementor-field-textual"
          placeholder="Max Price"
        />
      </div>
      <br />
      <input
        className="inpstyle"
        placeholder="location"
        type="text"
        list="state"
        style={{
          padding: "12px",
          width: "100%",
          zIndex: "-50px",
        }}
        name="City"
        onChange={handleChange}
      />
      <datalist id="state">
        {locationData.map((result) => {
          return <option value={result}>{result}</option>;
        })}
      </datalist>
      <br/>
      <div
        className="col-lg-3 col-sm-12"
        style={{ float: "right", marginLeft: "-20px" }}
      >
        <Link
          to={`/ads-listing/`}
          onClick={() => {
            console.log(searchSending);
            for (const key in searchSending) {
              if (
                searchSending[key]?.trim() === "" ||
                searchSending[key] === null
              ) {
                delete searchSending[key];
              }
            }
            searchSending["extraField"] = {
              ...ExtraFieldCopy,
              ...ExtraFieldCopy1,
              ...ExtraFieldCopy2,
              ...ExtraFieldCopy3,
              ...ExtraFieldCopy4,
              ...ExtraFieldCopy5,
              ...ExtraFieldCopy6,
              ...buildup,
              ...carpet,
            }; // Object Assign to searchSending object

            disptach(getExtraField(searchSending)); // Dispatching getExtraField action
          }}
        >
          <Button varient="outlined" className="">
            Search
          </Button>
        </Link>
      </div>
      <br />
    </div>
  );
};

export default SearchBox;
