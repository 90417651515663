import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Dashboard from "./Dashboard";
import Sidebar from "./Sidebar";
import Bussinessadminverify from "./Bussinessadminverify";
import Adminadsverify from "./Adminadsverify";

const FullAdminDash = () => {
  const adminLogin = useSelector((state) => state.adminLogin);

  const navigate = useNavigate();

  useEffect(() => {
    const loggedInUser = localStorage.getItem("adminLoggedIn");

    if (!loggedInUser) {
      navigate("/hola9-login/");
    }
  }, [navigate]);

  return (
    <>
      <div className="container-fluid" id="main">
        <div className="row row-offcanvas row-offcanvas-left">
        {/* <Sidebar /> */}
          <Dashboard />
         
        </div>
      </div>
    </>
  );
};

export default FullAdminDash;
