import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import { BiCloset, BiDownload, BiWindowClose } from 'react-icons/bi';
import { Modal } from '@mui/material';

// Define a function to convert Base64 to Blob
const base64ToBlob = (base64, type) => {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: type });
};

const DocumentViewer = ({ documentData }) => {
  const [isImageFullscreen, setIsImageFullscreen] = useState(false);

  const handleDownload = () => {
    const base64Data = documentData.resume_base64; // Base64 data
    const blob = base64ToBlob(base64Data, 'application/octet-stream'); // You may need to specify the appropriate MIME type
    saveAs(blob, documentData.resume);
  };
  const openImageFullscreen = () => {
    setIsImageFullscreen(true);
  };

  const closeImageFullscreen = () => {
    setIsImageFullscreen(false);
  };
  const fileExtension = documentData.resume.split('.').pop().toLowerCase();

  const renderFile = () => {
    const fileExtension = documentData.resume.split('.').pop().toLowerCase();


    // if (fileExtension === 'pdf') {
    //   // Render a PDF
    //   return (
    //     <object
    //     data={`data:application/pdf;base64,${documentData.document_base64}`}
    //     type="application/pdf" // Ensure this is set to "application/pdf"
    //     width="200px"
    //     height="200px"
    //   >
    //     PDF Viewer not available
    //   </object>
      
    //   );
    // } else
    // Check the file extension and render accordingly
    if (['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension)) {
      // Render an image
      return(<> <img src={`data:image/${fileExtension};base64,${documentData.resume_base64}`} alt="Image" height={150} width={150}           onClick={openImageFullscreen}
      />  <div>
      <button onClick={handleDownload} style={{padding:"10px",color:"white",backgroundColor:"navy",borderRadius:"20px"}}><BiDownload/> Download</button>
    </div></>);
    } else {
      // For other file types, provide a download button
      return (
        <>
        <img src="https://th.bing.com/th/id/OIP.wy2cYKNnY3E99T_z_quEEwHaHa?pid=ImgDet&rs=1" alt='filr'  height={150} width={150} /> 

        <div>
               <button onClick={handleDownload} style={{padding:"10px",color:"white",backgroundColor:"navy",borderRadius:"20px"}}><BiDownload/> Download</button>

        </div>
        </>
      );
    }
  };
  return (
  
    <div>
      {renderFile()}
      <Modal open={isImageFullscreen} onClose={closeImageFullscreen} className='modl'>
        <div className="fullscreen-modal">
        <div className="close-button" onClick={closeImageFullscreen}>
            <span style={{fontSize:"20px",color:"red",float:"right"}}>X</span>
          </div>
        <img src={`data:image/${fileExtension};base64,${documentData.resume_base64}`}
            alt="Fullscreen Image" height={500} width={500}
          />
          
        </div>
      </Modal>
    </div>
  );

  
};



export default DocumentViewer;
