import React, { useEffect, useState,useRef } from "react";
import axios from "axios";
import { decrypt } from "../Base/encryptDecrypt/encryptDecrypt";
import { Button } from "react-bootstrap";
import { Category } from "../env";
import { stateMain } from "../Location";
import { localUrl } from "../env.js";
import Spiner from "../Spiner";
import { useDispatch } from "react-redux";
import { add } from "../../store/Track/trackUserSlice";
import { Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { IoVideocamOutline } from "react-icons/io5";


const AddBlog = () => {
  let navigate = useNavigate();
  useEffect(() => {
    const loggedInUser = localStorage.getItem("adminLoggedIn");

    if (!loggedInUser) {
      navigate("/hola9-login/");
    }
  }, [navigate]);
  // const [id, setId] = useState();
  const USERdta = decrypt("userdata");
  const [image, setImage] = useState(null);
  const [image2, setImage2] = useState(null);
  const [title, setTitle] = useState(null);
  const [subtitle, setSubtitle] = useState(null);
  const [author, setAuthor] = useState(null);
  const [description, setDescription] = useState(null);
  const [state, setState] = useState();
  const [category, setcategory] = useState(null);
  const [date, setDate] = useState("");
  const [storeadsFlag, setstoreadsFlag] = useState(false);
  const [success, setsuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [city, setCity] = useState(null);
  const [error, setError] = useState(null);
  const [SelectedStates, SetSelectedStates] = useState();
  // const [buttoncheck, setbuttoncheck] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(add({ view: ["AddBlog"] }));
  }, []);

  const addNewBlog = (e) => {
    e.preventDefault();
    setstoreadsFlag(true);
    let formdata = new FormData();
    // formdata.append("image", fileInput.files[0], "/C:/Users/USER/Pictures/Screenshots/Screenshot (4).png");
    formdata.append("title", title);
    // formdata.append("subtitle", subtitle);
    formdata.append("description", description);
    console.log('state', SelectedStates);
    formdata.append("state", SelectedStates);
    formdata.append("city", city);
    formdata.append("user", 96);
    formdata.append("category", category);
    formdata.append("published_time", date);
    formdata.append("author", author);
    if (image !== null) {
      formdata.append("image", image);
    }
    if (image2 !== null) {
      formdata.append("imagesecond", image2);
    }
    setLoading(true);

    axios(`${localUrl}blogsapi/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: formdata,
    }).then((response) => {
      console.log('response', response);
      setsuccess(true);
      setstoreadsFlag(false);
      setLoading(false);
      //  navigate.push("/");
    })
      .catch((error) => {
        setError(error.message);
        setstoreadsFlag(false);
        setLoading(false);
      });
  };
  useEffect(() => {
    var today = new Date();
    let date = today.getDate();
    let month = today.getMonth() + 1;
    let year = today.getFullYear();
    var datevalue = `${year}-${month < 10 ? `0${month}` : `${month}`}-${date < 10 ? `0${date}` : `${date}`
      }`;
    setDate(datevalue);
  }, []);

  useEffect(() => {
    if (localStorage.getItem("access_token") != null) {
      let barererToken = "Bearer " + localStorage.getItem("access_token");
      var myHeaders = new Headers();
      myHeaders.append("Authorization", barererToken);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      setLoading(true);
      fetch(localUrl + "user/profile/", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          // setprofileform(profileform.name=result.name)
          // setId(result.id)
          setAuthor(result.name);
          setLoading(false);
          setError(null);
        })
        .catch((error) => {
          setError(error.message);
          setLoading(false);
        });
    }
  }, []);
  document.title = "Hola9 - AddBlog";
  // const style = { position: "fixed", top: "45%", left: "59%", transform: "translate(-50%, -50%)" };

  // video logic start
  const [showVideoForm, setShowVideoForm] = useState(false);

  const handleCheckboxChange = (e) => {
    setShowVideoForm(e.target.checked);
  };

  const fileInputRef = useRef(null);
  const [uploadedVideos, setUploadedVideos] = useState([]);

  const handleIconClick = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  const handleRemoveVideo = (index) => {
    const newUploadedVideos = [...uploadedVideos];
    newUploadedVideos.splice(index, 1);
    setUploadedVideos(newUploadedVideos);
  };

  const handleFileChange = async (e) => {
    const files = e.target.files;
    let validVideo = null;

    for (let i = 0; i < files.length; i++) {
      const video = document.createElement("video");
      video.src = URL.createObjectURL(files[i]);

      // Wait for metadata to be loaded
      await new Promise((resolve) => {
        video.onloadedmetadata = resolve;
      });

      // Check if video duration is less than or equal to 30 seconds
      if (video.duration <= 30) {
        validVideo = files[i];
        break; // Stop checking further files once a valid video is found
      }
    }

    // Update state with the valid uploaded video (clearing previous videos)
    setUploadedVideos(validVideo ? [validVideo] : []);
  };
  // video logic end
  return (
    <>
      {/* <div  style={style}>
      {loading ? <ClipLoader color={"#123abc"} loading={!storeadsFlag} />: ""}
      </div> */}
      <h1 style={{ fontFamily: "verdana", fontSize: "28px", fontWeight: "bold", marginBottom: "10px" }}>Add blog</h1>
      <div className="shadowssss">
        {loading && <Spiner />}
        {/* <div className="container"> */}

        {/* <div style={{marginTop:'-50px'}}>  */}
        <form>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div className="form-group  col-md-6 col-sm-12">
              <label>Image</label>
              <input
                type="file"
                className="form-control"
                onChange={(e) => setImage(e.target.files[0])}
                required
              />
            </div>
            <div className="form-group col-md-6 col-sm-12">
              <label>Image2</label>
              <input
                type="file"
                className="form-control"
                onChange={(e) => setImage2(e.target.files[0])}
                required
              />
            </div>
          </div>
          {/* video section start */}
          <div className="row p-2">
            <div className="col-lg-12">
              <label>
                Upload Videos &nbsp;{" "}
                <input
                  type="checkbox"
                  onChange={handleCheckboxChange}
                  style={{
                    visibility: "visible",
                    opacity: "1",
                    zIndex: "1",
                    background: "black",
                    WebkitAppearance: "checkbox",
                    cursor: "pointer",
                    position: "relative",
                  }}
                />{" "}
              </label>
              {showVideoForm && (
                <>
                  <div
                    style={{
                      border: "2px solid black",
                      padding: "20px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {uploadedVideos.map((video, index) => (
                      <div
                        key={index}
                        style={{
                          position: "relative",
                          marginRight: "10px",
                        }}
                      >
                        <video
                          src={URL.createObjectURL(video)}
                          width="80"
                          height="60"
                          controls
                        />
                        <p
                          onClick={() => handleRemoveVideo(index)}
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            background: "darkred",
                            border: "none",
                            cursor: "pointer",
                            color: "white",
                            fontSize: "10px",
                            borderRadius: "50%",
                            padding: "0 3px",
                            marginTop: "-7px",
                            marginRight: "3px",
                          }}
                        >
                          X
                        </p>
                      </div>
                    ))}

                    <label
                      htmlFor="fileInput"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        cursor: "pointer",
                      }}
                    >
                      <IoVideocamOutline
                        style={{ fontSize: "60px" }}
                        onClick={handleIconClick}
                      />
                      <p style={{ fontSize: "75%", marginBottom: "0" }}>
                        ADD VIDEO
                      </p>
                      {/* <i style={{color:'red', fontSize:'60%'}}>video length should be below 30seconds</i> */}
                    </label>

                    <input
                      ref={fileInputRef}
                      type="file"
                      accept="video/*"
                      id="fileInput"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          {/* video section end */}
          <div className="form-group col-lg-6 col-md-6 col-sm-12">
            <label className="mb-0 mt-2">Category</label>

            <select
              className="form-control"
              value={category}
              onChange={(e) => setcategory(e.target.value)}
            >
              <option value=""></option>
              {Category.map((result) => {
                return <option value={result}>{result}</option>;
              })}
            </select>
            <label className="mb-0 mt-2">State</label>
            {/* <input required type="Subtitle" name="subtitle" className="form-control" value={subtitle}  placeholder="enter title here"   onChange={(e) => setSubtitle(e.target.value)} /> */}
            <select
              className="form-control"
              placeholder="enter state here"
              value={SelectedStates}
              onChange={(e) => SetSelectedStates(e.target.value)}
            >
              <option value=""></option>
              {Object.keys(stateMain)?.map((result) => {
                return <option value={result}>{result}</option>;
              })}
            </select>
            <label className="mb-0 mt-2">Author</label>
            <input
              type="text"
              className="form-control"
              list="state"
              name="state"
              placeholder="enter state here"
              // value={USERdta.name}
              value={author}
              disabled={true}
              onChange={(e) => setAuthor(e.target.value)}
            />
            <datalist id="state">
              {Object.keys(stateMain)?.map((result) => {
                return <option value={result}>{result}</option>;
              })}
            </datalist>
          </div>
          {/* Start */}
          <div className="form-group col-lg-6 col-md-6 col-sm-12">
            <label className="mb-0 mt-2">Title</label>
            <input
              required
              type="Title"
              name="title"
              className="form-control"
              value={title}
              placeholder="enter title here"
              onChange={(e) => setTitle(e.target.value)}
            />
            <label className="mb-0 mt-2">City</label>

            <select
              className="form-control"
              placeholder="enter city here"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            >
              <option value=""></option>
              {/* Object.keys(stateMain).map(res=>stateMain[res]).flat(Infinity).map(val=>val) */}
              {Object.keys(stateMain)
                .filter((StateNames) => StateNames === SelectedStates)
                .map((StateNames) =>
                  stateMain[StateNames].map((result) => (
                    <option key={result} value={result}>{result}</option>
                  ))
                )}
            </select>
            {/* End */}
            <label className="mb-0 mt-2">Date</label>
            <input
              required
              type="text"
              name="date"
              className="form-control"
              value={date}
              placeholder="enter title here"
              onChange={(e) => setDate(e.target.value)}
              disabled
            />
          </div>
          {/* <div className="form-group col-lg-6 col-md-6 col-sm-12"> */}

          {/* <label  className="mb-0 mt-2">user</label>
            <input
              type="text"
              name="id"
              placeholder="enter date here"
              value={USERdta.name}
            /> */}
          {/* </div> */}

          <div className="form-group col-lg-12 col-sm-12">
            <label className="mb-0 mt-2">Description</label>
            <textarea
              required
              type="description"
              name="title"
              className="form-control"
              placeholder="enter description here"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          {/* <div className="form-group col-lg-12 col-md-12 col-sm-12">
            
          </div> */}
          <div className="col-lg-12 col-sm-12  mt-2 d-flex ">
            {/* <Button
              disabled={!description}
              className="btn btn-block style1 "
              onClick={() => {
                setbuttoncheck("free");
              }}
              variant='outlined'
            >
              Confirm
            </Button> */}
            {/* &nbsp;&nbsp;&nbsp;       */}
            <button
              type="submit"
              className="btn btn-primary btn-block mt-8 mb-2"
              onClick={addNewBlog}
            // disabled={buttoncheck == null}
            >
              Submit Blog
            </button>
          </div>
        </form>

        {success ? (
          <Modal
            title="Blog post successfully"
            open={success}
            fill="green"
            onOk={() => {
              navigate("/blogs-listing");
            }}
            onCancel={() => navigate("/dashboard")}
          />
        ) : null}
      </div>
    </>
  );
};

export default AddBlog;
